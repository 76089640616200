<template>
  <div
    id="images"
    class="d-flex justify-center mt-14"
  >
    <v-card
      class="article-content"
      outlined
      rounded
    >
      <v-card-title>
        <div class="d-flex flex-column">
          <span class="text-h5">
            {{ $t('article.images.title') }}
          </span>
          <span class="text-body-2 mt-2">
            {{ $t('article.images.description') }}
          </span>
        </div>
      </v-card-title>
      <v-card-text
        v-if="storeImages.length"
        class="pb-2 d-flex flex-column"
      >
        <div class="d-flex flex-row flex-grow-0">
          <div
            v-if="isLargeScreen"
            class="d-flex flex-column flex-grow-0"
          >
            <div style="height: 22px; min-width: 40px;" />
            <div
              v-for="index in storeImages.length"
              :key="index"
              class="d-flex align-center justify-end font-weight-bold text-body-1 px-2"
              style="height: 40px; margin-bottom: 18px; min-width: 40px;"
            >
              #{{ index }}
            </div>
          </div>
          <div
            class="d-flex flex-column flex-grow-1"
            style="min-width: 0;"
          >
            <div
              v-if="isLargeScreen"
              class="d-flex flex-row"
              style="margin-right: 88px;"
            >
              <span class="w-full mr-2">
                {{ $t('article.images.alt.header') }}
              </span>
              <span class="w-full ml-2">
                {{ $t('article.images.url.header') }}
              </span>
            </div>
            <div
              v-for="(image, index) in storeImages"
              :key="index"
              class="d-flex flex-grow-0"
              :class="[
                isLargeScreen ? 'flex-row' : 'flex-column'
              ]"
            >
              <span
                v-if="!isLargeScreen"
                class="w-full font-weight-bold text-body-1"
              >
                #{{ index + 1 }}
              </span>
              <span
                v-if="!isLargeScreen"
                class="w-full"
              >
                {{ $t('article.images.alt.header') }}
              </span>
              <OneLineEditor
                v-model="image.alt"
                :placeholder="$t('article.images.alt.placeholder')"
                hide-details
                :words-to-highlight="wordsToHighlight"
                :class="{ 'mr-2 editor': isLargeScreen }"
                style="--offset: 52px;"
              />
              <span
                v-if="!isLargeScreen"
                class="w-full"
              >
                {{ $t('article.images.url.header') }}
              </span>
              <OneLineEditor
                v-model="image.url"
                :placeholder="$t('article.images.url.placeholder')"
                :words-to-highlight="wordsToHighlight"
                :rules="[validUrlRule]"
                :class="{ 'ml-2 editor': isLargeScreen }"
                style="--offset: 8px;"
              >
                <template #append>
                  <v-tooltip
                    content-class="previewTooltip"
                    left
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn
                        icon
                        outlined
                        elevation="0"
                        v-bind="attrs"
                        class="ml-2"
                        :disabled="!image.url.length || rules.validUrlRule(stripTagsAndEntities(image.url)) !== true"
                        style="margin-top: 2px"
                        v-on="on"
                      >
                        <v-icon>
                          preview
                        </v-icon>
                      </v-btn>
                    </template>
                    <div class="d-flex align-center previewContainer">
                      <img
                        class="previewImage"
                        :src="stripTagsAndEntities(image.url)"
                        :alt="stripTagsAndEntities(image.alt)"
                      >
                    </div>
                  </v-tooltip>
                </template>
              </OneLineEditor>
              <div class="d-flex justify-end">
                <v-tooltip
                  top
                  :disabled="!isLargeScreen"
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      :icon="isLargeScreen"
                      :outlined="isLargeScreen"
                      elevation="0"
                      v-bind="attrs"
                      :class="{
                        'ml-2': isLargeScreen,
                      }"
                      style="margin-top: 2px"
                      v-on="on"
                      @click="removeImage(index)"
                    >
                      <v-icon
                        :class="{ 'mr-2': !isLargeScreen }"
                      >
                        delete
                      </v-icon>
                      <span v-if="!isLargeScreen">
                        {{ $t('article.images.remove') }}
                      </span>
                    </v-btn>
                  </template>
                  {{ $t('article.images.remove') }}
                </v-tooltip>
              </div>
            </div>
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="px-4 pb-4 pt-0">
        <v-btn
          block
          color="primary"
          elevation="0"
          @click="addImage"
        >
          <v-icon
            class="mr-2"
            style="margin-left: -12px;"
          >
            add
          </v-icon>
          {{ $t('article.images.add') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script lang="ts">
    import Vue, { PropType } from "vue";
    import { rules } from "@/utils/rules";
    import OneLineEditor from "@/components/OneLineEditor.vue";
    import { stripTagsAndEntities } from '@/utils/string';
    

    export default Vue.extend({
        name: "ArticleImagesTable",
        components: { OneLineEditor },
        props: {
            wordsToHighlight: {
                type: Object as PropType<{ [key: string]: string[] }>,
                default: () => ({})
            },
        },
        data: () => ({
            mounted: false,
            rules: rules,
            stripTagsAndEntities: stripTagsAndEntities
        }),
        computed: {
            isLargeScreen() {
                return this.$vuetify.breakpoint.lgAndUp;
            },
            storeImages() {
                return this.$store.getters.article.images;
            }
        },
        methods: {
            addImage() {
                this.$store.commit('addArticleImage', {
                    alt: '',
                    url: ''
                });
            },
            removeImage(index: number) {
                this.$store.commit('removeArticleImage', index);
            },
            validUrlRule(url: string) {
                return rules.validUrlRule(stripTagsAndEntities(url));
            }
        },
    });
</script>

<style scoped lang="sass">
  .previewTooltip
    opacity: 1 !important

  .previewContainer
    width: 200px
    height: 200px

  .previewImage
    width: 100%
    height: 100%
    object-fit: contain

  ::v-deep .v-input > .v-input__control .v-text-field__details
    margin-bottom: 0 !important

  .w-full
    width: 100%

  .editor
    min-width: calc(50% - var(--offset))
    max-width: calc(50% - var(--offset))
</style>
