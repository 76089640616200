<template>
  <v-container
    id="keywordSelectionContainer"
    class="justify-center"
  >
    <div class="d-flex flex-column align-center text-center mt-1">
      <div class="text-h5">
        {{ $t("keywords.keywordSelection") }}
        <strong>{{ articleMetadata.selectedKeyword || articleMetadata.keyword }}</strong>
        <v-btn
          v-if="readOnly"
          class="ml-2"
          text
          @click="showAllKeywords = !showAllKeywords"
        >
          <v-icon v-if="showAllKeywords">
            expand_less
          </v-icon>
          <v-icon v-else>
            expand_more
          </v-icon>
          {{ $t("keywords.allKeywords") }}
        </v-btn>
      </div>
      <div class="my-2">
        <template v-if="!isAnalyze">
          {{ $t("keywords.keywordSelectionCaption") }}
        </template>
        <template v-else>
          {{ $t('keywords.analyze.keywordSelectionCaption.begin') }}
          <strong>{{ $t('keywords.analyze.keywordSelectionCaption.bold') }}</strong>{{ $t('keywords.analyze.keywordSelectionCaption.end') }}
        </template>
      </div>
    </div>
    <div
      v-if="!readOnly"
      class="d-flex flex-row text-center simplifyButtons my-6 justify-center flex-wrap"
      style="row-gap: 24px; column-gap: 24px;"
    >
      <v-btn
        color="accent"
        link
        :loading="loadingSave"
        class="multi-line-button pa-5 action-button"
        @click="writeNow"
      >
        <div class="d-flex flex-column">
          <span class="text-h5 mb-3 d-flex justify-center">
            {{ isAnalyze ? $t('keywords.analyze.analyzeNow') : $t('keywords.simplify.writeNow') }}
            <v-icon class="ml-3">
              send
            </v-icon>
          </span>
          <span
            class="text-body-1"
            style="text-transform: initial"
          >
            {{ isAnalyze ? $t('keywords.analyze.analyzeNowDescription') : $t('keywords.simplify.writeNowDescription') }}
          </span>
        </div>
      </v-btn>
      <v-btn
        v-if="!isAnalyze"
        color="accent"
        outlined
        link
        class="multi-line-button pa-5 btnBackgroundColor action-button"
        @click="showAdvanced = !showAdvanced"
      >
        <div class="d-flex flex-column">
          <span class="text-h5 mb-3 d-flex justify-center">
            {{ $t('keywords.simplify.specify') }}
            <v-icon
              :class="{
                'rotate-180': showAdvanced
              }"
            >
              expand_more
            </v-icon>
          </span>
          <span
            class="text-body-1"
            style="text-transform: initial"
          >
            {{ isAnalyze ? $t('keywords.analyze.specifyDescription') : $t('keywords.simplify.specifyDescription') }}
          </span>
        </div>
      </v-btn>
    </div>
    <v-slide-y-transition>
      <div v-show="showAdvanced || (readOnly && showAllKeywords)">
        <KeywordInfoList
          v-if="!readOnly"
          class="my-4 mx-3"
        />
        <v-slide-y-transition hide-on-leave>
          <div v-show="showAllKeywords">
            <AIPrompt
              v-model="aiPrompt"
              :no-prompt="articleMetadata.noAiPrompt"
              :read-only="!!articleMetadata.selectedKeyword"
            />
            <v-row>
              <v-radio-group v-model="selectedKeyword">
                <div class="d-flex flex-row flex-wrap justify-center align-start">
                  <v-card
                    v-for="type in KeywordType"
                    :key="type"
                    class="ma-3"
                    :width="$vuetify.breakpoint.lgAndUp ? 'calc(50% - 36px)' : 'calc(100% - 36px)'"
                    flat
                  >
                    <div v-if="loading">
                      <div
                        class="primaryGradientBackground rounded-t"
                      >
                        <v-skeleton-loader
                          type="table-row"
                          class="pa-4"
                        />
                      </div>
                      <v-skeleton-loader
                        type="table-row@4"
                        class="pa-4"
                      />
                    </div>
                    <div v-else>
                      <KeywordsList
                        :keyword-type="type"
                        :keywords="getKeywords(type)"
                        :read-only="readOnly"
                        :article="articleMetadata"
                        :current-selected-keyword.sync="selectedKeyword"
                        :selected-for-ai.sync="keywordsForAi"
                        @change-main-keyword="changeMainKeyword"
                      />
                    </div>
                  </v-card>
                </div>
              </v-radio-group>
            </v-row>
          </div>
        </v-slide-y-transition>
        <div
          v-if="!readOnly || aiPromptMissing"
          class="d-flex justify-center"
        >
          <div class="d-flex flex-column align-center">
            <span class="mb-2 d-flex flex-row flex-nowrap">
              <span>
                {{ $t('keywords.info.selectedForAi') }}
              </span>
              <v-card
                class="ml-2 px-2"
                :color="aiMeterColor"
                disabled
                depressed
              >
                <span>
                  {{ keywordsForAi.length }}
                </span>
                /
                <span>
                  {{ maxAiKeywords }}
                </span>
              </v-card>
            </span>
            <span
              v-if="noInspirations"
              class="mb-2"
            >
              {{ $t('keywords.info.beforeButtonNoData') }}
            </span>
            <span
              v-else
              class="mb-2"
            >
              {{ $t('keywords.info.beforeButton') }}
            </span>
            <v-slide-y-reverse-transition hide-on-leave>
              <v-row
                v-show="selectNewKeyword"
                class="mt-3"
              >
                <KeywordInput
                  is-edit
                  :loading="loading"
                  :query-params="{}"
                  :article-in-progress="articleMetadata"
                  @input="onKeywordInput"
                />
              </v-row>
            </v-slide-y-reverse-transition>
            <v-row>
              <v-col
                v-if="noInspirations"
                class="d-flex justify-center"
              >
                <v-tooltip
                  bottom
                  :disabled="articleCreatedInThisPeriod"
                >
                  <template #activator="{ on }">
                    <span v-on="on">
                      <v-btn
                        :loading="loadingSave"
                        class="bg-blue-darken-4 text-white mt-4 mb-n2"
                        color="warning"
                        depressed
                        :disabled="!articleCreatedInThisPeriod"
                        @click="deleteArticleAndRefundCredit"
                      >
                        <v-icon class="mr-2">
                          cancel
                        </v-icon>
                        {{ $t('keywords.deleteArticleAndRefundCredit') }}
                      </v-btn>
                    </span>
                  </template>
                  <span>
                    {{ $t('keywords.notCreatedInThisBillingPeriod') }}
                  </span>
                </v-tooltip>
              </v-col>
              <v-col
                class="d-flex justify-center align-center text-center"
                :cols="!noInspirations ? 12 : undefined"
              >
                <v-tooltip
                  bottom
                  :disabled="canContinue"
                >
                  <template #activator="{ on }">
                    <span v-on="on">
                      <v-btn
                        id="generateInspirationButton"
                        :disabled="!selectedKeyword || !canContinue || selectNewKeyword"
                        :loading="loadingSave"
                        class="bg-blue-darken-4 text-white mt-4 mb-6"
                        :class="{
                          'multi-line-button': !noInspirations
                        }"
                        color="accent"
                        depressed
                        @click="saveSelectedKeyword(undefined)"
                      >
                        <template v-if="noInspirations">
                          <v-icon class="mr-2">
                            send
                          </v-icon>
                          {{ $t('keywords.generateInspirationAnyway') }}
                        </template>
                        <template v-else>
                          {{ $t('keywords.generateInspiration') }}
                        </template>
                      </v-btn>
                    </span>
                  </template>
                  <span>
                    {{ $t('keywords.info.tooManyKeywordsForAi') }}
                  </span>
                </v-tooltip>
                <v-btn
                  class="ml-2 mt-4 mb-6"
                  text
                  @click="toggleNewKeywordSelect"
                >
                  <v-icon v-if="selectNewKeyword">
                    expand_more
                  </v-icon>
                  <v-icon v-else>
                    expand_less
                  </v-icon>
                  {{ $t("keywords.selectNewKeyword") }}
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </v-slide-y-transition>
    <v-row id="keywordSelectionAfter" />
  </v-container>
</template>

<script lang="ts">
    import Vue from 'vue';
    import KeywordInfoList from '@/components/keywords/KeywordInfoList.vue';
    import KeywordsList from '@/components/keywords/KeywordsList.vue';
    import {KeywordType} from '@/enum/keyword_type';
    import {Functions} from '@/plugins/Functions';
    import {InspirationResponse} from '@/model/DataForSeo';
    import {Article, ArticleMetadata, Keyword, KeywordForAi, ArticleQuestion} from "@/model/Article";
    import AIPrompt from "@/components/ai/AIPrompt.vue";
    import KeywordInput from "@/components/keywords/KeywordInput.vue";
    import {Firestore} from "@/plugins/Firestore";
    import {FlashMessage} from "@/model/FlashMessage";
    import {scrollIntoViewIfNotVisible} from "@/utils/scroll";
    import {EventBus} from "@/utils/EventBus";
    import EventsListenerMixin from "@/mixins/EventsListenerMixin";
    import {questionize} from "@/utils/string";

    const MAX_AI_KEYWORDS = 20;

    export default Vue.extend({
        name: 'KeywordSelection',
        components: {KeywordInput, AIPrompt, KeywordInfoList, KeywordsList},
        mixins: [EventsListenerMixin],
        props: {
            articleId: {
                type: String,
                default: ''
            },
            articleMetadata: {
                type: Article,
                default: new Article
            },
            readOnly: {
                type: Boolean,
                default: false
            },
            aiPromptMissing: {
                type: Boolean,
                default: false
            },
            isAnalyze: {
                type: Boolean,
                default: false
            }
        },
        // emits: ['input'],
        data: () => ({
            KeywordType: KeywordType,
            inspirations: {} as InspirationResponse,
            loading: false,
            loadingSave: false,
            selectedKeyword: '',
            aiPrompt: '',
            keywordsForAi: [] as KeywordForAi[],
            maxAiKeywords: 20,
            showAllKeywords: true,
            selectNewKeyword: false,
            showAdvanced: false
        }),
        computed: {
            events: function () {
                return {
                    'keywords-selection-expand': this.expand
                };
            },
            noInspirations: function (): boolean {
                if (this.loading) {
                    return false;
                } else {
                    const related = this.getKeywords(KeywordType.RELATED) as null | Array<unknown>;
                    const questions = this.getKeywords(KeywordType.QUESTION) as null | Array<unknown>;
                    return (!Array.isArray(related) || related.length < 4) && (!Array.isArray(questions) || questions.length < 2);
                }
            },
            articleCreatedInThisPeriod: function (): boolean {
                if (!this.articleMetadata.created_at) {
                    return true;
                }
                return this.articleMetadata.created_at + (1000 * 60 * 60 * 24 * 31) > Date.now(); // Not older than one month
            },
            canContinue: function (): boolean {
                // user can select up to 20 keywords for AI
                return this.keywordsForAi.length <= MAX_AI_KEYWORDS;
            },
            aiMeterColor: function (): string {
                if (this.keywordsForAi.length <= this.maxAiKeywords) {
                    return 'accent';
                } else {
                    return 'warning';
                }
            }
        },
        created: function () {
            this.getInspirations();
            this.aiPrompt = this.articleMetadata.aiPrompt;
            this.showAllKeywords = !this.readOnly;
        },
        methods: {
            getInspirations: function () {
                this.loading = true;
                Functions.D4SGetInspiration({
                    keyword: this.articleMetadata.keyword,
                    language_code: this.articleMetadata.language_code,
                    location_code: (this.articleMetadata.location_code),
                }).then(response => {
                    this.inspirations = response;
                    if (this.inspirations.related) {
                        // @ts-ignore
                        this.inspirations.related = this.inspirations.related.map((relatedKeyword: Record<string, unknown>) => relatedKeyword.keyword_data);
                    }
                    this.inspirations.questions = this.inspirations.questions?.map((question: Keyword) => {
                        question.keyword = questionize(question.keyword, this.articleMetadata.language_code);
                        return question;
                    });
                    const keywordsInOrder = [];
                    for (const type of Object.values(KeywordType)) {
                        const keywords = this.getKeywords(type) as any;
                        if (keywords) {
                            keywordsInOrder.push(...keywords.map((item: any) => item.keyword));
                        }
                    }
                    if (keywordsInOrder.includes(this.articleMetadata.keyword)) {
                        this.selectedKeyword = this.articleMetadata.keyword;
                    } else if (keywordsInOrder.length > 0) {
                        this.selectedKeyword = keywordsInOrder[0];
                    }
                    if (this.articleMetadata.keywordsForAi !== null) {
                        this.fixKeywordType();
                        this.keywordsForAi = this.articleMetadata.keywordsForAi;
                    } else {
                        // select keywords from related and questions by default
                        if (this.inspirations.related) {
                            // @ts-ignore
                            this.keywordsForAi.push(...this.inspirations.related.map((keyword: Record<string, Keyword>) => ({
                                keyword: keyword.keyword,
                                type: KeywordType.RELATED
                            })));
                        }
                        if (this.inspirations.questions) {
                            // @ts-ignore
                            this.keywordsForAi.push(...this.inspirations.questions.map((keyword: Record<string, Keyword>) => ({
                                keyword: keyword.keyword,
                                type: KeywordType.QUESTION
                            })));
                        }
                    }
                    return response;
                }).catch(error => {
                    this.$store.dispatch("handleApiError", error);
                }).finally(() => {
                    this.loading = false;
                });
            },
            getKeywords: function (type: number) {
                switch (type) {
                case KeywordType.VARIATION:
                    return this.inspirations.ideas;
                case KeywordType.QUESTION:
                    return this.inspirations.questions;
                case KeywordType.LONG_TAIL:
                    return this.inspirations.suggestions;
                case KeywordType.RELATED:
                    return this.inspirations.related;
                default:
                    return [];
                }
            },
            fixKeywordType: function () {
                // Sometimes a type 0 (related) was wrongly assigned to the keywordForAi
                if (this.articleMetadata.keywordsForAi !== null) {
                    this.articleMetadata.keywordsForAi.forEach((kfa: KeywordForAi) => {
                        for (const type of Object.values(KeywordType)) {
                            // @ts-ignore
                            if ((this.getKeywords(type)|| []).some(d4sdata => d4sdata.keyword === kfa.keyword)) {
                                kfa.type = type;
                            }
                        }
                    });
                }
            },
            writeNow: function () {
                this.loadingSave = true;
                if (this.loading) { // Wait for keyword analysis to load
                    this.$watch('loading', value => {
                        if (value === false) {
                            this.$nextTick(() => {
                                this.saveSelectedKeyword(this.articleMetadata.keyword);
                            });
                        }
                    });
                } else {
                    this.saveSelectedKeyword(this.articleMetadata.keyword);
                }
            },
            saveSelectedKeyword: function (override?: string) {
                if (override) {
                    this.selectedKeyword = override;
                }
                this.showAllKeywords = false;
                this.loadingSave = true;
                let questions = [] as Partial<ArticleQuestion>[];
                if (this.inspirations.questions) {
                    // save only questions which were selected for AI, otherwise there would be questions with no answer generated
                    questions = this.keywordsForAi.filter(aiKeyword => aiKeyword.type === KeywordType.QUESTION).map(aiKeyword => ({
                        question: aiKeyword.keyword,
                        answer: ''
                    }));
                }
                this.$emit('input', this.selectedKeyword, this.aiPrompt, questions, this.keywordsForAi);
            },
            deleteArticleAndRefundCredit: function () {
                this.loadingSave = true;
                Functions.CancelArticle({articleId: this.articleId}).then(() => {
                    this.$router.push('/');
                }).catch(error => {
                    this.$store.dispatch("handleApiError", error);
                }).finally(() => {
                    this.loadingSave = false;
                });
            },
            expand: function () {
                this.showAllKeywords = true;
            },
            toggleNewKeywordSelect: function () {
                this.selectNewKeyword = !this.selectNewKeyword;
                this.$nextTick(() => {
                    scrollIntoViewIfNotVisible('#keywordSelectionAfter');
                });
            },
            changeMainKeyword: function (newKeyword: string): void {
                const initialKeyword = this.articleMetadata.keyword;
                this.loading = true;
                if (!this.articleMetadata.keywordChangeCounter) {
                    // eslint-disable-next-line vue/no-mutating-props
                    this.articleMetadata.keywordChangeCounter = 0;
                }
                this.$set(this.articleMetadata, 'keywordChangeCounter', this.articleMetadata.keywordChangeCounter + 1);
                this.$set(this.articleMetadata, 'keyword', newKeyword);
                Firestore.saveUserArticle(this.articleMetadata).then(() => {
                    // reset keywords selected for AI
                    this.keywordsForAi = [];
                    // generate new inspirations
                    this.getInspirations();
                    // refresh article list in left menu
                    EventBus.$emit('reload-article-list');
                    scrollTo({top: 0, behavior: 'smooth'});
                }).catch(error => {
                    // keyword change failed
                    this.$set(this.articleMetadata, 'keyword', initialKeyword);
                    this.$set(this.articleMetadata, 'keywordChangeCounter', this.articleMetadata.keywordChangeCounter - 1);
                    console.error(error);
                    this.$store.dispatch('displayFlashMessage', {
                        type: 'error',
                        content: 'unexpectedError',
                    } as FlashMessage);
                    this.loading = false;
                });
            },
            onKeywordInput: function (keywordForm: ArticleMetadata) {
                this.selectNewKeyword = false;
                this.changeMainKeyword(keywordForm.keyword);
            }
        }
    });
</script>

<style scoped lang="sass">
.simplifyButtons
    button
        width: calc(50% - 24px)
/* 2 * mx-3 */

.btnBackgroundColor
    background-color: var(--v-background-lighten1)

.action-button
    flex: 1
    min-width: 400px !important

    @media (max-width: 600px)
        min-width: 300px !important
</style>
