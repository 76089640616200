<template>
  <v-icon :color="icons[status].color">
    {{ icons[status].icon }}
  </v-icon>
</template>

<script lang="ts">
    import Vue, {PropType} from "vue";
    import {SeoScoreStatus} from "@/model/SeoScore";

    export default Vue.extend({
        name: "SeoScoreIcon",
        props: {
            status: {
                type: String as PropType<SeoScoreStatus>,
                default: 'GREEN'
            }
        },
        data: () => ({
            icons: {
                GREEN: {
                    color: 'accent',
                    icon: 'check_circle'
                },
                YELLOW: {
                    color: 'warning',
                    icon: 'check_circle'
                },
                RED: {
                    color: 'error',
                    icon: 'cancel'
                }
            } as Record<string, { color: string, icon: string }>
        })
    });
</script>

<style scoped lang="sass">

</style>
