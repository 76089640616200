// TODO keep this file in manual sync with backend until #16429 is resolved

export interface OpenAITupleValue {
    keyword: string | null;
    value1: string | null;
    value2: string | null;
}

export interface OpenAIEditRequest {
    articleId: string;
}

export type OpenAIEditResponse = Record<string, never>;

export type OpenAiCompletionType = "meta" | "h1" | "h2" | "h3" | "question";

export interface OpenAICompletionData {
    state: "ok" | "failed";
    count: number;
    touch: number;
}

export enum OpenAiExpandPartType {
    FROM_HEADING = "FROM_HEADING",
    FROM_USER_INPUT = "FROM_USER_INPUT",
    FROM_QUESTION = "FROM_QUESTION",
}

type OpenAiExpandPartDataFromHeading = {
    type: OpenAiExpandPartType.FROM_HEADING;
    data: {
        heading: string;
    };
};

type OpenAiExpandPartDataFromUserInput = {
    type: OpenAiExpandPartType.FROM_USER_INPUT;
    data: {
        userInput: string;
    };
};

type OpenAiExpandPartDataFromQuestion = {
    type: OpenAiExpandPartType.FROM_QUESTION;
    data: {
        question: string;
    };
};

export type OpenAiExtendPartRequest = {
    articleId: string;
    part: string;
    uid: number;
} & (
    | OpenAiExpandPartDataFromHeading
    | OpenAiExpandPartDataFromUserInput
    | OpenAiExpandPartDataFromQuestion
);

export interface OpenAiExtendPartResponse {
    valueId: string;
}

interface OpenAiExtendPartDataInterface {
    created_at: number;
    state: "running" | "done" | "failed";
    value: string;
    used: boolean;
    discarded: boolean;
}

export type OpenAiExtendPartData = OpenAiExtendPartDataInterface &
    (
        | OpenAiExpandPartDataFromHeading
        | OpenAiExpandPartDataFromUserInput
        | OpenAiExpandPartDataFromQuestion
    );

export interface OpenAiExtendPartDataWithIdInterface extends OpenAiExtendPartDataInterface {
    valueId: string;
}

export type OpenAiExtendPartDataWithId = OpenAiExtendPartDataWithIdInterface &
    (
        | OpenAiExpandPartDataFromHeading
        | OpenAiExpandPartDataFromUserInput
        | OpenAiExpandPartDataFromQuestion
    );
