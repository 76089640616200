<template>
  <v-card
    dark
    flat
    class="primaryGradientBackground pa-2"
  >
    <div class="ma-4 d-flex flex-column align-start">
      <h6 class="text-h6">
        {{ $t('article.recommendation.title') }}
      </h6>
      <ul
        v-for="index in numOfItems"
        :key="index"
        class="tipsList"
      >
        <li :class="{'font-weight-bold' : bold.includes(index)}">
          <v-icon class="mr-2">
            check
          </v-icon>
          {{ $t('article.recommendation.items.' + index) }}
          <ul v-if="index === insertSerpAfter">
            <li
              v-for="(link, serpIndex) of serpLinks"
              :key="serpIndex"
            >
              <a
                :href="link.url"
                target="_blank"
                class="primary--text"
              >
                <v-icon
                  small
                  class="mr-2"
                >
                  open_in_new
                </v-icon>{{ link.title }}
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </v-card>
</template>

<script lang="ts">
    import Vue, {PropType} from 'vue';

    export default Vue.extend({
        name: 'ArticleRecommendation',
        props: {
            serpLinks: {
                type: Array as PropType<{ url: string, title: string }[]>,
                default: () => []
            }
        },
        data: () => ({
            numOfItems: 11,
            insertSerpAfter: 6,
            bold: [4]
        })
    });
</script>

<style scoped lang="sass">
.tipsList
    li
        list-style: none
        margin-left: 10px

        i
            margin-left: -37px
</style>
