<template>
  <div id="export">
    <div class="d-flex flex-wrap">
      <span
        v-for="type in DownloadType"
        :key="'download-' + type"
      >
        <v-btn
          depressed
          color="primary"
          class="ma-2"
          @click="onDownload(type)"
        >
          {{ $t('article.download.formats.' + type) }}
        </v-btn>
      </span>
    </div>
    <div class="text-caption text--secondary mx-3">
      {{ $t('article.download.title') }}
    </div>
  </div>
</template>

<script lang="ts">
    import Vue from "vue";
    import {DownloadType} from '@/enum/download_type';
    import {articleExport} from "@/service/articleExport";
    import {FlashMessage} from "@/model/FlashMessage";
    import {captureException} from "@/plugins/Sentry";
    import {EventBus} from "@/utils/EventBus";

    export default Vue.extend({
        name: "ArticleExport",
        props: {
            recommendedLength: {
                type: Number,
                default: 0
            }
        },
        data: () => ({
            DownloadType: DownloadType,
        }),
        computed: {
            article: function () {
                return this.$store.getters.article;
            },
        },
        methods: {
            onDownload: function (downloadType: number): void {
                EventBus.$emit('debounce-flush'); // flush all debounced emits to get current data
                // wait for next event cycle for emits to finish
                this.$nextTick(() => {
                    articleExport(this.article, downloadType, this.recommendedLength)
                        .catch(error => {
                            this.$store.dispatch('displayFlashMessage', {
                                type: 'error',
                                content: 'Error while exporting article.'
                            } as FlashMessage);
                            captureException(error);
                        });
                });
            },
        }
    });
</script>

<style scoped>

</style>
