import {Article} from "@/model/Article";
import {
    AlignmentType,
    Document,
    ExternalHyperlink,
    HeadingLevel,
    LevelFormat,
    Packer,
    Paragraph,
    TableOfContents,
    TextRun
} from "docx";
import {I18n} from "@/plugins/I18n";
import {RecommendedLength} from "@/enum/article_recommended_length";
import {stripTagsAndEntities} from "@/utils/string";
import {ExternalLinks} from "@/config/ExternalLinks";

function htmlToElement(html: string) {
    const template = document.createElement('template');
    html = html.trim();
    template.innerHTML = html;
    return template.content.firstElementChild;
}

function extractAttributes(html: string): Record<string, string> {

    const el = htmlToElement(html);
    if (!el) {
        return {};
    }

    const ret: Record<string, string> = {};
    el.getAttributeNames().forEach(name => {
        const value = el.getAttribute(name);
        if (value) {
            ret[name] = value;
        }
    });
    return ret;
}

type TagBreakdown = [string, string, string, string, Record<string, string>];

// TODO rewrite to Elements and Nodes
function tagBreakdown(html: string): TagBreakdown {
    const tagName = html.slice(1, html.indexOf('>')).split(' ', 1)[0];
    const tagLength = html.indexOf(`>`) + 1;
    let position = tagLength;
    let nested = 0;
    let closeTagIndex = 0;

    let tries = 0;
    let tagContent, firstPart, rest;

    if (tagName === 'br') { // Should be list of all self-closing tags
        closeTagIndex = tagLength;
        tagContent = '';
        firstPart = html.slice(0, tagLength);
        rest = html.slice(closeTagIndex);
    } else {
        while (position < html.length) {
            const nextStartTagIndex = html.indexOf(`<${tagName}`, position);
            const startTagLength = html.indexOf(`>`, nextStartTagIndex) + 1 - nextStartTagIndex;
            closeTagIndex = html.indexOf(`</${tagName}>`, position);
            const isStart = nextStartTagIndex !== -1 && nextStartTagIndex < closeTagIndex;
            if (isStart) {
                nested++;
                position = nextStartTagIndex + startTagLength;
            } else {
                if (nested > 0) {
                    nested--;
                    position = closeTagIndex + `</${tagName}>`.length;
                } else {
                    break;
                }
            }
            tries++;
            if (tries === 50) {
                window.console.warn('Max tries reached');
                break;
            }
        }
        if (closeTagIndex === -1) {
            console.warn('Closing tag not found');
        }
        tagContent = html.slice(tagLength, closeTagIndex);
        firstPart = html.slice(0, tagLength + tagContent.length + `</${tagName}>`.length);
        rest = html.slice(closeTagIndex + (`</${tagName}>`.length));
    }

    const attributes = extractAttributes(html.slice(0, tagLength));

    return [tagName, tagContent, rest, firstPart, attributes];
}

function plainTextBreakdown(html: string): string[] {
    const startTagIndex = html.indexOf('<');
    if (startTagIndex === -1) {
        return [html, ''];
    } else {
        const plainText = html.slice(0, startTagIndex);
        const rest = html.slice(startTagIndex);
        return [plainText, rest];
    }
}

function convertToTextRuns(html: string, config: Record<string, (boolean | string)> = {}): (TextRun | ExternalHyperlink)[] {

    const ret: (TextRun | ExternalHyperlink)[] = [];

    while (html && html.length) {
        if (html.startsWith('<')) {
            const [tagName, tagContent, rest, _, attributes] = tagBreakdown(html);
            switch (tagName) {
                case 'strong':
                    ret.push(...convertToTextRuns(tagContent, {...config, bold: true}));
                    break;
                case 'i':
                    ret.push(...convertToTextRuns(tagContent, {...config, italics: true}));
                    break;
                case 'a':
                    ret.push(new ExternalHyperlink({
                        children: convertToTextRuns(tagContent, {...config, style: "Hyperlink"}),
                        link: attributes.href
                    }));
                    break;
                case 'br':
                    ret.push(new TextRun({
                        break: 1
                    }));
                    break;
                default:
                    ret.push(...convertToTextRuns(tagContent, config));
            }
            html = rest;
        } else {
            const [plainText, rest] = plainTextBreakdown(html);
            if (plainText) {
                ret.push(new TextRun({
                    text: stripTagsAndEntities(plainText, false),
                    ...config
                }));
            }
            html = rest;
        }
    }

    return ret;
}

function convertToParagraphs(html: string, level = -1, config: Record<string, any> = {}): Paragraph[] {

    const ret: Paragraph[] = [];
    let children: (TextRun | ExternalHyperlink)[] = [];

    function closePar() {
        if (children.length) {
            ret.push(new Paragraph({
                children: children,
                ...config
            }));
        }
        children = [];
    }

    while (html && html.length) {
        if (html.startsWith('<')) {
            const [tagName, tagContent, rest, firstPart] = tagBreakdown(html);
            switch (tagName) {
                case 'p':
                    closePar();
                    ret.push(...convertToParagraphs(tagContent, level, config));
                    break;
                case 'ul':
                    closePar();
                    ret.push(...convertToParagraphs(tagContent, level + 1, config));
                    break;
                case 'ol':
                    closePar();
                    ret.push(...convertToParagraphs(tagContent, level + 1, {
                        ...config, numbering: {
                            reference: "decimal-numbering",
                            level: level + 1,
                        }
                    }));
                    break;
                case 'li':
                    closePar();
                    ret.push(...convertToParagraphs(tagContent, level, {...config, bullet: {level: level}}));
                    break;
                default:
                    children.push(...convertToTextRuns(firstPart));
            }
            html = rest;
        } else {
            const [plainText, rest] = plainTextBreakdown(html);
            if (plainText) {
                children.push(...convertToTextRuns(plainText));
            }
            html = rest;
        }
    }
    closePar();

    return ret;
}

function convertArticleToWordDoc(article: Article, recommendedLength: number): Document {

    const capitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1);

    const content = [
        new Paragraph({
            children: [
                new TextRun({
                    text: capitalize(I18n.t("article.label.metaTitle").toString()) + ': ' + article.metaTitle || '',
                    italics: true
                }),
                new TextRun({
                    text: capitalize(I18n.t("article.label.metaDescription").toString()) + ': ' + article.metaDescription || '',
                    italics: true,
                    break: 1
                })
            ]
        })
    ] as (Paragraph | TableOfContents)[];

    if (recommendedLength !== RecommendedLength.CopyLeaks) {
        content.push(
            new Paragraph({
                spacing: {
                    after: 200
                },
                children: [
                    new TextRun({
                        text: I18n.t("article.overview.selectedKeyword.title") + ': ' + article.selectedKeyword || '',
                        italics: true
                    }),
                    new TextRun({
                        text: I18n.t("article.overview.idealURL.title") + ': https://your-page.com/' + article.selectedKeyword.replaceAll(' ', '-') || '',
                        italics: true,
                        break: 1
                    }),
                    new TextRun({
                        text: I18n.t("article.overview.ALTDescription.title") + ': ' + article.selectedKeyword || '' + " | your brand name",
                        italics: true,
                        break: 1
                    }),
                    new TextRun({
                        text: I18n.t("article.overview.recommendedLength.title") + ': ' + recommendedLength || '',
                        italics: true,
                        break: 1
                    }),
                    new TextRun({
                        text: I18n.t("article.overview.recommendedDensity.title") + ': ' + Math.floor(recommendedLength * 0.015) || '',
                        italics: true,
                        break: 1
                    })
                ]
            })
        );
    }

    for (const part of article.getAllParts()) {
        if (part.number === 2) {

            // This generates a message for the user https://answers.microsoft.com/en-us/msoffice/forum/all/this-document-contains-fields-that-can-share-data/d1d91e1c-6369-4084-a939-7fe782a31c83
            // content.push(
            //     new TableOfContents(I18n.t('article.contentList.title') as string, {
            //         headingStyleRange: "2-6",
            //         hyperlink: true
            //     })
            // );

            // Alternative without links and page numbers
            content.push(new Paragraph({text: I18n.t('article.contentList.title') + ':'}));
            article.getAllParts().forEach(part => {
                if (part.number > 1) {
                    part.value.forEach(subPart => {
                        content.push(new Paragraph({text: 'H' + part.number + ': ' + stripTagsAndEntities(subPart.h)}));
                    });
                }
            });
            content.push(new Paragraph({}));
        }
        for (const subpart of part.value) {
            content.push(
                ...convertToParagraphs(subpart.h, -1, {
                    heading: 'Heading' + part.number as HeadingLevel,
                    spacing: {
                        after: 100
                    }
                }),
            );
            content.push(
                ...convertToParagraphs(subpart.p),
                new Paragraph({
                    spacing: {
                        after: 300
                    }
                })
            );
        }
    }

    if (article.questions.length) {
        content.push(
            new Paragraph({}),
            new Paragraph({
                text: I18n.t('article.questions.title') as string
            }),
            new Paragraph({})
        );
        for (const {question, answer} of article.questions) {
            content.push(
                ...convertToParagraphs(question, -1, {
                    bullet: {
                        level: 0
                    }
                }),
                ...convertToParagraphs(answer, -1, {
                    bullet: {
                        level: 0
                    }
                }),
                new Paragraph({})
            );
        }
    }

    if (recommendedLength !== RecommendedLength.CopyLeaks) {
        content.push(new Paragraph({
            children: [
                new ExternalHyperlink({
                    children: [
                        new TextRun({
                            text: I18n.t("createdBy").toString(),
                            style: "Hyperlink"
                        })
                    ],
                    link: ExternalLinks.publicSite
                })
            ]
        }));
    }

    return new Document({
        title: article.metaTitle || '',
        description: article.metaDescription || '',
        numbering: {
            config: [
                {
                    reference: "decimal-numbering",
                    levels: [
                        {
                            level: 0,
                            format: LevelFormat.DECIMAL,
                            text: "%1",
                            alignment: AlignmentType.START,
                            style: {
                                paragraph: {
                                    indent: {left: 720, hanging: 260},
                                },
                            },
                        },
                        {
                            level: 1,
                            format: LevelFormat.DECIMAL,
                            text: "%1.%2",
                            alignment: AlignmentType.START,
                            style: {
                                paragraph: {
                                    indent: {left: 1440, hanging: 980},
                                },
                            },
                        },
                        {
                            level: 2,
                            format: LevelFormat.DECIMAL,
                            text: "%1.%2.%3",
                            alignment: AlignmentType.START,
                            style: {
                                paragraph: {
                                    indent: {left: 2160, hanging: 1700},
                                },
                            },
                        },
                        {
                            level: 3,
                            format: LevelFormat.DECIMAL,
                            text: "%1.%2.%3.%4",
                            alignment: AlignmentType.START,
                            style: {
                                paragraph: {
                                    indent: {left: 2880, hanging: 2420},
                                },
                            },
                        },
                    ],
                },
            ],
        },
        sections: [{
            children: content
        }]
    });
}

function packDocToBlob(doc: Document): Promise<Blob> {
    return Packer.toBlob(doc);
}

function articleAsBase64(article: Article, recommendedLength: number): Promise<string> {
    const doc = convertArticleToWordDoc(article, recommendedLength);
    return Packer.toBase64String(doc)
        .then(result => {
            return result;
        });
}

export {convertArticleToWordDoc, packDocToBlob, articleAsBase64};
