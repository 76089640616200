import {
    addDoc,
    collection,
    doc,
    query,
    getDoc,
    getDocs,
    getFirestore,
    limit,
    orderBy,
    onSnapshot,
    updateDoc,
    where,
    writeBatch,
    startAfter,
    getCountFromServer,
    endBefore,
    limitToLast,
    deleteDoc
} from 'firebase/firestore';
import {Query} from "firebase/firestore";
import {FirebaseApp} from '@/plugins/Firebase';
import {Store} from "@/plugins/Store";
import {Article, ArticleContentKey, ArticlesPage} from "@/model/Article";
import {Plan} from "@/model/User";
import firebase from "firebase/compat";
import DocumentData = firebase.firestore.DocumentData;
import Unsubscribe = firebase.Unsubscribe;
import {CopyLeaksResult} from "@/model/CopyLeaks";
import {
    OpenAICompletionData,
    OpenAiCompletionType,
    OpenAiExtendPartData,
    OpenAiExtendPartDataWithId,
    OpenAITupleValue
} from "@/model/OpenAI";
import {KeywordSuggestionLite} from "@/model/DataForSeo";
import {Functions} from "@/plugins/Functions";
import {StripePrice, StripeProduct} from "@/model/Stripe";
import {Sort, SortString} from "@/model/Sort";

const db = getFirestore(FirebaseApp);

// if (process.env.NODE_ENV === 'development') {
//     connectFirestoreEmulator(db, 'localhost', 5002);
// }

function getUserDoc(uid = Store.getters.user.uid) {
    return doc(collection(db, 'users'), uid);
}

const plansRef = collection(db, 'plans');

const articlesCollection = 'articles';

let stopObserver: Unsubscribe | null = null;

function transformArticles(articles: DocumentData[]): Article[] {
    return articles.map(queriedArticle => {
        const article = Article.fromObject(queriedArticle.data());
        if (queriedArticle.data().id === null) {
            // article saved without id
            article.id = queriedArticle.id;
        }
        article.loadedSeoScore = article.seoScore;
        /*
        if (queriedArticle.data().mainKeyword === undefined) {
            // loaded article without mainKeyword
            article.mainKeyword = article.selectedKeyword === '' ? article.keyword.toLowerCase() : article.selectedKeyword.toLowerCase();
        }
        */
        return article;
    });
}

function transformArticlesPage(articles: DocumentData[]): ArticlesPage {
    return {
        first: articles[0],
        last: articles[articles.length - 1],
        items: transformArticles(articles)
    };
}

const defaultSort: Sort = {
    field: 'created_at',
    sortString: SortString.DESC
};

const Firestore = {

    // User

    getUserDataByUid: async function (uid: string): Promise<DocumentData | null> {
        const doc = await getDoc(getUserDoc(uid));
        if (doc.exists()) {
            return doc.data();
        } else {
            return null;
        }
    },

    observeUserDoc: function (handler: (value: Record<string, number>) => void): void {
        stopObserver = onSnapshot(getUserDoc(), snapshot => {
            handler({
                trialUsed: snapshot.get('trialUsed') || false,
                theme: snapshot.get('theme') || false,
                articlesCreatedThisPeriod: snapshot.get('articlesCreatedThisPeriod') || 0,
                extraInspirations: snapshot.get('extraInspirations') || 0
            });
        });
    },

    stopObservingUser: function (): void {
        if (stopObserver !== null) {
            stopObserver();
        }
    },

    setTrialUsed: function (): Promise<void> {
        return updateDoc(getUserDoc(), {
            trialUsed: true,
            extraInspirations: 1
        });
    },

    // when some error occurs during creation of free article
    removeTrialUsed: function (): Promise<void> {
        return updateDoc(getUserDoc(), {
            trialUsed: false,
            extraInspirations: 0
        });
    },

    setTheme: function (value: boolean): Promise<void> {
        return updateDoc(getUserDoc(), {theme: value});
    },

    // Products

    getAllActiveProducts: function (): Promise<Array<StripeProduct>> {
        return getDocs(query(plansRef, where('active', '==', true)))
            .then(data => {
                return data.docs.map(doc => (
                    {
                        ...doc.data(),
                        id: doc.id
                    } as StripeProduct
                ));
            });
    },

    getActivePricesForProduct: function (planId: string): Promise<Array<StripePrice>> {
        return getDocs(query(collection(doc(plansRef, planId), 'prices'), where('active', '==', true)))
            .then(data => {
                return data.docs.map(doc => (
                    {
                        ...doc.data(),
                        id: doc.id
                    } as StripePrice
                ));
            });
    },

    // Plans

    getCurrentPlan: function (): Promise<Plan | null> {
        return getDocs(query(collection(getUserDoc(), 'subscriptions'),
            where('status', 'in', ['active', 'trialing']))
        ).then(data => {
            if (data.docs.length < 1) {
                return null;
            } else {
                const plan = data.docs[0].data();
                if (plan) {
                    return {
                        id: plan.items[0].plan.product,
                        name: plan.items[0].price.product.name,
                        metadata: plan.items[0].price?.product?.metadata,
                        monthly_credit: plan.items[0].plan?.transform_usage?.divide_by || Infinity,
                        period_end: new Date(plan.current_period_end.seconds * 1000),
                        cancel_at_period_end: plan.cancel_at_period_end
                    } as Plan;
                } else {
                    return null;
                }
            }
        });
    },

    // Keywords

    CACHE_LIFETIME: 1000 * 60 * 60 * 24 * 90, // 90 day
    getKeywordSuggestions: function (location_code: string, keyword: string): Promise<KeywordSuggestionLite[]> {
        return new Promise((resolve) => {
            getDoc(doc(collection(db, 'd4s_cache_suggestions_lite'), `${keyword}-${location_code}`))
                .then(document => {
                    if (document.exists()) {
                        if ((document.data().timestamp + this.CACHE_LIFETIME) > Date.now()) {
                            resolve(document.data().value as KeywordSuggestionLite[]);
                        } else {
                            throw new Error("Item in cache is too old");
                        }
                    } else {
                        throw new Error("Item is not in cache");
                    }
                }).catch(() => {
                Functions.D4SGetKeywordSuggestions({
                    location_code: location_code,
                    language_code: '', // not required
                    keyword: keyword
                }).then(response => {
                    resolve(response);
                }).catch(() => {
                    return [];
                });
            });
        });
    },

    // Articles

    checkIfSeoScoreHistoryCollectionExists: function (article: Article): Promise<boolean> {
        return getDocs(
            query(
                collection(doc(collection(getUserDoc(), articlesCollection), article.id as string), 'seoScoreHistory'),
                limit(1)
            )
        )
            .then(history => {
                return history.docs.length > 0;
            });
    },

    saveUserArticle: function (article: Article): Promise<string> {
        if (article.id) {
            // update existing article (batch write)
            const batch = writeBatch(db);
            if (article.loadedSeoScore !== article.seoScore || (article.forcedEmpty && article.loadedSeoScore === 0 && article.seoScore === 0)) {
                // if SEO score changed, create new document in seoScoreHistory collection
                const historyRef = doc(collection(doc(collection(getUserDoc(), articlesCollection), article.id as string), 'seoScoreHistory'));
                batch.set(historyRef, {
                    score: article.seoScore,
                    created_at: Date.now()
                });
            }
            batch.update(doc(collection(getUserDoc(), articlesCollection), article.id), article.toObject() as any);
            return batch.commit().then(() => {
                article.loadedSeoScore = article.seoScore;
                return article.id as string;
            });
        } else {
            // create new article
            article.created_at = Date.now();
            // article.mainKeyword = article.keyword.toLowerCase();
            return addDoc(collection(getUserDoc(), articlesCollection), article.toObject())
                .then((reference) => {
                    // save ID to article object
                    article.id = reference.id;
                    return updateDoc(doc(collection(getUserDoc(), articlesCollection), reference.id), article.toObject() as any);
                })
                .then(() => {
                    return article.id as string;
                });
        }
    },

    updateUserArticleFields: function (article: Article, data: Partial<Article>): Promise<void> {
        const keys = Object.keys(article) as (keyof typeof article)[];
        keys.forEach(key => {
            if (data[key] !== undefined) {
                (article[key] as any) = data[key];
            }
        });
        return this.saveUserArticle(article)
            .then(() => {
                return Promise.resolve();
            }).catch(() => {
                return Promise.reject();
            });
    },

    updateArticleAIGeneratedAt: function (article: Article): Promise<void> {
        article.ai_content_last_generated_at = Date.now();
        if (article.id) {
            return updateDoc(doc(collection(getUserDoc(), articlesCollection), article.id), {
                ai_content_last_generated_at: Date.now()
            });
        } else {
            return Promise.resolve();
        }
    },

    getUserArticle: function (articleId: string): Promise<Article | null> {
        return getDoc(doc(collection(getUserDoc(), articlesCollection), articleId))
            .then(article => {
                if (article.exists()) {
                    const articleData = article.data() as Article;
                    return {
                        ...articleData,
                        id: article.id,
                        loadedSeoScore: articleData.seoScore
                    } as Article;
                } else {
                    return null;
                }
            });
    },

    getUserArticles: function (limitAmount = 1000): Promise<Article[]> {
        return getDocs(
            query(
                collection(getUserDoc(), articlesCollection),
                orderBy(defaultSort.field, defaultSort.sortString),
                limit(limitAmount)
            )
        ).then(articles => {
            return transformArticles(articles.docs);
        });
    },

    getUserArticlesNextPage: function (itemsPerPage = 10, sort: Sort = defaultSort, article?: DocumentData): Promise<ArticlesPage> {
        let paginationQuery: Query<DocumentData> | null;
        if (!article) {
            // first page
            paginationQuery = query(
                collection(getUserDoc(), articlesCollection),
                orderBy(sort.field, sort.sortString),
                limit(itemsPerPage)
            );
        } else {
            // next page
            paginationQuery = query(
                collection(getUserDoc(), articlesCollection),
                orderBy(sort.field, sort.sortString),
                startAfter(article),
                limit(itemsPerPage),
            );
        }

        return getDocs(paginationQuery).then(articles => {
            return transformArticlesPage(articles.docs);
        });
    },

    getUserArticlesPreviousPage: function (itemsPerPage = 10, sort: Sort = defaultSort, article?: DocumentData): Promise<ArticlesPage> {
        return getDocs(
            query(
                collection(getUserDoc(), articlesCollection),
                orderBy(sort.field, sort.sortString),
                endBefore(article),
                limitToLast(itemsPerPage),
            )
        ).then(articles => {
            return transformArticlesPage(articles.docs);
        });
    },

    getUserArticlesCount: function (): Promise<number> {
        return getCountFromServer(
            query(
                collection(getUserDoc(), articlesCollection)
            )
        ).then(snapshot => {
            return snapshot.data().count;
        });
    },

    removeUserArticle: function (articleId: string): Promise<void> {
        return deleteDoc(doc(collection(getUserDoc(), articlesCollection), articleId));
    },

    // Article CopyLeaks reports

    getArticleScans: function (articleId: string): Promise<CopyLeaksResult[]> {
        return getDocs(collection(doc(collection(getUserDoc(), articlesCollection), articleId), 'copyLeaksScans'))
            .then(scans => {
                return scans.docs.map(scan => ({
                    ...scan.data(),
                    createdAt: scan.id
                } as unknown as CopyLeaksResult));
            });
    },

    createArticleScanWatcher: function (articleId: string, scanId: string, handler: any): Unsubscribe {
        return onSnapshot(
            doc(collection(doc(collection(getUserDoc(), articlesCollection), articleId), 'copyLeaksScans'), scanId),
            handler
        );
    },

    // Article OpenAI completion

    createArticleOpenAIWatcher: function (articleId: string, part: OpenAiCompletionType, handler: (content: OpenAICompletionData) => void): Unsubscribe {
        return onSnapshot(
            doc(collection(doc(collection(getUserDoc(), articlesCollection), articleId), 'OpenAICompletion'), part),
            snapshot => {
                if (snapshot.exists()) {
                    handler(snapshot.data() as OpenAICompletionData);
                }
            }
        );
    },

    getArticleOpenAIState: function (articleId: string, part: OpenAiCompletionType): Promise<OpenAICompletionData | null> {
        return getDoc(doc(collection(doc(collection(getUserDoc(), articlesCollection), articleId), 'OpenAICompletion'), part))
            .then(state => {
                if (state.exists()) {
                    return state.data() as OpenAICompletionData;
                } else {
                    return null;
                }
            })
            .catch(() => {
                return null;
            });
    },

    getArticleOpenAIValues: function (articleId: string, part: OpenAiCompletionType): Promise<OpenAITupleValue[]> {
        return getDocs(collection(doc(collection(doc(
            collection(getUserDoc(), articlesCollection), articleId
        ), 'OpenAICompletion'), part), 'values'))
            .then(completions => {
                return completions.docs.map(doc => ({
                    ...doc.data()
                } as OpenAITupleValue));
            })
            .catch(() => {
                return [];
            });
    },

    // Article OpenAI part extensions

    _openAiExtensionCollection(articleId: string, part: ArticleContentKey, uid: number) {
        return collection(doc(collection(doc(collection(
            doc(collection(getUserDoc(), articlesCollection), articleId), 'OpenAIExtensions'
        ), part), 'uids'), uid.toString(10)), 'values');
    },

    getArticleOpenAiPartExtensions: function (articleId: string, part: ArticleContentKey, uid: number)
        : Promise<OpenAiExtendPartDataWithId[]> {
        return getDocs(this._openAiExtensionCollection(articleId, part, uid))
            .then(extensionValues => {
                return extensionValues.docs.map(doc => ({
                    valueId: doc.id,
                    ...doc.data()
                } as OpenAiExtendPartDataWithId));
            }).catch(error => {
                window.console.error(error);
                return [];
            });
    },

    setArticleOpenAiPartExtensionUsed: function (
        articleId: string, part: ArticleContentKey, uid: number, valueId: string, used: boolean
    ): Promise<void> {
        return updateDoc(doc(this._openAiExtensionCollection(articleId, part, uid), valueId), {
            used: used
        });
    },

    setArticleOpenAiPartExtensionDiscarded: function (
        articleId: string, part: ArticleContentKey, uid: number, valueId: string, discarded: boolean
    ): Promise<void> {
        return updateDoc(doc(this._openAiExtensionCollection(articleId, part, uid), valueId), {
            discarded: discarded
        });
    },

    createArticleOpenAiPartExtensionWatcher: function (
        articleId: string, part: ArticleContentKey, uid: number, valueId: string,
        handler: (content: OpenAiExtendPartData) => void): Unsubscribe {
        return onSnapshot(doc(this._openAiExtensionCollection(articleId, part, uid), valueId),
            snapshot => {
                if (snapshot.exists()) {
                    handler(snapshot.data() as OpenAiExtendPartData);
                }
            }
        );
    },

};

export {Firestore};
