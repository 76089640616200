const generated: number[] = [];

export function getUid(): number {
    let newUid;
    do {
        newUid = Math.floor((Math.random() * 100000000));
    } while (generated.includes(newUid));

    generated.push(newUid);
    return newUid;
}
