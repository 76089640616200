<template>
  <v-btn
    :loading="value"
    outlined
    :color="color"
    width="100%"
    :class="{'multi-line-button' : multiLine}"
    :disabled="disabled"
    @click="openCustomerPortal"
  >
    <span>
      {{ $t(message) }}
    </span>
    <v-icon class="ml-2">
      open_in_new
    </v-icon>
  </v-btn>
</template>

<script lang="ts">
    import Vue from 'vue';
    import {Stripe} from "@/plugins/Stripe";

    export default Vue.extend({
        name: "StripeManageButton",
        props: {
            value: {
                type: Boolean,
                default: false
            },
            message: {
                type: String,
                default: "subscriptions.manageSubscription"
            },
            color: {
                type: String,
                default: "primary"
            },
            width: {
                type: Number,
                default: 1200
            },
            multiLine: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            openCustomerPortal: function (): void {
                this.$emit('input', true);
                Stripe.createCustomerPortalSession();
            }
        }
    });
</script>

<style scoped>
</style>
