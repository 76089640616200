<template>
  <v-chip
    small
    outlined
    class="chip-text-primary"
    @click="$emit('input', !value)"
  >
    <v-icon
      class="mr-2"
      :class="{
        'rotate-180': value
      }"
    >
      expand_more
    </v-icon>
    {{ $t(`article.extendWithAi.${langPath}.toggle`) }}
  </v-chip>
</template>

<script lang="ts">
    import Vue, {PropType} from 'vue';

    export default Vue.extend({
        name: "ArticlePartExtendToggle",
        props: {
            value: {
                type: Boolean,
                default: false
            },
            langPath: {
                type: String as PropType<'paragraph' | 'answer'>,
                default: 'paragraph'
            }
        }
    });
</script>

<style scoped lang="sass">

</style>
