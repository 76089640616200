import {Article, ArticlePart} from "@/model/Article";
import {DownloadType} from "@/enum/download_type";
import * as Export from "@/service/export";
import {stripTagsAndEntities} from "@/utils/string";
import {I18n} from "@/plugins/I18n";
import {Store} from "@/plugins/Store";
import {FlashMessage} from "@/model/FlashMessage";
import {captureException} from "@/plugins/Sentry";
import {convertArticleToWordDoc, packDocToBlob} from "@/service/docx";

function getContentToExport(article: Article, downloadType: number): Array<string> {
    if (downloadType === DownloadType.DOCX) {
        return [];
    }

    const headStart = '<head>\n<!DOCTYPE html>\n<html>\n';
    const metas = `<title>${article.metaTitle || ''}</title>\n` +
        '<meta charset="UTF-8">\n' +
        `<meta name="description" content="${article.metaDescription || ''}">\n`;
    const headEnd = '</head><body>';
    let visibleMetas = '';
    let plainText = '';
    if (article.metaTitle) {
        visibleMetas += `<h1>${article.metaTitle}</h1>\n`;
        plainText += `${article.metaTitle}\n\n`;
    }
    if (article.metaDescription) {
        visibleMetas += `<p>${article.metaDescription}</p>\n`;
        plainText += `${article.metaDescription}\n\n`;
    }
    let firstPart = '';
    let firstPartPlain = '';
    let richText = '';
    let contentList = `<h2>${I18n.t('article.contentList.title')}</h2><ul>`;
    let plainContent = `${I18n.t('article.contentList.title')}\n`;
    let idx = 0;
    for (const {number, value: parts} of article.getAllParts()
        .filter((part: ArticlePart) => part.value.some(subpart => subpart.h))) {
        for (const subpart of parts) {
            if (subpart.h) {
                if (number === 1) {
                    firstPart += `<h${number} id="h${number}-${idx}">${subpart.h}</h${number}>\n`;
                    firstPartPlain += subpart.h + '\n\n';
                } else {
                    richText += `<h${number} id="h${number}-${idx}">${subpart.h}</h${number}>\n`;
                    plainText += subpart.h + '\n\n';
                    if (downloadType === DownloadType.HTML) {
                        contentList += `<li><a href="#h${number}-${idx}">${subpart.h}</a></li>`;
                    } else {
                        contentList += `<li>${subpart.h}</li>`;
                    }
                    plainContent += subpart.h + '\n';
                }
            }
            if (subpart.p) {
                if (number === 1) {
                    firstPart += `<p>${subpart.p}</p>`;
                    firstPartPlain += subpart.p + '\n';
                } else {
                    richText += `<p>${subpart.p}</p>`;
                    plainText += subpart.p + '\n';
                }
            }
            idx++;
        }
    }
    contentList += '</ul>';
    richText = firstPart + contentList + richText;
    plainText = firstPartPlain + plainContent + plainText;
    if (article.questions.length) {
        for (const {question, answer} of article.questions) {
            richText += `<h4>${question}</h4><p>${answer}</p><br>`;
            plainText += question + '\n' + answer + '\n\n';
        }
    }
    richText += '</body></html>';
    const toDownload = headStart + metas + headEnd + richText;
    const toDownloadVisibleMetas = headStart + headEnd + visibleMetas + richText;
    switch (downloadType) {
        case DownloadType.HTML:
            return [toDownload];
        case DownloadType.CLIPBOARD:
            return [toDownload, plainText];
        case DownloadType.RTF:
            return [toDownloadVisibleMetas];
        default:
            return [];
    }
}

function articleExport(article: Article, downloadType: number, recommendedLength: number): Promise<void> {
    const filename = article.metaTitle || 'article';
    if (downloadType === DownloadType.DOCX) {
        return packDocToBlob(convertArticleToWordDoc(article, recommendedLength))
            .then((result: Blob) => {
                const a = window.document.createElement('a');
                a.href = window.URL.createObjectURL(result);
                a.download = filename + '.docx';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            })
            .catch(error => {
                Store.dispatch('displayFlashMessage', <FlashMessage>{
                    type: 'error',
                    content: 'Error while creating DOCX export of article.'
                });
                captureException(error);
            });
    } else {
        const contentToExport = getContentToExport(article, downloadType);
        switch (downloadType) {
            case DownloadType.HTML:
                Export.download(contentToExport[0], 'text/html', filename + '.html');
                return Promise.resolve();
            case DownloadType.CLIPBOARD:
                // replace paragraph ends and list items with newline
                // eslint-disable-next-line no-case-declarations
                const plainText = contentToExport[1].replaceAll(/<\/p>|<\/li>/g, '\n');
                return Export.copyToClipboard(contentToExport[0], stripTagsAndEntities(plainText))
                    .then(() => console.info('Copied to clipboard.'))
                    .catch(error => {
                        Store.dispatch('displayFlashMessage', <FlashMessage>{
                            type: 'error',
                            content: 'Error while copying article to clipboard.'
                        });
                        captureException(error);
                    });
            case DownloadType.RTF:
                Export.download(contentToExport[0], 'application/rtf', filename + '.rtf');
                return Promise.resolve();
            default:
                return Promise.resolve();
        }
    }
}


export {getContentToExport, articleExport};
