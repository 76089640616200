<template>
  <div class="d-flex align-center justify-center">
    <div
      v-if="value.isProcessing"
      style="position: relative;"
    >
      <v-skeleton-loader
        type="chip"
      />
      <v-icon
        :color="spinnerColor"
        class="spinner"
      >
        refresh
      </v-icon>
    </div>
    <v-chip
      v-else
      :color="color"
      :text-color="textColor"
    >
      <v-tooltip
        v-if="value.error"
        top
      >
        <template #activator="{ on }">
          <span v-on="on">
            {{ $t('articleTable.reloadableChip.error').toUpperCase() }}
          </span>
        </template>
        {{ $t(value.error) }}
      </v-tooltip>
      <span v-else>
        {{ value.position }}{{ suffix }}
      </span>
      <v-tooltip top>
        <template #activator="{ on }">
          <span v-on="on">
            <v-btn
              icon
              plain
              small
              class="mr-n2 ml-1"
              :class="{ 'fake-disabled': cooldown }"
              @click="reload"
            >
              <v-icon :color="textColor">
                refresh
              </v-icon>
            </v-btn>
          </span>
        </template>
        <span>{{ cooldownHint || hint }}</span>
      </v-tooltip>
    </v-chip>
  </div>
</template>

<script lang="ts">
    import Vue, {PropType} from "vue";
    import {OrganicPosition} from "@/model/Article";

    export default Vue.extend({
        name: 'ReloadableChip',
        props: {
            value: {
                type: Object as PropType<OrganicPosition>,
                required: true
            },
            suffix: {
                type: String,
                default: ''
            },
            hint: {
                type: String,
                default: ''
            },
            colorFn: {
                type: Function,
                default: (value: number) => (value < 10 ? 'error' : value < 20 ? 'warning' : 'success')
            }
        },
        computed: {
            color: function (): string | undefined {
                if (typeof this.value.position === 'string') {
                    return 'error';
                } else if (this.value.error) {
                    return this.$vuetify.theme.dark ? 'grey' : undefined;
                } else {
                    // eslint-disable-next-line @typescript-eslint/ban-types
                    return (this.colorFn as Function)(this.value.position);
                }
            },
            textColor: function (): string {
                return this.color === undefined ? 'black' : 'white';
            },
            spinnerColor: function (): string {
                return this.$vuetify.theme.dark ? 'white' : 'black';
            },
            cooldown: function (): boolean {
                // timestamp in less than 24 hours
                return this.value.timestamp > Date.now() - 24 * 60 * 60 * 1000 && !this.value.error;
            },
            cooldownHint: function (): string | undefined {
                const now = Date.now();
                const difference = now - this.value.timestamp;
                const remaining = (24 * 60 * 60 * 1000) - difference;
                const remainingHours = Math.floor(remaining / (60 * 60 * 1000));
                const remainingMinutes = Math.floor((remaining - remainingHours * 60 * 60 * 1000) / (60 * 1000));
                return this.cooldown ? this.$t('articleTable.reloadableChip.cooldown', [remainingHours, remainingMinutes]) as string : undefined;
            }
        },
        methods: {
            reload: function () {
                if (!this.cooldown) {
                    this.$emit('reload');
                }
            }
        }
    });
</script>

<style scoped lang="sass">
::v-deep .v-skeleton-loader__chip
    width: 88px

@keyframes spin
    0%
        transform: rotate(0deg)
    100%
        transform: rotate(360deg)

.spinner
    position: absolute
    top: calc(50% - 12px)
    left: calc(50% - 12px)
    animation: spin 2s linear infinite

.fake-disabled
    pointer-events: none
</style>
