import Vue from "vue";
import App from './App.vue';
import {Router} from '@/plugins/Router';
import {Store} from '@/plugins/Store';
import {I18n} from '@/plugins/I18n';
import {initSentry} from '@/plugins/Sentry';
import {Vuetify} from '@/plugins/Vuetify';
import './registerServiceWorker';
import {loadFilters} from "@/utils/filters";
// @ts-ignore
import CKEditorComponent from '@/components/ckeditor.js';
import {useMoment} from "@/plugins/Moment";

const rn = document.getElementById('refreshNotice');
if (rn) {
    document.body.removeChild(rn);
}

initSentry();
loadFilters();
useMoment();
Vue.component('Ckeditor', CKEditorComponent);

new Vue({
    router: Router,
    store: Store,
    i18n: I18n,
    vuetify: Vuetify,
    render: h => h(App)
}).$mount('#app');
