const Environment = {
    isDevelopment(): boolean {
        return process.env.NODE_ENV === 'development';
    },
    isProduction(): boolean {
        return process.env.NODE_ENV === 'production';
    },
    get supportsPositiveLookBehindInRegexp(): boolean {
        let res = false;
        try {
            res = ('test'.search(RegExp('(?<=(t))est')) === 1);
        } catch {
            return false;
        }
        return res;
    }
};

export {Environment};
