<template>
  <v-app>
    <router-view :key="$route.path" />
    <NewContentDialog />
  </v-app>
</template>

<script lang="ts">
    import Vue from 'vue';
    import NewContentDialog from '@/components/NewContentDialog.vue';
    import UserPlanMixin from "@/mixins/UserPlanMixin";
    import {FlashMessage} from "@/model/FlashMessage";
    import {LocalStorage} from './plugins/LocalStorage';
    import "./styles/common.css";

    export default Vue.extend({
        name: 'App',
        components: {NewContentDialog},
        mixins: [UserPlanMixin],
        data: () => ({
            authUnWatch: null as null | (() => void),
            planUnWatch: null as null | (() => void),
            actmUnWatch: null as null | (() => void)
        }),
        created: function () {
            this.handleQueryParams();
        },
        mounted() {
            this.$vuetify.theme.dark = LocalStorage.getTheme();
        },
        methods: {
            handleQueryParams: function () {
                const {keyword, langloc, via} = this.$route.query;
                if (via) {
                    // Rewardful handles its business in the included script in index.html
                }
                if (keyword || langloc) {
                    this.redirectToArticleCreation(keyword, langloc);
                }
            },
            redirectToArticleCreation: function (keyword: string | (string | null)[], langloc: string | (string | null)[]) {
                if (this.$store.getters.authenticated) {
                    if (this.hasPlanOrExtras) {
                        if (!this.loadingArticlesCreatedThisPeriod) {
                            if (this.hasCreditFromSubscriptionOrExtras) {
                                this.$router.replace({
                                    path: '/createArticle',
                                    query: this.$route.query
                                });
                            } else {
                                this.$store.dispatch('displayFlashMessage', {
                                    type: 'error',
                                    content: 'subscriptions.noInspirationsLeftThisPeriodUpgradeToContinue'
                                } as FlashMessage);
                                this.$router.replace({
                                    path: '/account',
                                    query: this.$route.query
                                });
                            }
                        } else {
                            if (this.actmUnWatch === null) {
                                this.actmUnWatch = this.$watch('loadingArticlesCreatedThisPeriod', value => {
                                    if (!value) {
                                        this.actmUnWatch && this.actmUnWatch();
                                        this.redirectToArticleCreation(keyword, langloc);
                                    }
                                });
                            }
                        }
                    } else {
                        if (this.planUnWatch === null) {
                            this.planUnWatch = this.$watch('hasPlan', value => {
                                if (value) {
                                    this.planUnWatch && this.planUnWatch();
                                    this.redirectToArticleCreation(keyword, langloc);
                                }
                            });
                        }
                    }
                } else {
                    if (this.authUnWatch === null) {
                        this.authUnWatch = this.$watch('$store.getters.authenticated', value => {
                            if (value) {
                                this.authUnWatch && this.authUnWatch();
                                this.redirectToArticleCreation(keyword, langloc);
                            }
                        });
                    }
                }
            }
        }
    });
</script>

<style lang="sass">
.v-application--wrap
    background: var(--v-background-base)

.primaryGradientBackground
    background: linear-gradient(90deg, var(--v-primary-lighten1) 0%, var(--v-primary-darken2) 100%)

    ::selection
        background: var(--v-accent-base)

    .v-application & .error--text
        color: #fffb00 !important
        caret-color: #fffb00 !important

    .v-application & .primary--text
        color: white !important
        caret-color: white !important

thead.primaryGradientBackground
    background-attachment: fixed

.primaryBorder
    border-color: var(--v-primary-base) !important
    border-width: thick
    border-style: outset

.dark-background
    .v-application & .primary--text
        color: white !important
        caret-color: white !important

.v-slide-group__prev
    border-radius: 50px
    margin: 0 10px
    height: 50px
    align-self: center

.v-slide-group__next
    border-radius: 50px
    margin: 0 10px
    height: 50px
    align-self: center

.v-list-item--superdense
    min-height: 28px !important
    line-height: 1.25rem

    .text-caption
        line-height: .9rem

.text-decoration-dotted
    text-decoration: underline dotted
    text-underline-offset: .15em

.v-card__title
    word-break: normal

.multi-line-button
    display: inline-block
    min-height: 3em
    height: auto !important
    white-space: normal
    padding: 5px 15px !important

.article-content
    width: 70vw

.article-inspiration
    margin-right: 24px
    max-width: calc(50% - 24px)
    position: sticky
    top: 66px
    max-height: calc(100vh - 66px)
    overflow: auto

.text-caption-primary
    // @extend .text-caption // TODO make this work
    font-size: 0.75rem !important
    font-weight: 400
    line-height: 1.25rem
    letter-spacing: 0.0333333333em !important
    font-family: "Roboto", sans-serif !important
    // End of copy of extend
    color: var(--v-anchor-base)

.chip-text-primary
    color: var(--v-primary-base) !important
    border: none !important
    padding-left: 0 !important

.text-wrap-balance
    text-wrap: balance

.v-tooltip__content
    max-width: 400px

html
    & > ::-webkit-scrollbar-track
        background: var(--v-background-base)

::-webkit-scrollbar
    width: 7px

::-webkit-scrollbar-track
    background: none

::-webkit-scrollbar-thumb
    background: #888
    border-radius: 5px

::-webkit-scrollbar-thumb:hover
    background: #555

.rotate-180
    transform: rotate(180deg)

.pie-card
    box-shadow: 0 3px 11px 0 #e8eafc, 0 3px 3px -2px hsla(0, 0%, 69.8%, .10196078431372549), 0 1px 8px 0 hsla(0, 0%, 60.4%, .10196078431372549) !important

.v-data-table__wrapper
    border-radius: 4px !important

.v-navigation-drawer.theme--dark
    background-color: #23272d !important

.ck-powered-by
    display: none !important

.marker-yellow
    .theme--light &
        color: var(--v-markerYellow-darken2)

    .theme--dark &
        color: var(--v-markerYellow-base)

.marker-green
    .theme--light &
        color: var(--v-markerGreen-darken2)

    .theme--dark &
        color: var(--v-markerGreen-base)

.marker-blue
    .theme--light &
        color: var(--v-markerBlue-darken2)

    .theme--dark &
        color: var(--v-markerBlue-base)

.marker-pink
    .theme--light &
        color: var(--v-markerPink-darken2)

    .theme--dark &
        color: var(--v-markerPink-base)

.marker-green, .marker-blue, .marker-pink, .marker-yellow
    background-color: unset !important
    font-weight: bold

    .theme--dark &
        text-shadow: 0 0 20px white

// Transition
.card-list-item
    transition: all .3s

.card-list-enter, .card-list-leave-to
    opacity: 0
    transform: translateY(-60px)

.card-list-leave-active
    position: absolute
    width: 100%
</style>
