<template>
  <v-sheet
    class="primaryGradientBackground d-flex justify-center"
    :style="fullHeight ? 'min-height: calc(100svh - 88px)' : ''"
  >
    <div
      class="d-flex flex-column align-start justify-center pa-8"
      style="max-width: 100%"
    >
      <h6 class="text-h6 mt-3">
        {{ $t('subscriptions.list.title') }}
      </h6>
      <v-list
        width="600"
        max-width="100%"
      >
        <v-list-item
          v-for="index in 7"
          :key="index"
          dense
        >
          <v-list-item-avatar size="10">
            <v-icon color="white">
              chevron_right
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            {{ $t('subscriptions.list.items.' + index) }}
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </v-sheet>
</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        name: 'SubscriptionInfoList',
        props: {
            fullHeight: {
                type: Boolean,
                default: false
            }
        }
    });
</script>

<style scoped lang="sass">
.v-sheet
    width: 100%
    color: white

    .v-list
        background-color: transparent !important

        .v-list-item
            padding: 0
            color: white
</style>
