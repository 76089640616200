import {getAuth, browserLocalPersistence, onAuthStateChanged, setPersistence, signOut} from "firebase/auth";
import {Store} from "@/plugins/Store";
import {User} from "@/model/User";
import {FirebaseApp} from "@/plugins/Firebase";
import * as firebaseui from "firebaseui";
import firebase from "firebase/compat/app";
import AuthUI = firebaseui.auth.AuthUI;
import {ExternalLinks} from "@/config/ExternalLinks";
import {FlashMessage} from "@/model/FlashMessage";
import {captureException} from "@/plugins/Sentry";

const auth = getAuth(FirebaseApp);
let authUiInstance: AuthUI | null = null;

onAuthStateChanged(auth, user => {
    if (user) {
        Store.dispatch('login', new User(
            user.uid,
            user.displayName || user.email || '',
            user.email || ''
        ));
    } else {
        Store.dispatch('noUser');
    }
});

const Auth = {
    getAuthUiInstance: function (): Promise<AuthUI> {
        if (authUiInstance === null) {
            authUiInstance = new firebaseui.auth.AuthUI(getAuth(FirebaseApp));
            return setPersistence(auth, browserLocalPersistence)
                .then(() => {
                    return authUiInstance as AuthUI;
                })
                .catch((error) => {
                    Store.dispatch('displayFlashMessage', <FlashMessage>{
                        type: 'error',
                        content: 'unknownError'
                    });
                    captureException(error);
                    return authUiInstance as AuthUI;
                });
        } else {
            return Promise.resolve(authUiInstance);
        }
    },

    getAuthUIConfig: function (): firebaseui.auth.Config {
        return {
            signInOptions: [
                {
                    provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                    requireDisplayName: false
                }, {
                    provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                }
            ],
            signInSuccessUrl: window.location.href,
            callbacks: {
                signInSuccessWithAuthResult: function (authResult, redirectUrl) {
                    Store.dispatch('login', new User(
                        authResult.user.uid,
                        authResult.user.displayName,
                        authResult.user.email
                    ));
                    return false;
                },
                uiShown: function () {
                    // The widget is rendered.
                    // Hide the loader.
                    // document.getElementById('loader').style.display = 'none';
                }
            },
            tosUrl: ExternalLinks.termsAndConditions,
            privacyPolicyUrl: ExternalLinks.privacyPolicy
        };
    },

    get isAuthenticated(): boolean {
        return Store.getters.authenticated;
    },

    logout: function (): Promise<void> {
        return signOut(auth);
    }
};

export {Auth};
