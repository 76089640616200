<template>
  <div
    class="d-flex flex-column align-center"
  >
    <v-card
      class="d-flex flex-column align-center primaryBorder my-6 ma-3 pa-5"
      max-width="800"
      style="width: 100%"
    >
      <v-card-title class="word-break-normal">
        {{ isAnalyze ? $t('keywords.analyze.description') : $t('keywords.aiPrompt.description') }}
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <template v-if="isAnalyze">
            <v-text-field
              ref="urlInput"
              v-model="form.url"
              :hint="$t('keywords.analyze.urlInputHint')"
              outlined
              :disabled="aiConfirmed"
              persistent-hint
              required
              rows="1"
              :rules="[rules.requiredRule, rules.validUrlRule]"
              class="mb-4"
            />
            <div class="v-card__title word-break-normal px-0 text--primary">
              {{ $t('keywords.analyze.aiPrompt.description') }}
            </div>
          </template>
          <v-textarea
            ref="aiPrompt"
            v-model="form.aiPrompt"
            :hint="$t('keywords.aiPrompt.promptExample')"
            outlined
            persistent-hint
            required
            rows="1"
            :rules="[rules.requiredRule, rules.maxWordsRule(200)]"
            auto-grow
            class="mb-4"
          />
        </v-form>
      </v-card-text>
      <v-card-actions
        v-if="!aiConfirmed"
      >
        <v-btn
          depressed
          :loading="loading"
          color="accent"
          @click="confirmForm"
        >
          {{ $t('keywords.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <div v-show="aiConfirmed">
      <KeywordInput
        :loading="loading"
        :query-params="queryParams"
        @input="onKeywordInput"
      />
    </div>
    <span class="my-16" />
    <span id="keywordLocationLanguageFormAfter" />
  </div>
</template>

<script lang="ts">
    import Vue from 'vue';
    import {rules} from "@/utils/rules";
    import KeywordInput from "@/components/keywords/KeywordInput.vue";
    import {ArticleMetadata} from "@/model/Article";
    import {scrollIntoViewIfNotVisible} from "@/utils/scroll";

    export default Vue.extend({
        name: "KeywordForm",
        components: {KeywordInput},
        // emits: ['input'],
        props: {
            loading: {
                type: Boolean,
                default: false
            },
            isAnalyze: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            form: {
                keyword: '',
                aiPrompt: '',
                url: '',
                language_code: null as (string | null),
                location_code: null as (string | null)
            },
            rules: rules,
            aiConfirmed: false
        }),
        computed: {
            queryParams: function (): Record<string, string> {
                const {keyword, aiPrompt, langloc} = this.$route.query;
                if (keyword || aiPrompt || langloc) {
                    const [language_code, location_code] = (langloc as string || '-').split('-');
                    return {
                        keyword: (keyword as string),
                        aiPrompt: (aiPrompt as string),
                        language_code,
                        location_code
                    };
                } else return {};
            }
        },
        mounted: function () {
            if (Object.keys(this.queryParams).length) {
                this.form.aiPrompt = this.queryParams.aiPrompt;
            }
        },
        methods: {
            onKeywordInput: function (keywordInputForm: ArticleMetadata) {
                this.form.keyword = keywordInputForm.keyword;
                this.form.location_code = keywordInputForm.location_code;
                this.form.language_code = keywordInputForm.language_code;
                this.saveForm();
            },
            confirmForm: function () {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (this.$refs.form.validate()) {
                    this.aiConfirmed = true;
                    this.$nextTick(() => {
                        scrollIntoViewIfNotVisible('#keywordLocationLanguageFormAfter');
                    });
                }

            },
            saveForm: function () {
                if (Object.keys(this.queryParams).length) {
                    this.$router.replace({'query': {}});
                }
                this.$emit('input', this.form);
            }
        }
    });
</script>

<style scoped lang="sass">

</style>
