<template>
  <div :class="$attrs">
    <v-input
      :disabled="disabled"
      hide-details
      class="mb-2"
    >
      <template
        v-if="waitingForAi"
        #message
      >
        {{ $t('article.openAi.loading') }}
      </template>
      <template #prepend>
        <slot name="prepend" />
      </template>
      <ckeditor
        v-if="disabled || !waitingForAi"
        v-model="localValue"
        :editor="editor"
        :config="config"
        :words-to-highlight="wordsToHighlight"
        class="flex-grow-1 focusedStyleOnHover"
        :class="{
          'ck-border' : !disabled,
          'ck-border-disabled' : disabled,
          'text-h5' : isHeading,
          'ck-small-margin': isHeading,
          'ck-text-bold': isHeading
        }"
      />
      <template #append>
        <slot name="append" />
      </template>
    </v-input>
  </div>
</template>

<script lang="ts">
    import Vue, {PropType} from 'vue';
    import { InlineEditor } from '@ckeditor/ckeditor5-editor-inline';
    import { Autoformat } from '@ckeditor/ckeditor5-autoformat';
    import { Essentials } from '@ckeditor/ckeditor5-essentials';
    import {Bold, Italic} from '@ckeditor/ckeditor5-basic-styles';
    import { Link } from '@ckeditor/ckeditor5-link';
    import { List } from '@ckeditor/ckeditor5-list';
    import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
    import { Indent } from '@ckeditor/ckeditor5-indent';
    import { Highlight } from '@ckeditor/ckeditor5-highlight';

    export default Vue.extend({
        name: 'Editor',
        props: {
            value: {
                type: String,
                default: () => ''
            },
            placeholder: {
                type: String,
                default: null
            },
            wordsToHighlight: {
                type: Object as PropType<{ [key: string]: string[] }>,
                default: () => ({})
            },
            shouldListenForChange: {
                type: Boolean,
                default: false
            },
            waitingForAi: {
                type: Boolean,
                default: false
            },
            allowFormatting: {
                type: Boolean,
                default: true
            },
            disabled: {
                type: Boolean,
                default: false
            },
            isHeading: {
                type: Boolean,
                default: false
            }
        },
        // emits: ['input'],
        data: () => ({
            editor: InlineEditor,
            localValue: '',
        }),
        computed: {
            config: function (): any {
                return {
                    placeholder: this.placeholder,
                    toolbar: this.toolbar,
                    plugins: [
                        Autoformat,
                        Essentials,
                        Bold,
                        Italic,
                        Indent,
                        List,
                        Link,
                        Paragraph,
                        Highlight
                    ],
                };
            },
            toolbar: function (): string[] {
                if (!this.allowFormatting) {
                    return ['undo', 'redo'];
                }
                return [
                    'undo', 'redo', '|',
                    'bold', 'italic', '|',
                    'numberedList', 'bulletedList', '|',
                    'outdent', 'indent', '|',
                    'link'
                ];
            }
        },
        watch: {
            localValue: function () {
                this.$emit('input', this.localValue);
            },
            value: {
                immediate: true,
                handler: function () {
                    if (this.value !== null && this.shouldListenForChange) {
                        this.localValue = this.value;
                    }
                }
            }
        },
        created: function () {
            if (this.value) {
                this.localValue = this.value;
            }
        }
    });
</script>

<style lang="sass">
/*
 * source: CKEditor documentation - https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/frameworks/css.html
 */
body
    --ck-z-default: 100
    --ck-z-modal: calc(var(--ck-z-default) + 999)

.focusedStyleOnHover:hover
    outline: none
    border: var(--ck-focus-ring)
    box-shadow: var(--ck-inner-shadow),0 0

.ck.ck-content strong
    font-weight: bold

.ck.ck-content ul,
.ck.ck-content ul li
    list-style-type: inherit

.ck.ck-content ul
    padding-left: 40px

.ck.ck-content ol,
.ck.ck-content ol li
    list-style-type: decimal

.ck.ck-content ol
    padding-left: 40px

.ck.ck-content ul,
.ck.ck-content ul li
    list-style-type: inherit

.ck.ck-content ul
    padding-left: 40px

.ck.ck-content ol,
.ck.ck-content ol li
    list-style-type: decimal

.ck.ck-content ol
    padding-left: 40px

.ck input.ck-input.ck-input-text
    box-shadow: var(--ck-inner-shadow), 0 0
    background: var(--ck-color-input-background)
    border: 1px solid var(--ck-color-input-border)
    padding: var(--ck-spacing-extra-tiny) var(--ck-spacing-medium)
    transition-property: box-shadow, border
    transition: .2s ease-in-out

    height: inherit
    width: inherit
    font-size: inherit
    margin: 0
    box-sizing: border-box

.ck input.ck-input.ck-input-text:focus
    border: var(--ck-focus-ring)
    box-shadow: var(--ck-focus-outer-shadow), var(--ck-inner-shadow)

.ck-border
    // border-bottom: 1px solid var(--v-primary-base) !important
    margin-bottom: -4px

.ck-border-disabled
    border-bottom: 1px solid grey !important

.ck-small-margin p
    // Same as calculated for basic 'p'
    margin-bottom: 14.4px !important
    margin-top: 14.4px !important

.ck-text-bold p
    font-weight: 600

    strong
        font-weight: 800 !important
</style>
