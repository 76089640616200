<template>
  <div>
    <v-dialog
      v-model="leaveConfirmDialog"
      width="600"
    >
      <v-card>
        <v-card-title class="primaryGradientBackground whiteText">
          {{ $t('unsavedChanges.title') }}
        </v-card-title>
        <v-card-text class="mt-2">
          {{ $t('unsavedChanges.text') }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            outlined
            color="accent darken-1"
            text
            @click="onStay"
          >
            {{ $t('unsavedChanges.stay') }}
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            @click="nextRoute()"
          >
            <v-icon left>
              east
            </v-icon>
            {{ $t('unsavedChanges.leave') }}
          </v-btn>
          <v-btn
            color="accent"
            :loading="saving"
            @click="saveAndLeave"
          >
            <v-icon left>
              save
            </v-icon>
            {{ $t('unsavedChanges.saveAndLeave') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <UserLayout
      :article-step="articleStep"
      :seo-score-context="seoScoreContext"
      :disable-save="analyzeExtractionFailed || analyzeExtractionPartial"
    >
      <v-progress-linear
        v-if="loading"
        color="accent"
        indeterminate
      />
      <div v-else>
        <KeywordForm
          v-if="!article.keyword || !article.language_code || !article.location_code"
          @input="onKeywordInput"
        />
        <div v-else-if="!analyzeExtractionFailed && !analyzeExtractionPartial">
          <KeywordSelection
            :article-id="article.id"
            :article-metadata="article"
            :read-only="!!article.selectedKeyword"
            :ai-prompt-missing="!!article.selectedKeyword && !article.aiPrompt && !article.noAiPrompt"
            :is-analyze="isArticleAnalyze"
            @input="onKeywordSelected"
          />
          <ArticleCreation
            v-if="inArticleCreation"
            :preparing-ai="preparingAi"
            :seo-score-context="seoScoreContext"
            :is-analyze="isArticleAnalyze"
            @save="onArticleSave"
            @extractionFailed="onExtractionFailed"
            @extractionPartial="onExtractionPartial"
          />
        </div>
        <div
          v-else
          class="d-flex justify-center"
        >
          <v-card
            max-width="800"
            color="warning"
            :class="{'pie-card' : !$vuetify.theme.dark}"
            class="pa-8"
          >
            <v-card-title class="d-flex justify-center white--text pb-8">
              <h1 class="text-center">
                {{ analyzeExtractionPartial ? $t('article.analyze.scrapePartial.title') : $t('article.analyze.scrapeFail.title') }}
              </h1>
            </v-card-title>
            <v-card-text style="color: #fff">
              {{ analyzeExtractionPartial ? $t('article.analyze.scrapePartial.description') : $t('article.analyze.scrapeFail.description') }}
              <h3 class="d-flex justify-center pt-4 text-uppercase">
                {{ $t('article.analyze.scrapeFail.creditRefunded') }}
              </h3>
            </v-card-text>
            <v-card-actions
              class="d-flex flex-column align-center"
              style="row-gap: 12px"
            >
              <div
                v-for="button in analyzeExtractionButtons"
                :key="button.text"
                class="d-flex flex-column align-center"
                style="width: 100%"
              >
                <template v-if="button.show">
                  <v-btn
                    :loading="button.loading"
                    :disabled="button.disabled"
                    block
                    @click="button.click()"
                  >
                    <v-icon>
                      {{ button.icon }}
                    </v-icon>
                    <v-spacer />
                    <span class="pr-6">
                      {{ button.text }}
                    </span>
                    <v-spacer />
                  </v-btn>
                  <small class="whiteText">
                    {{ button.description }}
                  </small>
                </template>
              </div>
            </v-card-actions>
          </v-card>
        </div>
      </div>
    </UserLayout>
  </div>
</template>

<script lang="ts">
    import Vue from 'vue';
    import {Article, ArticleMetadata, ArticleQuestion, KeywordForAi} from "@/model/Article";
    import {Firestore} from "@/plugins/Firestore";
    import KeywordSelection from "@/components/keywords/KeywordSelection.vue";
    import ArticleCreation from "@/components/article/ArticleCreation.vue";
    import UserLayout from "@/layouts/UserLayout.vue";
    import KeywordForm from "@/components/keywords/KeywordForm.vue";
    import {NavigationGuardNext, Route} from "vue-router/types/router";
    import {EventBus} from '@/utils/EventBus';
    import {FlashMessage} from "@/model/FlashMessage";
    import {captureException} from "@/plugins/Sentry";
    import {Functions} from "@/plugins/Functions";
    import {Scroll} from "@/config/Behaviour";
    import {watchForAi} from "@/service/articleAiContent";
    import {seoScoreContext} from "@/model/SeoScore";
    import {getArticleSnapshotHash} from "@/utils/ArticleComputed";
    import {OrganicPositionRequest} from "@/model/DataForSeo";
    import {ScrapperResponse} from "@/model/Scrapper";

    type RouteNext = NavigationGuardNext | (() => any);
    type AnalyzeExtractionButton = {
        loading: boolean,
        disabled: boolean,
        text: string,
        description: string,
        show: boolean,
        click: () => void,
        icon: string
    }

    export default Vue.extend({
        name: "ArticleView",
        components: {KeywordForm, UserLayout, ArticleCreation, KeywordSelection},
        beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
            this.routeChangeGuard(next);
        },
        beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext) {
            this.routeChangeGuard(next);
        },
        data: () => ({
            loading: false,
            preparingAi: false,
            leaveConfirmDialog: false,
            nextRoute: null as null | RouteNext,
            saving: false,
            isTrial: false,
            seoScoreContext: seoScoreContext,
            analyzeExtractionFailed: false,
            analyzeExtractionPartial: false,
            analyzeExtractionButtonsLoading: {
                deleteArticle: false,
                partialContent: false,
                emptyContent: false
            },
            partialContent: null as null | ScrapperResponse
        }),
        computed: {
            article: {
                get: function () {
                    return this.$store.getters.article;
                },
                set: function (value: Article) {
                    return this.$store.dispatch('setArticle', value);
                }
            },
            articleId: function (): string {
                return this.$route.params.articleId as string;
            },
            articleStep: function (): number {
                if (!this.article.keyword || !this.article.language_code || !this.article.location_code) {
                    return 1;
                } else if (!this.article.selectedKeyword) {
                    return 2;
                } else {
                    return 3;
                }
            },
            inArticleCreation: function (): boolean {
                return !!(this.article.selectedKeyword && (this.article.noAiPrompt || this.article.aiPrompt || this.article.metaTitle));
            },
            isArticleAnalyze: function (): boolean {
                return this.article.isAnalyze;
            },
            analyzeExtractionButtons: function (): AnalyzeExtractionButton[] {
                return [
                    {
                        loading: this.analyzeExtractionButtonsLoading.partialContent,
                        disabled: this.analyzeExtractionButtonsLoading.deleteArticle || this.analyzeExtractionButtonsLoading.emptyContent,
                        text: this.$t('article.analyze.scrapePartial.openPartial.text') as string,
                        description: this.$t('article.analyze.scrapePartial.openPartial.description') as string,
                        show: this.analyzeExtractionPartial,
                        click: this.openPartialEditors,
                        icon: 'article'
                    },
                    {
                        loading: this.analyzeExtractionButtonsLoading.emptyContent,
                        disabled: this.analyzeExtractionButtonsLoading.deleteArticle || this.analyzeExtractionButtonsLoading.partialContent,
                        text: this.$t('article.analyze.scrapeFail.openEmpty.text') as string,
                        description: this.$t('article.analyze.scrapeFail.openEmpty.description') as string,
                        show: true,
                        click: this.openEmptyEditors,
                        icon: 'edit_document'
                    },
                    {
                        loading: this.analyzeExtractionButtonsLoading.deleteArticle,
                        disabled: this.analyzeExtractionButtonsLoading.partialContent || this.analyzeExtractionButtonsLoading.emptyContent,
                        text: this.$t('article.analyze.scrapeFail.deleteArticle.text') as string,
                        description: this.$t('article.analyze.scrapeFail.deleteArticle.description') as string,
                        show: true,
                        click: this.deleteArticle,
                        icon: 'delete'
                    },
                ];
            }
        },
        created: function () {
            // TODO: if the query is missing we are not able removeTrialUsed if something fails
            // example: user creates article without generating/analyzing (the green button) and leaves
            // when he comes back the trial is not present in the query
            if (this.$route.query.trial) {
                if (this.$route.query.trial === 'yes') {
                    this.isTrial = true;
                }
            }
            if (this.articleId) {
                if (this.$store.getters.authenticated) {
                    this.getArticle();
                } else {
                    this.loading = true;
                    this.$watch('$store.getters.authenticated', (value) => {
                        if (value) {
                            this.getArticle();
                        }
                    });
                }
            } else {
                window.console.error("No article uid provided");
                this.$router.push('/');
            }
            EventBus.$on('onArticleSave', (callback: (() => void)) => {
                this.onArticleSave(callback);
            });
            EventBus.$on('onArticleLeave', (callback: (() => void)) => {
                this.routeChangeGuard(callback);
            });
        },
        destroyed: function () {
            EventBus.$off('onArticleSave');
            EventBus.$off('onArticleLeave');
        },
        methods: {
            getArticle: function () {
                this.loading = true;
                this.article = new Article; // clear Store
                this.$store.commit('setArticleLastSavedSnapshotHash', null); // clear Store
                Firestore.getUserArticle(this.articleId)
                    .then((article: Article | null) => {
                        if (article) {
                            this.article = Article.fromObject(article);
                            // TODO: should get current data form editors (debounce-flush) and after that set last saved hash
                            // otherwise false-positive on routeChangeGuard (document does not have html tags but they are in editors)
                            this.$nextTick(() => {
                                this.$store.dispatch('setArticleLastSavedSnapshotHash');
                            });
                        } else {
                            window.console.error("Unable to fetch article");
                            this.$router.push('/');
                        }
                    })
                    .catch(error => {
                        this.$store.dispatch('displayFlashMessage', {
                            type: 'error',
                            content: 'Error while getting article.'
                        } as FlashMessage);
                        captureException(error);
                        this.$router.push('/');
                    }).finally(() => {
                        this.loading = false;
                    });
            },
            saveArticle: function () {
                EventBus.$emit('debounce-flush'); // flush all debounced emits to get current data
                return new Promise<void>((resolve) => {
                    this.$nextTick(() => { // wait for next event cycle for emits to finish
                        Firestore.saveUserArticle(this.article)
                            .then(articleId => {
                                this.$store.dispatch('setArticleLastSavedSnapshotHash');
                                this.article.id = articleId;
                            })
                            .catch(error => {
                                if (this.isTrial) {
                                    Firestore.removeTrialUsed();
                                }
                                this.$store.dispatch('displayFlashMessage', {
                                    type: 'error',
                                    content: 'Error while saving article.'
                                } as FlashMessage);
                                captureException(error);
                            })
                            .finally(() => {
                                resolve();
                            });
                    });
                });
            },
            saveAndLeave: function () {
                this.saving = true;
                EventBus.$emit('save');
            },
            routeChangeGuard: function (next: RouteNext) {
                if (!this.article || !this.article.selectedKeyword || this.analyzeExtractionFailed || this.analyzeExtractionPartial) {
                    next();
                }
                // flush all debounced emits to get current data
                EventBus.$emit('debounce-flush');
                // wait for next event cycle for emits to finish
                if (this.inArticleCreation) {
                    this.$nextTick(() => {
                        getArticleSnapshotHash(this.$store.getters.article).then(hash => {
                            if (this.$store.getters.articleLastSavedSnapshotHash !== hash) {
                                this.leaveConfirmDialog = true;
                                this.nextRoute = next;
                            } else {
                                next();
                            }
                        });
                    });
                } else {
                    next();
                }
            },
            onKeywordInput: function (input: ArticleMetadata) {
                this.$set(this.article, 'keyword', input.keyword);
                this.$set(this.article, 'aiPrompt', input.aiPrompt);
                if (!input.aiPrompt) {
                    this.$set(this.article, 'noAiPrompt', true);
                }
                this.$set(this.article, 'language_code', input.language_code);
                this.$set(this.article, 'location_code', input.location_code);
                this.saveArticle();
            },
            onKeywordSelected: function (keyword: string, aiPrompt: string, questions: ArticleQuestion[], keywordsForAi: KeywordForAi[]) {
                // TODO use proper store commit for all fields
                this.$set(this.article, 'selectedKeyword', keyword);
                this.$set(this.article, 'aiPrompt', aiPrompt);
                this.$set(this.article, 'keywordsForAi', keywordsForAi);
                if (!aiPrompt) {
                    // user did not supply AI prompt need to save this information
                    this.article.noAiPrompt = true;
                }

                if (this.isArticleAnalyze) {
                    // if we are in analyze mode we do not need to prepare AI content
                    this.saveArticle();
                    return;
                }

                for (const [index, question] of questions.entries()) {
                    this.$store.commit('setArticleQuestion', {
                        index: index,
                        questionKey: 'question',
                        value: question.question
                    });
                }
                this.preparingAi = true;
                this.saveArticle().then(() => {
                    if (this.article.id) {
                        Functions.OpenAIArticleGenerate({
                            articleId: this.article.id
                        }).then(() => {
                            watchForAi().then(() => {
                                this.preparingAi = false;
                            });
                        });
                        Firestore.updateArticleAIGeneratedAt(this.article);
                    }
                    this.$vuetify.goTo('#keywordSelectionContainer', {
                        duration: Scroll.duration,
                        offset: Scroll.offset
                    });
                });
            },
            onArticleSave: function (callback: () => void) {
                this.saveArticle().then(() => {
                    callback();
                    if (this.saving) {
                        if (this.nextRoute) {
                            this.nextRoute();
                        }
                        this.saving = false;
                    }
                });
            },
            onStay: function (): void {
                this.leaveConfirmDialog = false;
                this.nextRoute = null;
            },
            onExtractionFailed: function () {
                this.analyzeExtractionFailed = true;
            },
            onExtractionPartial: function (response: ScrapperResponse) {
                this.analyzeExtractionPartial = true;
                this.partialContent = response;
            },
            deleteArticle: function () {
                this.analyzeExtractionButtonsLoading.deleteArticle = true;
                new Promise((resolve) => {
                    if (this.isTrial) {
                        Firestore.removeUserArticle(this.article.id as string).then(() => {
                            resolve(Firestore.removeTrialUsed());
                        });
                    } else {
                        resolve(Functions.CancelArticle({
                            articleId: this.articleId,
                            force: true
                        }));
                    }
                }).then(() => {
                    this.$router.push('/');
                }).catch(error => {
                    this.$store.dispatch("handleApiError", error);
                }).finally(() => {
                    this.analyzeExtractionButtonsLoading.deleteArticle = false;
                });
            },
            openEmptyEditors: function () {
                this.analyzeExtractionButtonsLoading.emptyContent = true;
                this.analyzeExtractionPartial = false;
                this.analyzeExtractionFailed = false;
                this.partialContent = null;
                this.$store.dispatch('setArticleForcedEmpty')
                    .then(() => {
                        this.$store.commit('setArticleAnalyzeScrapeLoading', false);
                        this.analyzeExtractionButtonsLoading.emptyContent = false;
                    });
            },
            openPartialEditors: function () {
                this.analyzeExtractionButtonsLoading.partialContent = true;
                this.$store.dispatch('setArticleScrapedContent', this.partialContent)
                    .then(() => {
                        Functions.D4SGetOrganicPosition({
                            article_id: this.article.id,
                        } as OrganicPositionRequest);
                        this.$store.commit('setArticleAnalyzeScrapeLoading', false);
                        this.analyzeExtractionButtonsLoading.partialContent = false;
                        this.analyzeExtractionPartial = false;
                    });
            }
        }
    });
</script>

<style lang="sass" scoped>
.whiteText
    color: white !important
</style>
