var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.$store.getters.authenticated)?_c('div',{staticClass:"d-flex align-center",class:{
    'mr-5': _vm.$vuetify.breakpoint.mdAndUp
  }},[(_vm.hasPlanOrExtras)?_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.smAndUp),expression:"$vuetify.breakpoint.smAndUp"}],class:{
      'mx-2': _vm.$vuetify.breakpoint.mdAndUp
    },attrs:{"loading":_vm.loadingArticlesCreatedThisPeriod,"outlined":!_vm.hasLimitedPlan,"color":_vm.usageColor,"depressed":""},on:{"click":_vm.openAccount}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({},on),[(_vm.hasLimitedPlan)?[_c('v-fade-transition',{attrs:{"mode":"out-in"}},[_c('span',{key:_vm.articlesLeftThisPeriod},[_vm._v(" "+_vm._s(_vm.articlesLeftThisPeriod)+" ")])]),_vm._v(" / "),_c('v-fade-transition',{attrs:{"mode":"out-in"}},[_c('span',{key:_vm.maxInspirationsThisPeriod},[_vm._v(" "+_vm._s(_vm.maxInspirationsThisPeriod)+" ")])])]:[_c('v-fade-transition',{attrs:{"mode":"out-in"}},[_c('span',{key:_vm.articlesCreatedThisPeriod},[_vm._v(" "+_vm._s(_vm.articlesCreatedThisPeriod)+" ")])]),_vm._v(" / "),_c('v-fade-transition',{attrs:{"mode":"out-in"}},[_c('span',{key:_vm.maxInspirationsThisPeriod},[_vm._v(" ∞ ")])])],(_vm.extraInspirations > 0)?[_vm._v(" + "),_c('v-fade-transition',{attrs:{"mode":"out-in"}},[_c('span',{key:_vm.extraInspirations},[_vm._v(" "+_vm._s(_vm.extraInspirations)+" ")])])]:_vm._e()],2)]}}],null,false,173050861)},[_vm._v(" "+_vm._s(_vm.usageTooltip)+" ")])],1):_vm._e(),_c('span',{class:{
      'mx-2': _vm.$vuetify.breakpoint.mdAndUp
    }},[_c('v-tooltip',{attrs:{"nudge-bottom":"10px","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"text":"","small":_vm.$vuetify.breakpoint.mdAndDown},on:{"click":_vm.openAccount}},on),[_c('v-icon',[_vm._v(" account_circle ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.xl),expression:"$vuetify.breakpoint.xl"}],staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.user.name || _vm.user.email)+" ")])],1)]}}],null,false,507622674)},[_c('span',[_vm._v(_vm._s(_vm.user.email))]),_c('span',[_vm._v(" ("+_vm._s(_vm.planName)+") ")])])],1),_c('v-btn',{attrs:{"text":"","small":_vm.$vuetify.breakpoint.mdAndDown},on:{"click":_vm.logout}},[_c('v-icon',[_vm._v(" logout ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.xl),expression:"$vuetify.breakpoint.xl"}],staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.$t('logout'))+" ")])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }