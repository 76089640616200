import { render, staticRenderFns } from "./KeywordSelection.vue?vue&type=template&id=4bcbcb1c&scoped=true"
import script from "./KeywordSelection.vue?vue&type=script&lang=ts"
export * from "./KeywordSelection.vue?vue&type=script&lang=ts"
import style0 from "./KeywordSelection.vue?vue&type=style&index=0&id=4bcbcb1c&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.13_css-loader@6.11.0_webpack@5.98.0__ejs@3.1.1_da81010b62634e1624d752d96320e5d3/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bcbcb1c",
  null
  
)

export default component.exports