import {I18n} from "@/plugins/I18n";

const rules = {
    requiredRule: function (value: string): boolean | string {
        return !!value || I18n.t('errors.requiredField') as string;
    },

    maxWordsRule: function (maxWords: number): (value: string) => boolean | string {
        const message = I18n.t('errors.maxWords', [maxWords]) as string;
        return (value: string) => value.split(' ').length < maxWords || message;
    },

    validUrlRule: function (value: string): boolean | string {
        if (!value) return true;

        if (!value.startsWith('http://') && !value.startsWith('https://')) {
            return I18n.t('errors.startsWithProtocol') as string;
        }

        try {
            new URL(value);
            return true;
        } catch {
            return I18n.t('errors.invalidUrl') as string;
        }
    }
};

export {rules};
