<template>
  <v-sparkline
    v-if="show"
    :value="value"
    :gradient="gradient"
    :smooth="10"
    :padding="1"
    :line-width="2"
    :stroke-linecap="10"
    gradient-direction="top"
    type="trend"
    auto-draw
    :auto-draw-duration="cycle"
    :height="24"
  />
</template>

<script lang="ts">
    import Vue from "vue";
    import {themes} from "@/plugins/Vuetify";

    export default Vue.extend({
        name: "AISparkline",
        data: () => ({
            show: true,
            cycle: 2000,
            value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
        }),
        computed: {
            gradient: function () {
                if (this.$vuetify.theme.dark) {
                    return [themes.dark.ai1, themes.dark.ai2, themes.dark.ai3];
                } else {
                    return [themes.light.ai1, themes.light.ai2, themes.light.ai3];
                }
            }
        },
        created: function () {
            this.cycle += (Math.random() - 0.5) * 1000;
            setInterval(() => {
                this.show = false;
                this.value = this.value.map(() => Math.round(Math.random() * 10));
                this.$nextTick(() => {
                    this.show = true;
                });
            }, this.cycle);
        }
    });
</script>

<style scoped>
</style>
