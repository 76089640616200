<template>
  <v-card
    id="aiPrompt"
    class="aiCard d-flex flex-column align-center text-center ma-3 pa-5"
    dark
  >
    {{ $t('keywords.aiPrompt.description') }}
    <v-text-field
      v-model="prompt"
      :disabled="readOnly"
      :hint="$t('keywords.aiPrompt.promptExample')"
      background-color="#ffffff88"
      class="ma-3"
      outlined
      persistent-hint
      placeholder="..."
      required
    />
  </v-card>
</template>

<script lang="ts">
    import Vue from "vue";

    export default Vue.extend({
        name: "AIPrompt",
        // emits: ['input', 'continue'],
        props: {
            value: {
                type: String,
                default: ''
            },
            noPrompt: {
                type: Boolean,
                default: false
            },
            readOnly: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            prompt: '',
            noPromptLocal: false
        }),
        watch: {
            value: {
                immediate: true,
                handler: function () {
                    if (!this.prompt) {
                        this.prompt = this.value;
                    }
                }
            },
            prompt: function () {
                this.$emit('input', this.prompt);
            },
            noPrompt: {
                immediate: true,
                handler: function () {
                    this.noPromptLocal = this.noPrompt;
                }
            }
        }
    });
</script>

<style lang="sass" scoped>
.aiCard
    background: linear-gradient(90deg, var(--v-ai-base) 0%, var(--v-ai-darken4) 100%)
</style>

<style lang="sass">
.aiCard input
    color: var(--v-ai-darken4) !important

</style>
