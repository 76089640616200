// Do a manual refresh from https://sandbox.dataforseo.com/v3/dataforseo_labs/locations_and_languages
// Keep in sync with functions/src/CopyLeaks/locations_languages
const LocationLanguages = [
    {
        "location_code": 2012,
        "location_name": "Algeria",
        "location_code_parent": null,
        "country_iso_code": "DZ",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "French",
                "language_code": "fr",
                "keywords": 20487288,
                "serps": 1416735
            },
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Arabic",
                "language_code": "ar",
                "keywords": 6427952,
                "serps": 940654
            }
        ]
    },
    {
        "location_code": 2024,
        "location_name": "Angola",
        "location_code_parent": null,
        "country_iso_code": "AO",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Portuguese",
                "language_code": "pt",
                "keywords": 9831449,
                "serps": 1612967
            }
        ]
    },
    {
        "location_code": 2031,
        "location_name": "Azerbaijan",
        "location_code_parent": null,
        "country_iso_code": "AZ",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Azeri",
                "language_code": "az",
                "keywords": 12834097,
                "serps": 386701
            }
        ]
    },
    {
        "location_code": 2032,
        "location_name": "Argentina",
        "location_code_parent": null,
        "country_iso_code": "AR",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google",
                    "bing"
                ],
                "language_name": "Spanish",
                "language_code": "es",
                "keywords": 42320083,
                "serps": 3293393
            }
        ]
    },
    {
        "location_code": 2036,
        "location_name": "Australia",
        "location_code_parent": null,
        "country_iso_code": "AU",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google",
                    "bing"
                ],
                "language_name": "English",
                "language_code": "en",
                "keywords": 190692323,
                "serps": 14728752
            }
        ]
    },
    {
        "location_code": 2040,
        "location_name": "Austria",
        "location_code_parent": null,
        "country_iso_code": "AT",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google",
                    "bing"
                ],
                "language_name": "German",
                "language_code": "de",
                "keywords": 41993100,
                "serps": 3124227
            }
        ]
    },
    {
        "location_code": 2048,
        "location_name": "Bahrain",
        "location_code_parent": null,
        "country_iso_code": "BH",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Arabic",
                "language_code": "ar",
                "keywords": 7786715,
                "serps": 568848
            }
        ]
    },
    {
        "location_code": 2050,
        "location_name": "Bangladesh",
        "location_code_parent": null,
        "country_iso_code": "BD",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Bengali",
                "language_code": "bn",
                "keywords": 19348171,
                "serps": 1748607
            }
        ]
    },
    {
        "location_code": 2051,
        "location_name": "Armenia",
        "location_code_parent": null,
        "country_iso_code": "AM",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Armenian",
                "language_code": "hy",
                "keywords": 1061138,
                "serps": 128624
            }
        ]
    },
    {
        "location_code": 2056,
        "location_name": "Belgium",
        "location_code_parent": null,
        "country_iso_code": "BE",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "French",
                "language_code": "fr",
                "keywords": 23566715,
                "serps": 391077
            },
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Dutch",
                "language_code": "nl",
                "keywords": 47024242,
                "serps": 3136739
            },
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "German",
                "language_code": "de",
                "keywords": 12219442,
                "serps": 895642
            }
        ]
    },
    {
        "location_code": 2068,
        "location_name": "Bolivia",
        "location_code_parent": null,
        "country_iso_code": "BO",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Spanish",
                "language_code": "es",
                "keywords": 5642257,
                "serps": 386880
            }
        ]
    },
    {
        "location_code": 2076,
        "location_name": "Brazil",
        "location_code_parent": null,
        "country_iso_code": "BR",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Portuguese",
                "language_code": "pt",
                "keywords": 101210071,
                "serps": 12535323
            }
        ]
    },
    {
        "location_code": 2100,
        "location_name": "Bulgaria",
        "location_code_parent": null,
        "country_iso_code": "BG",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Bulgarian",
                "language_code": "bg",
                "keywords": 5256310,
                "serps": 507600
            }
        ]
    },
    {
        "location_code": 2104,
        "location_name": "Myanmar (Burma)",
        "location_code_parent": null,
        "country_iso_code": "MM",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google",
                    "bing"
                ],
                "language_name": "English",
                "language_code": "en",
                "keywords": 9659947,
                "serps": 894812
            }
        ]
    },
    {
        "location_code": 2112,
        "location_name": "Belarus",
        "location_code_parent": null,
        "country_iso_code": "BY",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Russian",
                "language_code": "ru",
                "keywords": 6445962,
                "serps": 936163
            }
        ]
    },
    {
        "location_code": 2116,
        "location_name": "Cambodia",
        "location_code_parent": null,
        "country_iso_code": "KH",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google",
                    "bing"
                ],
                "language_name": "English",
                "language_code": "en",
                "keywords": 9635173,
                "serps": 897332
            }
        ]
    },
    {
        "location_code": 2120,
        "location_name": "Cameroon",
        "location_code_parent": null,
        "country_iso_code": "CM",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "French",
                "language_code": "fr",
                "keywords": 3881601,
                "serps": 464645
            }
        ]
    },
    {
        "location_code": 2124,
        "location_name": "Canada",
        "location_code_parent": null,
        "country_iso_code": "CA",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google",
                    "bing"
                ],
                "language_name": "English",
                "language_code": "en",
                "keywords": 214045496,
                "serps": 15431194
            },
            {
                "available_sources": [
                    "google",
                    "bing"
                ],
                "language_name": "French",
                "language_code": "fr",
                "keywords": 110356192,
                "serps": 8195289
            }
        ]
    },
    {
        "location_code": 2144,
        "location_name": "Sri Lanka",
        "location_code_parent": null,
        "country_iso_code": "LK",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "English",
                "language_code": "en",
                "keywords": 11930675,
                "serps": 991269
            }
        ]
    },
    {
        "location_code": 2152,
        "location_name": "Chile",
        "location_code_parent": null,
        "country_iso_code": "CL",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google",
                    "bing"
                ],
                "language_name": "Spanish",
                "language_code": "es",
                "keywords": 30858113,
                "serps": 1881416
            }
        ]
    },
    {
        "location_code": 2158,
        "location_name": "Taiwan",
        "location_code_parent": null,
        "country_iso_code": "TW",
        "location_type": "Region",
        "available_languages": [
            {
                "available_sources": [
                    "google",
                    "bing"
                ],
                "language_name": "Chinese (Traditional)",
                "language_code": "zh-TW",
                "keywords": 40960456,
                "serps": 3242355
            }
        ]
    },
    {
        "location_code": 2170,
        "location_name": "Colombia",
        "location_code_parent": null,
        "country_iso_code": "CO",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google",
                    "bing"
                ],
                "language_name": "Spanish",
                "language_code": "es",
                "keywords": 36568768,
                "serps": 1952729
            }
        ]
    },
    {
        "location_code": 2188,
        "location_name": "Costa Rica",
        "location_code_parent": null,
        "country_iso_code": "CR",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Spanish",
                "language_code": "es",
                "keywords": 9507426,
                "serps": 509123
            }
        ]
    },
    {
        "location_code": 2191,
        "location_name": "Croatia",
        "location_code_parent": null,
        "country_iso_code": "HR",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Croatian",
                "language_code": "hr",
                "keywords": 4685950,
                "serps": 506851
            }
        ]
    },
    {
        "location_code": 2196,
        "location_name": "Cyprus",
        "location_code_parent": null,
        "country_iso_code": "CY",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Greek",
                "language_code": "el",
                "keywords": 13892520,
                "serps": 557269
            },
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "English",
                "language_code": "en",
                "keywords": 10797621,
                "serps": 522133
            }
        ]
    },
    {
        "location_code": 2203,
        "location_name": "Czechia",
        "location_code_parent": null,
        "country_iso_code": "CZ",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Czech",
                "language_code": "cs",
                "keywords": 14029430,
                "serps": 1550277
            }
        ]
    },
    {
        "location_code": 2208,
        "location_name": "Denmark",
        "location_code_parent": null,
        "country_iso_code": "DK",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google",
                    "bing"
                ],
                "language_name": "Danish",
                "language_code": "da",
                "keywords": 18509574,
                "serps": 2558670
            }
        ]
    },
    {
        "location_code": 2218,
        "location_name": "Ecuador",
        "location_code_parent": null,
        "country_iso_code": "EC",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Spanish",
                "language_code": "es",
                "keywords": 13749949,
                "serps": 964655
            }
        ]
    },
    {
        "location_code": 2222,
        "location_name": "El Salvador",
        "location_code_parent": null,
        "country_iso_code": "SV",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Spanish",
                "language_code": "es",
                "keywords": 5318510,
                "serps": 332334
            }
        ]
    },
    {
        "location_code": 2233,
        "location_name": "Estonia",
        "location_code_parent": null,
        "country_iso_code": "EE",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Estonian",
                "language_code": "et",
                "keywords": 2180566,
                "serps": 293972
            }
        ]
    },
    {
        "location_code": 2246,
        "location_name": "Finland",
        "location_code_parent": null,
        "country_iso_code": "FI",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google",
                    "bing"
                ],
                "language_name": "Finnish",
                "language_code": "fi",
                "keywords": 15105354,
                "serps": 2049874
            }
        ]
    },
    {
        "location_code": 2250,
        "location_name": "France",
        "location_code_parent": null,
        "country_iso_code": "FR",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google",
                    "bing"
                ],
                "language_name": "French",
                "language_code": "fr",
                "keywords": 143969290,
                "serps": 16838713
            }
        ]
    },
    {
        "location_code": 2276,
        "location_name": "Germany",
        "location_code_parent": null,
        "country_iso_code": "DE",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google",
                    "bing"
                ],
                "language_name": "German",
                "language_code": "de",
                "keywords": 172957505,
                "serps": 22938625
            }
        ]
    },
    {
        "location_code": 2288,
        "location_name": "Ghana",
        "location_code_parent": null,
        "country_iso_code": "GH",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "English",
                "language_code": "en",
                "keywords": 6312098,
                "serps": 584312
            }
        ]
    },
    {
        "location_code": 2300,
        "location_name": "Greece",
        "location_code_parent": null,
        "country_iso_code": "GR",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Greek",
                "language_code": "el",
                "keywords": 13987029,
                "serps": 1603054
            }
        ]
    },
    {
        "location_code": 2320,
        "location_name": "Guatemala",
        "location_code_parent": null,
        "country_iso_code": "GT",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Spanish",
                "language_code": "es",
                "keywords": 9894919,
                "serps": 515541
            }
        ]
    },
    {
        "location_code": 2344,
        "location_name": "Hong Kong",
        "location_code_parent": null,
        "country_iso_code": "HK",
        "location_type": "Region",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "English",
                "language_code": "en",
                "keywords": 18326461,
                "serps": 1474109
            },
            {
                "available_sources": [
                    "google",
                    "bing"
                ],
                "language_name": "Chinese (Traditional)",
                "language_code": "zh-TW",
                "keywords": 48864311,
                "serps": 3137429
            }
        ]
    },
    {
        "location_code": 2348,
        "location_name": "Hungary",
        "location_code_parent": null,
        "country_iso_code": "HU",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Hungarian",
                "language_code": "hu",
                "keywords": 10263949,
                "serps": 1046186
            }
        ]
    },
    {
        "location_code": 2356,
        "location_name": "India",
        "location_code_parent": null,
        "country_iso_code": "IN",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google",
                    "bing"
                ],
                "language_name": "English",
                "language_code": "en",
                "keywords": 164823334,
                "serps": 14184952
            },
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Hindi",
                "language_code": "hi",
                "keywords": 71635025,
                "serps": 12919089
            }
        ]
    },
    {
        "location_code": 2360,
        "location_name": "Indonesia",
        "location_code_parent": null,
        "country_iso_code": "ID",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google",
                    "bing"
                ],
                "language_name": "English",
                "language_code": "en",
                "keywords": 35532667,
                "serps": 4287161
            },
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Indonesian",
                "language_code": "id",
                "keywords": 74820413,
                "serps": 6746218
            }
        ]
    },
    {
        "location_code": 2372,
        "location_name": "Ireland",
        "location_code_parent": null,
        "country_iso_code": "IE",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google",
                    "bing"
                ],
                "language_name": "English",
                "language_code": "en",
                "keywords": 43400044,
                "serps": 2432760
            }
        ]
    },
    {
        "location_code": 2376,
        "location_name": "Israel",
        "location_code_parent": null,
        "country_iso_code": "IL",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Hebrew",
                "language_code": "he",
                "keywords": 17059732,
                "serps": 2080483
            },
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Arabic",
                "language_code": "ar",
                "keywords": 8289259,
                "serps": 499942
            }
        ]
    },
    {
        "location_code": 2380,
        "location_name": "Italy",
        "location_code_parent": null,
        "country_iso_code": "IT",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google",
                    "bing"
                ],
                "language_name": "Italian",
                "language_code": "it",
                "keywords": 88777119,
                "serps": 9969576
            }
        ]
    },
    {
        "location_code": 2384,
        "location_name": "Cote d'Ivoire",
        "location_code_parent": null,
        "country_iso_code": "CI",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "French",
                "language_code": "fr",
                "keywords": 4912830,
                "serps": 584481
            }
        ]
    },
    {
        "location_code": 2392,
        "location_name": "Japan",
        "location_code_parent": null,
        "country_iso_code": "JP",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Japanese",
                "language_code": "ja",
                "keywords": 184999468,
                "serps": 17166136
            }
        ]
    },
    {
        "location_code": 2398,
        "location_name": "Kazakhstan",
        "location_code_parent": null,
        "country_iso_code": "KZ",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Russian",
                "language_code": "ru",
                "keywords": 10757229,
                "serps": 759231
            }
        ]
    },
    {
        "location_code": 2400,
        "location_name": "Jordan",
        "location_code_parent": null,
        "country_iso_code": "JO",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Arabic",
                "language_code": "ar",
                "keywords": 5365837,
                "serps": 550738
            }
        ]
    },
    {
        "location_code": 2404,
        "location_name": "Kenya",
        "location_code_parent": null,
        "country_iso_code": "KE",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "English",
                "language_code": "en",
                "keywords": 15739960,
                "serps": 1463712
            }
        ]
    },
    {
        "location_code": 2410,
        "location_name": "South Korea",
        "location_code_parent": null,
        "country_iso_code": "KR",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Korean",
                "language_code": "ko",
                "keywords": 34904326,
                "serps": 8017051
            }
        ]
    },
    {
        "location_code": 2428,
        "location_name": "Latvia",
        "location_code_parent": null,
        "country_iso_code": "LV",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Latvian",
                "language_code": "lv",
                "keywords": 6141185,
                "serps": 557095
            }
        ]
    },
    {
        "location_code": 2440,
        "location_name": "Lithuania",
        "location_code_parent": null,
        "country_iso_code": "LT",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Lithuanian",
                "language_code": "lt",
                "keywords": 3565553,
                "serps": 544485
            }
        ]
    },
    {
        "location_code": 2458,
        "location_name": "Malaysia",
        "location_code_parent": null,
        "country_iso_code": "MY",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google",
                    "bing"
                ],
                "language_name": "English",
                "language_code": "en",
                "keywords": 26311709,
                "serps": 2211693
            },
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Malay",
                "language_code": "ms",
                "keywords": 25253183,
                "serps": 2589088
            }
        ]
    },
    {
        "location_code": 2470,
        "location_name": "Malta",
        "location_code_parent": null,
        "country_iso_code": "MT",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "English",
                "language_code": "en",
                "keywords": 2235880,
                "serps": 93141
            }
        ]
    },
    {
        "location_code": 2484,
        "location_name": "Mexico",
        "location_code_parent": null,
        "country_iso_code": "MX",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google",
                    "bing"
                ],
                "language_name": "Spanish",
                "language_code": "es",
                "keywords": 69874229,
                "serps": 6688097
            }
        ]
    },
    {
        "location_code": 2504,
        "location_name": "Morocco",
        "location_code_parent": null,
        "country_iso_code": "MA",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Arabic",
                "language_code": "ar",
                "keywords": 6574966,
                "serps": 940264
            }
        ]
    },
    {
        "location_code": 2528,
        "location_name": "Netherlands",
        "location_code_parent": null,
        "country_iso_code": "NL",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google",
                    "bing"
                ],
                "language_name": "Dutch",
                "language_code": "nl",
                "keywords": 61238495,
                "serps": 6440290
            }
        ]
    },
    {
        "location_code": 2554,
        "location_name": "New Zealand",
        "location_code_parent": null,
        "country_iso_code": "NZ",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google",
                    "bing"
                ],
                "language_name": "English",
                "language_code": "en",
                "keywords": 24854046,
                "serps": 1518579
            }
        ]
    },
    {
        "location_code": 2558,
        "location_name": "Nicaragua",
        "location_code_parent": null,
        "country_iso_code": "NI",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Spanish",
                "language_code": "es",
                "keywords": 2773182,
                "serps": 158550
            }
        ]
    },
    {
        "location_code": 2566,
        "location_name": "Nigeria",
        "location_code_parent": null,
        "country_iso_code": "NG",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "English",
                "language_code": "en",
                "keywords": 10564924,
                "serps": 933601
            }
        ]
    },
    {
        "location_code": 2578,
        "location_name": "Norway",
        "location_code_parent": null,
        "country_iso_code": "NO",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google",
                    "bing"
                ],
                "language_name": "Norwegian (Bokmål)",
                "language_code": "nb",
                "keywords": 19058820,
                "serps": 2560833
            }
        ]
    },
    {
        "location_code": 2586,
        "location_name": "Pakistan",
        "location_code_parent": null,
        "country_iso_code": "PK",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "English",
                "language_code": "en",
                "keywords": 23440097,
                "serps": 1581588
            },
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Urdu",
                "language_code": "ur",
                "keywords": 17068416,
                "serps": 519907
            }
        ]
    },
    {
        "location_code": 2600,
        "location_name": "Paraguay",
        "location_code_parent": null,
        "country_iso_code": "PY",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Spanish",
                "language_code": "es",
                "keywords": 4990587,
                "serps": 330671
            }
        ]
    },
    {
        "location_code": 2604,
        "location_name": "Peru",
        "location_code_parent": null,
        "country_iso_code": "PE",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google",
                    "bing"
                ],
                "language_name": "Spanish",
                "language_code": "es",
                "keywords": 26652244,
                "serps": 1495781
            }
        ]
    },
    {
        "location_code": 2608,
        "location_name": "Philippines",
        "location_code_parent": null,
        "country_iso_code": "PH",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google",
                    "bing"
                ],
                "language_name": "English",
                "language_code": "en",
                "keywords": 26263392,
                "serps": 2209048
            },
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Tagalog",
                "language_code": "tl",
                "keywords": 24536394,
                "serps": 2186519
            }
        ]
    },
    {
        "location_code": 2616,
        "location_name": "Poland",
        "location_code_parent": null,
        "country_iso_code": "PL",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Polish",
                "language_code": "pl",
                "keywords": 34147489,
                "serps": 2898493
            }
        ]
    },
    {
        "location_code": 2620,
        "location_name": "Portugal",
        "location_code_parent": null,
        "country_iso_code": "PT",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Portuguese",
                "language_code": "pt",
                "keywords": 20109408,
                "serps": 1923822
            }
        ]
    },
    {
        "location_code": 2642,
        "location_name": "Romania",
        "location_code_parent": null,
        "country_iso_code": "RO",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Romanian",
                "language_code": "ro",
                "keywords": 13108404,
                "serps": 1578704
            }
        ]
    },
    {
        "location_code": 2643,
        "location_name": "Russia",
        "location_code_parent": null,
        "country_iso_code": "RU",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Russian",
                "language_code": "ru",
                "keywords": 71751146,
                "serps": 6654435
            }
        ]
    },
    {
        "location_code": 2682,
        "location_name": "Saudi Arabia",
        "location_code_parent": null,
        "country_iso_code": "SA",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Arabic",
                "language_code": "ar",
                "keywords": 28373623,
                "serps": 3915437
            }
        ]
    },
    {
        "location_code": 2686,
        "location_name": "Senegal",
        "location_code_parent": null,
        "country_iso_code": "SN",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "French",
                "language_code": "fr",
                "keywords": 2887716,
                "serps": 309850
            }
        ]
    },
    {
        "location_code": 2688,
        "location_name": "Serbia",
        "location_code_parent": null,
        "country_iso_code": "RS",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Serbian",
                "language_code": "sr",
                "keywords": 3948065,
                "serps": 468810
            }
        ]
    },
    {
        "location_code": 2702,
        "location_name": "Singapore",
        "location_code_parent": null,
        "country_iso_code": "SG",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google",
                    "bing"
                ],
                "language_name": "English",
                "language_code": "en",
                "keywords": 45297803,
                "serps": 2481137
            },
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Chinese (Simplified)",
                "language_code": "zh-CN",
                "keywords": 24038369,
                "serps": 985712
            }
        ]
    },
    {
        "location_code": 2703,
        "location_name": "Slovakia",
        "location_code_parent": null,
        "country_iso_code": "SK",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Slovak",
                "language_code": "sk",
                "keywords": 7126324,
                "serps": 983721
            }
        ]
    },
    {
        "location_code": 2704,
        "location_name": "Vietnam",
        "location_code_parent": null,
        "country_iso_code": "VN",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google",
                    "bing"
                ],
                "language_name": "English",
                "language_code": "en",
                "keywords": 10340618,
                "serps": 938051
            },
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Vietnamese",
                "language_code": "vi",
                "keywords": 26209277,
                "serps": 1571149
            }
        ]
    },
    {
        "location_code": 2705,
        "location_name": "Slovenia",
        "location_code_parent": null,
        "country_iso_code": "SI",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Slovenian",
                "language_code": "sl",
                "keywords": 3694575,
                "serps": 518626
            }
        ]
    },
    {
        "location_code": 2710,
        "location_name": "South Africa",
        "location_code_parent": null,
        "country_iso_code": "ZA",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "English",
                "language_code": "en",
                "keywords": 35930227,
                "serps": 2426129
            }
        ]
    },
    {
        "location_code": 2724,
        "location_name": "Spain",
        "location_code_parent": null,
        "country_iso_code": "ES",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google",
                    "bing"
                ],
                "language_name": "Spanish",
                "language_code": "es",
                "keywords": 110505134,
                "serps": 8401763
            }
        ]
    },
    {
        "location_code": 2752,
        "location_name": "Sweden",
        "location_code_parent": null,
        "country_iso_code": "SE",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google",
                    "bing"
                ],
                "language_name": "Swedish",
                "language_code": "sv",
                "keywords": 24492925,
                "serps": 2912350
            }
        ]
    },
    {
        "location_code": 2756,
        "location_name": "Switzerland",
        "location_code_parent": null,
        "country_iso_code": "CH",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google",
                    "bing"
                ],
                "language_name": "German",
                "language_code": "de",
                "keywords": 57248840,
                "serps": 3525858
            },
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "French",
                "language_code": "fr",
                "keywords": 63568575,
                "serps": 1500639
            }
        ]
    },
    {
        "location_code": 2764,
        "location_name": "Thailand",
        "location_code_parent": null,
        "country_iso_code": "TH",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Thai",
                "language_code": "th",
                "keywords": 30211777,
                "serps": 3013957
            }
        ]
    },
    {
        "location_code": 2784,
        "location_name": "United Arab Emirates",
        "location_code_parent": null,
        "country_iso_code": "AE",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Arabic",
                "language_code": "ar",
                "keywords": 26136420,
                "serps": 1520483
            },
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "English",
                "language_code": "en",
                "keywords": 26781341,
                "serps": 1453237
            }
        ]
    },
    {
        "location_code": 2788,
        "location_name": "Tunisia",
        "location_code_parent": null,
        "country_iso_code": "TN",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Arabic",
                "language_code": "ar",
                "keywords": 6131019,
                "serps": 454212
            }
        ]
    },
    {
        "location_code": 2792,
        "location_name": "Turkey",
        "location_code_parent": null,
        "country_iso_code": "TR",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Turkish",
                "language_code": "tr",
                "keywords": 35991719,
                "serps": 3069027
            }
        ]
    },
    {
        "location_code": 2804,
        "location_name": "Ukraine",
        "location_code_parent": null,
        "country_iso_code": "UA",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Ukrainian",
                "language_code": "uk",
                "keywords": 95437208,
                "serps": 383392
            },
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Russian",
                "language_code": "ru",
                "keywords": 95980619,
                "serps": 10048120
            }
        ]
    },
    {
        "location_code": 2807,
        "location_name": "North Macedonia",
        "location_code_parent": null,
        "country_iso_code": "MK",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Macedonian",
                "language_code": "mk",
                "keywords": 6736763,
                "serps": 413119
            }
        ]
    },
    {
        "location_code": 2818,
        "location_name": "Egypt",
        "location_code_parent": null,
        "country_iso_code": "EG",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Arabic",
                "language_code": "ar",
                "keywords": 22593874,
                "serps": 2725384
            },
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "English",
                "language_code": "en",
                "keywords": 10684057,
                "serps": 671226
            }
        ]
    },
    {
        "location_code": 2826,
        "location_name": "United Kingdom",
        "location_code_parent": null,
        "country_iso_code": "GB",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google",
                    "bing"
                ],
                "language_name": "English",
                "language_code": "en",
                "keywords": 310616949,
                "serps": 31991149
            }
        ]
    },
    {
        "location_code": 2840,
        "location_name": "United States",
        "location_code_parent": null,
        "country_iso_code": "US",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google",
                    "bing"
                ],
                "language_name": "English",
                "language_code": "en",
                "keywords": 826513106,
                "serps": 177248294
            },
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Spanish",
                "language_code": "es",
                "keywords": 94741374,
                "serps": 7240197
            }
        ]
    },
    {
        "location_code": 2854,
        "location_name": "Burkina Faso",
        "location_code_parent": null,
        "country_iso_code": "BF",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "French",
                "language_code": "fr",
                "keywords": 3041499,
                "serps": 309169
            }
        ]
    },
    {
        "location_code": 2858,
        "location_name": "Uruguay",
        "location_code_parent": null,
        "country_iso_code": "UY",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google"
                ],
                "language_name": "Spanish",
                "language_code": "es",
                "keywords": 8585840,
                "serps": 486076
            }
        ]
    },
    {
        "location_code": 2862,
        "location_name": "Venezuela",
        "location_code_parent": null,
        "country_iso_code": "VE",
        "location_type": "Country",
        "available_languages": [
            {
                "available_sources": [
                    "google",
                    "bing"
                ],
                "language_name": "Spanish",
                "language_code": "es",
                "keywords": 35246531,
                "serps": 2605978
            }
        ]
    }
];

export {LocationLanguages};
