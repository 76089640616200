<template>
  <div
    v-if="$store.getters.authenticated"
    class="d-flex align-center"
    :class="{
      'mr-5': $vuetify.breakpoint.mdAndUp
    }"
  >
    <v-btn
      v-if="hasPlanOrExtras"
      v-show="$vuetify.breakpoint.smAndUp"
      :loading="loadingArticlesCreatedThisPeriod"
      :outlined="!hasLimitedPlan"
      :color="usageColor"
      :class="{
        'mx-2': $vuetify.breakpoint.mdAndUp
      }"
      depressed
      @click="openAccount"
    >
      <v-tooltip bottom>
        <template #activator="{ on }">
          <span v-on="on">
            <template v-if="hasLimitedPlan">
              <v-fade-transition mode="out-in">
                <span :key="articlesLeftThisPeriod">
                  {{ articlesLeftThisPeriod }}
                </span>
              </v-fade-transition>
              /
              <v-fade-transition mode="out-in">
                <span :key="maxInspirationsThisPeriod">
                  {{ maxInspirationsThisPeriod }}
                </span>
              </v-fade-transition>
            </template>
            <template v-else>
              <v-fade-transition mode="out-in">
                <span :key="articlesCreatedThisPeriod">
                  {{ articlesCreatedThisPeriod }}
                </span>
              </v-fade-transition>
              /
              <v-fade-transition mode="out-in">
                <span :key="maxInspirationsThisPeriod">
                  ∞
                </span>
              </v-fade-transition>
            </template>
            <template v-if="extraInspirations > 0">
              +
              <v-fade-transition mode="out-in">
                <span :key="extraInspirations">
                  {{ extraInspirations }}
                </span>
              </v-fade-transition>
            </template>
          </span>
        </template>
        {{ usageTooltip }}
      </v-tooltip>
    </v-btn>
    <span
      :class="{
        'mx-2': $vuetify.breakpoint.mdAndUp
      }"
    >
      <v-tooltip
        nudge-bottom="10px"
        bottom
      >
        <template #activator="{ on }">
          <v-btn
            text
            :small="$vuetify.breakpoint.mdAndDown"
            v-on="on"
            @click="openAccount"
          >
            <v-icon>
              account_circle
            </v-icon>
            <span
              v-show="$vuetify.breakpoint.xl"
              class="ml-2"
            >
              {{ user.name || user.email }}
            </span>
          </v-btn>
        </template>
        <span>{{ user.email }}</span>
        <span>
          ({{ planName }})
        </span>
      </v-tooltip>
    </span>
    <v-btn
      text
      :small="$vuetify.breakpoint.mdAndDown"
      @click="logout"
    >
      <v-icon>
        logout
      </v-icon>
      <span
        v-show="$vuetify.breakpoint.xl"
        class="ml-2"
      >
        {{ $t('logout') }}
      </span>
    </v-btn>
  </div>
</template>

<script lang="ts">
    import Vue from "vue";
    import UserPlanMixin from "@/mixins/UserPlanMixin";
    import {EventBus} from "@/utils/EventBus";

    export default Vue.extend({
        name: "AppBarUserInfo",
        mixins: [UserPlanMixin],
        methods: {
            openAccount: function () {
                if (this.$route.path !== '/account') {
                    this.$router.push('/account');
                }
            },
            logout: function () {
                if (this.$route.path.split('/')[1] === 'article') {
                    EventBus.$emit('onArticleLeave', () => {
                        this.$store.dispatch('logout');
                    });
                } else {
                    this.$store.dispatch('logout');
                }
            }
        }
    });
</script>

<style scoped>

</style>
