<template>
  <v-card
    :id="part + '-inspiration'"
    flat
    dark
    class="pa-4 ma-2 mr-0 primaryGradientBackground"
  >
    <div class="text-h5 mb-2">
      {{ $t('article.inspirationFor.title', [$t('article.label.' + part)]) }}
    </div>
    <div
      v-for="(inspiration, index) of Object.values(inspirations)"
      :key="index"
    >
      <ArticleInspirationPart
        v-for="(subPart, i) of inspiration[part]"
        :key="i + 's'"
        :site="inspiration.site"
        :content="subPart"
        :words-to-highlight="wordsToHighlight"
      />
    </div>
    <v-skeleton-loader
      v-if="loadingInspirations"
      :type="'paragraph@' + skeletonCounter"
      class="skeleton-right"
    />
    <span
      v-if="showNoInspiration"
      class="caption"
    >
      {{ $t('article.noInspiration') }}
    </span>
  </v-card>
</template>

<script lang="ts">
    import Vue, {PropType} from "vue";
    import ArticleInspirationPart from "@/components/article/ArticleInspirationPart.vue";
    import ArticleInspirationMixin from "@/mixins/ArticleInspirationMixin";

    export default Vue.extend({
        name: "ArticleContentInspirations",
        components: {ArticleInspirationPart},
        mixins: [ArticleInspirationMixin],
        props: {
            wordsToHighlight: {
                type: Object as PropType<{[key: string]: string[] }>,
                default: () => ({})
            }
        },
        computed: {
            inspirationsForPart: function (): number {
                // @ts-ignore - in mixin
                return Object.values(this.inspirations)
                    // @ts-ignore - in mixin
                    .map((site: any) => site[this.part].length)
                    .reduce((a, b) => a + b, 0);
            }
        }
    });
</script>

<style lang="sass">
.skeleton-right .v-skeleton-loader__paragraph .v-skeleton-loader__text
    &:nth-child(2)
        margin-left: 50%

    &:nth-child(3)
        margin-left: 30%
</style>
