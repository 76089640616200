<template>
  <div
    v-if="show && plainContent"
  >
    <v-divider />
    <div
      class="to-highlight-part pa-1"
      :class="{
        'colored': !short
      }"
      @click="short = false"
    >
      <div class="d-flex align-start">
        <div
          class="mr-2 d-flex flex-column"
        >
          <v-tooltip
            right
            max-width="170px"
          >
            <template #activator="{ on }">
              <v-chip
                light
                color="white"
                outlined
                small
                v-on="on"
              >
                {{ wordsCount }}
              </v-chip>
            </template>
            <span>
              {{ $t("article.keyWordsCount") }}
            </span>
          </v-tooltip>
          <v-tooltip
            right
            max-width="170px"
          >
            <template #activator="{ on }">
              <v-btn
                v-show="!short"
                icon
                text
                rounded
                v-on="on"
                @click.stop="short = true"
              >
                <v-icon>
                  expand_less
                </v-icon>
              </v-btn>
            </template>
            <span>
              {{ $t("close") }}
            </span>
          </v-tooltip>
        </div>
        <v-tooltip
          v-model="tooltip"
          bottom
        >
          {{ $t('article.copiedToClipboard') }}
          <template #activator="{ attr }">
            <span
              class="mr-1"
              :class="{
                'ellipsis': short
              }"
              :title="$t('article.inspirationDetail')"
              v-bind="attr"
              v-html="displayedContent"
            />
          </template>
        </v-tooltip>
      </div>
      <div
        v-show="!short"
        class="my-3"
      >
        <a
          target="_blank"
          :href="site"
          :title="site"
          class="text-decoration-none"
        >
          <v-chip
            class="chip-clickable"
            outlined
            small
          >
            <v-icon
              small
              class="mr-2"
            >
              open_in_new
            </v-icon>
            {{ site }}
          </v-chip>
        </a>
        <v-chip
          v-for="(button, index) of buttons"
          :key="index"
          class="chip-clickable"
          outlined
          small
          @click="button.action"
        >
          <v-icon
            small
            class="mr-2"
          >
            {{ button.icon }}
          </v-icon>
          {{ $t(button.label) }}
        </v-chip>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
    import Vue from 'vue';
    import HighlightWordsMixin from "@/mixins/HighlightWordsMixin";
    import {copyToClipboard} from "@/service/export";
    import {FlashMessage} from "@/model/FlashMessage";
    import {captureException} from "@/plugins/Sentry";
    import {stripTagsAndEntities} from "@/utils/string";

    interface Button {
        icon: string;
        label: string;
        action: () => void;
    }

    export default Vue.extend({
        name: "ArticleInspirationPart",
        mixins: [HighlightWordsMixin],
        props: {
            site: {
                type: String,
                default: ''
            },
            content: {
                type: String,
                default: '',
            },
            smallWidth: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            show: true,
            short: true,
            displayedContent: '',
            tooltip: false
        }),
        computed: {
            plainContent: function (): string {
                return stripTagsAndEntities(this.content);
            },
            buttons: function (): Button[] {
                return [
                    {
                        icon: 'content_copy',
                        label: 'article.copyInspiration',
                        action: this.copyInspiration
                    }, {
                        icon: 'visibility_off',
                        label: 'article.hideInspiration',
                        action: this.hideOneTime
                        // }, {
                        //     icon: 'delete_sweep',
                        //     label: 'article.hideAndMarkUnrelevant',
                        //     action: this.markUnrelevant
                    }
                ];
            },
            wordsCount: function (): number {
                if (!this.displayedContent.length) {
                    return 0;
                }

                return (this.displayedContent.match(/<\/mark>/g) || []).length;
            }
        },
        watch: {
            plainContent: {
                immediate: true,
                handler: function (newValue: string): void {
                    // @ts-ignore - in mixin
                    this.displayedContent = this.highlightWords(newValue);
                }
            },
            wordsToHighlight: function (): void {
                // @ts-ignore - in mixin
                this.displayedContent = this.highlightWords(this.displayedContent);
            }
        },
        methods: {
            hideOneTime: function (): void {
                this.$nextTick(() => {
                    this.show = false;
                });
            },
            markUnrelevant: function (): void {
                this.hideOneTime();
                // TODO send irrelevant to the server
            },
            copyInspiration: function (): void {
                copyToClipboard(this.plainContent)
                    .then(() => {
                        this.tooltip = true;
                        setTimeout(() => {
                            this.tooltip = false;
                        }, 1500);
                    })
                    .catch(error => {
                        this.$store.dispatch('displayFlashMessage', {
                            type: 'error',
                            content: 'Error while copying to clipboard.'
                        } as FlashMessage);
                        captureException(error);
                    });

            }
        }
    });
</script>

<style scoped lang="sass">
.text-transform-none
    text-transform: none

.colored
    background-color: #f0f8ff36

.ellipsis
    overflow: hidden
    display: -webkit-box
    -webkit-line-clamp: 3
    -webkit-box-orient: vertical

.chip-clickable
    cursor: pointer
</style>

<style lang="sass">
.to-highlight-part .marker-green
    background-color: var(--ck-highlight-marker-green)

.to-highlight-part .marker-blue
    background-color: var(--ck-highlight-marker-blue)

.to-highlight-part .marker-pink
    background-color: var(--ck-highlight-marker-pink)
</style>
