export default {
    apiKey: "AIzaSyB0mCtGzLtYNZIlSHNg9DejkSCK-A0ruwM",
    authDomain: "app.seowritee.com",
    projectId: "seowritee-com",
    storageBucket: "seowritee-com.appspot.com",
    messagingSenderId: "300035048123",
    appId: "1:300035048123:web:38a4abdfa8c8707e261f9e",
    measurementId: "G-1D11W1FHPE"
};

