import Vue from "vue";
import {Router} from '@/plugins/Router';
import * as SentryService from '@sentry/vue';
import {BrowserTracing} from "@sentry/tracing";
import {Environment} from '@/utils/Environment';
import {ExternalLinks} from "@/config/ExternalLinks";

function initSentry(): void {
    if (Environment.isProduction()) {
        SentryService.init({
            Vue,
            dsn: "https://6c5f2c04630a4922ada3b4e06a582ba3@sentry.jagu.cz/36",
            integrations: [
                new BrowserTracing({
                    routingInstrumentation: SentryService.vueRouterInstrumentation(Router),
                    tracingOrigins: ["localhost", ExternalLinks.publicSite, /^\//],
                }),
            ],
            logErrors: true, // Also dump errors in the browser console,
            tracesSampleRate: 1.0,
        });
    }
}

function captureException(error: unknown): void {
    if (Environment.isDevelopment()) {
        console.error(error);
    } else {
        SentryService.captureException(error);
    }
}

export {initSentry, captureException};
