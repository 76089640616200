<template>
  <UserLayout>
    <v-container class="mt-6">
      <div
        v-if="hasPlan"
        class="d-flex flex-column align-center"
      >
        <h2
          class="text-h4 text-center"
          :class="{
            'error--text': articlesLeftThisPeriod <= 0
          }"
        >
          {{ usageTooltip }}
        </h2>
        <h3 class="text-h5 text-center">
          {{ planRenewTooltip }}
          <DateTimeWithTooltip
            :date-time="planPeriodEnd"
            underline
          />
        </h3>
      </div>
    </v-container>
    <SubscriptionSelection :read-only="hasPlan">
      <div
        v-if="hasPlan"
        class="d-flex justify-center mt-10"
      >
        <StripeManageButton
          v-model="stripeLoading"
          :multi-line="true"
          :disabled="!stripeLoading && stripeDisabled"
        />
      </div>
    </SubscriptionSelection>
    <div class="d-flex justify-center">
      <v-btn
        depressed
        color="accent"
        class="multi-line-button pa-3"
        style="margin: 30px 0 50px 0"
        width="100%"
        :href="ExternalLinks.affiliateRegister"
        target="_blank"
      >
        {{ $t("affiliate") }}
      </v-btn>
    </div>
  </UserLayout>
</template>

<script lang="ts">
    import Vue from 'vue';
    import UserLayout from "@/layouts/UserLayout.vue";
    import StripeManageButton from "@/components/account/StripeManageButton.vue";
    import SubscriptionSelection from "@/components/subscriptions/SubscriptionSelection.vue";
    import UserPlanMixin from "@/mixins/UserPlanMixin";
    import DateTimeWithTooltip from "@/components/DateTimeWithTooltip.vue";
    import {EventBus} from "@/utils/EventBus";
    import {ExternalLinks} from "@/config/ExternalLinks";

    export default Vue.extend({
        name: "AccountView",
        components: {DateTimeWithTooltip, SubscriptionSelection, StripeManageButton, UserLayout},
        mixins: [UserPlanMixin],
        data: () => ({
            stripeLoading: false,
            stripeDisabled: false,
            ExternalLinks: ExternalLinks
        }),
        watch: {
            stripeLoading() {
                EventBus.$emit("stripeLoading");
            }
        },
        created: function (): void {
            EventBus.$on("stripeLoading", () => {
                this.stripeDisabled = true;
            });
        }
    });
</script>

<style scoped>

</style>
