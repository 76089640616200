<template>
  <div class="d-flex justify-center">
    <v-expand-transition>
      <v-card
        v-show="metaTitle && metaDescription"
        id="SERPPreview"
        flat
        class="mt-14 pa-4 primaryGradientBackground article-content"
      >
        <div class="text-h5 white--text">
          {{ $t('article.SERPPreview.title') }}:
        </div>
        <v-card
          flat
          class="mt-2 pl-3 py-2 normal-highlight"
          :class="{'SERPDark' : $vuetify.theme.dark}"
        >
          <div
            class="SERPFont"
            style="font-size: 14px; color: var(--v-background)"
          >
            <span>
              {{ 'https://your-page.com' }}
            </span>
            <span style="color: #5f6368">
              {{ ' > ' + hyphenated(keyword) }}
            </span>
          </div>
          <div
            class="SERPFont to-highlight-part"
            :class="{'SERPDarkHeading' : $vuetify.theme.dark}"
            style="font-size: 20px; color: #1a0dab"
            v-html="sanitizeHTML(highlightWords(shortenedMetaTitle), {
              allowedAttributes: allowedAttributes
            })"
          />
          <div
            class="SERPFont to-highlight-part"
            :class="{'SERPDark' : $vuetify.theme.dark}"
            style="font-size: 14px; color: #4d5156; max-width: 600px"
            v-html="sanitizeHTML(highlightWords(shortenedMetaDescription),{
              allowedAttributes: allowedAttributes
            })"
          />
        </v-card>
      </v-card>
    </v-expand-transition>
  </div>
</template>

<script lang="ts">
    import Vue from "vue";
    import HighlightWordsMixin from "@/mixins/HighlightWordsMixin";
    import sanitizeHTML from 'sanitize-html';
    import {hyphenated} from "@/utils/ArticleComputed";

    export default Vue.extend({
        name: "ArticleSERPPreview",
        mixins: [HighlightWordsMixin],
        props: {
            metaTitle: {
                type: String,
                default: ''
            },
            metaDescription: {
                type: String,
                default: ''
            },
            keyword: {
                type: String,
                default: ''
            }
        },
        data: () => ({
            sanitizeHTML: sanitizeHTML,
            hyphenated: hyphenated,
        }),
        computed: {
            allowedAttributes: function () {
                const allowedAttributes = sanitizeHTML.defaults.allowedAttributes;
                allowedAttributes['mark'] = ['class'];
                return allowedAttributes;
            },
            shortenedMetaTitle: function (): string {
                if (this.metaTitle.length < 67)
                    return this.metaTitle;
                return this.metaTitle.replace(/^(.{65}[^\s]*).*/, "$1") + ' ...';
            },
            shortenedMetaDescription: function (): string {
                if (this.metaDescription.length < 154)
                    return this.metaDescription;
                return this.metaDescription.replace(/^(.{152}[^\s]*).*/, "$1") + ' ...';
            }
        }
    });
</script>

<style scoped lang="sass">
.SERPFont
    font-family: arial, sans-serif

.SERPDark
    color: #bdc1c6 !important

.SERPDarkHeading
    color: #8ab4f8 !important

.normal-highlight
    ::selection
        // TODO: Deprecated, any other way to reset?
        background: highlight
        color: highlighttext
</style>
