var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticStyle:{"width":"100%"}},[_c('v-dialog',{attrs:{"max-width":"90vw"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"multi-line-button",attrs:{"depressed":"","color":"primary","block":_vm.block}},on),[_c('v-spacer'),_vm._v(" "+_vm._s(_vm.$t('article.preview'))+" "),_c('v-spacer'),_c('v-icon',{staticClass:"ml-2"},[_vm._v(" open_in_new ")])],1)]}}]),model:{value:(_vm.preview),callback:function ($$v) {_vm.preview=$$v},expression:"preview"}},[_c('v-card',{staticClass:"pa-2",staticStyle:{"max-height":"800px","overflow-y":"hidden"}},[_c('v-card',{staticClass:"mb-4",staticStyle:{"overflow-y":"scroll"},attrs:{"max-height":"785","flat":""}},[_c('v-card-text',{staticClass:"scrollable-content mt-4 article-preview-in-app",domProps:{"innerHTML":_vm._s(_vm.sanitizeHTML(_vm.getContentToExport(_vm.article, _vm.DownloadType.HTML)[0], {
            allowedTags: _vm.sanitizeHTML.defaults.allowedTags.concat([ 'title' ]),
            allowedAttributes: {
              ..._vm.sanitizeHTML.defaults.allowedAttributes,
              'h1': [ 'id' ],
              'h2': [ 'id' ],
              'h3': [ 'id' ],
              'h4': [ 'id' ],
              'h5': [ 'id' ],
              'h6': [ 'id' ]
            }
          }))}}),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.preview = false}}},[_vm._v(" "+_vm._s(_vm.$t('close'))+" ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }