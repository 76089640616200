<template>
  <div class="ma-2 mt-5 d-flex flex-wrap justify-center">
    <div class="article-content">
      <span
        v-for="part of parts"
        :key="part.selector"
      >
        <v-btn
          class="ma-1"
          color="primary"
          :class="{ 'noSelector': !part.selector }"
          :outlined="part.loading"
          :block="!part.selector"
          @click="part.loading ? () => {} : scroll(part.selector)"
        >
          {{ part.title }}
          <v-progress-linear
            v-if="part.loading"
            indeterminate
            class="btnLoader"
          />
        </v-btn>
      </span>
    </div>
  </div>
</template>

<script lang="ts">
    import {TranslateResult} from "vue-i18n";
    import {Scroll} from "@/config/Behaviour";
    import Vue from "vue";

    interface Part {
        title: TranslateResult | string,
        selector: string | null,
        loading: boolean,
    }

    export default Vue.extend({
        name: "ArticleNavigationChips",
        props: {
            isAnalyze: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            article: function () {
                return this.$store.getters.article;
            },
            parts: function (): Part[] {
                if (this.isAnalyze && this.$store.getters.articleAnalyzeScrapeLoading) {
                    // after content extraction is done, this will be replaced with the actual parts
                    return [
                        {
                            title: this.$t('article.contentExtraction') as string,
                            selector: null,
                            loading: this.$store.getters.articleAnalyzeScrapeLoading
                        }
                    ];
                }

                const ret = [];
                for (const part of this.article.getAllParts()) {
                    let i = 0;
                    while (i < part.value.length) {
                        ret.push({
                            title: part.number === 1 ? this.$t('article.label.h1p') : ('H' + part.number),
                            selector: 'h' + part.number + (i > 0 ? ('hw' + i) : ''),
                            loading: this.$store.getters.articleAiLoading['h' + part.number][i]?.h
                                || this.$store.getters.articleAiLoading['h' + part.number][i]?.p
                        });
                        i++;
                    }
                }
                ret.splice(1, 0, {
                    title: this.$t('article.contentList.title') as string,
                    selector: 'contentList',
                    loading: this.$store.getters.articleAiLoading.loadingRatioContent > 0
                });
                let i = 0;
                while (i < this.article.questions.length) {
                    ret.push({
                        title: this.$t('article.questions.question') + ' ' + (i + 1),
                        selector: 'question-' + (i > 0 ? i : ''),
                        loading: this.$store.getters.articleAiLoading.questions[i]?.answer
                    });
                    i++;
                }
                for (const meta of ['metaTitle', 'metaDescription']) {
                    ret.push({
                        title: this.$t('article.label.' + meta),
                        selector: meta,
                        loading: this.$store.getters.articleAiLoading[meta]
                    });
                }
                ret.push({
                    title: this.$t('article.label.images'),
                    selector: 'images',
                    loading: false
                });
                return ret;
            },
            loadingCompleted: function (): boolean {
                return this.parts.filter(p => p.loading).length === 0;
            }
        },
        watch: {
            loadingCompleted: {
                handler: function (val: boolean) {
                    if (val) {
                        this.$store.commit('setArticleSeoScoreAiLoadingCompleted', true);
                    }
                },
                immediate: true
            }
        },
        methods: {
            scroll: function (selector: string) {
                this.$vuetify.goTo('#' + selector, {
                    duration: Scroll.duration,
                    offset: Scroll.offset
                });
            }
        }
    });
</script>

<style scoped lang="sass">
.btnLoader
    position: absolute
    left: -16px
    width: calc(100% + 32px)
    height: 36px !important
    opacity: 0.10

.noSelector
    cursor: unset
    pointer-events: none
</style>
