import {EventBus} from "@/utils/EventBus";
import Vue from "vue";

export default Vue.extend({
    data: () => ({
        $eventListenerActive: false,
    }),
    computed: {
        // events: () => <Record<string, () => void>>{} // Redeclare this on the component
    },
    created: function () {
        this.eventsOn();
    },
    activated: function () {
        this.eventsOn();
    },
    deactivated: function () {
        this.eventsOff();
    },
    destroyed: function () {
        this.eventsOff();
    },
    methods: {
        eventsOn: function () {
            if (!this.$eventListenerActive) {
                this.$eventListenerActive = true;
                // @ts-ignore
                for (const [listener, executor] of Object.entries(this.events || [])) {
                    // @ts-ignore
                    EventBus.$on(listener, executor);
                }
            }
        },
        eventsOff: function () {
            if (this.$eventListenerActive) {
                this.$eventListenerActive = false;
                // @ts-ignore
                for (const [listener, executor] of Object.entries(this.events || [])) {
                    // @ts-ignore
                    EventBus.$off(listener, executor);
                }
            }
        }
    }
});
