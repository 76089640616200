const PUBLIC_SITE = 'https://seowritee.com';

export const ExternalLinks = {
    publicSite: PUBLIC_SITE,
    logoutRedirect: PUBLIC_SITE,
    termsAndConditions: PUBLIC_SITE + '/terms-of-service',
    privacyPolicy: PUBLIC_SITE + '/privacy-policy',
    manual: PUBLIC_SITE + '/seo-writing-tool',
    blog: PUBLIC_SITE + '/blog',
    affiliateRegister: 'https://seomaker.getrewardful.com/signup',
    domainSeoTest: 'https://seomaker.cz/seo-test/domain/'
};
