const Density = {
  LOW: 0,
  OK: 1,
  HIGH: 2
};

const DensityColors = {
    [Density.LOW]: '#FFC260',
    [Density.OK]: '#3CD4A0',
    [Density.HIGH]: '#FF5C93',
};

const DensityLangPaths = {
  [Density.LOW]: 'low',
  [Density.OK]: 'ok',
  [Density.HIGH]: 'high',
};

export { Density, DensityColors, DensityLangPaths };
