export function scrollIntoViewIfNotVisible(selector: string, scrollOptions: ScrollIntoViewOptions = {
    behavior: 'smooth',
    block: 'end',
    inline: 'nearest'
}): void {
    const targetEl = document.querySelector(selector);
    if (targetEl) {
        if (targetEl.getBoundingClientRect().bottom > window.innerHeight) {
            targetEl.scrollIntoView(scrollOptions);
        }

        if (targetEl.getBoundingClientRect().top < 0) {
            targetEl.scrollIntoView(scrollOptions);
        }
    }
}
