<template>
  <v-card
    outlined
    rounded
    color="background"
    class="d-flex flex-column align-center"
    style="border: unset; width: 100%"
  >
    <v-btn
      depressed
      block
      color="primary"
      class="multi-line-button"
      @click="showDetails = !showDetails"
    >
      <v-spacer />
      <span>
        <v-scroll-y-transition mode="out-in">
          <strong :key="totalOccurrences">{{ totalOccurrences }}</strong>
        </v-scroll-y-transition>
        <span class="mx-1">|</span>
        <span class="text-wrap">{{ $t('keywords.label') }}</span>
      </span>
      <v-spacer />
      <v-icon v-if="showDetails">
        expand_less
      </v-icon>
      <v-icon v-else>
        expand_more
      </v-icon>
    </v-btn>
    <v-expand-transition>
      <div v-show="showDetails">
        <div class="d-flex flex-column align-center">
          <div
            v-for="(keyword, index) of keywords"
            :key="index"
            class="mt-3"
          >
            <div class="d-flex flex-row align-start">
              <v-checkbox
                v-model="keyword.checked"
                dense
                hide-details
              />
              <v-text-field
                v-model="keyword.keyword"
                :hint="`${(wordOccurrences[keyword.keyword.trim()] || 0)} ${$t('keywords.occurrences')}`"
                outlined
                dense
                persistent-hint
              />
              <v-btn
                small
                icon
                color="error"
                class="mt-1"
                @click="removeKeyword(index)"
              >
                <v-icon>close</v-icon>
              </v-btn>
            </div>
          </div>
          <div>
            <v-tooltip right>
              <template #activator="{ on }">
                <v-icon
                  class="mr-8"
                  v-on="on"
                >
                  info_outlined
                </v-icon>
              </template>
              {{ $t('keywords.toCount') }}
            </v-tooltip>
            <v-btn
              outlined
              @click="addKeyword"
            >
              <v-icon>add</v-icon>
            </v-btn>
          </div>
          <span id="keywordInputListAfter" />
        </div>
      </div>
    </v-expand-transition>
  </v-card>
</template>


<script lang="ts">
    import Vue from 'vue';
    import {Keyword} from "@/model/Article";
    import {debounce} from "lodash-es";
    import EventsListenerMixin from "@/mixins/EventsListenerMixin";

    export default Vue.extend({
        name: "ArticleKeywords",
        mixins: [EventsListenerMixin],
        data: () => ({
            showDetails: false,
        }),
        computed: {
            events: function () {
                return {
                    'keywords-expand': this.expand
                };
            },
            keywords: {
                get: function (): Keyword[] {
                    return this.$store.getters.article.allKeywords;
                },
                set: function (newKeywords: Keyword[]) {
                    this.debouncedSet(newKeywords);
                }
            },
            wordOccurrences: function (): Record<string, number> {
                return this.$store.getters.wordOccurrences;
            },
            totalOccurrences: function (): number {
                return this.$store.getters.article.allKeywords
                    .filter((keyword: Keyword) => keyword.keyword.trim().length)
                    .filter((keyword: Keyword) => keyword.checked)
                    .reduce((total: number, keyword: Keyword) => {
                        return total + this.wordOccurrences[keyword.keyword];
                    }, 0) || 0;
            },
        },
        methods: {
            expand: function () {
                this.showDetails = true;
            },
            debouncedSet: function (newKeywords: Keyword[]) {
                return debounce(() => {
                    return this.$store.getters.article.allKeywords = newKeywords.map(k => k.keyword.trim());
                }, 500);
            },
            addKeyword: function () {
                this.$store.getters.article.allKeywords.push({
                    keyword: '',
                    checked: true
                });
            },
            removeKeyword: function (index: number) {
                this.$store.getters.article.allKeywords.splice(index, 1);
            }
        }
    });
</script>

<style scoped lang="sass">

</style>
