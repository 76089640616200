<template>
  <div class="seoScoreProgressBar">
    <v-expand-transition>
      <div
        v-show="!small"
        key="circular"
      >
        <div class="d-flex justify-center">
          <v-progress-circular
            :size="circular.size"
            width="25"
            color="error"
            rotate="-90"
            value="100"
          />
          <v-progress-circular
            :size="circular.size"
            width="25"
            color="warning"
            rotate="-90"
            :value="yellow"
            :style="`margin-left: -${circular.size}px`"
          />
          <v-progress-circular
            :size="circular.size"
            width="25"
            color="accent"
            rotate="-90"
            :value="green"
            :style="`margin-left: -${circular.size}px`"
          >
            <template #default>
              <slot />
            </template>
          </v-progress-circular>
        </div>
      </div>
    </v-expand-transition>
    <v-expand-transition>
      <div
        v-show="small"
        key="linear"
      >
        <v-progress-linear
          dark
          rounded
          height="25"
          color="accent"
          background-color="warning"
          :value="green"
          :buffer-value="yellow"
          :style="'background-color: var(--v-error-base'"
        >
          <template #default>
            <slot />
          </template>
        </v-progress-linear>
      </div>
    </v-expand-transition>
  </div>
</template>


<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        name: "SeoScoreProgressBar",
        props: {
            small: {
                type: Boolean,
                default: false
            },
            green: {
                type: Number,
                default: 0
            },
            yellow: {
                type: Number,
                default: 0
            },
        },
        data: () => ({
            circular: {
                size: 180,
            }
        })
    });
</script>

<style lang="sass">
.seoScoreProgressBar
    .v-progress-circular__info
        color: var(--v-selectedKeyword-base)

    .v-progress-circular__underlay
        stroke: none
</style>
