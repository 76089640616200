import Vue from "vue";
import moment from 'moment';

function useMoment(): void {
    // import('moment/locale/cs');

    // eslint-disable-next-line @typescript-eslint/no-var-requires
    Vue.use(require('vue-moment'), {
        moment
    });
}

export {useMoment};
