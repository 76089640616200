import {HighlightOption} from "@/enum/highlight_option";
import {Environment} from "@/utils/Environment";

export function stripTagsAndEntities(html: string | null, trim = true): string {
    if (!html) return '';
    const stripped = html
        .replace(/<\/p>|<\/li>/g, ' ') // replace </p> and </li> with gap so two words are not counted as one
        .replace(/(<([^>]+)>)/gi, '') // remove html tags
        .replace(/&[a-z]*;/g, '') // remove HTML entities - e.g. &nbsp;
        .replace(/ +/g, ' '); // squeeze multiple spaces into one
    return trim ? stripped.trim() /* remove spaces in front and back */ : stripped;
}

export function stripMarkers(html = ''): string {
    const highlightOptions = Object.values(HighlightOption);
    for (const tag of ['mark', 'span']) {
        for (const color of highlightOptions) {
            // Remove all markers twice to handle nested markers with same class
            // example <p><span class="marker-yellow"><span class="marker-yellow">dluhopisy</span></span></p>
            // will be after one replace <p><span class="marker-yellow">dluhopisy</span></p>
            // should be possible to do it in one regex, but this is hot fix for now
            html = html.replaceAll(new RegExp(`<${tag} class="${color}">(.*?)</${tag}>`, 'g'), '$1');
            html = html.replaceAll(new RegExp(`<${tag} class="${color}">(.*?)</${tag}>`, 'g'), '$1');
        }
    }
    return html;
}

export function reformatOpenAiResponseToHtml(plain: string | null): string | null {
    if (!plain) {
        return plain;
    }

    // fix markdown ** -> <strong>
    let markdownStripped = plain;
    if (Environment.supportsPositiveLookBehindInRegexp) {
        const markdownPattern = /\*\*(.*?)\*\*/g;
        markdownStripped = plain.replace(markdownPattern, '<strong>$1</strong>');
    }

    // Replace unwanted tags with their inner content
    const allowedTags = ['b', 'i', 'u', 'a', 'br', 'ul', 'ol', 'li', 'strong', 'em'];
    const tagPattern = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi; // Matches any html tag
    let lastTag: string | null = null;
    const unwraped = markdownStripped.replace(tagPattern, (match, p1) => {
        lastTag = p1;
        // If the tag is in the allowed list, keep it
        if (allowedTags.includes(p1.toLowerCase())) {
            return match;
        }
        // If for some reason ai generated p tags replace closing tag as br
        if (p1.toLowerCase() === 'p' && lastTag === 'p') {
            return '<br>';
        }
        // Otherwise, remove the tag but keep its content
        return '';
    });

    // This is ugly but the order of these replacements matter
    return unwraped
        .replace(/(^(\n)+)|((\n)+$)/g, "") // Drop leading and trailing newline
        .replace(/^"|"$/g, "") // Drop ""
        // fix spacing around lists
        .replace(/\n<ul>/g, "<ul>") // Remove newline before <ul>
        .replace(/<ul>\n/g, "<ul>") // Remove newline after <ul>
        .replace(/\n<\/ul>/g, "</ul>") // Remove newline before </ul>
        .replace(/<\/ul>\n/g, "</ul>") // Remove newline after </ul>
        .replace(/\n<ol>/g, "<ol>") // Remove newline before <ol>
        .replace(/<ol>\n/g, "<ol>") // Remove newline after <ol>
        .replace(/\n<\/ol>/g, "</ol>") // Remove newline before </ol>
        .replace(/<\/ol>\n/g, "</ol>") // Remove newline after </ol>
        .replace(/<\/li>\n/g, "</li>") // Remove newline after </li>
        // fix spacing around paragraphs
        .replace(/\n/g, "<br>") // Replace br with <br>
        .replace(/\u00A0/g, " ") // Replace non-breaking space or normal space
        .replace(/<br><ul>/g, "<ul>") // Remove br before <ul>
        .replace(/<ul><br>/g, "<ul>") // Remove br after <ul>
        .replace(/<br><\/ul>/g, "</ul>") // Remove br before </ul>
        .replace(/<br><ol>/g, "<ol>") // Remove br before <ol>
        .replace(/<ol><br>/g, "<ol>") // Remove br after <ol>
        .replace(/<br><\/ol>/g, "</ol>") // Remove br before </ol>
        .replace(/<\/li><br>/g, "</li>") // Remove br after </li>
        .replace(/<\/ul><br><br>/g, "</ul><br>") // Remove one br after </ul>
        .replace(/<\/ol><br><br>/g, "</ol><br>") // Remove one br after </ol>
        .replace(/\s+/g, ' ') // Replace multiple spaces with one space
        .replace(/<br>\s+/g, '<br>') // Remove spaces after <br>
        .replace(/(<br\s*\/?>\s*){3,}/g, "<br><br>") // Replace multiple <br> with one <br>
        .replace(/>\s+</g, '><') // Remove spaces between tags
        .replace(/(<br>)+$/g, ""); // Remove trailing br
}

const permutations: Record<string, string[]> = {};

/**
 * For sentence shorter than 5 words, return all its permutations.
 * Otherwise, return sentence itself to prevent obnoxious amount of results.
 * * 1 word = 1 permutation
 * * 2 words = 2 permutations
 * * 3 words = 6 permutations
 * * 4 words = 24 permutations
 * * 5 words = 120 permutations
 * * 6 words = 720 permutations
 * * 7 words = 5040 permutations
 * * 8 words = 40,320 permutations
 * * 9 words = 362,880 permutations
 * @param sentence
 */
export function permute(sentence: string): string[] {
    if (sentence.split(' ').length > 4) {
        return [sentence];
    }
    if (permutations[sentence]) {
        return permutations[sentence];
    }
    const words = sentence.split(' ');
    if (words.length === 1) {
        return [sentence];
    }
    // Heap's permutation algorithm
    const length = words.length;
    const result = [words.slice()];
    const stackState = new Array(length).fill(0);
    let stackPointer = 1;
    let idxToSwap;
    let tmp;

    while (stackPointer < length) {
        if (stackState[stackPointer] < stackPointer) {
            idxToSwap = stackPointer % 2 && stackState[stackPointer];
            // swap strings
            tmp = words[stackPointer];
            words[stackPointer] = words[idxToSwap];
            words[idxToSwap] = tmp;
            ++stackState[stackPointer];
            stackPointer = 1;
            result.push(words.slice());
        } else {
            stackState[stackPointer] = 0;
            ++stackPointer;
        }
    }
    return permutations[sentence] = result.map(words => words.join(' '));
}

export function getKeywordRegExpString(word: string): string {
    // Safari is the new IE https://stackoverflow.com/questions/51568821/works-in-chrome-but-breaks-in-safari-invalid-regular-expression-invalid-group
    if (Environment.supportsPositiveLookBehindInRegexp) {
        return `(?<=(?:[\\W])|^)(${word})(?=(?:[\\W]|$))`;
    } else {
        return `(?:[\\W]|^)(${word})(?=(?:[\\W]|$))`;
    }
}

export async function getHash(str: string): Promise<string> {
    if (crypto.subtle === undefined) {
        // Workaround for unsecured context. See https://developer.mozilla.org/en-US/docs/Web/API/Crypto/subtle
        return str;
    } else {
        const encoder = new TextEncoder();
        const data = encoder.encode(str);
        const hashBuffer = await crypto.subtle.digest('SHA-256', data);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    }
}

export function removeDiacritics(str: string): string {
    return str.normalize("NFD").replace(/\p{Diacritic}/gu, "");
}

export function questionize(question: string, lang: string): string {
    if (!question) return "";

    // Capitalize the first letter
    question = question.charAt(0).toUpperCase() + question.slice(1);

    // Language-specific question formatting
    switch (lang) {
        case "es": // Spanish
            return `¿${question}?`;
        case "zh": // Chinese (Simplified & Traditional)
        case "ja": // Japanese
        case "ko": // Korean
            return question.endsWith("？") ? question : `${question}？`;
        case "uk": // Ukrainian
        case "ru": // Russian
            return question.endsWith("?") ? question : `${question}?`;
        case "ar": // Arabic
        case "fa": // Persian
        case "ur": // Urdu
        case "he": // Hebrew
            return question.endsWith("؟") ? question : `${question}؟`;
        default:
            return question.endsWith("?") ? question : `${question}?`;
    }
}
