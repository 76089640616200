<template>
  <div class="d-flex flex-column flex-wrap align-center text-center">
    <h2 class="text-h5">
      {{ $t('register') }}
    </h2>
    <p class="text-h6 text--secondary">
      {{ $t('noCardRequired') }}
    </p>
    <div id="firebaseui-auth-container" />
  </div>
</template>

<script lang="ts">
    import Vue from 'vue';
    import {Auth} from "@/plugins/Auth";
    // @ts-ignore

    export default Vue.extend({
        name: "FirebaseAuthUi",
        mounted: function () {
            if (!Auth.isAuthenticated) {
                Auth.getAuthUiInstance()
                    .then(authUI => {
                        if (!Auth.isAuthenticated) { // It could have been changed in the meantime...
                            authUI.start('#firebaseui-auth-container', Auth.getAuthUIConfig());
                        }
                    });
            }
        }
    });
</script>

<style lang="sass">
#firebaseui-auth-container
    @import "firebaseui/dist/firebaseui"
</style>
