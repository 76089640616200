<template>
  <div>
    <div class="d-flex flex-column">
      <div
        v-for="extension of extensions.filter(ex => !ex.used && !ex.discarded && ex.value)"
        :key="extension.valueId"
        class="d-flex flex-row"
      >
        <ArticlePartRemoveDialog
          :content="extension.value"
          lang-path="article.extendWithAi.discard"
          class="mt-2"
          @removePart="discardExtension(extension)"
        />
        <v-tooltip top>
          {{ $t(`article.extendWithAi.${langPath}.use`) }}
          <v-icon
            class="ml-2"
            dark
          >
            playlist_add
          </v-icon>
          <template #activator="{ on }">
            <div
              class="extensionSheet"
              @click="useExtension(extension)"
              v-on="on"
            >
              <div
                class="pa-2"
                v-html="extension.value"
              />
            </div>
          </template>
        </v-tooltip>
      </div>
      <div
        class="d-flex"
        :class="$vuetify.breakpoint.smAndDown ? 'flex-column' : 'flex-row'"
      >
        <v-textarea
          v-if="!forcePrompt"
          v-model="prompt"
          auto-grow
          class="ma-3"
          dense
          :disabled="loading"
          :hint="prompt ? $t(`article.extendWithAi.${langPath}.prompt`) : null"
          :loading="loading"
          outlined
          persistent-hint
          :placeholder="$t(`article.extendWithAi.${langPath}.prompt`)"
          :rows="1"
        />
        <v-btn
          color="accent"
          outlined
          :elevation="prompt ? 4 : 0"
          :disabled="wordsGeneratedTotal >= wordsAvailable"
          :loading="loading"
          :class="btnClass"
          @click="onInput"
        >
          <v-icon class="mr-2">
            send
          </v-icon>
          {{
            $t(`article.extendWithAi.${langPath}.run`) + ` (${Math.max(wordsAvailable - wordsGeneratedTotal, 0)}/${wordsAvailable})`
          }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

    const OPENAI_ARTICLE_PART_EXTENSION_LIMIT = 500;

    import Vue, {PropType} from 'vue';
    import {Functions} from "@/plugins/Functions";
    import {Firestore} from "@/plugins/Firestore";
    import {Article, ArticleContentKey} from "@/model/Article";
    import {reformatOpenAiResponseToHtml} from "@/utils/string";
    import {FlashMessage} from "@/model/FlashMessage";
    import ArticlePartRemoveDialog from "@/components/article/ArticlePartRemoveDialog.vue";
    import {Store} from "@/plugins/Store";
    import {OpenAiExpandPartType, OpenAiExtendPartData, OpenAiExtendPartDataWithId, OpenAiExtendPartRequest} from "@/model/OpenAI";
    import {captureException} from "@/plugins/Sentry";

    export default Vue.extend({
        name: "ArticlePartExtend",
        components: {ArticlePartRemoveDialog},
        props: {
            part: {
                type: String as PropType<ArticleContentKey | 'question'>,
                default: ''
            },
            uid: {
                type: Number,
                default: 0
            },
            forcePrompt: {
                type: String,
                default: ''
            },
            hideExtensions: {
                type: Boolean,
                default: false
            },
            loading: {
                type: Boolean,
                default: false
            },
            wordsGeneratedElsewhere: {
                type: Number,
                default: 0
            },
            watchForExtensionValue: {
                type: String,
                default: ''
            },
            langPath: {
                type: String as PropType<'heading' | 'paragraph' | 'question' | 'answer'>,
                default: 'paragraph'
            },
            languageName: {
                type: String,
                default: 'English'
            },
            type: {
                type: String as PropType<OpenAiExpandPartType>,
                default: OpenAiExpandPartType.FROM_USER_INPUT
            }
        },
        data: () => ({
            prompt: '',
            extensions: [] as OpenAiExtendPartDataWithId[],
            wordsAvailable: OPENAI_ARTICLE_PART_EXTENSION_LIMIT
        }),
        computed: {
            articleId: function (): string {
                return this.$store.getters['article'].id;
            },
            article: function (): Article {
                return this.$store.getters.article;
            },
            wordsGenerated: function (): number {
                const count = this.extensions.reduce((acc, curr) => acc + curr.value.split(' ').length, 0);
                this.$emit('wordsGenerated', count);
                return count;
            },
            wordsGeneratedTotal: function (): number {
                return this.wordsGenerated + this.wordsGeneratedElsewhere;
            },
            btnClass: function (): string {
                let list = this.$vuetify.breakpoint.smAndDown ? ' mb-3' : ' mt-3';
                list += this.hideExtensions ? ' multi-line-button' : '';
                return list;
            }
        },
        watch: {
            watchForExtensionValue: function (valueId) {
                if (valueId) {
                    this.watchForExtension(valueId);
                }
            },
        },
        created: function () {
            this.fetchExtensions();
        },
        methods: {
            fetchExtensions: function () {
                if (!this.hideExtensions) {
                    this.$emit('update:loading', true);
                    Firestore.getArticleOpenAiPartExtensions(this.articleId, this.part as ArticleContentKey, this.uid)
                        .then(extensions => {
                            this.extensions = extensions;
                            this.$emit('update:loading', false);
                        });
                }
            },
            onInput: function () {
                if (!(this.forcePrompt || this.prompt)) {
                    this.$store.dispatch('displayFlashMessage', {
                        type: 'warning',
                        content: 'article.extendWithAi.fillPrompt'
                    } as FlashMessage);
                    return;
                }
                this.$emit('update:loading', true);
                Functions.OpenAIArticlePartExtend({
                    articleId: this.articleId,
                    part: this.part,
                    uid: this.uid,
                    type: this.type,
                    data: {
                        ...(this.type === OpenAiExpandPartType.FROM_USER_INPUT ? {userInput: this.prompt} : {}),
                        ...(this.type === OpenAiExpandPartType.FROM_HEADING ? {heading: this.forcePrompt} : {}),
                        ...(this.type === OpenAiExpandPartType.FROM_QUESTION ? {question: this.forcePrompt} : {}),
                    }
                } as OpenAiExtendPartRequest).then(response => {
                    this.$emit('generatingExtensionValueId', response.valueId);
                    if (!this.hideExtensions) {
                        this.watchForExtension(response.valueId);
                    }
                }).catch(error => {
                    Store.dispatch('displayFlashMessage', {
                        type: 'error',
                        content: 'unknownError',
                    } as FlashMessage);
                    captureException(error);
                    this.$emit('update:loading', false);
                });
            },
            watchForExtension: function (valueId: string) {
                const stopWatcher = Firestore.createArticleOpenAiPartExtensionWatcher(
                    this.articleId, this.part as ArticleContentKey, this.uid, valueId,
                    (data: OpenAiExtendPartData) => {
                        if (data.state !== "running") {
                            this.$emit('update:loading', false);
                            this.prompt = '';
                            stopWatcher();
                        }
                        const localIndex = this.extensions.findIndex(ex => ex.valueId === valueId);
                        if (localIndex !== -1) {
                            this.extensions[localIndex].state = data.state;
                            this.extensions[localIndex].value = reformatOpenAiResponseToHtml(data.value) || '';
                        } else {
                            this.extensions.push({
                                ...data,
                                valueId: valueId,
                                value: reformatOpenAiResponseToHtml(data.value) || ''
                            });
                        }
                    });
            },
            useExtension: function (extension: OpenAiExtendPartDataWithId) {
                this.$set(extension, 'used', true);
                this.$emit('append', extension.value);
                Firestore.setArticleOpenAiPartExtensionUsed(
                    this.articleId, this.part as ArticleContentKey, this.uid, extension.valueId, true
                );
            },
            discardExtension: function (extension: OpenAiExtendPartDataWithId) {
                this.$set(extension, 'discarded', true);
                Firestore.setArticleOpenAiPartExtensionDiscarded(
                    this.articleId, this.part as ArticleContentKey, this.uid, extension.valueId, true
                );
            }
        }
    });
</script>

<style scoped lang="sass">
.extensionSheet

    margin-top: 8px

    &, div
        transition: 150ms
        cursor: pointer

    div
        border: 1px dashed var(--v-primary-base)

    &:hover
        padding-bottom: 8px

        div
            transition: 150ms
            margin-top: -8px
            // @extend .elevation-8
            box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12) !important
// end of extend
</style>
