<template>
  <v-divider
    vertical
    class="mx-3"
  />
</template>

<script lang="ts">
    import Vue from "vue";

    export default Vue.extend({
        name: "VerticalDivider"
    });
</script>

<style scoped lang="sass">
</style>
