import {DownloadType} from '@/enum/download_type';

const heading = 'heading';
const paragraph = 'paragraph';

export default {
    hello: 'Hello',
    and: 'and',
    continue: 'Continue',
    close: 'Close',
    cancel: 'cancel',
    yearWeek: '{1}. week of {0}',
    unexpectedError: 'An unexpected error has occurred. Please try again.',
    createdBy: "Created in SEOWRITEE",
    affiliate: "Sign up to our affiliate program and get rewarded for every user you bring to our application!",
    unknownError: "Unexpected error occurred!",

    save: 'Save',
    delete: 'Delete',

    subscriptions: {
        title: 'The application is now ready to write you a complete article perfectly optimized for internet search engines and attractive for readers.',
        subTitle: 'Select your subscription plan, pay online and start immediately generate quality SEO content.',

        perMonth: '{0} / month',
        inspiration: 'article',
        upToInspirations: '{0} articles per month',
        keywords: '{0} keywords in 4 groups',
        buy: 'Buy',

        allPlansInclude: 'All subscriptions include',
        mustChoosePlan: 'You need to choose a subscription!',
        continueWithPlan: 'Continue with \'{0}\'',
        noPlan: 'no subscription',
        activePlan: 'active',
        changePlan: 'change',
        choosePlan: 'choose',
        chosenPlan: 'chosen',
        usageTooltip: 'You\'ve created {0} out of {1} articles this period',
        unlimitedUsageTooltip: 'In this period, you\'ve created {0} articles',
        planRenewTooltip: 'The counter will reset',
        planWillBeCancelled: 'You subscription will end',
        noInspirationsLeftThisPeriodUpgradeToContinue: 'You\'ve got no articles left in the current period. If you wish to continue, upgrade your plan or purchase a one-time package of inspirations!',
        manageSubscription: 'Manage or upgrade your subscription or billing information',
        oneTimePacks: 'One-time packages of articles',

        tryOneFree: 'Try one SEO article for FREE!',
        getOneFree: 'Continue to try one FREE article',

        free: 'free',
        individual: 'individual',
        individualContact: 'We will arrange an individual price with you. Please contact us by email rudolf.sehnal@seowritee.com',

        features: {
            aiWriting: 'Up to several hundred thousand words written by AI',
            seoScore: 'SEO score calculated from 20+ parameters',
            instructions: 'Detailed step-by-step instructions',
            export: '4 types of export to your web page',
            chatGpt: 'ChatGPT for content tuning',
            keywordAnalysis: 'Automatic keyword analysis',
            inspiration: 'Inspiration from TOP10 articles',
            plagiarismCheck: 'Plagiarism check'
        },

        usageLimit: {
            warning: 'You\'re a really heavy above-average writer. Great, we\'re rooting for you, there\'s never enough ' +
                'quality content :-) You can still keep generating articles, but if you continue at this rate for the next ' +
                'month, we\'ll arrange to upgrade you to the BUSINESS rate. Thank you for understanding and using SEOWRITEE.',
            stop: 'We\'re still rooting for you in your intense writing, but you\'ve already exceeded ' +
                'the limit of even the most intense writers :-). If you need to keep writing this month, ' +
                'pay for your next articles with a one-time payment and arrange a price for an BUSINESS ' +
                'license for the next month with us at rudolf.sehnal@seowritee.com.',
            useExtraInspiration: 'Use extra article ({0} left)',
            buyExtraInspirations: 'Buy extra articles',
        },

        terms: {
            IAccept: 'I accept',
            termsOfService: 'Terms of service',
            privacyPolicy: 'Privacy policy',
            info: 'I am aware, that payment will recur every month till I unsubscribe'
        },

        change: 'You can upgrade or downgrade your plan anytime, no additional fees are incurred. No contract is needed, you can cancel at any time by simply stopping your payments.',
        upgradeToUse: 'Choose any subscription plan to use this feature!',

        list: {
            title: 'Generate article text or analyze content from URLs to improve your SEO score to the max',
            items: {
                1: 'To generate the article text, just enter a keyword and AI Lucy will write optimized text in one click.',
                2: 'Or insert the URL of the existing article, and improve it based on the SEO analysis.',
                3: 'The application creates a keyword analysis and writes the content of the article based on the keywords.',
                4: 'The written text also includes answers to the most frequently asked questions that people are interested in.',
                5: 'You will get inspiration to improve all parts of the article from the best optimized text on the internet.',
                6: 'You can easily edit the written title, meta description, H1 to H6 headings, bodycopy etc.',
                7: 'The application automatically monitors over 20 SEO factors important for high search engine rankings.',
                8: 'Then you just check the originality of the text, export the article and upload it to the web.'
            }
        }
    },
    newContentAvailable: 'New page version is available!',
    refresh: 'Refresh',
    logout: 'Log out',

    register: 'Register and generate or analyze an article for free!',
    noCardRequired: 'No credit card required.',

    unsavedChanges: {
        title: 'Unsaved changes',
        text: 'Do you really wish to leave?',
        stay: 'Stay',
        saveAndLeave: 'Save and leave',
        leave: 'Leave'
    },

    steps: {
        step: 'Step',
        initForm: 'Basic parameters entry',
        selectKeyword: 'Keyword selection',
        writeArticle: 'Article writing',
    },

    keywords: {
        locationTitle: "Choose a target country and language to write the article in.",
        title: "Insert the main keyword of the article, for example: pie, apple pie or apple pie recipe.",
        subtitle: "You will be able to refine the keyword later in the keyword analysis.",
        label: "Keywords",
        manage: "Manage keywords",
        hint: "A keyword in SEO search engine optimization of texts is a term or phrase that people use when searching for information on the Internet. These keyword phrases are essential because search engines use them to index and rank search result pages..",
        location: "Country",
        language: "Language",
        keyword: "Keyword",

        confirm: "Confirm",

        edit: {
            locationTitle: "Chosen target country and language to write the article in.",
            title: "Here you can choose new main keyword",
            confirm: "Confirm"
        },

        toCount: 'Entered words are highlighted, checked words are also being counted into density',
        occurrences: 'occurrences',
        countIntoDensity: 'count into density',
        wordAlreadySelected: 'This word was already entered!',

        keywordSelection: 'Main keyword of your article is',
        keywordSelectionCaption: 'Within tens of seconds, AI Lucy will write an entire article based on the selected keywords. Fine-tune it, export it and publish it to the web.',
        allKeywords: 'Keyword analysis',
        selectNewKeyword: 'Choose new keyword',
        avgMonthlySearches: 'Average monthly searches',

        info: {
            items: {
                1: 'You can select a more precise keyword from the suggested keywords below in the "Select main keyword for optimization" column, or you can leave the original one.',
                2: 'You can select only one main keyword. Based on it, you will get inspiration to improve your texts.',
                3: 'As a main keyword, we recommend winning one that has an average number of searches per month of at least 400 and above.',
                4: 'In the "Choose for writing by AI Lucy" column, you can change the keywords on which the paragraphs of the article will be written. We recommend selecting "related" and "questions" keywords as much as possible.'
            },
            beforeButton: 'Have you chosen your main keyword for optimization? If so, press the button below.',
            selectedForAi: 'The number of keywords selected for text writing by AI Lucy:',
            beforeButtonNoData: 'People are not searching for the keyword you entered or only a few times a month and also the low monthly variation of the keyword will keep the article short. You now have two options to choose from.',
            tooManyKeywordsForAi: 'Choose at most 20 keywords for AI Lucy'
        },

        list: {
            header: {
                title: 'Keywords',
                keywordDifficulty: 'SEO difficulty',
                unknownKeywordDifficulty: 'Unknown SEO difficulty',
                knownKeywordDifficulty: 'The lower the value, the better your chances of being ranked in organic search results. The higher the value, the more attractive the keyword and the higher the competition.',
                averageSearches: 'Average number of searches per month',
                chooseKeyword: 'Choose main keyword for optimization',
                chooseKeywordHint: 'If you want, you can change the main keyword, based on which you will get inspires from the top 10 sites ranked in search engines.',
                chooseForAi: 'Choose for writing by AI Lucy',
                chooseForAiHint: 'If you want, you can change the keywords for which Lucy\'s AI will write the article headlines and paragraphs, including the answers to the questions.'
            },
            variations: {
                title: 'variations',
                description: 'These are close variations of the main keyword. Replace the main keyword here and there, but beware of grammar - never use grammatically incorrect variants in the text.'
            },
            questions: {
                title: 'questions',
                description: 'Questions related to the main keyword. Incorporate them into the article and answer them as practically as possible.'
            },
            longTail: {
                title: 'long tail',
                description: 'Less searched, but more accurate queries related to the main keyword. You can incorporate them into the article and analyze them in more detail.'
            },
            related: {
                title: 'related',
                description: 'Related keywords are secondary terms that help narrow the focus of the main keyword. These keywords allow target more specific phrases that branch off of the larger topic. So try not to deviate from the topic of the article.'
            },
            empty: 'Unfortunately, there are no alternatives available for the chosen keyword for this category because people are not searching for them on the internet.',
            nothing: 'Unfortunately, there are no alternatives available for the chosen keyword for this category because people are not searching for them on the internet.',
            chooseNewKeyword: 'Change keyword',
            chooseNewKeywordHint: 'If this keyword is more concise, you can choose it as the new main keyword to generate inspiration for fine-tuning texts.'
        },

        generateInspiration: 'Generate SEO article',
        generateInspirationAnyway: 'Write article anyway',
        deleteArticleAndRefundCredit: 'Cancel this article and refund credit',
        notCreatedInThisBillingPeriod: 'Cannot refund credit, because the article was not created in this billing period.',

        aiPrompt: {
            title: 'AI text writing',
            letHer: 'Let Lucy write the article',
            description: 'Describe as best as possible everything that the article should contain. The more precise, the better.',
            promptExample: 'Example of description: Hints and tips on how to bake a delicious apple pie. Traditional, modern, and healthy apple pie recipes. See the Manual for more examples.',
        },

        simplify: {
            writeNow: 'Write article now',
            writeNowDescription: 'I leave it to the application to write the whole article without specifying the content of the paragraphs immediately.',
            specify: 'Specify article content',
            specifyDescription: 'I want to refine the paragraph content according to the keyword analysis (an extra minute of work).',
        },

        analyze: {
            description: 'Enter the URL of the article whose SEO score you wish to improve.',
            urlInputHint: 'For example: https://www.yourweb.com/article-for-improvement',
            keywordSelectionCaption: {
                begin: 'After extracting the text from the URL, improve it according to',
                bold: 'SEO score factors',
                end: '. If the texts were not extracted, copy them manually.'
            },
            analyzeNow: 'Analyze the article now',
            analyzeNowDescription: 'I leave it to the application to handle the extraction without immediately detailing the content of paragraphs.',
            specifyDescription: 'I want to refine the content of paragraphs according to the keyword analysis before extraction (an extra minute of work).',
            aiPrompt: {
                description: 'Describe what the article contains and for whom it is intended.',
            }
        }
    },

    article: {
        getInspired: 'Inspiration from TOP content',
        editYours: 'Edit the text written by AI Lucy',
        words: 'words',
        numOfWords: '{0} words',
        details: "SEO parameters of the article",
        keyWordsCount: "Number of keywords in this inspiration text",

        list: 'Your articles',
        listMore: 'more',
        create: 'Create new article',
        createAnotherInTrial: 'To create another article, please select a subscription below.',
        view: 'View',
        createdAt: 'Created at',
        empty: 'No articles yet...',
        noTitle: "Article without a title",

        createDescription: "Generate new content for website or blog",

        analyze: {
            title: "Analyze article from URL",
            description: "Improve the content published on the URL",
            scrapeFail: {
                title: "Unsuccessful content extraction",
                description: "We're sorry, but SEOWRITEE was not able to extract any content from the URL provided. This might be due to the website's structure, restrictions on access, or the URL being incorrect. Please check the URL for any mistakes and try again.",
                creditRefunded: "Your credit has been refunded.",
                deleteArticle: {
                    text: "Delete article",
                    description: "You can try to create a new one or analyze another article."
                },
                openEmpty: {
                    text: "Open empty article",
                    description: "You can write the article from scratch with our Lucy AI."
                }
            },
            scrapePartial: {
                title: "Partial content extraction",
                description: "We're sorry, but SEOWRITEE was not able to extract all of the content from the URL provided. This might be due to the website's structure. Please check the URL for any mistakes and try again.",
                openPartial: {
                    text: "Open partial article",
                    description: "You can fill in the missing parts of the article manually or with our Lucy AI."
                },
            }
        },

        contentExtraction: "Content extraction",

        save: 'Save article',
        saved: 'Article saved',

        updated: 'Article updated',

        overview: {
            selectedKeyword: {
                title: 'Chosen keyword',
                description: 'The main keyword, or topic, about which you are writing an article. We will help you get inspiration for its detailed description and explanation to readers, ie the target group.'
            },
            idealURL: {
                title: 'Ideal URL of article',
                description: 'This is what an article URL, landing page, or any part of a website should look like in SEO terms. The shorter and more relevant the keyword, the better. The variant with www or without depends on how you have the website domain set up.'
            },
            ALTDescription: {
                title: 'ALT image description',
                description: 'Be sure to describe the images with the main keyword and its variants the ALT attribute of the image. You can usually find this option in the web content administration.'
            },
            recommendedLength: {
                title: 'Minimum recommended article length',
                description: 'If you want to be in the top positions in organic search, your article must have the correct number of words. We have calculated the minimum number of the best articles on the Internet and I will keep an eye on your word count.'
            },
            recommendedDensity: {
                title: 'Recommended keyword density',
                description: 'The main keyword and its variants must be used reasonably in the text of the article. We look after its correct density for you.'
            },
            length: "Length",
            density: "Density of chosen words",
            moreDetails: "More details",
            lessDetails: "Less details",
            recommendedKeywordCount: 'for article length of {0} words it is {1} keywords'
        },

        label: {
            metaTitle: 'meta title',
            metaDescription: 'meta description',
            h1h: 'H1 ' + heading,
            h1p: 'H1 ' + paragraph,
            h2h: 'H2 ' + heading,
            h2p: 'H2 ' + paragraph,
            h3h: 'H3 ' + heading,
            h3p: 'H3 ' + paragraph,
            h4h: 'H4 ' + heading,
            h4p: 'H4 ' + paragraph,
            h5h: 'H5 ' + heading,
            h5p: 'H5 ' + paragraph,
            h6h: 'H6 ' + heading,
            h6p: 'H6 ' + paragraph,
            images: 'images',
        },

        inspirationFor: {
            title: '{0} inspiration',
            metaTitle: 'The text that will appear in the search results. Be inspired by the best headlines and write even better, more attractive and concise.',
            metaDescription: 'The text that will appear in the search results. Be inspired by the best descriptions and write even better, more attractive and concise.',
            h: 'Get inspired by the headlines of the best articles on the Internet and write even more attractive and concise.',
            p: 'Get inspired by the paragraphs of the best articles on the Internet and write even more attractive and concise.'
        },
        inspirationDetail: 'Open detail',
        noInspiration: 'None of the inspiration sites used this part of the page, but you can fill it out.',
        hideInspiration: 'Hide this inspiration',
        hideAndMarkUnrelevant: 'Hide and mark as irrelevant',
        copyInspiration: 'Copy to overwrite',
        copiedToClipboard: 'Copied to clipboard!',

        placeholder: {
            metaTitle: 'Write the meta title here',
            metaDescription: 'Write the meta description here',
            heading: 'Write the heading here',
            paragraph: 'Write the paragraph here',
            question: 'Write the question here',
            answer: 'Write the answer here',
        },

        writeBetter: {
            meta: 'Edit the {0} to be more descriptive than the inspiration',
            heading: 'Edit the {0} to be more descriptive than the inspiration',
            headingBrief: '{0} heading and paragraph',
            paragraph: 'Edit the paragraph to make it more interesting than the inspiration',
            placeholder: {
                meta: 'Write your own {0}, which will be more concise than those stated on the left',
                heading: 'Write {0} in here which will be corresponding to the purpose of your article',
                paragraph: 'Write paragraph in here which will be related to the heading above and describe the topic in detail'
            }
        },

        contentList: {
            title: 'Content list',
            hint: 'Will be generated automatically during writing of the article.',
            description: 'The list of article content is important for the quick orientation of the reader in the text. It is created automatically as you type headings.',
            empty: 'empty',
        },

        questions: {
            title: 'Edit the answer or write your own',
            question: 'Question',
            answer: 'Answer',
            remove: 'Delete question'
        },

        answers: {
            title: "Answers to questions",
            description: "Answering frequently asked questions closely related to the keyword will increase the quality of the article optimization as well as the content value for your readers."
        },

        tips: {
            label: 'Tips',
            metaTitle: 'In addition to the main keyword, use a number, the question how, why or where, a list, an attractive price, etc. in the title. Use the maximum recommended number of characters.',
            metaDescription: 'Using the main keyword and other information, describe the content of the article as attractively as possible by listing the content, unique advantages and benefits. Make the most of the recommended number of characters.',
            h1h: 'Use the keyword in the title and describe the main message of the article. Use the H1 heading only once in an article',
            h1p: 'Use the main keyword meaningfully at the beginning of the paragraph and summarize the content of the whole article in it so that you entice the reader to read it in its entirety.',
            h2h: 'Use another keyword variation or question keyword in this and other headings. You can reuse headings H2 through H6 in this article.'
        },

        extendWithAi: {
            fillPrompt: 'Fill the instruction for AI Lucy',
            heading: {
                run: 'Generate paragraph for this heading',
            },
            paragraph: {
                toggle: 'Extend paragraph with AI Lucy',
                prompt: 'Give our AI Lucy what to write about next for this paragraph.',
                run: 'Generate next part',
                use: 'Add to paragraph',
            },
            question: {
                run: 'Generate answer for this question',
            },
            answer: {
                toggle: 'Extend answer with AI Lucy',
                prompt: 'Give our AI Lucy what to write about next for this questions answer.',
                run: 'Generate next part',
                use: 'Add to answer',
            },
            discard: {
                tooltip: 'Discard',
                title: 'Are you sure to discard the following extension?',
                confirm: 'Discard'
            },
        },

        addSubpart: 'Insert {0} with paragraph',
        addAnotherSubpart: 'Insert another {0} with paragraph',
        removeSubpart: {
            tooltip: 'Delete this heading and paragraph',
            title: 'Are you sure to delete the following {0} with its paragraph?',
            confirm: 'Delete'
        },
        addQuestion: 'Add another question',
        removeQuestion: {
            tooltip: 'Delete this question',
            title: 'Are you sure to delete the following question with its answer?',
            confirm: 'Delete',
        },

        images: {
            title: 'Article images',
            description: 'List images used in the article. Fill out the ALT attributes of the images so SEOWRITEE can check it for you.',
            add: 'Add image',
            remove: 'Delete image',
            alt: {
                header: 'ALT attribute',
                placeholder: 'Write the description of the image here',
            },
            url: {
                header: 'URL of externally hosted image',
                placeholder: 'https://www.yourweb.com/image.jpg',
            }
        },

        SERPPreview: {
            title: 'See, how your SERP preview will look like',
            description: 'This is exactly how people will see your site\'s page in organic search results. Make sure it is attractive and entices people to click. This will affect traffic to your site. SERP means Search Engine Results Page.'
        },
        urlSuggestion: {
            ideal: 'Suggested ideal URL',
            yours: 'Your URL',
        },
        preview: 'Article preview',

        generateCopyLeaksReport: "Generate plagiarism report",
        generatingCopyLeaksReport: "Checking your article, this can take a few minutes...",
        twoChecksUsed: "You've already used 2/2 plagiarism checks!",

        currentLength: {
            title: 'Current length of your article',
            description: 'We count the current number of words for you and at the same time compare it with their ideal number.'
        },
        currentDensity: {
            title: 'Current density of chosen words',
            description: 'We calculate the current keyword density and monitor the ideal number used in the article.'
        },
        originality: {
            title: 'Originality check',
            description: 'You can export the finished article in four different ways, including HTML code, and insert it directly into the administration of your website or send it to someone else for its deployment or use.'
        },
        export: {
            title: 'Article export',
            description: 'We strongly recommend to publish only your original text. When you finish the final version of your article, make sure that all of its contents are original. If not, rewrite them using your own words.'
        },

        density: {
            low: 'Add some more keywords',
            ok: 'Current number of keywords is alright',
            high: 'You are repeating the keywords too often, remove some of them'
        },

        download: {
            title: 'Download the content of your optimized article and put it on your website',
            formats: {
                [DownloadType.DOCX]: 'docx',
                [DownloadType.HTML]: 'html',
                [DownloadType.CLIPBOARD]: 'Copy to Clipboard',
                [DownloadType.RTF]: 'rtf'
            }
        },

        generateNewInspiration: 'Generate new article',

        recommendation: {
            title: 'More tips how to optimize your web and strengthen your ranking with search engines',
            description: 'Here are some more tips and tricks to help your article rank high in organic search results and get free web traffic.',
            items: {
                1: 'Add interesting information, ideas and inspiration from TOP content to the texts written by AI Lucy.',
                2: 'Insert images, videos, graphs etc. into the article and insert relevant keywords into the ALT description.',
                3: 'Do not use keywords too often, keep the occurence of them between the recommended value.',
                4: 'Use texts from other sites only for your inspiration and never copy them into your article.',
                5: 'Your article should be at least that long, ideally longer, than article on the first position.',
                6: 'Insert links to sources related to the topic, which do not compete with your company, into your article.',
                7: 'Insert links to other related articles on your website.',
                8: 'Make sure to update information in the article, because search engines prefer up to date information.',
                9: 'Get good backlinks for your article from trustworthy and non competing websites.',
                10: 'Share the article on social media and reach out to other authors writing about the same topic.',
                11: 'Create video, podcast or presentation from the article and share them on the internet appropriately.',
                12: 'If you are looking for professional advice with content and technical SEO, contact us directly at https://seomaker.cz/en/home/'
            }
        },

        openAi: {
            loading: 'Artificial intelligence Lucy is thinking...',
        }
    },

    seoScore: {
        seo: 'SEO',
        score: 'SEO score',
        showDetails: 'Show details',
        keywordVariants: {
            label: 'Keyword variants',
            description: 'The text of the article should contain multiple variations of the main keyword. You can find them in the keyword analysis: Keywords - related. Entered keyword variants: {0}',
            green: 'Two or more ',
            yellow: 'One',
            red: 'None',
        },
        keywordLength: {
            label: 'Keyword length',
            description: 'The more words the chosen main keyword consists of (this is the so-called long-tail), the less competitive it is (see SEO difficulty) but also the fewer the number of searches. Keyword consists of {0} words',
            green: '3 or more words',
            yellow: '2 words',
            red: '1 word'
        },
        keywordInSlug: {
            label: 'URL slug includes keyword',
            description: 'Make sure that the URL of the published article contains the main keyword in the slug. URL slug includes "{1}"',
            green: 'Used',
            red: '"Missing'
        },
        imgAltIncludesKeyword: {
            label: 'Image ALT includes keyword',
            description: 'Make sure that the ALT, which is the alternate text of the image, contains the main keyword. Image ALT includes "{1}"',
            green: 'Used',
            red: '"Missing'
        },
        titleIncludesKeyword: {
            label: 'Meta title includes keyword',
            description: 'The meta title is the most SEO relevant HTML tag and must contain, ideally right at the beginning, the main keyword. Meta title includes "{1}"',
            green: 'Includes',
            red: 'Does not include'
        },
        titleLength: {
            label: 'Meta title length',
            description: 'Meta title length is {0} characters',
            green: 'Less than {1} characters',
            red: 'More than {1} characters'
        },
        descriptionIncludesKeyword: {
            label: 'Meta description includes keyword',
            description: 'The meta description tag informs site visitors and search engines what your article is about. Make sure it contains the main keyword. Meta description includes "{1}"',
            green: 'Includes',
            red: 'Does not include'
        },
        descriptionLength: {
            label: 'Meta description length',
            description: 'Meta description length is {0} characters',
            green: 'Less than {1} characters',
            red: 'More than {1} characters'
        },
        keywordDensity: {
            label: 'Main keyword density',
            description: 'The main keyword should naturally occur in the text. Do not overdo it with the amount and keep to the recommended density. Main keyword density is {0}%',
            green: 'Within recommended range {1} - {2}%',
            yellow: 'Low density (< {1}%)',
            red: 'High density (> {2}%)'
        },
        serpIsComplete: {
            label: 'SERP is completely written',
            description: 'Make sure the Meta title and Meta description are filled in the article code. Meta title and meta description are filled',
            green: 'Filled',
            red: 'Not filled',
        },
        avgWordCountOfTopTen: {
            label: 'Longer than average top ten articles',
            description: 'Current length of the article is {0} words',
            green: 'More than {1} words',
            red: 'Less than {1} words',
        },
        longestWordCountOfTopTen: {
            label: 'Longer than longest top ten article',
            description: 'The length of an article also affects its SEO score. If you want to get top organic search engine rankings, write a longer article than the top ten. Current length of the article is {0} words',
            green: 'More than {1} words',
            red: 'Less than {1} words',
        },
        keywordInIntroduction: {
            label: 'Keyword in first paragraph',
            description: '"{1} in first paragraph',
            green: 'Used',
            red: '"Missing'
        },
        keywordInH1: {
            label: 'Keyword in H1 heading',
            description: '"{1}" in H1 heading',
            green: 'Used',
            red: '"Missing'
        },
        keywordInH2: {
            label: 'Keyword in H2 heading',
            description: '"{1}" in H2 heading',
            green: 'Used',
            red: '"Missing'
        },
        keywordInH3: {
            label: 'Keyword in H3 heading',
            description: '"{1}" in H3 heading',
            green: 'Used',
            red: '"Missing'
        },
        relatedKeywords: {
            label: 'Additional relevant keywords',
            description: 'Also incorporate other related keywords found in the keyword analysis into the headings and paragraphs of the article. {0} additional relevant keywords used in the article',
            green: 'At least half used',
            red: 'Less than half used'
        },
        inboundLinks: {
            label: 'Internal links',
            description: 'Insert a few internal links to your articles on similar topics or explaining the concepts used in them. Links to your own website: {0}',
            green: 'At least one link',
            red: 'No links'
        },
        outboundLinks: {
            label: 'External links',
            description: 'Make sure that the text of the article contains several links to sites (that are not your competitors) with interesting and quality content. Links to other websites: {0}',
            green: 'At least one link',
            red: 'No links'
        },
        inspirationFromTopTen: {
            label: 'Inspiration from top ten articles.',
            description: 'Enrich your article with information from the best optimized web sites. Simply click on "Inspiration from Top Content" under each heading or paragraph.',
            green: 'Inspiration from top ten articles.',
            red: 'Without inspiration'
        },
        subHeadingDistribution: {
            label: 'Subheading distribution',
            description: 'To make it easier to read the content of an article, it is important to use subheadings to separate the text of long paragraphs. Subheading filled for {0} out of {1} parts',
            green: 'All filled',
            red: 'Some missing'
        },
        paragraphLength: {
            label: 'Paragraph length',
            description: 'Short paragraphs are important to make the content of the article easier to read. Short (<150 words) paragraph ratio is {0}',
            green: 'Most paragraphs are short',
            yellow: 'At least half of the paragraphs are short',
            red: 'Paragraphs are long'
        },
        sentenceLength: {
            label: 'Average sentence length',
            description: 'Short sentences make it easier and faster to read and understand the content of the article. Average sentence length is {0} words',
            green: 'Less than {1} words',
            yellow: 'Less than {2} words',
            red: 'More than {2} words',
        }
    },

    articleTable: {
        selectedKeyword: 'Main keyword',
        url: {
            header: {
                text: 'URL of the published article',
                tooltip: 'Enter the URL of the article to find out the organic position on Google and the SEO score of the domain.'
            },
            dialog: {
                title: {
                    add: 'Add URL',
                    edit: 'Edit URL',
                },
                placeholder: 'https://www.example.com/path-to-article',
                errors: {
                    couldNotSave: 'Could not save the URL of the article. Please try again.',
                }
            },
            tooltip: {
                add: 'Add URL of the article',
                edit: 'Edit URL of the article',
            }
        },
        createdAt: 'Created',
        organicPosition: {
            header: 'Organic position',
            tooltip: "Find out the position of the article in organic search results on Google. This information is only for informational purposes and does not guarantee the actual position of the article.",
        },
        reloadableChip: {
            remeasure: 'Remeasure',
            cooldown: 'Remeasure is available in {0} hours and {1} minutes',
            errors: {
                unexpectedError: 'An unexpected error has occurred. Please try again.',
            },
            error: 'Error',
        },
        seoScore: 'Article SEO score',
        domainSeoScore: {
            header: {
                text: 'Domain SEO score',
                tooltip: 'Find out the SEO score of the domain where the article is published.'
            },
            tooltip: 'Open in new window',
        },
        edit: 'Improve article',
    },

    articleCrossroad: {
        newContent: {
            header: 'New content',
            description: 'Generate new content for website or blog',
            button: 'Create new content',
        },
        existingContent: {
            header: 'Existing content',
            description: 'Improve the content published on the URL',
            button: 'Analyze existing content',
        }
    },

    errors: {
        requiredField: "This field is required.",
        maxWords: "Maximum length is {0} words!",
        minLength: "You must fill at least {length} letters.",
        startsWithProtocol: "URL must start with http:// or https://",
        invalidUrl: "Invalid URL",
    },
    topBar: {
        articles: 'Articles & Ranking',
        seoParams: 'SEO Parameters',
        thisArticle: 'This article',
        manual: 'Manual',
        blog: 'Blog',
        promo: 'Schedule free live demo',
    },
    checkOutFeatures: "Check out what SEOWRITEE can do for you!"
};
