import Vue from "vue";
import {Plan} from "@/model/User";
import {TranslateResult} from "vue-i18n";

export default Vue.extend({
    name: 'UserPlanMixin',
    computed: {
        user: function (): string {
            return this.$store.getters.user;
        },
        hasPlan: function (): boolean {
            return this.$store.getters.hasPlan;
        },
        hasLimitedPlan: function (): boolean {
            return this.hasPlan && this.plan?.monthly_credit !== Infinity;
        },
        hasUnLimitedPlan: function (): boolean {
            return this.hasPlan && this.plan?.monthly_credit === Infinity;
        },
        hasUsageLimitedPlan: function (): boolean {
            return this.hasPlan && this.plan?.metadata?.usageLimit === 'true';
        },
        hasPlanOrExtras: function (): boolean {
            return this.hasPlan || (this.extraInspirations > 0);
        },
        hasLimitedPlanOrExtras: function (): boolean {
            return this.hasLimitedPlan || (this.extraInspirations > 0);
        },
        plan: function (): Plan | null {
            return this.$store.getters.plan;
        },
        planName: function (): TranslateResult | string {
            return this.plan?.name || this.$t('subscriptions.noPlan');
        },
        planPeriodEnd: function (): Date | undefined {
            return this.plan?.period_end;
        },
        loadingArticlesCreatedThisPeriod: function (): boolean {
            if (!this.user) {
                return true;
            } else {
                return this.$store.getters.user.articlesCreatedThisPeriod === undefined;
            }
        },
        articlesCreatedThisPeriod: function (): number {
            return this.$store.getters.user?.articlesCreatedThisPeriod || 0;
        },
        extraInspirations: function (): number {
            return this.$store.getters.user?.extraInspirations || 0;
        },
        maxInspirationsThisPeriod: function (): number {
            if (this.plan) {
                return this.plan.monthly_credit;
            } else {
                return 0;
            }
        },
        articlesLeftThisPeriod: function (): number {
            if (this.plan) {
                return Math.max(this.maxInspirationsThisPeriod - this.articlesCreatedThisPeriod, 0);
            } else {
                return 0;
            }
        },
        hasCreditFromSubscriptionOrExtras: function (): boolean {
            return this.articlesLeftThisPeriod > 0 || this.extraInspirations > 0;
        },
        usageTooltip: function (): TranslateResult {
            if (this.maxInspirationsThisPeriod === Infinity) {
                return this.$t('subscriptions.unlimitedUsageTooltip', [this.articlesCreatedThisPeriod])
            } else {
                return this.$t('subscriptions.usageTooltip', [
                    this.articlesCreatedThisPeriod,
                    this.maxInspirationsThisPeriod
                ]);
            }
        },
        planRenewTooltip: function (): TranslateResult {
            if (this.plan?.cancel_at_period_end) {
                return this.$t('subscriptions.planWillBeCancelled');
            } else {
                return this.$t('subscriptions.planRenewTooltip');
            }
        },
        usageColor: function (): string {
            if (this.plan) {
                const coeff = this.articlesCreatedThisPeriod / this.maxInspirationsThisPeriod;
                if (coeff <= 0.75) {
                    return 'accent';
                } else if (coeff < 1) {
                    return 'warning';
                } else {
                    if (this.extraInspirations > 0) {
                        return 'warning';
                    } else {
                        return 'error';
                    }
                }
            } else {
                if (this.extraInspirations > 0) {
                    return 'warning';
                } else {
                    return 'error';
                }
            }
        }
    }
});
