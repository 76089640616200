<template>
  <v-tooltip
    :key="$i18n.locale"
    bottom
  >
    <template #activator="{ on }">
      <span
        :class="{
          'counter-reset-highlight': underline
        }"
        v-on="on"
      >
        <span v-if="absDateFirst">
          {{ dateTime | dateTime }}
        </span>
        <span v-else>
          {{ dateTime | momentAgo }}
        </span>
      </span>
    </template>
    <span v-if="absDateFirst">
      {{ dateTime | momentAgo }}
    </span>
    <span v-else>
      {{ dateTime | dateTime }}
    </span>
  </v-tooltip>
</template>

<script lang="ts">
    import Vue from "vue";

    export default Vue.extend({
        name: "DateTimeWithTooltip",
        props: {
            dateTime: {
                type: [Number, String, Date],
                default: null
            },
            absDateFirst: {
                type: Boolean,
                default: false
            },
            underline: {
                type: Boolean,
                default: false
            }
        }
    });
</script>

<style scoped lang="sass">
.counter-reset-highlight
    color: var(--v-primary-base)
    font-weight: bold
</style>
