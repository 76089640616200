<template>
  <i :class="standardClass">{{ parsed.id }}</i>
</template>

<script lang="ts">
    // component from Vuetify doc
    import Vue from "vue";

    interface ParsedIcon {
        suffix: string;
        id: string;
    }

    export default Vue.extend( {
        props: {
            name: {
                type: String,
                default: null
            }
        },
        computed: {
            parsed(): ParsedIcon {
                const check = (customSuffixes: string[], standardSuffix: string) => {
                    for (let suffix of customSuffixes) {
                        suffix = `_${suffix}`;
                        if (this.name.endsWith(suffix)) {
                            return {
                                suffix: standardSuffix,
                                id: this.name.substring(0, this.name.indexOf(suffix))
                            };
                        }
                    }
                    return false;
                };

                return (
                    check(['fill', 'filled'], '') ||
                    check(['outline', 'outlined'], 'outlined') ||
                    check(['two-tone', 'two-toned'], 'two-tone') ||
                    check(['round', 'rounded'], 'round') ||
                    check(['sharp', 'sharpened'], 'sharp') || {
                        suffix: '',
                        id: this.name
                    }
                );
            },
            standardClass(): string {
                if (this.parsed.suffix) {
                    return `material-icons-${this.parsed.suffix}`;
                }
                return 'material-icons';
            }
        }
    });
</script>
