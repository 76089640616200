import {ScrapperResponse} from "@/model/Scrapper";
import {Density} from "@/enum/density";
import {getHash, getKeywordRegExpString} from "@/utils/string";
import {getContentToExport} from "@/service/articleExport";
import {DownloadType} from "@/enum/download_type";
import {Article} from "@/model/Article";

export function inspirationWordLength(inspiration: ScrapperResponse): number {
    let length = 0;
    for (let i = 1; i <= 6; ++i) {
        for (const tag of ['h', 'p']) {
            const sitePart = (inspiration as any)['h' + i + tag];
            if (Array.isArray(sitePart)) {
                length += sitePart.reduce((acc: number, curr: string) => acc + curr.split(' ').length, 0);
            }
        }
    }
    return length;
}

export const keywordDensityThresholds = <Record<number, number>>{
    [Density.LOW]: 1,
    [Density.HIGH]: 3
};

export function keywordDensityStatus(density: number) {
    if (density < keywordDensityThresholds[Density.LOW]) {
        return Density.LOW;
    }
    if (density > keywordDensityThresholds[Density.HIGH]) {
        return Density.HIGH;
    }
    return Density.OK;
}

export function keywordOccurrences(text: string, keyword: string): number {
    return (text.match(new RegExp(getKeywordRegExpString(keyword), 'gi')) || []).length;
}

export function hyphenated(string: string): string {
    return string.replaceAll(' ', '-');
}

export function geometricAverage(lengths: number[]) {
    const validLengths = lengths.filter(l => l !== 0);
    return Math.round(Math.pow(
        validLengths.reduce((acc, curr) => acc * curr, 1),
        1 / validLengths.length
    ));
}

export function getArticleSnapshotHash(article: Article): Promise<string> {
    const html = getContentToExport(article, DownloadType.HTML)[0];
    return getHash(html);
}
