<template>
  <div>
    <v-list
      v-for="(step, index) of navigationSteps"
      :key="index"
    >
      <v-list-group
        v-if="step.items && step.items.length"
        :value="!isInArticleDetail"
        class="navHeader"
      >
        <template #activator>
          <v-subheader class="font-weight-medium">
            {{ index + 1 }}) {{ $t(step.title) }}
          </v-subheader>
        </template>
        <v-list-item
          v-for="(item, index2) of step.items"
          :key="index + '-' + index2"
          dense
        >
          <v-list-item-content>
            <v-tooltip top>
              <template #activator="{ on }">
                <v-list-item-title
                  class="mouse-cursor font-weight-medium"
                  :class="{'pointer' : shouldHavePointer(item)}"
                  style="color: var(--v-greyTint-base)"
                  v-on="on"
                  @click="scroll(item)"
                >
                  {{ step.itemPrepend }}{{ item.title }}
                  <v-icon
                    color="greyTintBtn"
                    small
                  >
                    info_outlined
                  </v-icon>
                </v-list-item-title>
              </template>
              <span v-if="item.description">
                {{ item.description }}
              </span>
            </v-tooltip>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <v-subheader
        v-else
        class="font-weight-medium"
      >
        {{ index + 1 }}) {{ $t(step.title) }}
      </v-subheader>
    </v-list>
  </div>
</template>

<script lang="ts">
    import Vue from "vue";
    import {Scroll} from "@/config/Behaviour";
    import {EventBus} from "@/utils/EventBus";
    import {TranslateResult} from "vue-i18n";

    interface Item {
        expansion: boolean,
        step: number,
        selector: string,
        title: TranslateResult,
        description: TranslateResult,
        container: string,
        offset: number
    }

    interface Step {
        title: string;
        itemPrepend: TranslateResult;
        items: Item[];
    }

    export default Vue.extend({
        name: "ListOfFeatures",
        props: {
            articleStep: {
                type: Number,
                default: null
            },
            isInArticleDetail: {
                type: Boolean,
                default: false
            },
        },
        computed: {
            navigationSteps: function () {
                let steps = [{
                    title: 'steps.initForm'
                }, {
                    title: 'steps.selectKeyword',
                    itemPrepend: this.$t('keywords.list.header.title') + ' - ',
                    items: this.transformItems([
                        'keywords.list.related',
                        'keywords.list.questions',
                        'keywords.list.longTail',
                        'keywords.list.variations',
                    ], 2, false)
                }, {
                    title: 'steps.writeArticle',
                    items: [...this.transformItems([
                                'keywords.aiPrompt',
                            ], 3, false),
                            ...this.transformItems([
                                'article.overview.selectedKeyword',
                                'article.overview.idealURL',
                                'article.overview.ALTDescription',
                                'article.overview.recommendedLength',
                                'article.overview.recommendedDensity',
                                'article.currentLength',
                                'article.currentDensity',
                            ], 3, true)
                    ]
                }] as Step[];
                steps[1].items.splice(-1, 0, {
                    expansion: false,
                    step: 2,
                    selector: 'keywordDifficulty',
                    title: this.$t('keywords.list.header.keywordDifficulty'),
                    description: this.$t('keywords.list.header.knownKeywordDifficulty'),
                    container: "html",
                    offset: Scroll.offset
                });
                for (const tag of ['h', 'p']) {
                    steps[2].items.push({
                        expansion: false,
                        step: 3,
                        selector: "h1",
                        title: this.$t('article.label.h1' + tag),
                        description: this.$t('article.inspirationFor.' + tag),
                        container: "#partEditorContainer",
                        offset: -65
                    });
                }
                steps[2].items.push({
                    expansion: false,
                    step: 3,
                    selector: 'contentList',
                    title: this.$t('article.contentList.title'),
                    description: this.$t('article.contentList.description'),
                    container: "html",
                    offset: Scroll.offset
                });
                for (let number = 2; number <= 6; ++number) {
                    for (const tag of ['h', 'p']) {
                        steps[2].items.push({
                            expansion: false,
                            step: 3,
                            selector: "h" + number,
                            title: this.$t('article.label.h' + number + tag),
                            description: this.$t('article.inspirationFor.' + tag),
                            container: "#partEditorContainer",
                            offset: -65
                        });
                    }
                }
                for (const part of ['metaTitle', 'metaDescription']) {
                    steps[2].items.push({
                        expansion: false,
                        step: 3,
                        selector: part,
                        title: this.$t('article.label.' + part),
                        description: this.$t('article.inspirationFor.' + part),
                        container: "html",
                        offset: Scroll.offset
                    });
                }
                steps[2].items.push(...this.transformItems(['article.SERPPreview'], 3, false));
                steps[2].items.push({
                    expansion: false,
                    step: 3,
                    selector: 'answers',
                    title: this.$t('article.answers.title'),
                    description: this.$t('article.answers.description'),
                    container: "html",
                    offset: Scroll.offset
                });
                steps[2].items.push(...this.transformItems([
                    'article.originality',
                    'article.export',
                    'article.recommendation'
                ], 3, false));
                return steps;
            }
        },
        methods: {
            transformItems: function (items: string[], step: number, expansion: boolean): Item[] {
                return items.map(item => ({
                    expansion: expansion,
                    step: step,
                    selector: item.substring(item.lastIndexOf('.') + 1),
                    title: this.$t(item + '.title'),
                    description: this.$t(item + '.description'),
                    container: "html",
                    offset: Scroll.offset
                }));
            },
            shouldHavePointer(item: Item): boolean {
                if (this.$route.name === "Article") {
                    if (item.step <= this.articleStep) {
                        return true;
                    }
                }
                return false;
            },
            scroll(item: Item): void {
                if (item.expansion) {
                    EventBus.$emit("activatePanel");
                } else if (this.$route.name === "Article") {
                    if (item.step <= this.articleStep) {
                        if (item.container === "#partEditorContainer") {
                            this.$vuetify.goTo('#' + item.selector,
                                               {
                                                   duration: Scroll.duration,
                                                   offset: Scroll.offset
                                               });
                        }
                        this.$vuetify.goTo('#' + item.selector,
                                           {
                                               duration: Scroll.duration,
                                               offset: item.offset,
                                               container: item.container
                                           }
                        );
                    }
                }
            },
        }
    });
</script>

<style scoped lang="sass">

</style>
