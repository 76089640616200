<template>
  <v-expand-transition>
    <div
      v-if="!(waitingForAi?.question || waitingForAi?.answer)"
      :id="'question-' + (index ? index : '')"
      class="d-flex flex-row mb-2"
    >
      <div class="d-flex flex-column flex-grow-1">
        <Editor
          :value="question.question"
          :placeholder="$t('article.placeholder.question')"
          :words-to-highlight="wordsToHighlight"
          :allow-formatting="false"
          should-listen-for-change
          is-heading
          @input="updateQuestion('question', $event)"
        >
          <template #append>
            <v-expand-x-transition>
              <ArticlePartExtend
                v-if="question.question && !question.answer"
                part="question"
                :uid="question.uid"
                :loading.sync="loadingExtend"
                :words-generated-elsewhere="wordsGeneratedInA"
                :force-prompt="strippedQuestion"
                :type="partExtendFromQuestion"
                hide-extensions
                lang-path="question"
                @wordsGenerated="count => wordsGeneratedInQ = count"
                @generatingExtensionValueId="createWatcherForA"
                @append="appendToAnswer"
              />
            </v-expand-x-transition>
          </template>
        </Editor>
        <div class="d-flex flex-wrap align-center text-caption-primary mx-3">
          <span>
            {{ $t('article.questions.question') + ' ' + (index + 1) }}
          </span>
          <VerticalDivider />
          <span>
            {{ $t('article.questions.title') }}
          </span>
        </div>
        <Editor
          class="mt-5"
          :value="question.answer"
          :placeholder="$t('article.placeholder.answer')"
          :words-to-highlight="wordsToHighlight"
          should-listen-for-change
          @input="updateQuestion('answer', $event)"
        />
        <v-expand-transition>
          <ArticlePartExtend
            v-if="showExtendWithAi"
            part="question"
            :uid="question.uid"
            :loading.sync="loadingExtend"
            :words-generated-elsewhere="wordsGeneratedInQ"
            :watch-for-extension-value="watchForExtenstionValue"
            lang-path="answer"
            @wordsGenerated="count => wordsGeneratedInA = count"
            @append="appendToAnswer"
          />
        </v-expand-transition>
        <div class="d-flex flex-wrap align-center text-caption-primary mx-3">
          <span>
            {{ $t('article.questions.answer') }}
          </span>
          <VerticalDivider />
          <ArticlePartExtendToggle
            v-model="showExtendWithAi"
            lang-path="answer"
          />
          <VerticalDivider />
          <v-chip
            small
            outlined
            :disabled="$store.getters.articleAiLoading.loadingRatioQuestions > 0"
            class="chip-text-primary"
            @click="$emit('add-question')"
          >
            <v-icon class="mr-2">
              playlist_add
            </v-icon>
            {{ $t('article.addQuestion') }}
          </v-chip>
        </div>
      </div>
      <div>
        <ArticlePartRemoveDialog
          part="question"
          :title="question.question"
          :content="question.answer"
          lang-path="article.removeQuestion"
          @removePart="removeQuestion"
        />
      </div>
    </div>
  </v-expand-transition>
</template>

<script lang="ts">
    import Vue, {PropType} from 'vue';
    import Editor from '@/components/Editor.vue';
    import { ArticleQuestion } from "@/model/Article";
    import VerticalDivider from "@/components/VerticalDivider.vue";
    import ArticlePartRemoveDialog from "@/components/article/ArticlePartRemoveDialog.vue";
    import ArticlePartExtendToggle from "@/components/article/ArticlePartExtendToggle.vue";
    import ArticlePartExtend from "@/components/article/ArticlePartExtend.vue";
    import { Store } from "@/plugins/Store";
    import { stripTagsAndEntities } from '@/utils/string';
    import { OpenAiExpandPartType } from "@/model/OpenAI";

    export default Vue.extend({
        name: 'ArticleQuestionsEditor',
        components: {ArticlePartExtend, ArticlePartExtendToggle, ArticlePartRemoveDialog, VerticalDivider, Editor},
        props: {
            index: {
                type: Number,
                default: 0
            },
            wordsToHighlight: {
                type: Object as PropType<{ [key: string]: string[] }>,
                default: () => ({})
            },
        },
        data: () => ({
            showExtendWithAi: true,
            watchForExtenstionValue: '',
            wordsGeneratedInQ: 0,
            wordsGeneratedInA: 0,
            loadingExtend: false,
            partExtendFromQuestion: OpenAiExpandPartType.FROM_QUESTION
        }),
        computed: {
            question: function (): ArticleQuestion {
                return this.$store.getters.article.questions[this.index];
            },
            waitingForAi: function (): ArticleQuestion {
                return this.$store.getters.articleAiLoading.questions[this.index];
            },
            strippedQuestion: function (): string {
                return stripTagsAndEntities(this.question.question);
            },
        },
        methods: {
            updateQuestion: function (key: 'question' | 'answer', value: string): void {
                this.$store.commit('setArticleQuestion', {
                    index: this.index,
                    questionKey: key,
                    value: value
                });
            },
            createWatcherForA: function (valueId: string) {
                this.watchForExtenstionValue = valueId;
            },
            removeQuestion: function (): void {
                this.$store.commit('removeArticleQuestion', this.index);
            },
            appendToAnswer: function (text: string): void {
                Store.commit('setArticleQuestion', {
                    index: this.index,
                    questionKey: 'answer',
                    value: this.question.answer + (this.question.answer ? '<br><br>' : '') + text
                });
            }
        }
    });
</script>

<style scoped lang="sass">
</style>
