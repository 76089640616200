import { render, staticRenderFns } from "./FeatureList.vue?vue&type=template&id=33e1a1e2&scoped=true"
import script from "./FeatureList.vue?vue&type=script&lang=ts"
export * from "./FeatureList.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.7_css-loader@6.9.0_vue-template-compiler@2.7.16_webpack@5.89.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33e1a1e2",
  null
  
)

export default component.exports