<template>
  <v-expand-transition>
    <div
      v-if="!waitingForAi"
      class="d-flex justify-center mt-14"
    >
      <v-expand-x-transition>
        <div
          v-show="showInspirations"
          class="article-inspiration"
        >
          <ArticleMetaInspirations
            :inspirations="inspirations"
            :part="part"
            :loading-inspirations="loadingInspirations"
            :words-to-highlight="wordsToHighlight"
          />
        </div>
      </v-expand-x-transition>
      <div
        :id="part"
        class="article-content"
      >
        <Editor
          v-model="value"
          :placeholder="$t('article.placeholder.' + part)"
          :words-to-highlight="wordsToHighlight"
          :should-listen-for-change="waitingForAi"
          :waiting-for-ai="waitingForAi"
        />
        <div class="d-flex flex-wrap align-center text-caption-primary mx-3">
          <v-progress-linear
            v-if="!waitingForAi"
            height="25"
            rounded
            :color="length < suggestedLength + 1 ? 'accent' : 'error'"
            :value="length"
            :max="suggestedLength * 4/3"
            style="width: 10rem;"
          >
            <template #default="{ }">
              <strong>{{ length }} / {{ suggestedLength }}</strong>
            </template>
          </v-progress-linear>
          <VerticalDivider />
          <span class="text-capitalize">
            {{ $t('article.label.' + part) }}
          </span>
          <VerticalDivider />
          <ArticlePartInspirationToggle v-model="showInspirations" />
          <VerticalDivider />
          <ArticlePartTipToggle v-model="showTips" />
        </div>
        <ArticlePartTip
          :show="showTips"
          :lang-path="'article.tips.' + part"
        />
      </div>
    </div>
  </v-expand-transition>
</template>

<script lang="ts">
    import Vue, {PropType} from 'vue';
    import Editor from '@/components/Editor.vue';
    import {stripTagsAndEntities} from '@/utils/string';
    import {ArticleInspirations} from "@/model/Article";
    import ArticleMetaInspirations from "@/components/article/ArticleMetaInspirations.vue";
    import VerticalDivider from "@/components/VerticalDivider.vue";
    import ArticlePartTipToggle from "@/components/article/ArticlePartTipToggle.vue";
    import ArticlePartTip from "@/components/article/ArticlePartTip.vue";
    import ArticlePartInspirationToggle from "@/components/article/ArticlePartInspirationToggle.vue";

    export default Vue.extend({
        name: "ArticleMetaPartEditor",
        components: {
            ArticlePartInspirationToggle,
            ArticlePartTip, ArticlePartTipToggle, VerticalDivider, ArticleMetaInspirations, Editor
        },
        props: {
            part: {
                type: String as PropType<'metaTitle' | 'metaDescription'>,
                default: () => 'metaTitle'
            },
            suggestedLength: {
                type: Number,
                default: 0
            },
            wordsToHighlight: {
                type: Object as PropType<{ [key: string]: string[] }>,
                default: () => ({})
            },
            inspirations: {
                type: Object as PropType<ArticleInspirations>,
                default: () => ({})
            },
            loadingInspirations: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            length: 0,
            showInspirations: false,
            showTips: false,
        }),
        computed: {
            articleId: function (): string {
                return this.$route.params.articleId as string;
            },
            value: {
                get: function (): string {
                    return this.$store.getters.article[this.part];
                },
                set: function (value: string): void {
                    const stripped = stripTagsAndEntities(value);
                    this.length = stripped.length;
                    this.$store.commit('setArticleMeta', {
                        metaPart: this.part,
                        value: stripped
                    });
                },
            },
            waitingForAi: function (): boolean {
                return this.$store.getters.articleAiLoading[this.part];
            },
        }
    });
</script>

<style scoped lang="sass">
</style>
