<template>
  <v-dialog
    v-model="localDialog"
    :persistent="loading"
    width="600"
    eager
  >
    <v-card>
      <v-card-title>
        {{ $t('articleTable.url.dialog.title.' + (isEdit ? 'edit' : 'add')) }}
        <v-spacer />
        <v-btn
          icon
          plain
          @click="closeDialog"
        >
          <v-icon>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-alert
        v-if="error"
        type="warning"
        dense
        class="ma-4"
      >
        {{ error }}
      </v-alert>
      <v-progress-linear
        v-if="loading"
        indeterminate
      />
      <v-form
        ref="form"
        v-model="valid"
        @submit.prevent
      >
        <v-text-field
          ref="urlInput"
          v-model="url"
          single-line
          class="mx-4 mt-4"
          clearable
          dense
          outlined
          validate-on-blur
          suffix="*"
          :disabled="loading"
          :placeholder="$t('articleTable.url.dialog.placeholder')"
          :rules="[rules.requiredRule, rules.validUrlRule]"
        />
      </v-form>
      <v-card-actions class="pb-4">
        <v-btn
          text
          color="error"
          outlined
          :disabled="!isEdit"
          @click="removeUrl"
        >
          <v-icon left>
            delete
          </v-icon>
          {{ $t('delete') }}
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          elevation="0"
          @click="saveUrl"
        >
          <v-icon left>
            save
          </v-icon>
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
    import Vue from "vue";
    import {rules} from "@/utils/rules";

    export default Vue.extend({
        name: 'UrlEditDialog',
        model: {
            prop: 'dialog',
            event: 'change'
        },
        props: {
            dialog: {
                type: Boolean,
                default: false
            },
            article: {
                type: Object,
                default: null
            },
            loading: {
                type: Boolean,
                default: false
            },
            error: {
                type: String,
                default: null
            }
        },
        data: () => ({
            rules: rules,
            url: '',
            valid: true
        }),
        computed: {
            isEdit: function (): boolean {
                return this.article?.url && this.article.url !== '';
            },
            localDialog: {
                get: function (): boolean {
                    return this.dialog;
                },
                set: function (val: boolean) {
                    this.$emit('change', val);
                }
            }
        },
        watch: {
            dialog: function (val: boolean) {
                if (val) {
                    if (this.$refs.form) {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        this.$refs.form.resetValidation();
                    }
                    this.populate();
                    this.$nextTick(() => {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        this.$refs.urlInput.focus();
                    });
                }
            }
        },
        created() {
            if (this.dialog) {
                this.populate();
            }
        },
        methods: {
            populate: function () {
                this.url = this.article?.url || '';
            },

            closeDialog: function () {
                if (!this.loading) {
                    this.localDialog = false;
                }
            },

            saveUrl: function () {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (!this.loading && this.$refs.form.validate()) {
                    this.$emit('action', this.url.trim());
                }
            },
            removeUrl: function () {
                if (!this.loading) {
                    this.url = '';
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    this.$refs.form.resetValidation();
                    this.$emit('action', '');
                }
            },
        }
    });
</script>

<style scoped lang="sass">

</style>
