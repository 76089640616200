<template>
  <v-btn
    depressed
    color="accent"
    :disabled="loading || disabled"
    v-bind="$attrs"
    class="multi-line-button"
    :block="block"
    @click="saveArticle"
  >
    <v-spacer />
    {{ $t(text) }}
    <v-spacer />
    <v-icon class="ml-2">
      {{ icon }}
    </v-icon>
  </v-btn>
</template>

<script lang="ts">
    import Vue from "vue";

    export default Vue.extend({
        name: "ArticleSaveButton",
        props: {
            block: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            loading: false,
            text: 'article.save',
            icon: 'save'
        }),
        methods: {
            saveArticle: function (callback: (() => void) | null = null): void {
                this.loading = true;
                this.$emit('input', () => {
                    this.loading = false;
                    this.text = 'article.saved';
                    this.icon = 'done';
                    if (typeof callback === 'function') {
                        callback();
                    }
                    setTimeout(() => {
                        this.text = 'article.save';
                        this.icon = 'save';
                    }, 4000);
                });
            }
        }
    });
</script>
