<template>
  <v-chip
    small
    outlined
    class="chip-text-primary"
    @click="$emit('input', !value)"
  >
    <v-icon
      class="mr-2"
      :class="{
        'rotate-180': value
      }"
    >
      chevron_left
    </v-icon>
    {{ $t('article.getInspired') }}
  </v-chip>
</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        name: "ArticlePartInspirationToggle",
        props: {
            value: {
                type: Boolean,
                default: false
            }
        },
    });
</script>

<style scoped lang="sass">
</style>
