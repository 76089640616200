<template>
  <div>
    <v-btn
      id="originality"
      depressed
      color="primary"
      :disabled="loading || isTrial || used >= total || !article.id"
      class="ma-2"
      @click="generateCopyLeaksReport"
    >
      {{ $t('article.generateCopyLeaksReport') }}
      ({{ total - used }}/{{ total }})
      <v-icon class="ml-2">
        search
      </v-icon>
    </v-btn>
    <span
      v-if="isTrial"
      class="caption"
    >
      <v-icon class="mx-2">info</v-icon>
      {{ $t('subscriptions.upgradeToUse') }}
    </span>
    <v-chip
      v-for="report of reports"
      :key="report.createdAt"
      class="ma-1"
      color="primary lighten-2"
      :disabled="!report.pdfReady"
      @click="downloadReport(report)"
    >
      <v-icon class="mr-2">
        {{ report.pdfReady ? 'description' : 'hourglass_top' }}
      </v-icon>
      {{ report.createdAt | dateTime }}
      <v-icon
        v-if="report.pdfReady"
        class="ml-2"
      >
        open_in_new
      </v-icon>
    </v-chip>
    <v-chip v-if="loading">
      <v-progress-circular
        indeterminate
        class="mr-2"
        size="20"
        width="2"
      />
      {{ $t('article.generatingCopyLeaksReport') }}
    </v-chip>
  </div>
</template>

<script lang="ts">
    import {Functions} from "@/plugins/Functions";
    import Vue from "vue";
    import {Firestore} from "@/plugins/Firestore";
    import {CopyLeaksResult} from "@/model/CopyLeaks";
    import {getDownloadURL, getStorageRef} from "@/plugins/Storage";
    import firebase from "firebase/compat";
    import DocumentData = firebase.firestore.DocumentData;
    import DocumentSnapshot = firebase.firestore.DocumentSnapshot;
    import {RecommendedLength} from "@/enum/article_recommended_length";
    import {articleAsBase64} from "@/service/docx";

    export default Vue.extend({
        name: "ArticlePlagiarismReport",
        data: () => ({
            used: 2,
            total: 2,
            loading: false,
            reports: [] as CopyLeaksResult[]
        }),
        computed: {
            article: function () {
                return this.$store.getters.article;
            },
            isTrial: function (): boolean {
                return !this.$store.getters.hasPlan;
            }
        },
        watch: {
            'article.id': {
                immediate: true,
                handler: function () {
                    this.fetchArticleScans();
                }
            }
        },
        methods: {
            fetchArticleScans: function () {
                if (this.article.id) {
                    return Firestore.getArticleScans(this.article.id)
                        .then(reports => {
                            this.reports = reports;
                            this.used = reports.length;
                        });
                } else {
                    return Promise.resolve();
                }
            },
            generateCopyLeaksReport: function () {
                this.loading = true;
                articleAsBase64(this.article, RecommendedLength.CopyLeaks)
                    .then(base64 => {
                        this.used++;
                        const articleId = this.article.id;
                        if (articleId === null) {
                            return; // Should not happen
                        }
                        Functions.CLSubmitDocument({
                            articleId: articleId,
                            articleTitle: this.article.metaTitle || this.article.selectedKeyword,
                            articleContentInBase64: base64
                        }).then(response => {
                            const scanId = response.scanDocumentId;
                            const stopWatcher = Firestore.createArticleScanWatcher(
                                articleId,
                                scanId,
                                (doc: DocumentSnapshot<DocumentData>) => {
                                    const ready = doc.get('pdfReady');
                                    if (ready) {
                                        stopWatcher && stopWatcher();
                                        this.fetchArticleScans().finally(() => {
                                            this.loading = false;
                                        });
                                    }
                                }
                            );
                        }).catch(error => {
                            this.fetchArticleScans().finally(() => {
                                this.loading = false;
                            });
                            this.$store.dispatch("handleApiError", error);
                        });
                    })
                    .catch(() => {
                        this.loading = false;
                        this.$store.dispatch("handleApiError");
                    });
            },
            downloadReport: function (report: CopyLeaksResult) {
                getDownloadURL(getStorageRef(`users/${report.developerPayload.userId}`
                    + `/articles/${report.developerPayload.articleId}`
                    + `/copyLeaksScans/${report.developerPayload.timestamp}`)
                ).then(url => {
                    window.open(url, '_blank');
                });
            }
        }
    });
</script>

<style scoped>

</style>
