import Vue from "vue";
import VueI18n from "vue-i18n";
import en from '@/lang/en';
import cs from '@/lang/cs';

Vue.use(VueI18n);

const locale = {
    locale: 'en',
    defaultLocale: 'en',
    fallbackLocale: 'en',
    messages: {en, cs}
};

const I18n = new VueI18n({
    ...locale
});

export {locale, I18n};
