<template>
  <div class="mt-6">
    <v-progress-linear
      v-if="loading"
      indeterminate
    />
    <v-list>
      <v-list-item
        to="/"
        class="font-weight-medium"
      >
        {{ $t('article.list') }}:
      </v-list-item>
      <v-list-item
        v-for="article of articles.slice(0, 10)"
        :key="article.id"
        dense
        :to="'/article/' + article.id"
      >
        <v-list-item-title
          class="font-weight-medium"
          style="color: var(--v-greyTint-base)"
        >
          <v-icon
            x-small
            class="mr-2"
          >
            chevron_right
          </v-icon>
          {{ article.selectedKeyword || article.keyword || article.metaTitle }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="articles.length >= 11"
        dense
        to="/"
      >
        <v-list-item-title
          class="font-weight-medium"
        >
          ... {{ $t('article.listMore') }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </div>
</template>

<script lang="ts">
    import Vue from 'vue';
    import {Article} from "@/model/Article";
    import {Firestore} from "@/plugins/Firestore";
    import EventsListenerMixin from "@/mixins/EventsListenerMixin";

    export default Vue.extend({
        name: "ArticleSimpleList",
        mixins: [EventsListenerMixin],
        data: () => ({
            loading: false,
            articles: [] as Article[]
        }),
        computed: {
            events: function () {
                return [{
                    'reload-article-list': this.getUserArticles
                }];
            }
        },
        created: function () {
            this.getUserArticles();
        },
        methods: {
            getUserArticles: function (): void {
                this.loading = true;
                Firestore.getUserArticles(11)
                    .then(articles => {
                        this.articles = articles;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
        },
    });
</script>
