import Vue from "vue";

function date(date: string | number): string {
    return date && (Vue as any).options.filters.moment(+date, "D. M. YYYY");
}

function dateTime(date: string | number): string {
    return date && (Vue as any).options.filters.moment(+date, "D. M. YYYY H:mm");
}

function momentAgo(date: string | number): string {
    return date && (Vue as any).options.filters.moment(+date, "from");
}

function loadFilters(): void {
    Vue.filter('date', date);
    Vue.filter('dateTime', dateTime);
    Vue.filter('momentAgo', momentAgo);
}

export {loadFilters};
