<template>
  <span>
    <v-tooltip left>
      <template #activator="{ on, attrs }">
        <v-btn
          color="error"
          v-bind="attrs"
          :disabled="disabled"
          text
          x-small
          class="side-button ml-4"
          @click="dialog = true"
          v-on="on"
        >
          <v-icon>delete_forever</v-icon>
        </v-btn>
      </template>
      <span>
        {{ $t(langPath + '.tooltip') }}
      </span>
    </v-tooltip>
    <v-dialog
      v-model="dialog"
      max-width="800"
    >
      <v-card>
        <v-card-title class="text-h5 primary pb-4 mb-2 white--text">
          {{ $t(langPath + '.title', [$te('article.label.' + part + 'h') ? $t('article.label.' + part + 'h') : '']) }}
        </v-card-title>
        <v-card-text>
          <div
            class="text-h6"
            v-html="title"
          />
          <div v-html="content.substring(0, 100).concat(content.length > 100 ? '...' : '')" />
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            text
            @click="removePart"
          >
            {{ $t(langPath + '.confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script lang="ts">
    import Vue from "vue";

    export default Vue.extend({
        name: 'ArticlePartRemoveDialog',
        props: {
            part: {
                type: String,
                default: ''
            },
            title: {
                type: String,
                default: ''
            },
            content: {
                type: String,
                default: ''
            },
            langPath: {
                type: String,
                default: 'article.removeSubpart'
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            dialog: false,
        }),
        methods: {
            removePart: function () {
                this.dialog = false;
                this.$emit('removePart');
            }
        }
    });
</script>

<style scoped lang="sass">
.side-button
    height: 100% !important
</style>
