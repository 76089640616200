<template>
  <UserLayout :article-step="1">
    <KeywordForm
      :loading="loading"
      :is-analyze="isAnalyze"
      @input="onKeywordInput"
    />
    <v-dialog
      v-model="usageDialog"
      max-width="600"
    >
      <v-card class="pa-10">
        <v-card-title>
          {{ dialogText }}
        </v-card-title>
        <v-card-actions v-if="dialogAction">
          <v-spacer />
          <v-btn
            :color="dialogActionColor"
            @click="dialogAction"
          >
            {{ dialogActionText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </UserLayout>
</template>

<script lang="ts">
    import Vue from 'vue';
    import {Article, ArticleMetadata} from "@/model/Article";
    import {Firestore} from "@/plugins/Firestore";
    import UserLayout from "@/layouts/UserLayout.vue";
    import KeywordForm from "@/components/keywords/KeywordForm.vue";
    import {FlashMessage} from "@/model/FlashMessage";
    import UserPlanMixin from "@/mixins/UserPlanMixin";
    import {TranslateResult} from "vue-i18n";
    import {UnlimitedPlanLimits} from "@/config/UnlimitedPlan";

    export default Vue.extend({
        name: "CreateArticleView",
        components: {KeywordForm, UserLayout},
        mixins: [UserPlanMixin],
        props: {
            isAnalyze: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            article: new Article,
            loading: false,
            trialArticle: false,
            usageDialog: false,
            dialogText: '' as TranslateResult,
            dialogAction: null as null | (() => any),
            dialogActionText: '' as TranslateResult,
            dialogActionColor: 'primary',
        }),
        created: function () {
            if (this.$store.getters.user) {
                this.trialArticle = this.$store.getters.user.trialUsed;
            }
        },
        activated: function () {
            if (this.$store.getters.user) {
                this.trialArticle = this.$store.getters.user.trialUsed;
            }
        },
        methods: {
            onKeywordInput: function (input: ArticleMetadata) {
                this.loading = true;
                this.$set(this.article, 'keyword', input.keyword);
                this.$set(this.article, 'aiPrompt', input.aiPrompt);
                this.$set(this.article, 'url', input.url.trim());
                this.$set(this.article, 'isAnalyze', this.isAnalyze);
                if (!input.aiPrompt) {
                    this.$set(this.article, 'noAiPrompt', true);
                }
                this.$set(this.article, 'language_code', input.language_code);
                this.$set(this.article, 'location_code', input.location_code);
                if (!this.hasCreditFromSubscriptionOrExtras) {
                    if (this.$store.getters.user.trialUsed !== true) {
                        Firestore.setTrialUsed().then(() => {
                            return this.saveArticle();
                        }).catch(error => {
                            console.error(error);
                            this.$store.dispatch('displayFlashMessage', {
                                type: 'error',
                                content: 'unexpectedError',
                            } as FlashMessage);
                        });
                    } else {
                        this.$store.dispatch('displayFlashMessage', {
                            type: 'error',
                            content: 'subscriptions.noInspirationsLeftThisPeriodUpgradeToContinue',
                        } as FlashMessage);
                        return;
                    }
                } else {
                    if (this.hasUnLimitedPlan && this.hasUsageLimitedPlan) {
                        // TODO keep this in sync with functions/src/Subscription.ts
                        if (this.articlesCreatedThisPeriod >= UnlimitedPlanLimits.stop) {
                            this.usageDialog = true;
                            this.dialogText = this.$t('subscriptions.usageLimit.stop');
                            if (this.extraInspirations > 0) {
                                this.dialogAction = this.saveArticle; // BE will handle the subtraction in ArticleOnCreateObserver
                                this.dialogActionText = this.$t('subscriptions.usageLimit.useExtraInspiration', [this.extraInspirations]);
                                this.dialogActionColor = 'accent';
                            } else {
                                this.dialogAction = () => this.$router.push('/account'); // TODO autoscroll to packages
                                this.dialogActionText = this.$t('subscriptions.usageLimit.buyExtraInspirations');
                                this.dialogActionColor = 'primary';
                            }
                            return;
                        } else if (this.articlesCreatedThisPeriod === UnlimitedPlanLimits.warning) {
                            this.usageDialog = true;
                            this.dialogText = this.$t('subscriptions.usageLimit.warning');
                            this.dialogAction = this.saveArticle;
                            this.dialogActionText = this.$t('continue');
                            this.dialogActionColor = 'accent';
                            return;
                        } else {
                            return this.saveArticle();
                        }
                    } else {
                        return this.saveArticle();
                    }
                }
            },
            saveArticle: function () {
                Firestore.saveUserArticle(this.article).then(articleId => {
                    this.$router.push({
                        path: '/article/' + articleId,
                        query: !this.trialArticle ? {trial: 'yes'} : {}
                    });
                }).catch(error => {
                    Firestore.removeTrialUsed();
                    console.error(error);
                    this.$store.dispatch('displayFlashMessage', {
                        type: 'error',
                        content: 'unexpectedError',
                    } as FlashMessage);
                }).finally(() => {
                    this.loading = false;
                });
            },
        }
    });
</script>

<style scoped>

</style>
