<template>
  <v-expand-transition v-if="$te(langPath)">
    <div
      v-show="show"
      class="ma-2 font-italic"
    >
      {{ $t(langPath) }}
    </div>
  </v-expand-transition>
</template>

<script lang="ts">
    import Vue, {PropType} from 'vue';

    export default Vue.extend({
        name: "ArticlePartTip",
        props: {
            show: {
                type: Boolean as PropType<boolean>,
                default: false
            },
            langPath: {
                type: String as PropType<string>,
                default: ''
            }
        }
    });
</script>

<style scoped lang="sass">
</style>
