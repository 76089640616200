<template>
  <div>
    <h4 class="text-h4 text-center mb-4">
      {{ $t('subscriptions.oneTimePacks') }}
    </h4>
    <div class="d-flex flex-wrap justify-center">
      <v-card
        v-for="(pack, index) of packages"
        :id="'oneTimeInspiration' + (5 - (index + 1))"
        :key="pack.id"
        flat
        class="ma-4 pa-4 one-time-pack d-flex"
        dark
        hover
        rounded
        width="200"
        height="186"
      >
        <div style="z-index: 1">
          <v-card-title class="font-weight-bold pl-2">
            {{ pack.transform_quantity?.divide_by }}x
            {{ $t('subscriptions.inspiration') }}
          </v-card-title>
          <v-card-text
            class="pl-2"
            style="color: white; font-size: 17px; font-weight: 600"
          >
            {{ (pack.unit_amount / 100).toFixed(2) }}
            {{ pack.currency.toUpperCase() }}
          </v-card-text>
          <v-card-actions>
            <v-btn
              outlined
              :loading="loading"
              @click="checkoutPack(pack.id)"
            >
              <v-icon class="mr-2">
                payment
              </v-icon>
              {{ $t('subscriptions.buy') }}
            </v-btn>
          </v-card-actions>
        </div>
        <div
          style="position: absolute; width: 200px; height: 186px; z-index: 0; top: 0; left: 0"
        >
          <OneTimeInspiration1
            v-if="(5 - (index + 1)) === 1"
            style="position: absolute; z-index: 0;"
            class="rounded"
          />
          <OneTimeInspiration2
            v-if="(5 - (index + 1)) === 2"
            style="position: absolute;"
            class="rounded"
          />
          <OneTimeInspiration3
            v-if="(5 - (index + 1)) === 3"
            style="position: absolute;"
            class="rounded"
          />
          <OneTimeInspiration4
            v-if="(5 - (index + 1)) === 4"
            style="position: absolute;"
            class="rounded"
          />
        </div>
      </v-card>
    </div>
  </div>
</template>

<script lang="ts">
    import Vue, {PropType} from "vue";
    import {Stripe} from "@/plugins/Stripe";
    // @ts-ignore
    import OneTimeInspiration1 from '@/assets/vue-svg-loader/oneTimeInspiration/OneTimeInspiration1.svg';
    // @ts-ignore
    import OneTimeInspiration2 from '@/assets/vue-svg-loader/oneTimeInspiration/OneTimeInspiration2.svg';
    // @ts-ignore
    import OneTimeInspiration3 from '@/assets/vue-svg-loader/oneTimeInspiration/OneTimeInspiration3.svg';
    // @ts-ignore
    import OneTimeInspiration4 from '@/assets/vue-svg-loader/oneTimeInspiration/OneTimeInspiration4.svg';
    import {FlashMessage} from "@/model/FlashMessage";
    import {captureException} from "@/plugins/Sentry";
    import {StripePrice} from "@/model/Stripe";

    export default Vue.extend({
        name: "OneTimePacks",
        components: {
            OneTimeInspiration1, OneTimeInspiration2, OneTimeInspiration3, OneTimeInspiration4
        },
        props: {
            packages: {
                type: Array as PropType<Array<StripePrice>>,
                default: () => []
            }
        },
        data: () => ({
            loading: false
        }),
        methods: {
            checkoutPack: function (packId: string): void {
                this.loading = true;
                Stripe.createStripeCheckoutSession(packId, this.$store.getters.user.uid, true)
                    .then(stripeCheckoutUrl => {
                        this.loading = false;
                        window.location.assign(stripeCheckoutUrl);
                    }).catch(error => {
                        this.$store.dispatch('displayFlashMessage', {
                            type: 'error',
                            content: 'Error during communication with Stripe.'
                        } as FlashMessage);
                        captureException(error);
                        this.loading = false;
                    });
            }
        }
    });
</script>

<style lang="sass" scoped>
.one-time-pack
    white-space: nowrap

#transformEl
    transition-duration: 500ms
    transition-timing-function: ease-in-out
    transform-origin: left

#transformEl2
    transition-duration: 500ms
    transition-timing-function: ease-in-out
    transform-origin: left

#oneTimeInspiration1
    #transformEl
        transform-origin: bottom right
    #transformEl2
        transform-origin: bottom right

#oneTimeInspiration2
    #transformEl2
        transform-origin: right

#oneTimeInspiration3
    #transformEl
        transform-origin: top left
    #transformEl2
        transform-origin: top left

#oneTimeInspiration4
    #transformEl
        transform-origin: top right
    #transformEl2
        transform-origin: bottom left

#oneTimeInspiration1:hover
    #transformEl
        transform: scale(5)
    #transformEl2
        transform: scale(5)
#oneTimeInspiration2:hover
    #transformEl
        transform: scale(5)
    #transformEl2
        transform: scale(5)
#oneTimeInspiration3:hover
    #transformEl
        transform: scale(5)
    #transformEl2
        transform: scale(10)
#oneTimeInspiration4:hover
    #transformEl
        transform: scale(5)
    #transformEl2
        transform: scale(5)

#oneTimeInspiration4:hover
    #transformEl
        transform: scale(5)
</style>
