import Vue, {PropType} from "vue";
import {ArticleInspirations} from "@/model/Article";

/**
 * @requires this.inspirationsForPart
 */
export default Vue.extend({
    name: 'ArticleInspirationMixin',
    props: {
        inspirations: {
            type: Object as PropType<ArticleInspirations>,
            default: () => ({})
        },
        loadingInspirations: {
            type: Boolean,
            default: false
        },
        part: {
            type: String,
            default: null
        },
    },
    computed: {
        inspirationsForPart: (): number => {
            // Implement in concrete usage
            return 0;
        },
        skeletonCounter: function (): number {
            return Math.floor((10 - this.inspirationsForPart) / 3);
        },
        showNoInspiration: function (): boolean {
            if (this.loadingInspirations) {
                return false;
            } else {
                return this.inspirationsForPart === 0;
            }
        }
    }
});
