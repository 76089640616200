var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:_vm.$attrs},[_c('v-input',{staticClass:"mb-2",attrs:{"disabled":_vm.disabled,"hide-details":""},scopedSlots:_vm._u([(_vm.waitingForAi)?{key:"message",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('article.openAi.loading'))+" ")]},proxy:true}:null,{key:"prepend",fn:function(){return [_vm._t("prepend")]},proxy:true},{key:"append",fn:function(){return [_vm._t("append")]},proxy:true}],null,true)},[(_vm.disabled || !_vm.waitingForAi)?_c('ckeditor',{staticClass:"flex-grow-1 focusedStyleOnHover",class:{
        'ck-border' : !_vm.disabled,
        'ck-border-disabled' : _vm.disabled,
        'text-h5' : _vm.isHeading,
        'ck-small-margin': _vm.isHeading,
        'ck-text-bold': _vm.isHeading
      },attrs:{"editor":_vm.editor,"config":_vm.config,"words-to-highlight":_vm.wordsToHighlight},model:{value:(_vm.localValue),callback:function ($$v) {_vm.localValue=$$v},expression:"localValue"}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }