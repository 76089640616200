import {getFunctions, httpsCallable, connectFunctionsEmulator} from 'firebase/functions';
import {FirebaseApp} from '@/plugins/Firebase';
import {
    OrganicPositionRequest,
    D4SRequest,
    InspirationResponse,
    KeywordSuggestionLite,
    SERPResponse
} from '@/model/DataForSeo';
import {ScrapperRequest, ScrapperResponse} from '@/model/Scrapper';
import {config} from "@/config";
import {CopyLeaksRequest, CopyLeaksResponse} from "@/model/CopyLeaks";
import {CancelArticleRequest, OrganicPositionResponse} from "@/model/Article";
import {OpenAIEditRequest, OpenAIEditResponse, OpenAiExtendPartRequest, OpenAiExtendPartResponse} from "@/model/OpenAI";

/**
 * Enable for local emulator
 * Must be always false before commit
 */
const ENABLE_LOCAL_EMULATOR = false;

const functions = getFunctions(FirebaseApp, config.region);

if (ENABLE_LOCAL_EMULATOR) {
    // See https://firebase.google.com/docs/functions/local-emulator#set_up_admin_credentials_optional
    connectFunctionsEmulator(functions, 'localhost', 5001);
}

type FunctionResponse<T> = {status: string, reason?: string, data?: T};

function createCloudFunction<T, D = undefined>(functionName: string) {
    return function (params: T): Promise<D> {
        return httpsCallable<T, FunctionResponse<D>>(functions, functionName, {
            timeout: 600_000 // 10 minutes (v1 functions lives for 9 minutes if triggered by HTTP)
        })(params)
            .then(({data: response}) => {
                if (response.status && response.status === "error") {
                    throw response.reason as string;
                } else {
                    return response.data as D;
                }
            });
    };
}

const Functions = {
    CancelArticle: createCloudFunction<CancelArticleRequest>('CancelArticle'),
    D4SGetInspiration: createCloudFunction<D4SRequest, InspirationResponse>('D4SGetInspiration'),
    D4SGetSERP: createCloudFunction<D4SRequest, SERPResponse>('D4SGetSERP'),
    D4SGetKeywordSuggestions: createCloudFunction<D4SRequest, KeywordSuggestionLite[]>('D4SGetKeywordSuggestions'),
    D4SGetOrganicPosition: createCloudFunction<OrganicPositionRequest, OrganicPositionResponse>('D4SGetOrganicPosition'),
    GetScrapped: createCloudFunction<ScrapperRequest, ScrapperResponse>('GetScrapped'),
    CLSubmitDocument: createCloudFunction<CopyLeaksRequest, CopyLeaksResponse>("CLSubmitDocument"),
    OpenAIArticleGenerate: createCloudFunction<OpenAIEditRequest, OpenAIEditResponse>("OpenAIArticleGenerate"),
    OpenAIArticleReGenerate: createCloudFunction<OpenAIEditRequest, OpenAIEditResponse>("OpenAIArticleReGenerate"),
    OpenAIArticlePartExtend: createCloudFunction<OpenAiExtendPartRequest, OpenAiExtendPartResponse>("OpenAIArticlePartExtend"),
};

export {Functions};
