const KeywordType = {
  RELATED: 0,
  QUESTION: 1,
  LONG_TAIL: 2,
  VARIATION: 3
};

const KeywordTypeLangPaths = {
  [KeywordType.RELATED]: 'keywords.list.related.',
  [KeywordType.QUESTION]: 'keywords.list.questions.',
  [KeywordType.LONG_TAIL]: 'keywords.list.longTail.',
  [KeywordType.VARIATION]: 'keywords.list.variations.'
};

export { KeywordType, KeywordTypeLangPaths };
