import {Density} from "@/enum/density";

export const seoScoreStatuses = [
    'GREEN',
    'YELLOW',
    'RED'
] as const;
export type SeoScoreStatus = typeof seoScoreStatuses[number];

export const seoScoreMetricNames = [
    'keywordLength',
    'keywordVariants',
    'relatedKeywords',
    'keywordInSlug',
    'keywordInH1',
    'keywordInIntroduction',
    'keywordInH2',
    'keywordInH3',
    'keywordDensity',
    'avgWordCountOfTopTen',
    'longestWordCountOfTopTen',
    'subHeadingDistribution',
    'paragraphLength',
    'sentenceLength',
    'inspirationFromTopTen',
    'outboundLinks',
    'inboundLinks',
    'imgAltIncludesKeyword',
    'titleIncludesKeyword',
    'titleLength',
    'descriptionIncludesKeyword',
    'descriptionLength',
    'serpIsComplete',

] as const;

export type SeoScoreMetricName = typeof seoScoreMetricNames[number];
export type SeoMetricValue = boolean | number | string | Array<string>;

export interface SeoScoreMetric {
    getStatus: (currentValue: SeoMetricValue, referenceValue?: SeoMetricValue) => SeoScoreStatus;
    getTrParams?: (value: SeoMetricValue, referenceValue?: SeoMetricValue) => Array<SeoMetricValue | undefined>;
    domSelector?: string
    getClickAction?: () => void
}

export type SeoScoreContext = {
    articleDensity: number,
    topTenGeometricAverageWordCount: number,
    topTenLongestWordCount: number,
    mergedText: string
}
export const seoScoreContext: SeoScoreContext = {
    articleDensity: Density.OK,
    topTenGeometricAverageWordCount: 0,
    topTenLongestWordCount: 0,
    mergedText: ''
};
