import Vuex from 'vuex';
import Vue from 'vue';
import {User, Plan} from '@/model/User';
import {FlashMessage, FlashMessageType} from "@/model/FlashMessage";
import {Auth} from '@/plugins/Auth';
import {ExternalLinks} from "@/config/ExternalLinks";
import {Firestore} from "@/plugins/Firestore";
import {I18n} from "@/plugins/I18n";
import {captureException} from "@/plugins/Sentry";
import {
    Article,
    ArticleContentPartStorePath, ArticleContentStorePath,
    ArticleLoadings,
    ArticleMetaPartStorePath,
    ArticleQuestionPartStorePath, ArticleQuestionStorePath,
    ArticleWordCounts, getDefaultForContent, getDefaultForQuestion
} from "@/model/Article";
import {stripMarkers, stripTagsAndEntities} from "@/utils/string";
import {getUid} from "@/utils/uid";
import {getArticleSnapshotHash} from "@/utils/ArticleComputed";
import {ScrapperResponse, Image} from "@/model/Scrapper";

type Timeout = NodeJS.Timeout;

Vue.use(Vuex);

class State {
    newContent = false;
    user: User | null | undefined = undefined;
    plan: Plan | null | undefined = undefined;
    flashMessages: FlashMessage[] = [];
    flashMessageTimeouts: Partial<Record<FlashMessageType, Timeout>> = {};
    drawer = true;
    article: Article = new Article();
    articleAiLoading: ArticleLoadings = new ArticleLoadings();
    articleWordCount: ArticleWordCounts = new ArticleWordCounts();
    articleLastSavedSnapshotHash: string | null = null;
    articleAnalyzeScrapeLoading = false;
    wordOccurrences: Record<string, number> = {};
}

const Store = new Vuex.Store({
    state: (new State()),
    getters: {
        newContent: (state: State): boolean => state.newContent,
        user: (state: State): User | null | undefined => state.user,
        authenticated: (state: State): boolean => (state.user !== null && state.user !== undefined),
        plan: (state: State): Plan | null | undefined => state.plan,
        hasPlan: (state: State): boolean => (state.plan !== null && state.plan !== undefined),
        flashMessages: (state: State): FlashMessage[] => state.flashMessages,
        drawer: (state: State): boolean => state.drawer,
        // Article
        article: (state: State): Article => state.article,
        articleWordCount: (state: State): ArticleWordCounts => state.articleWordCount,
        articleAiLoading: (state: State): ArticleLoadings => state.articleAiLoading,
        articleLastSavedSnapshotHash: (state: State): string | null => state.articleLastSavedSnapshotHash,
        articleAnalyzeScrapeLoading: (state: State): boolean => state.articleAnalyzeScrapeLoading,
        // Article computed
        wordOccurrences: (state: State): Record<string, number> => state.wordOccurrences,
    },
    mutations: {
        newContent: (state: State, value: boolean): void => {
            state.newContent = value;
        },
        setUser: (state: State, value: User): void => {
            state.user = value;
        },
        setPlan: (state: State, value: Plan): void => {
            state.plan = value;
        },
        setUserTrialUsed: (state: State, value: boolean): void => {
            if (state.user) {
                state.user.trialUsed = value;
            }
        },
        setUserTheme: (state: State, value: boolean): void => {
            if (state.user) {
                state.user.theme = value;
            }
        },
        setUserArticlesCreatedThisPeriod: (state: State, value: number): void => {
            if (state.user) {
                state.user.articlesCreatedThisPeriod = value;
            }
        },
        setUserExtraInspirations: (state: State, value: number): void => {
            if (state.user) {
                state.user.extraInspirations = value;
            }
        },
        logout: (state): void => {
            state.user = null;
        },
        addFlashMessage: (state: State, flashMessage: FlashMessage): void => {
            state.flashMessages.push(flashMessage);
        },
        addFlashMessageTimeout: (state: State, payload: { type: FlashMessageType, timeout: Timeout }): void => {
            state.flashMessageTimeouts[payload.type] = payload.timeout;
        },
        removeFlashMessage: (state: State, index: number): void => {
            state.flashMessages.splice(index, 1);
        },
        removeFlashMessageTimeout: (state: State, type: FlashMessageType): void => {
            state.flashMessageTimeouts[type] = undefined;
        },
        setDrawer: (state: State, value: boolean): void => {
            state.drawer = value;
        },
        // Article // TODO in Pinia move to separate store
        insertArticleContentSubPart: (state: State, payload: ArticleContentStorePath): void => {
            state.article[payload.contentPart].splice(payload.index, 0, getDefaultForContent());
            state.articleWordCount[payload.contentPart].splice(payload.index, 0, getDefaultForContent<number>(0));
            state.articleAiLoading[payload.contentPart].splice(payload.index, 0, getDefaultForContent<boolean>(!!payload.aiLoading));
        },
        insertArticleQuestion: (state: State, payload: ArticleQuestionStorePath): void => {
            state.article.questions.splice(payload.index, 0, getDefaultForQuestion());
            state.articleWordCount.questions.splice(payload.index, 0, getDefaultForQuestion<number>(0));
            state.articleAiLoading.questions.splice(payload.index, 0, getDefaultForQuestion<boolean>(!!payload.aiLoading));
        },
        removeArticleContentSubPart: (state: State, payload: ArticleContentStorePath): void => {
            state.article[payload.contentPart].splice(payload.index, 1);
            state.articleWordCount[payload.contentPart].splice(payload.index, 1);
            state.articleAiLoading[payload.contentPart].splice(payload.index, 1);
        },
        removeArticleQuestion: (state: State, index: number): void => {
            state.article.questions.splice(index, 1);
            state.articleWordCount.questions.splice(index, 1);
            state.articleAiLoading.questions.splice(index, 1);
        },
        setArticleMeta: (state: State, payload: ArticleMetaPartStorePath): void => {
            const noHtmlContent = stripTagsAndEntities(payload.value);
            const wordCount = noHtmlContent === '' ? 0 : noHtmlContent.split(' ').length;
            state.article[payload.metaPart] = payload.value;
            state.articleWordCount[payload.metaPart] = wordCount;
        },
        setArticleContent: (state: State, payload: ArticleContentPartStorePath): void => {
            const noHtmlContent = stripTagsAndEntities(payload.value);
            const wordCount = noHtmlContent === '' ? 0 : noHtmlContent.split(' ').length;
            if (!state.article[payload.contentPart][payload.index]) {
                Vue.set(state.article[payload.contentPart], payload.index, getDefaultForContent());
            }
            if (!state.articleWordCount[payload.contentPart][payload.index]) {
                Vue.set(state.articleWordCount[payload.contentPart], payload.index, getDefaultForContent<number>(0));
            }
            state.article[payload.contentPart][payload.index][payload.contentKey] = stripMarkers(payload.value);
            if (!state.article[payload.contentPart][payload.index].uid) {
                state.article[payload.contentPart][payload.index].uid = getUid();
            }
            state.articleWordCount[payload.contentPart][payload.index][payload.contentKey] = wordCount;
        },
        setArticleQuestion: (state: State, payload: ArticleQuestionPartStorePath): void => {
            const noHtmlContent = stripTagsAndEntities(payload.value);
            const wordCount = noHtmlContent === '' ? 0 : noHtmlContent.split(' ').length;
            if (!state.article.questions[payload.index]) {
                Vue.set(state.article.questions, payload.index, getDefaultForQuestion());
            }
            if (!state.articleWordCount.questions[payload.index]) {
                Vue.set(state.articleWordCount.questions, payload.index, getDefaultForQuestion<number>(0));
            }
            state.article.questions[payload.index][payload.questionKey] = stripMarkers(payload.value);
            if (!state.article.questions[payload.index].uid) {
                state.article.questions[payload.index].uid = getUid();
            }
            state.articleWordCount.questions[payload.index][payload.questionKey] = wordCount;
        },
        setArticleImages: (state: State, payload: { images: Image[] }): void => {
            state.article.images = payload.images;
        },
        addArticleImage: (state: State, payload: Image): void => {
            state.article.images.push(payload);
        },
        removeArticleImage: (state: State, index: number): void => {
            state.article.images.splice(index, 1);
        },
        setArticleMetaAiLoading: (state: State, payload: ArticleMetaPartStorePath<boolean>): void => {
            state.articleAiLoading[payload.metaPart] = payload.value;
        },
        setArticleContentAiLoading: (state: State, payload: ArticleContentPartStorePath<boolean>): void => {
            if (!state.articleAiLoading[payload.contentPart][payload.index]) {
                Vue.set(state.articleAiLoading[payload.contentPart], payload.index, getDefaultForContent<boolean>(false));
            }
            state.articleAiLoading[payload.contentPart][payload.index][payload.contentKey] = payload.value;
        },
        setArticleQuestionAiLoading: (state: State, payload: ArticleQuestionPartStorePath<boolean>): void => {
            if (!state.articleAiLoading.questions[payload.index]) {
                Vue.set(state.articleAiLoading.questions, payload.index, getDefaultForQuestion<boolean>(false));
            }
            state.articleAiLoading.questions[payload.index][payload.questionKey] = payload.value;
        },
        setWordOccurrences: (state: State, payload: [word: string, occurrences: number]): void => {
            Vue.set(state.wordOccurrences, payload[0], payload[1]);
        },
        setArticleSeoScore: (state: State, value: number): void => {
            state.article.seoScore = value;
        },
        setArticleSeoScoreAiLoadingCompleted: (state: State, value: boolean): void => {
            state.articleAiLoading.loadingCompleted = value;
        },
        setArticleLastSavedSnapshotHash: (state: State, value: string | null): void => {
            state.articleLastSavedSnapshotHash = value;
        },
        setArticleAnalyzeScrapeLoading: (state: State, value: boolean): void => {
            state.articleAnalyzeScrapeLoading = value;
        },
        setArticleForcedEmpty: (state: State, value: boolean): void => {
            state.article.forcedEmpty = value;
        }
    },
    actions: {
        login: async ({commit}, user: User) => {
            commit('setUser', user);
            commit('setPlan', await Firestore.getCurrentPlan());
            Firestore.observeUserDoc((value: Record<string, number>) => {
                commit('setUserTrialUsed', value.trialUsed);
                commit('setUserTheme', value.theme);
                commit('setUserArticlesCreatedThisPeriod', value.articlesCreatedThisPeriod);
                commit('setUserExtraInspirations', value.extraInspirations);
            });
        },
        logout: ({commit, dispatch}): void => {
            Firestore.stopObservingUser();
            Auth.logout()
                .then(() => {
                    window.location.assign(ExternalLinks.logoutRedirect);
                    commit('logout');
                })
                .catch(error => {
                    dispatch('displayFlashMessage', <FlashMessage>{
                        type: 'error',
                        content: 'unknownError'
                    });
                    captureException(error);
                });
        },
        noUser: ({commit}): void => {
            commit('setUser', null);
        },
        handleApiError: ({dispatch}, error: string | unknown = "unexpectedError"): void => {
            let userError = error;
            if (typeof error !== "string" || !I18n.te(error)) {
                userError = 'unexpectedError';
                window.console.error(error);
            }
            dispatch('displayFlashMessage', <FlashMessage>{
                type: 'error',
                content: userError
            });
            captureException(error);
        },
        // Article
        setArticle: ({state, commit}, article: Article): void => {
            state.article = article;
            state.articleAiLoading = new ArticleLoadings;
            state.articleWordCount = new ArticleWordCounts;

            for (const meta of ['metaTitle', 'metaDescription']) {
                commit('setArticleMeta', <ArticleMetaPartStorePath>{
                    metaPart: meta,
                    value: (article as any)[meta]
                });
            }

            for (const part of article.getAllParts()) {
                for (const [index, value] of part.value.entries()) {
                    for (const key of ['h', 'p']) {
                        // To trigger all side effects like wordCounts
                        commit('setArticleContent', <ArticleContentPartStorePath>{
                            contentPart: 'h' + part.number,
                            index: index,
                            contentKey: key,
                            value: (value as any)[key]
                        });
                    }
                }
            }

            for (const [index, question] of article.questions.entries()) {
                for (const key of ['question', 'answer']) {
                    commit('setArticleQuestion', <ArticleQuestionPartStorePath>{
                        index: index,
                        questionKey: key,
                        value: (question as any)[key]
                    });
                }
            }
        },
        displayFlashMessage: ({commit, dispatch}, flashMessage: FlashMessage): void => {
            // first remove previous message of same type
            const type = flashMessage.type;
            dispatch('clearFlashMessage', type);
            const messageTimeout = flashMessage.timeout ?? 5000;
            commit('addFlashMessage', flashMessage);
            commit('addFlashMessageTimeout', {
                type: type,
                timeout: setTimeout(() => dispatch('clearFlashMessage', type), messageTimeout)
            });
        },
        clearFlashMessage: ({commit, state}, type: FlashMessageType): void => {
            const messageIdx = state.flashMessages.findIndex(m => m.type === type);
            if (messageIdx === -1) {
                // no message of that type
                return;
            }
            // @ts-ignore
            clearTimeout(state.flashMessageTimeouts[type]);
            commit('removeFlashMessageTimeout', type);
            commit('removeFlashMessage', messageIdx);
        },
        setArticleLastSavedSnapshotHash: ({commit, getters}): void => {
            getArticleSnapshotHash(getters.article).then(hash => {
                commit('setArticleLastSavedSnapshotHash', hash);
            });
        },
        setArticleScrapedContent: ({ commit }, response: ScrapperResponse): Promise<void> => {
            for (const meta of ['metaTitle', 'metaDescription']) {
                commit('setArticleMeta', <ArticleMetaPartStorePath>{
                    metaPart: meta,
                    value: (response as any)[meta] || ''
                });
            }
            for (let i = 1; i <= 6; ++i) {
                for (const tag of ['h', 'p']) {
                    const sitePart = 'h' + i + tag;
                    if ((response as any)[sitePart].length) {
                        for (const [index, value] of (response as any)[sitePart].entries()) {
                            commit('setArticleContent', <ArticleContentPartStorePath>{
                                contentPart: 'h' + i,
                                index: index,
                                contentKey: tag,
                                value: value
                            });
                        }
                    }
                }
            }
            commit('setArticleImages', {images: response.images});
            return Promise.resolve();
        },
        setArticleForcedEmpty: ({commit}): Promise<void> => {
            commit('setArticleForcedEmpty', true);
            return Promise.resolve();
        }
    }
});

export {Store, State};
