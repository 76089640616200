<template>
  <v-card
    dark
    flat
    color="primaryGradientBackground"
    class="pa-8"
  >
    <div class="d-flex flex-column align-start">
      <div
        v-for="index in numOfItems"
        :key="index"
      >
        <v-icon class="mr-2">
          check
        </v-icon>
        <span
          :class="{'font-weight-bold' : index === 1}"
        >
          {{ $t('keywords.info.items.' + index) }}
        </span>
      </div>
    </div>
  </v-card>
</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        name: 'KeyWordInfoList',
        data: () => ({
            numOfItems: 4
        })
    });
</script>

<style scoped>

</style>
