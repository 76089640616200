<template>
  <v-navigation-drawer
    app
    clipped
    :value="value"
    width="290"
    @input="$emit('input', $event)"
  >
    <SeoScore
      v-if="isInArticleDetail && articleStep === 3"
      :context="seoScoreContext"
    />
    <template v-else>
      <SeoScore
        v-if="!Auth.isAuthenticated"
        demo
      />
      <ListOfFeatures
        :article-step="articleStep"
        :is-in-article-detail="isInArticleDetail"
      />
    </template>
    <div v-if="isInArticleDetail && Auth.isAuthenticated">
      <div
        class="d-flex flex-column align-center mt-6 mx-auto"
        style="max-width: 95%; row-gap: 16px"
      >
        <ArticleKeywords />
        <ArticleSaveButton
          block
          :disabled="disableSave"
          @input="saveArticle"
        />
        <ArticlePreview block />
        <ArticleNewInspirationButton block />
        <ArticleNewInspirationButton
          block
          is-analyze
        />
      </div>
      <ArticleSimpleList />
    </div>
  </v-navigation-drawer>
</template>

<script lang="ts">
    import {EventBus} from "@/utils/EventBus";
    import Vue, {PropType} from "vue";
    import ArticleNewInspirationButton from "@/components/article/ArticleNewInspirationButton.vue";
    import ArticlePreview from "@/components/article/ArticlePreview.vue";
    import ArticleSaveButton from "@/components/article/ArticleSaveButton.vue";
    import ArticleSimpleList from "@/components/article/ArticleSimpleList.vue";
    import {Auth} from "@/plugins/Auth";
    import ListOfFeatures from "@/components/ListOfFeatures.vue";
    import SeoScore from "@/components/seoscore/SeoScore.vue";
    import {seoScoreContext, SeoScoreContext} from "@/model/SeoScore";
    import ArticleKeywords from "@/components/article/ArticleKeywords.vue";

    export default Vue.extend({
        name: "NavigationDrawer",
        components: {
            ArticleKeywords,
            SeoScore, ListOfFeatures, ArticleSimpleList, ArticleSaveButton,
            ArticlePreview, ArticleNewInspirationButton
        },
        props: {
            value: {
                type: Boolean,
                default: false
            },
            articleStep: {
                type: Number,
                default: null
            },
            seoScoreContext: {
                type: Object as PropType<SeoScoreContext>,
                default: () => seoScoreContext
            },
            disableSave: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            Auth() {
                return Auth;
            },
            EventBus() {
                return EventBus;
            },
            isInArticleDetail: function () {
                return this.$route.name === 'Article';
            },
        },
        methods: {
            saveArticle: function (callback: (() => void) | null = null): void {
                EventBus.$emit('save', callback);
            },
        }
    });
</script>

<style lang="sass">
.navHeader .v-subheader
    margin-left: -16px

.mouse-cursor
    cursor: default

.pointer
    cursor: pointer !important
</style>
