<template>
  <v-tooltip top>
    <template #activator="{ on }">
      <v-btn
        depressed
        v-bind="$attrs"
        color="accent"
        class="multi-line-button"
        :class="buttonClass"
        :block="block"
        v-on="on"
        @click="$router.push({ name: routeName })"
      >
        <v-spacer />
        {{ isAnalyze ? $t('article.analyze.title') : $t('article.generateNewInspiration') }}
        <v-spacer />
        <v-icon class="ml-2">
          send
        </v-icon>
      </v-btn>
    </template>
    {{ isAnalyze ? $t('article.analyze.description') : $t('article.createDescription') }}
  </v-tooltip>
</template>

<script lang="ts">
    import Vue from "vue";

    export default Vue.extend({
        name: "ArticleNewInspirationButton",
        props: {
            block: {
                type: Boolean,
                default: false
            },
            isAnalyze: {
                type: Boolean,
                default: false
            },
            buttonClass: {
                type: String,
                default: ''
            }
        },
        computed: {
            routeName: function () {
                return this.isAnalyze ? 'AnalyzeArticle' : 'CreateArticle';
            }
        }
    });
</script>
