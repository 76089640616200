<template>
  <div>
    <v-container v-if="!readOnly">
      <div class="text-center">
        <h1 class="text-h4 mt-5">
          {{ $t('subscriptions.title') }}
        </h1>
        <h2 class="text-h5 mt-5">
          {{ $t('subscriptions.subTitle') }}
        </h2>
      </div>
    </v-container>
    <v-divider class="my-8" />
    <span class="d-flex align-center justify-center text-center text-h4 my-3">
      {{ $t('subscriptions.allPlansInclude') }}:
    </span>
    <FeatureList />
    <v-slide-group
      v-if="plans.length"
      v-model="selectedPlanIndex"
      center-active
      show-arrows
      class="subscriptionSlider mt-6"
    >
      <v-slide-item
        v-for="(plan, index) of plans"
        :key="index"
        v-slot="{ active, toggle }"
      >
        <SubscriptionCard
          :active="active"
          :initial="!selectedPlanId"
          :bg-type="(index + 1) % 4 + 1"
          :loading="loading"
          :plan="loading ? null : plan"
          :read-only="readOnly || loadingContinue"
          class="mx-3 my-5"
          @input="toggle"
          @individual="individualDialog = true"
        />
      </v-slide-item>
    </v-slide-group>
    <v-container>
      <v-row
        v-if="!readOnly"
        class="justify-center"
      >
        <v-checkbox
          v-model="termsAccepted"
          :readonly="loadingContinue"
        >
          <template #label>
            <div>
              {{ $t('subscriptions.terms.IAccept') }} <a
                target="_blank"
                :href="ExternalLinks.termsAndConditions"
                @click.stop
              >{{ $t('subscriptions.terms.termsOfService') }}</a> {{ $t('and') }} <a
                target="_blank"
                :href="ExternalLinks.privacyPolicy"
                @click.stop
              >{{ $t('subscriptions.terms.privacyPolicy') }}</a>. {{ $t('subscriptions.terms.info') }}
            </div>
          </template>
        </v-checkbox>
      </v-row>
      <v-row
        v-if="!readOnly"
        class="justify-center mb-3"
      >
        <v-btn
          color="accent"
          depressed
          :disabled="!termsAccepted || !selectedPlanId"
          :loading="loadingContinue"
          @click="onClickContinue"
        >
          <template v-if="selectedPlan">
            {{ $t('subscriptions.continueWithPlan', [selectedPlan.name]) }}
            <v-icon class="ml-2">
              payment
            </v-icon>
          </template>
          <template v-else>
            {{ $t('subscriptions.mustChoosePlan') }}
          </template>
        </v-btn>
      </v-row>
      <v-row class="justify-center text--disabled text-center">
        {{ $t('subscriptions.change') }}
      </v-row>
      <slot />
      <v-container v-if="readOnly">
        <div class="d-flex justify-center mt-5">
          <v-icon x-large>
            keyboard_double_arrow_down
          </v-icon>
        </div>
        <v-divider class="mt-6 mb-12" />
        <OneTimePacks :packages="packages" />
      </v-container>
    </v-container>
    <v-dialog
      v-model="individualDialog"
      max-width="600"
    >
      <v-card class="pa-10">
        <v-card-title>
          {{ $t('subscriptions.individualContact') }}
        </v-card-title>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
    import Vue from 'vue';
    import {Firestore} from '@/plugins/Firestore';
    import {Stripe} from "@/plugins/Stripe";
    import {ExternalLinks} from "@/config/ExternalLinks";
    import OneTimePacks from "@/components/subscriptions/OneTimePacks.vue";
    import {captureException} from "@/plugins/Sentry";
    import {FlashMessage} from "@/model/FlashMessage";
    import SubscriptionCard from "@/components/subscriptions/SubscriptionCard.vue";
    import {StripePrice, StripeProduct, StripeProductLike, StripeRecurringProduct} from "@/model/Stripe";
    import FeatureList from "@/components/subscriptions/FeatureList.vue";

    export default Vue.extend({
        name: 'SubscriptionSelection',
        components: {FeatureList, SubscriptionCard, OneTimePacks},
        props: {
            readOnly: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            plans: [] as Array<StripeProduct | StripeProductLike>,
            packages: [] as Array<StripePrice>,
            loading: false,
            loadingContinue: false,
            selectedPlanIndex: null as number | null,
            termsAccepted: false,
            individualDialog: false,
            ExternalLinks: ExternalLinks
        }),
        computed: {
            selectedPlan: function (): StripeProduct | StripeProductLike | null {
                if (this.selectedPlanIndex !== null) {
                    return this.plans[this.selectedPlanIndex] || null;
                } else {
                    return null;
                }
            },
            selectedPlanId: function (): string | undefined {
                return this.selectedPlan?.id;
            },
            freePlan: function (): StripeProductLike {
                return {
                    id: 'free',
                    name: 'FREE',
                    priceLabel: this.$t('subscriptions.free'),
                    metadata: {
                        description_en: 'Try one free article',
                        description_cs: 'Vyzkoušejte jeden článek zdarma'
                    },
                };
            },
        },
        watch: {
            '$store.getters.plan.id': function () {
                this.setSelectedPlan();
            }
        },
        created: function () {
            const pricesPromises = [] as Promise<any>[];
            this.loading = true;
            const stripePlans = [] as Array<StripeRecurringProduct>;
            Firestore.getAllActiveProducts().then((products: Array<StripeProduct>) => {
                products.forEach(product => {
                    pricesPromises.push(
                        Firestore.getActivePricesForProduct(product.id).then(prices => {
                            const price: StripePrice = prices[0]; // Take first active price
                            if (price.type === 'one_time') {
                                // Credit pack
                                product.recurring = false;
                                this.packages = prices.sort((a, b) => (a.unit_amount - b.unit_amount));
                            } else {
                                // Plan
                                product.recurring = true;
                                price.display = (price.unit_amount / 100).toFixed(2);
                                (product as StripeRecurringProduct).price = price;
                                stripePlans.push(product as StripeRecurringProduct);
                            }
                        })
                    );
                });
                return Promise.all(pricesPromises).then(() => {
                    this.plans = [
                        this.freePlan,
                        ...(stripePlans.sort((a, b) => (a.price.unit_amount - b.price.unit_amount))),
                    ];
                    this.setSelectedPlan();
                }).catch(error => {
                    this.$store.dispatch('displayFlashMessage', {
                        type: 'error',
                        content: 'Error while loading plan prices.'
                    } as FlashMessage);
                    captureException(error);
                });
            }).finally(() => {
                this.loading = false;
            });
        },
        methods: {
            onClickContinue: function (): void {
                if (!this.selectedPlan) {
                    return;
                }
                if (this.selectedPlanId === 'free') {
                    this.$router.push('/articleCrossroad');
                    return;
                }
                if (this.selectedPlan.name === 'BUSINESS') {
                    this.individualDialog = true;
                    return;
                }
                this.loadingContinue = true;
                // @ts-ignore
                const priceId = this.selectedPlan.price.id;
                if (!priceId) {
                    console.warn('No price in plan ' + this.selectedPlan?.name);
                    return;
                }

                Stripe.createStripeCheckoutSession(priceId, this.$store.getters.user.uid)
                    .then(stripeCheckoutUrl => {
                        this.loadingContinue = false;
                        window.location.assign(stripeCheckoutUrl);
                    }).catch(error => {
                        this.$store.dispatch('displayFlashMessage', {
                            type: 'error',
                            content: 'Error during communication with Stripe.'
                        } as FlashMessage);
                        captureException(error);
                        this.loadingContinue = false;
                    });
            },
            setSelectedPlan: function (): void {
                if (!this.$store.getters.plan) {
                    return;
                }
                const planIndex = this.plans.findIndex(plan => {
                    if (this.$store.getters.plan) {
                        return plan.id === this.$store.getters.plan.id;
                    } else {
                        return false;
                    }
                });
                if (planIndex !== -1) {
                    this.selectedPlanIndex = planIndex;
                }
            },
        }
    });
</script>

<style lang="sass">
.subscriptionSlider
    .v-slide-group__content
        justify-content: center

    .v-slide-group__prev, .v-slide-group__next
        &:not(.v-slide-group__prev--disabled, .v-slide-group__next--disabled)
            border: thin solid
</style>
