<template>
  <v-card
    v-if="show"
    class="dialogPosition"
    elevation="12"
    color="primary"
    dark
  >
    <v-card-title>
      {{ $t('newContentAvailable') }}
    </v-card-title>
    <v-card-actions class="mb-2 mx-2">
      <v-btn
        color="accent"
        @click="refresh"
      >
        {{ $t('refresh') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        name: 'NewContentDialog',
        computed: {
            show: function (): boolean {
                return this.$store.getters.newContent;
            }
        },
        methods: {
            refresh: function () {
                this.$store.commit('newContent', false);
                location.replace(location.href);
            }
        }
    });
</script>

<style scoped lang="sass">
.dialogPosition
    position: fixed
    right: 1em
    bottom: 1em
    z-index: 10
</style>
