<template>
  <div id="articleCreationContainer">
    <div
      v-if="preparingAi || preparingAiLocal"
      class="d-flex justify-center my-16"
    >
      <AISparkline class="article-content" />
    </div>
    <div v-else>
      <ArticleNavigationChips
        :is-analyze="isAnalyze"
        :class="{ 'editorsBuffer' : !showEditors }"
      />
      <div
        v-for="{number} of article.getAllParts()"
        v-show="showEditors"
        id="partEditorContainer"
        :key="'e' + number"
      >
        <ArticleContentPartEditors
          class="mt-4"
          :part="'h' + number"
          :words-to-highlight="wordsToHighlight"
          :inspirations="inspirations"
          :loading-inspirations="loadingSERP || loadingInspirations"
        />
        <ContentList v-if="number === 1" />
      </div>
      <ArticleQuestionEditors
        v-if="showEditors"
        class="mt-4"
        :words-to-highlight="wordsToHighlight"
      />

      <template v-if="showEditors">
        <ArticleMetaPartEditor
          v-for="[part, config] of Object.entries(ArticleMetasConfig)"
          :key="part"
          class="mt-2"
          :part="part"
          v-bind="config"
          :words-to-highlight="wordsToHighlight"
          :inspirations="inspirations"
          :loading-inspirations="loadingSERP || loadingInspirations"
        />
      </template>

      <ArticleImagesTable
        v-if="showEditors"
        :words-to-highlight="wordsToHighlight"
      />
      <ArticleSERPPreview
        v-if="showEditors"
        :meta-title="article.metaTitle"
        :meta-description="article.metaDescription"
        :keyword="selectedKeyword"
        :words-to-highlight="wordsToHighlight"
        class="mb-8"
      />
      <ArticleURLSuggestion
        v-if="article.url"
        :words-to-highlight="wordsToHighlight"
        class="mb-8"
      />
    </div>
    <div class="d-flex justify-center">
      <div class="article-content">
        <ArticleSaveButton
          class="ma-2"
          @input="saveArticle"
        />
        <ArticlePreview class="mx-2" />
        <ArticlePlagiarismReport />
        <v-divider class="my-4" />
        <ArticleExport :recommended-length="recommendedWordCount" />
        <ArticleRecommendation
          id="recommendation"
          :serp-links="SERPLinks"
          class="mt-4"
        />
        <ArticleNewInspirationButton
          block
          button-class="mt-4"
        />
        <ArticleNewInspirationButton
          block
          is-analyze
          button-class="newArticleInspirationButton"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
    import Vue, {PropType} from 'vue';
    import ArticleRecommendation from '@/components/article/ArticleRecommendation.vue';
    import {debounce, DebouncedFunc} from 'lodash-es';
    import {permute, stripMarkers, stripTagsAndEntities} from '@/utils/string';
    import {Functions} from '@/plugins/Functions';
    import {ArticleMetasConfig} from '@/config/ArticleConfig';
    import {
        Article, ArticleInspirations, ArticlePartContent, ArticleQuestion, ArticleWordCounts, Keyword
    } from "@/model/Article";
    import ArticleMetaPartEditor from "@/components/article/ArticleMetaPartEditor.vue";
    import ArticleContentPartEditors from "@/components/article/ArticleContentPartEditors.vue";
    import ArticleSERPPreview from "@/components/article/ArticleSERPPreview.vue";
    import ArticleQuestionEditors from "@/components/article/ArticleQuestionsEditors.vue";
    import {EventBus} from "@/utils/EventBus";
    import ContentList from "@/components/article/ContentList.vue";
    import ArticlePlagiarismReport from "@/components/article/ArticlePlagiarismReport.vue";
    import ArticleExport from "@/components/article/ArticleExport.vue";
    import ArticlePreview from "@/components/article/ArticlePreview.vue";
    import {captureException} from "@/plugins/Sentry";
    import {FlashMessage} from "@/model/FlashMessage";
    import {ScrapperResponse} from "@/model/Scrapper";
    import {Firestore} from "@/plugins/Firestore";
    import ArticleNavigationChips from "@/components/article/ArticleNavigationChips.vue";
    import {clearAiContentWatchers, watchForAi} from "@/service/articleAiContent";
    import AISparkline from "@/components/ai/AISparkline.vue";
    import ArticleNewInspirationButton from "@/components/article/ArticleNewInspirationButton.vue";
    import ArticleSaveButton from "@/components/article/ArticleSaveButton.vue";
    import {geometricAverage, inspirationWordLength, keywordOccurrences} from "@/utils/ArticleComputed";
    import {seoScoreContext, SeoScoreContext} from "@/model/SeoScore";
    import {OrganicPositionRequest} from "@/model/DataForSeo";
    import ArticleURLSuggestion from "@/components/article/ArticleURLSuggestion.vue";
    import ArticleImagesTable from "@/components/article/ArticleImagesTable.vue";

    export default Vue.extend({
        name: 'ArticleCreation',
        components: {
            ArticleSaveButton, ArticleNewInspirationButton, AISparkline, ArticleNavigationChips, ArticlePreview,
            ArticleExport, ArticlePlagiarismReport, ArticleQuestionEditors, ArticleSERPPreview, ArticleURLSuggestion,
            ArticleContentPartEditors, ArticleMetaPartEditor, ArticleRecommendation, ContentList, ArticleImagesTable
        },
        // emits: ['save'],
        props: {
            preparingAi: {
                type: Boolean,
                default: false
            },
            seoScoreContext: {
                type: Object as PropType<SeoScoreContext>,
                default: () => seoScoreContext
            },
            isAnalyze: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            loadingSERP: true,
            SERPLinks: [] as { url: string, title: string }[],
            ArticleMetasConfig: ArticleMetasConfig,
            preparingAiLocal: false,
            mergedText: '',
            wordsToHighlight: {} as { [key: string]: string[] }, /* TODO this is just a permutation, refactor */
            inspirations: {} as ArticleInspirations,
            loadingInspirations: true,
            articleWordCount: 0,
            AIRegenerateInterval: null as any,
            isTrial: false
        }),
        computed: {
            articleId: function (): string {
                return this.$route.params.articleId as string;
            },
            article: function (): Article {
                return this.$store.getters.article;
            },
            selectedKeyword: function (): string {
                return this.article.selectedKeyword;
            },
            debouncedMergeText: function (): DebouncedFunc<() => void> {
                return debounce(() => {
                    this.mergeText();
                }, 500, {leading: true});
            },
            debouncedOccurrenceCount: function (): DebouncedFunc<() => void> {
                return debounce(() => {
                    this.countWordOccurrences();
                }, 500, {leading: true});
            },
            validInspirations: function (): ScrapperResponse[] {
                return Object.values(this.inspirations)
                    .filter(inspiration => inspiration.status === 200);
            },
            validKeywords: function (): string[] {
                return this.article.allKeywords
                    .map((word: Keyword) => word.keyword)
                    .filter((word: string) => word && word.trim());
            },
            wordsToCountIntoDensity: function (): string[] {
                return this.article.allKeywords
                    .filter((word: Keyword) => word.checked)
                    .map((word: Keyword) => word.keyword)
                    .filter((word: string) => word && word.trim());
            },
            articleDensity: function (): number {
                if (this.wordsToCountIntoDensity.length) {
                    const occurrences = this.wordsToCountIntoDensity.map(word => {
                        return permute(word).reduce((acc: number, cur: string) => {
                            return acc + keywordOccurrences(this.mergedText, cur);
                        }, 0);
                    }).reduce((acc: number, cur: number) => acc + cur, 0);
                    return (occurrences / (this.articleWordCount || 1)) * 100;
                } else {
                    return 0;
                }
            },
            topTenGeometricAverageWordCount: function (): number | null {
                if (this.loadingInspirations) {
                    return null;
                } else {
                    return geometricAverage(this.validInspirations.map(inspirationWordLength));
                }
            },
            topTenLongestWordCount: function (): number | null {
                if (this.loadingInspirations) {
                    return null;
                } else {
                    return Math.max(...this.validInspirations.map(inspirationWordLength));
                }
            },
            recommendedWordCount: function (): number {
                return this.topTenGeometricAverageWordCount || 1200;
            },
            showEditors: function (): boolean {
                return !this.isAnalyze || !this.$store.getters.articleAnalyzeScrapeLoading;
            }
        },
        watch: {
            article: {
                immediate: true,
                deep: true,
                handler: function (): void {
                    this.debouncedMergeText();
                    if (this.article) {
                        if (!this.article.allKeywords || this.article.allKeywords.length === 0) {
                            this.article.allKeywords = [{keyword: this.selectedKeyword, checked: true}];
                            const subKeywords = this.selectedKeyword.split(' ');
                            if (subKeywords.length > 1) {
                                this.article.allKeywords.push(
                                    ...subKeywords.map(sk => ({keyword: sk, checked: false}))
                                );
                            }
                        }
                        this.updateWordsToHighlight();
                    }
                }
            },
            /** SeoScore context watchers **/
            articleDensity: {
                immediate: true,
                handler: function (value): void {
                    // eslint-disable-next-line vue/no-mutating-props
                    this.seoScoreContext.articleDensity = value;
                }
            },
            topTenGeometricAverageWordCount: {
                immediate: true,
                handler: function (value): void {
                    // eslint-disable-next-line vue/no-mutating-props
                    this.seoScoreContext.topTenGeometricAverageWordCount = value;
                }
            },
            topTenLongestWordCount: {
                immediate: true,
                handler: function (value): void {
                    // eslint-disable-next-line vue/no-mutating-props
                    this.seoScoreContext.topTenLongestWordCount = value;
                }
            },
            mergedText: {
                immediate: true,
                handler: function (value): void {
                    // eslint-disable-next-line vue/no-mutating-props
                    this.seoScoreContext.mergedText = value;
                }
            },
            /** End SeoScore context watchers **/
            'article.selectedKeyword': {
                immediate: true,
                handler: function () {
                    if (this.article.selectedKeyword) {
                        this.loadingSERP = true;
                        Functions.D4SGetSERP({
                            keyword: this.article.selectedKeyword,
                            location_code: this.article.location_code,
                            language_code: this.article.language_code
                        }).then(response => {
                            this.SERPLinks = response as { url: string, title: string }[];
                            this.scrapUrls(this.SERPLinks.map(link => link.url));
                        }).catch(error => {
                            this.$store.dispatch("handleApiError", error);
                        }).finally(() => {
                            this.loadingSERP = false;
                        });
                    }
                }
            },
            'article.allKeywords': {
                deep: true,
                handler: function (): void {
                    this.updateWordsToHighlight();
                    this.debouncedOccurrenceCount();
                }
            },
            '$store.getters.articleWordCount': {
                deep: true,
                immediate: true,
                handler: function (newValue: ArticleWordCounts): void {
                    this.articleWordCount = newValue.getSum();
                }
            },
            '$store.getters.articleAiLoading': {
                deep: true,
                immediate: true,
                handler: function (): void {
                    this.$store.getters.articleAiLoading.computeLoadingRatio();
                    // Only after AI content for questions has been loaded, add default empty question, if not already present
                    if (this.$store.getters.articleAiLoading.loadingRatioQuestions === 0) {
                        if (this.$store.getters.article.questions.length === 0) {
                            this.$store.commit('insertArticleQuestion', {
                                index: 0,
                                aiLoading: false
                            });
                        }
                    }
                }
            },
        },
        created: function (): void {
            // TODO: if the query is missing we are not able removeTrialUsed if something fails
            // example: user creates article without generating/analyzing (the green button) and leaves
            // when he comes back the trial is not present in the query
            if (this.$route.query.trial) {
                if (this.$route.query.trial === 'yes') {
                    this.isTrial = true;
                }
            }

            if (this.article.metaTitle || this.article.forcedEmpty) {
                // saved at least once, ignore AI content
            } else if (this.isAnalyze) {
                this.$store.commit('setArticleAnalyzeScrapeLoading', true);

                // if extraction is partial or failed, parent component will handle user decision how to proceed
                Functions.GetScrapped({site: this.article.url, skipCache: true})
                    .then(response => {
                        if (response.status === 200) {
                            if (!this.checkScrapedResponse(response)) {
                                // The extracted article is PROBABLY not correctly extracted
                                this.$emit('extractionPartial', response);
                            } else {
                                this.$store.dispatch('setArticleScrapedContent', response)
                                    .then(() => {
                                        Functions.D4SGetOrganicPosition({
                                            article_id: this.article.id,
                                        } as OrganicPositionRequest);
                                        this.$store.commit('setArticleAnalyzeScrapeLoading', false);
                                    });
                            }
                        } else {
                            // Extraction failed
                            window.console.warn('Extraction failed');
                            this.$emit('extractionFailed');
                        }
                    })
                    .catch((error) => {
                        // firebase library failed for some reason
                        window.console.error(error);
                        this.$emit('extractionFailed');
                    });
            } else {
                if (this.article.ai_content_last_generated_at &&
                    (this.article.ai_content_last_generated_at + 1000 * 60 * 5) < Date.now()
                ) { // Older than 10 minutes
                    setTimeout(this.reGenerateAiContent, 1000 * 30); // Wait for AI progress loaded
                }
                this.preparingAiLocal = true;
                watchForAi().then(() => {
                    this.preparingAiLocal = false;
                });
                this.AIRegenerateInterval = setInterval(this.reGenerateAiContent, 1000 * 60 * 5); // Every 5 minutes
            }

            EventBus.$on('save', (callback = null) => {
                this.saveArticle(callback);
            });
        },
        mounted: function (): void {
            document.addEventListener("copy", this.copyListener);
        },
        destroyed: function (): void { // TODO should be beforeUnmount but TypeScript definition does not know it...
            this.AIRegenerateInterval && clearInterval(this.AIRegenerateInterval);
            clearAiContentWatchers();
            document.removeEventListener("copy", this.copyListener);
            EventBus.$off('save');
        },
        deactivated: function () {
            this.AIRegenerateInterval && clearInterval(this.AIRegenerateInterval);
            clearAiContentWatchers();
            document.removeEventListener("copy", this.copyListener);
            EventBus.$off('save');
        },
        methods: {
            mergeText: function (): void {
                let mergedText = '';
                for (const {value: part} of this.article.getAllParts()) {
                    mergedText += part.reduce((acc: string, subpart: ArticlePartContent) => `${acc} | ${subpart.h} | ${subpart.p} `, '');
                }
                mergedText += this.article.questions.reduce((acc: string, question: ArticleQuestion) => `${acc} | ${question.question} | ${question.answer} `, '');
                this.mergedText = stripTagsAndEntities(mergedText);
                // text has changed, count density
                this.debouncedOccurrenceCount();
            },
            updateWordsToHighlight: function (): void {
                for (const keyword of this.validKeywords) {
                    this.$set(this.wordsToHighlight, keyword, permute(keyword));
                }
                Object.keys(this.wordsToHighlight)
                    .filter(isHighlighted => !this.validKeywords.includes(isHighlighted))
                    .forEach(toDelete => {
                        this.$delete(this.wordsToHighlight, toDelete);
                    });
                this.debouncedOccurrenceCount();
            },
            scrapUrls: function (urls: string[]): void {
                this.loadingInspirations = true;
                const promises = [];
                for (const url of urls) {
                    promises.push(Functions.GetScrapped({site: url})
                        .then(response => {
                            if (response.status !== 200) {
                                window.console.warn("Failed to scrap " + response.site);
                            } else {
                                this.$set(this.inspirations, encodeURIComponent(response.site), response);
                            }
                        })
                        .catch(error => {
                            // Do not show scrap error to the user
                            // this.$store.dispatch("handleApiError", error);
                            window.console.warn(error);
                        }));
                }
                Promise.all(promises)
                    .catch(error => {
                        this.$store.dispatch('displayFlashMessage', {
                            type: 'error',
                            content: 'Error while getting inspirations.'
                        } as FlashMessage);
                        captureException(error);
                    })
                    .finally(() => {
                        this.loadingInspirations = false;
                    });
            },
            countWordOccurrences: function (): void {
                for (const word of Object.keys(this.wordsToHighlight)) {
                    this.$store.commit('setWordOccurrences', [word, this.wordsToHighlight[word]
                        .reduce((acc: number, cur: string) => acc + keywordOccurrences(this.mergedText, cur), 0)]);
                }
            },
            saveArticle: function (callback: (() => void) | null = null): void {
                this.$emit('save', () => {
                    if (typeof callback === 'function') {
                        callback();
                    }
                });
            },
            copyListener: function (event: ClipboardEvent): void {
                if (!event.clipboardData || !window.getSelection()) {
                    return;
                }
                const helper = document.createElement("div");
                const selection = window.getSelection();
                if (selection !== null && selection.type !== 'None') {
                    helper.appendChild(selection.getRangeAt(0).cloneContents());
                    event.clipboardData.setData("text/html", stripMarkers(helper.innerHTML));
                    event.clipboardData.setData("text/plain", helper.innerText);
                }
            },
            reGenerateAiContent: function () {
                if (this.article.id && this.$store.getters.articleAiLoading.loadingRatioTotal > 0) {
                    window.console.info('Re-generating AI content after 10 minutes');
                    Functions.OpenAIArticleReGenerate({
                        articleId: this.article.id
                    });
                    Firestore.updateArticleAIGeneratedAt(this.article);
                }
            },
            checkScrapedResponse: function (response: ScrapperResponse): boolean {
                // check if we scraped h1
                if (response.h1h.length === 0) {
                    return false;
                }

                // check if we scraped enough content (at least 500 letters)
                const subparts = [];
                for (let i = 1; i <= 6; ++i) {
                    subparts.push(...(response as any)[`h${i}h`]);
                    subparts.push(...(response as any)[`h${i}p`]);
                }
                const length = subparts.reduce((acc, cur) => acc + cur.length, 0);
                return length >= 500;
            }
        }
    });
    // TODO refactor
</script>

<style scoped lang="sass">

.scrollable
    display: flex !important
    flex-direction: column

.scrollable-content
    flex-grow: 1
    overflow: auto

.animated
    animation: flash .5s linear infinite

.no-drawer
    width: 100vw !important

@keyframes flash
    50%
        background: rgba(255, 255, 255, 0.5)
</style>

<style lang="sass">
.ck-toolbar-container
    z-index: 1 !important

.inspirationsLoader .v-progress-linear__determinate
    background: linear-gradient(90deg, var(--v-primary-lighten1) 0%, var(--v-primary-darken2) 100%)

.v-application .aiLoader
    .v-progress-linear__determinate
        background: linear-gradient(90deg, var(--v-ai-base) 0%, var(--v-ai-darken4) 100%)

    .v-progress-linear__stream
        color: var(--v-ai-base) !important
        caret-color: var(--v-ai-base) !important

.newArticleInspirationButton
    margin-top: 16px !important
    margin-bottom: 175px !important

.editorsBuffer
    padding-bottom: 420px
</style>
