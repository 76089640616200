/**
 * @param payload {string}
 * @param payloadType {string}
 * @param filename {string}
 */
function download(payload: string, payloadType = 'text/plain', filename = 'article.txt'): void {
    const a = window.document.createElement('a');
    a.href = `data:${payloadType};charset=utf-8,` + encodeURIComponent(payload);
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

/**
 * @param textWithTags {string} text with html tags
 * @param plainText {string} plain text
 * @return {Promise}
 */
function copyToClipboard(textWithTags: string, plainText: string = textWithTags): Promise<void> {
    return new Promise((resolve, reject) => {
        try {
            // try to create ClipboardEvent to find out if current browser supports it
            new ClipboardEvent('copy');

            // if user agent is Safari, jump to the "catch" statement directly
            // @ts-ignore
            if (window.safari !== undefined) {
                throw "is safari!";
            }

            // eslint-disable-next-line no-inner-declarations
            function listener(clipboardEvent: ClipboardEvent) {
                if (clipboardEvent.clipboardData) {
                    // copies both rich text and plain text
                    clipboardEvent.clipboardData.setData('text/html', textWithTags);
                    clipboardEvent.clipboardData.setData('text/plain', plainText);
                } else {
                    throw Error();
                }
                clipboardEvent.preventDefault();
            }

            document.addEventListener('copy', listener);
            try {
                document.execCommand('copy') ? resolve() : reject();
            } catch (err) {
                reject(err);
            } finally {
                document.removeEventListener('copy', listener);
            }
        } catch (error) {
            // there is no ClipboardEvent, try to use navigator.clipboard
            // copies only as rich text which means it wont be visible in editors which does not support it
            if (navigator.clipboard) {
                const htmlBlob = new Blob([textWithTags], {type: 'text/html'});
                // @ts-ignore
                navigator.clipboard.write([new ClipboardItem({'text/html': htmlBlob})]).then(resolve).catch(reject);
            } else {
                // exports only as plain text
                const textArea = document.createElement('textarea');
                textArea.value = plainText;

                // Avoid scrolling to bottom
                textArea.style.top = '0';
                textArea.style.left = '0';
                textArea.style.position = 'fixed';

                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();

                try {
                    document.execCommand('copy') ? resolve() : reject();
                } catch (err) {
                    reject(err);
                } finally {
                    document.body.removeChild(textArea);
                }
            }
        }
    });
}

export {copyToClipboard, download};
