<template>
  <div>
    <v-app-bar
      app
      clipped-left
      flat
      dark
      class="primaryGradientBackground"
      :class="{
        'smallButtonPadding': $vuetify.breakpoint.xsOnly
      }"
    >
      <v-app-bar-nav-icon
        v-if="showNavigationDrawer"
        @click.stop="drawer = !drawer"
      />
      <v-btn
        v-if="$vuetify.breakpoint.lgAndUp || ($vuetify.breakpoint.mdAndUp && $route.name !== 'Article')"
        text
        :small="$vuetify.breakpoint.mdAndDown"
        @click="openArticles"
      >
        <v-toolbar-title class="d-flex">
          <img
            :src="require('@/assets/logos/SEOWRITEE-LOGO-4.png')"
            alt="SEOWRITEE logo"
            height="30px"
          >
        </v-toolbar-title>
      </v-btn>
      <v-btn
        v-else-if="$route.path !== '/'"
        text
        :small="$vuetify.breakpoint.mdAndDown"
        @click="openArticles"
      >
        <v-toolbar-title class="d-flex">
          <v-icon>
            home
          </v-icon>
        </v-toolbar-title>
      </v-btn>
      <TopBarInternalLinks
        :article-step="articleStep"
        :disable-save="disableSave"
      />
      <v-spacer />
      <TopBarExternalLinks />
      <AppBarUserInfo />
      <ThemeButton />
      <LanguageSelector />
    </v-app-bar>
    <NavigationDrawer
      v-if="showNavigationDrawer"
      v-model="drawer"
      :article-step="articleStep"
      :seo-score-context="seoScoreContext"
      :disable-save="disableSave"
    />
    <v-main>
      <v-container
        fluid
      >
        <FlashMessages />
        <v-progress-linear
          v-if="$store.getters.user === undefined"
          color="accent"
          indeterminate
        />
        <div v-else>
          <template v-if="$store.getters.authenticated">
            <slot />
            <v-footer
              v-if="showFooter"
              padless
              class="mt-10"
            >
              <SubscriptionInfoList />
            </v-footer>
          </template>
          <div
            v-else
            class="d-flex flex-row align-center flex-wrap"
            :class="{
              'justify-left': $vuetify.breakpoint.lgAndUp,
              'justify-center': $vuetify.breakpoint.mdAndDown,
            }"
          >
            <SubscriptionInfoList
              v-if="$vuetify.breakpoint.lgAndUp"
              full-height
              style="max-width: 40vw"
            />
            <FirebaseAuthUi
              class="pa-12"
              style="max-width: 28em"
            />
            <SubscriptionInfoList
              v-if="$vuetify.breakpoint.mdAndDown"
            />
          </div>
        </div>
      </v-container>
    </v-main>
  </div>
</template>

<script lang="ts">
    import Vue, {PropType} from 'vue';
    import LanguageSelector from '@/components/LanguageSelector.vue';
    import FirebaseAuthUi from "@/components/FirebaseAuthUi.vue";
    import AppBarUserInfo from "@/components/account/AppBarUserInfo.vue";
    import NavigationDrawer from "@/components/NavigationDrawer.vue";
    import FlashMessages from "@/components/FlashMessages.vue";
    import TopBarExternalLinks from "@/components/TopBarExternalLinks.vue";
    import ThemeButton from "@/components/ThemeButton.vue";
    import TopBarInternalLinks from "@/components/TopBarInternalLinks.vue";
    import SubscriptionInfoList from "@/components/subscriptions/SubscriptionInfoList.vue";
    import {SeoScoreContext, seoScoreContext} from "@/model/SeoScore";

    export default Vue.extend({
        name: 'UserLayout',
        components: {
            SubscriptionInfoList,
            TopBarInternalLinks,
            ThemeButton,
            TopBarExternalLinks,
            FlashMessages,
            NavigationDrawer,
            AppBarUserInfo,
            FirebaseAuthUi,
            LanguageSelector
        },
        props: {
            articleStep: {
                type: Number,
                default: null
            },
            seoScoreContext: {
                type: Object as PropType<SeoScoreContext>,
                default: () => seoScoreContext
            },
            disableSave: {
                type: Boolean,
                default: false
            }
        },
        data: function () {
            return {
                drawer: this.$vuetify.breakpoint.lgAndUp,
            };
        },
        computed: {
            showFooter(): boolean {
                return this.$route.name === 'Home'
                    && this.$store.getters.user !== undefined
                    && !this.$store.getters.hasPlan;
            },
            showNavigationDrawer(): boolean {
                return this.$route.name === 'Article';
            }
        },
        watch: {
            drawer() {
                this.$store.commit("setDrawer", this.drawer);
            }
        },
        methods: {
            openArticles(): void {
                this.$route.path !== '/' ? this.$router.push('/') : null;
            }
        }
    });
</script>

<style lang="sass">
.smallButtonPadding button.v-btn
    padding: 0 4px !important
</style>
