<template>
  <div
    v-if="show"
    class="d-flex justify-center"
  >
    <div
      id="contentList"
      class="article-content my-2 py-4 mt-8"
    >
      <h2 class="ml-3">{{ $t('article.contentList.title') }}</h2>
      <v-list
        v-for="{number, value} of contents"
        :key="'e' + number"
        dense
        class="very-dense"
      >
        <v-list-item
          v-for="(subpart, index) of value"
          v-show="subpart.h.length"
          :key="index"
          selectable
          @click="scrollTo('h' + number + 'h', index)"
        >
          <a>
            {{ stripTagsAndEntities(subpart.h) }}
          </a>
        </v-list-item>
      </v-list>
      <div class="d-flex flex-wrap align-center text-caption-primary mt-2 mx-3">
        <span>
          {{ $t('article.contentList.title') }}
        </span>
        <VerticalDivider />
        <span>
          {{ $t('article.contentList.hint') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
    import Vue from 'vue';
    import {ArticlePart} from "@/model/Article";
    import {stripTagsAndEntities} from "@/utils/string";
    import VerticalDivider from "@/components/VerticalDivider.vue";
    import {Scroll} from "@/config/Behaviour";

    export default Vue.extend({
        name: 'ContentList',
        components: {VerticalDivider},
        mixins: [],
        computed: {
            contents: function (): ArticlePart[] {
                return this.$store.getters.article.getAllParts()
                    .filter((part: ArticlePart) => part.number !== 1)
                    .filter((part: ArticlePart) => part.value.some(subpart => subpart.h && subpart.h.length));
            },
            show: function (): boolean {
                return this.contents.some(part => part.value.length);
            }
        },
        methods: {
            stripTagsAndEntities,
            scrollTo: function (selector: string, index: number): void {
                this.$vuetify.goTo('#' + selector + 'w' + (index ? index : ''), {
                    duration: Scroll.duration,
                    offset: Scroll.offset
                });
            }
        }
    });
</script>

<style lang="sass" scoped>
#contentList .v-list
    background-color: unset

.very-dense
    min-height: 0
    padding: 0
</style>
