import {ScrapperResponse} from "@/model/Scrapper";
import {getUid} from "@/utils/uid";
import {DocumentData} from "firebase/firestore";
import {Image} from '@/model/Scrapper';

export class ArticleMetadata {
    keyword = '';
    // how many times user change the main keyword
    keywordChangeCounter = 0;
    aiPrompt = '';
    noAiPrompt = false;
    language_code = '';
    location_code = '';
    seoScore = 0;
    loadedSeoScore = 0; // used only for check if seo score changed
    url = '';
    // mainKeyword = ''; // used for sorting in ArticleTable (firestore uses lexicographical order)
    organicPosition = null as null | OrganicPosition;

    isAnalyze = false;
    forcedEmpty = false;

    created_at?: number = Date.now();
}

export interface OrganicPosition {
    position: null | number | string; // string if "> 100"
    error: null | string;
    isProcessing: boolean;
    timestamp: number;
}

export interface ArticlePart {
    number: number;
    value: ArticlePartContent[];
}

export type ArticleMetaKey = 'metaTitle' | 'metaDescription';
export type ArticleContentKey = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
type ArticleContentTag = 'h' | 'p';
type ArticleQuestionTag = 'question' | 'answer';

export interface ArticlePartContent<T = string> {
    h: T;
    p: T;
    uid: number;
}

export interface ArticleContent<T = string> {
    metaTitle: T;
    metaDescription: T;
    h1: ArticlePartContent<T>[];
    h2: ArticlePartContent<T>[];
    h3: ArticlePartContent<T>[];
    h4: ArticlePartContent<T>[];
    h5: ArticlePartContent<T>[];
    h6: ArticlePartContent<T>[];
    questions: ArticleQuestion<T>[];
}

export interface ArticleQuestion<T = string> {
    question: T;
    answer: T;
    uid: number;
}

export interface Keyword {
    keyword: string;
    checked: boolean;
}

export interface KeywordForAi {
    keyword: string;
    // from keywordType enum
    type: number;
}

export function getDefaultForContent<T = string>(defaultValue: string | number | boolean = '') {
    return <ArticlePartContent<T>>{
        h: defaultValue,
        p: defaultValue,
        uid: getUid()
    };
}

export function getDefaultForQuestion<T = string>(defaultValue: string | number | boolean = '') {
    return <ArticleQuestion<T>>{
        question: defaultValue,
        answer: defaultValue,
        uid: getUid()
    };
}

export class Article extends ArticleMetadata implements ArticleContent {

    selectedKeyword = '';
    allKeywords: Keyword[] = [];
    // Up to 20 keywords selected by user which can be used as prompts for AI
    keywordsForAi: (null | KeywordForAi[]) = null;

    metaTitle = '';
    metaDescription = '';
    h1: ArticlePartContent[] = [];
    h2: ArticlePartContent[] = [];
    h3: ArticlePartContent[] = [];
    h4: ArticlePartContent[] = [];
    h5: ArticlePartContent[] = [];
    h6: ArticlePartContent[] = [];
    questions: ArticleQuestion[] = [];
    images: Image[] = [];

    id: string | null = null;
    created_at: number = Date.now();
    ai_content_last_generated_at: number | null = null;

    static fromObject(value: any): Article {
        const a = new Article;
        for (const prop in a) {
            if (Object.prototype.hasOwnProperty.call(a, prop) && Object.prototype.hasOwnProperty.call(value, prop)) {
                (a as any)[prop] = value[prop];
            }
            type OldKeywordFormat = Keyword & {count: boolean};
            (a.allKeywords as Array<OldKeywordFormat>).forEach(keyword => {
                if (keyword.count !== undefined) {
                    keyword.checked = keyword.count;
                }
            });
        }
        return a;
    }

    toObject(): Record<string, unknown> {
        const ignore = ['loadedSeoScore', 'organicPosition'];

        const r = {};
        for (const prop in this) {
            if (Object.prototype.hasOwnProperty.call(this, prop) && !ignore.includes(prop)) {
                (r as any)[prop] = this[prop];
            }
        }
        return r;
    }

    /**
     * Return all content parts (no metas and questions)
     */
    getAllParts(): ArticlePart[] {
        const ret = [];
        for (let i = 1; i <= 6; ++i) {
            ret.push({
                number: i,
                value: (this as any)['h' + i]
            });
        }
        return ret;
    }

    length(): number {
        return this.getAllParts()
            .map(part => part.value)
            .map(subparts => subparts
                .reduce((total, subpart) => total + subpart.h.length + subpart.p.length, 0)
            ).reduce((total, partLength) => total + partLength, 0);
    }
}

export class ArticleLoadings implements ArticleContent<boolean> {
    metaTitle = false;
    metaDescription = false;
    h1: ArticlePartContent<boolean>[] = [];
    h2: ArticlePartContent<boolean>[] = [];
    h3: ArticlePartContent<boolean>[] = [];
    h4: ArticlePartContent<boolean>[] = [];
    h5: ArticlePartContent<boolean>[] = [];
    h6: ArticlePartContent<boolean>[] = [];
    questions: ArticleQuestion<boolean>[] = [];
    loadingRatioTotal = 0;
    loadingRatioContent = 0;
    loadingRatioQuestions = 0;

    loadingCompleted = false;

    computeLoadingRatio(): void {
        let metaCount = 0;
        let metaLoading = 0;
        let contentCount = 0;
        let contentLoading = 0;
        let questionsCount = 0;
        let questionsLoading = 0;

        for (const meta of ['metaTitle', 'metaDescription']) {
            metaCount++;
            metaLoading += (this as any)[meta] ? 1 : 0;
        }

        for (let i = 1; i <= 6; ++i) {
            for (const subpart of (this as any)['h' + i]) {
                contentCount += 2;
                contentLoading += subpart.h ? 1 : 0;
                contentLoading += subpart.p ? 1 : 0;
            }
        }
        this.loadingRatioContent = contentLoading / contentCount;

        for (const question of this.questions) {
            questionsCount += 2;
            questionsLoading += question?.question ? 1 : 0;
            questionsLoading += question?.answer ? 1 : 0;
        }

        this.loadingRatioQuestions = questionsLoading / questionsCount;
        this.loadingRatioTotal = (metaLoading + contentLoading + questionsLoading) / (metaCount + contentCount + questionsCount);
    }
}

export class ArticleWordCounts implements ArticleContent<number> {
    metaTitle = 0;
    metaDescription = 0;
    h1: ArticlePartContent<number>[] = [];
    h2: ArticlePartContent<number>[] = [];
    h3: ArticlePartContent<number>[] = [];
    h4: ArticlePartContent<number>[] = [];
    h5: ArticlePartContent<number>[] = [];
    h6: ArticlePartContent<number>[] = [];
    questions: ArticleQuestion<number>[] = [];

    getSum(): number {
        let sum = 0;

        // Do not count metas into length
        // for (const meta of ['metaTitle', 'metaDescription']) {
        //     sum += (this as any)[meta];
        // }

        for (let i = 1; i <= 6; ++i) {
            for (const subpart of (this as any)['h' + i]) {
                sum += subpart.h;
                sum += subpart.p;
            }
        }

        for (const question of this.questions) {
            sum += question.question;
            sum += question.answer;
        }

        return sum;
    }
}

export interface CancelArticleRequest {
    articleId: string;
    force?: boolean;
}

export interface ArticleInspirations {
    [site: string]: ScrapperResponse;
}

export interface ArticleMetaPartStorePath<T = string> {
    metaPart: ArticleMetaKey,
    value: T
}

export interface ArticleContentStorePath {
    contentPart: ArticleContentKey,
    index: number,
    aiLoading?: boolean
}

export interface ArticleContentPartStorePath<T = string> {
    contentPart: ArticleContentKey,
    index: number,
    contentKey: ArticleContentTag,
    value: T
}

export interface ArticleQuestionStorePath {
    index: number,
    aiLoading?: boolean
}

export interface ArticleQuestionPartStorePath<T = string> {
    index: number,
    questionKey: ArticleQuestionTag,
    value: T
}

export type ArticlePartStorePath<T = string> =
    ArticleMetaPartStorePath<T>
    | ArticleContentPartStorePath<T>
    | ArticleQuestionPartStorePath<T>

export type ArticlesPage = {
    first: DocumentData,
    last: DocumentData,
    items: Article[]
}

export type OrganicPositionResponse = OrganicPosition;
