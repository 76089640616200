import { register } from 'register-service-worker';
import { Store } from '@/plugins/Store';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.info(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      );
    },
    registered () {
      console.info('Service worker has been registered.');
    },
    cached () {
      console.info('Content has been cached for offline use.');
    },
    updatefound () {
      console.info('New content is downloading.');
    },
    updated (reg: ServiceWorkerRegistration) {
      console.info('New content is available; please refresh.');
      reg.waiting && reg.waiting.postMessage('skipWaiting');
      Store.commit('newContent', true);
    },
    offline () {
      console.info('No internet connection found. App is running in offline mode.');
    },
    error (error) {
      console.error('Error during service worker registration:', error);
    }
  });
}
