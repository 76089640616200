<template>
  <div class="d-flex flex-row pt-1">
    <div class="mr-3">
      <v-icon
        v-if="$vuetify.theme.dark"
        color="grey lighten-2"
        class="pb-1"
      >
        dark_mode
      </v-icon>
      <v-icon
        v-else
        color="yellow"
        class="pb-1"
      >
        light_mode
      </v-icon>
    </div>
    <v-switch
      v-model="$vuetify.theme.dark"
      inset
      hide-details
    />
  </div>
</template>

<script lang="ts">
    import Vue from "vue";
    import {Firestore} from "@/plugins/Firestore";
    import {LocalStorage} from "@/plugins/LocalStorage";

    export default Vue.extend({
        name: "ThemeButton",
        computed: {
            theme() {
                if (this.$store.getters.authenticated)
                    return this.$store.getters.user.theme;
                return null;
            }
        },
        watch: {
            '$vuetify.theme.dark': function (newTheme): void {
                if(this.$store.getters.authenticated) {
                    Firestore.setTheme(newTheme);
                }
            },
            theme: function (theme): void {
                if (theme === true || theme === false) {
                    this.$vuetify.theme.dark = theme;
                    LocalStorage.setTheme(theme);
                }
            }
        }
    });
</script>

<style scoped>

</style>
