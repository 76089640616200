import Vue, {PropType} from "vue";
import {HighlightOption} from "@/enum/highlight_option";
import {getKeywordRegExpString, stripMarkers} from "@/utils/string";
import {Environment} from "@/utils/Environment";

export default Vue.extend({
    name: 'HighlightWordsMixin',
    props: {
        wordsToHighlight: {
            type: Object as PropType<{[key: string]: string[] }>,
            default: () => ({})
        }
    },
    methods: {
        highlightWord: function (curContent: string, wordPermutations: string[], marker: string) {
            const regExp = new RegExp(wordPermutations.map(word => getKeywordRegExpString(word)).join('|'), 'gi');
            if (Environment.supportsPositiveLookBehindInRegexp) {
                return curContent.replace(regExp, `<mark class="${marker}">$1</mark>`);
            } else {
                return curContent.replace(regExp, (match, group1) => {
                    const prefix = match.split(group1)[0];
                    return `${prefix}<mark class="${marker}">${group1}</mark>`;
                });
            }
        },
        highlightWords: function (toHighlight: string): string {
            if (!Object.keys(this.wordsToHighlight).length || !toHighlight) {
                return toHighlight;
            }
            let content = stripMarkers(toHighlight);
            const highlightOptions = Object.values(HighlightOption);
            const numOfHighlightOptions = highlightOptions.length;
            let i = 0;
            for (const entry of Object.values(this.wordsToHighlight)) {
                content = this.highlightWord(content, entry as string[], highlightOptions[i++ % numOfHighlightOptions]);
            }
            return content;
        }
    }
});
