<template>
  <v-expand-transition>
    <div
      v-if="!waitingForAi"
      class="d-flex flex-row"
    >
      <div class="d-flex flex-column flex-grow-1">
        <template v-for="tag of ['h', 'p']">
          <div
            :id="part + tag + 'w' + (index ? index : '')"
            :key="tag"
            class="mt-2"
          >
            <Editor
              :should-listen-for-change="shouldListedForChange[tag]"
              :value="value[tag]"
              :placeholder="$t('article.placeholder.' + (tag === 'h' ? 'heading' : 'paragraph'))"
              :waiting-for-ai="waitingForAi"
              :words-to-highlight="wordsToHighlight"
              :is-heading="tag === 'h'"
              @input="updatePart(tag, $event)"
            >
              <template #append>
                <v-expand-x-transition>
                  <ArticlePartExtend
                    v-if="tag === 'h' && value.h && !value.p"
                    :part="part"
                    :uid="value.uid"
                    :loading.sync="loadingExtend"
                    :words-generated-elsewhere="wordsGeneratedInP"
                    :force-prompt="strippedHeading"
                    :type="partExtendFromHeading"
                    hide-extensions
                    lang-path="heading"
                    @wordsGenerated="count => wordsGeneratedInH = count"
                    @generatingExtensionValueId="createWatcherForP"
                    @append="appendToParagraph"
                  />
                </v-expand-x-transition>
              </template>
            </Editor>
            <v-expand-transition>
              <ArticlePartExtend
                v-if="tag === 'p' && showExtendWithAi"
                :part="part"
                :uid="value.uid"
                :loading.sync="loadingExtend"
                :words-generated-elsewhere="wordsGeneratedInH"
                :watch-for-extension-value="watchForExtenstionValue"
                :language-name="languageName"
                lang-path="paragraph"
                @wordsGenerated="count => wordsGeneratedInP = count"
                @append="appendToParagraph"
              />
            </v-expand-transition>
            <div class="d-flex flex-wrap align-center text-caption-primary mx-3">
              <span>
                {{ $t('article.numOfWords', [wordCount[tag]]) }}
              </span>
              <VerticalDivider />
              <span>
                {{ $t('article.label.' + part + tag) }}
              </span>
              <template v-if="hasInspirations">
                <VerticalDivider />
                <ArticlePartInspirationToggle
                  :value="showInspirations"
                  @input="$emit('toggle-inspirations')"
                />
              </template>
              <template v-if="$te('article.tips.' + part + tag)">
                <VerticalDivider />
                <ArticlePartTipToggle v-model="showTips[tag]" />
              </template>
              <template v-if="tag === 'p'">
                <VerticalDivider />
                <ArticlePartExtendToggle
                  v-model="showExtendWithAi"
                  lang-path="paragraph"
                />
              </template>
              <slot
                v-if="tag === 'p'"
                name="paragraphToolbarAppend"
              />
            </div>
            <ArticlePartTip
              :show="showTips[tag]"
              :lang-path="'article.tips.' + part + tag"
            />
          </div>
        </template>
      </div>
      <div>
        <ArticlePartRemoveDialog
          :part="part"
          :title="value.h"
          :content="value.p"
          @removePart="removePart"
        />
      </div>
    </div>
  </v-expand-transition>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import Editor from '@/components/Editor.vue';
    import { Article, ArticleContentKey, ArticlePartContent } from "@/model/Article";
    import VerticalDivider from "@/components/VerticalDivider.vue";
    import ArticlePartTip from "@/components/article/ArticlePartTip.vue";
    import ArticlePartTipToggle from "@/components/article/ArticlePartTipToggle.vue";
    import ArticlePartInspirationToggle from "@/components/article/ArticlePartInspirationToggle.vue";
    import ArticlePartRemoveDialog from "@/components/article/ArticlePartRemoveDialog.vue";
    import ArticlePartExtendToggle from "@/components/article/ArticlePartExtendToggle.vue";
    import ArticlePartExtend from "@/components/article/ArticlePartExtend.vue";
    import { Store } from "@/plugins/Store";
    import { LocationLanguages } from "@/enum/locations_languages";
    import { stripTagsAndEntities } from "@/utils/string";
    import { OpenAiExpandPartType } from "@/model/OpenAI";

    export default Vue.extend({
        name: 'ArticleContentPartEditor',
        components: {
            ArticlePartExtend,
            ArticlePartExtendToggle,
            ArticlePartRemoveDialog,
            ArticlePartInspirationToggle, ArticlePartTipToggle, ArticlePartTip, VerticalDivider, Editor
        },
        props: {
            part: {
                type: String as PropType<ArticleContentKey>,
                default: () => ''
            },
            index: {
                type: Number,
                default: 0
            },
            hasInspirations: {
                type: Boolean,
                default: false
            },
            showInspirations: {
                type: Boolean,
                default: false
            },
            wordsToHighlight: {
                type: Object as PropType<{ [key: string]: string[] }>,
                default: () => ({})
            },
        },
        // emits: ['remove'],
        data: () => ({
            showExtendWithAi: true,
            watchForExtenstionValue: '',
            wordsGeneratedInH: 0,
            wordsGeneratedInP: 0,
            loadingExtend: false,
            showTips: {
                h: false,
                p: false,
            },
            shouldListedForChange: {
                h: true,
                p: true
            },
            partExtendFromHeading: OpenAiExpandPartType.FROM_HEADING,
        }),
        computed: {
            article: function (): Article {
                return this.$store.getters.article;
            },
            value: function (): ArticlePartContent {
                const storePart = this.$store.getters.article[this.part];
                if (!storePart[this.index]) {
                    storePart[this.index] = {h: '', p: ''};
                }
                return storePart[this.index];
            },
            wordCount: function (): { h: number, p: number } {
                return this.$store.getters.articleWordCount[this.part][this.index];
            },
            waitingForAi: function (): boolean {
                const subpart = this.$store.getters.articleAiLoading[this.part][this.index];
                return subpart?.h || subpart?.p || false;
            },
            languageName: function (): string {
                const languageName = "English";
                for (const location of LocationLanguages) {
                    for (const language of location.available_languages) {
                        if (language.language_code === this.article.language_code) {
                            return language.language_name;
                        }
                    }
                }
                return languageName;
            },
            strippedHeading: function (): string {
                return stripTagsAndEntities(this.value.h);
            },
        },
        methods: {
            updatePart: function (tag: 'h' | 'p', value: string): void {
                this.$set(this.shouldListedForChange, tag, false);
                this.$store.commit('setArticleContent', {
                    contentPart: this.part,
                    index: this.index,
                    contentKey: tag,
                    value: value
                });
            },
            createWatcherForP: function (valueId: string) {
                this.watchForExtenstionValue = valueId;
            },
            removePart: function (): void {
                this.$emit('remove');
            },
            appendToParagraph: function (text: string): void {
                this.shouldListedForChange.p = true;
                Store.commit('setArticleContent', {
                    contentPart: this.part,
                    index: this.index,
                    contentKey: 'p',
                    value: this.value.p + (this.value.p ? '<br><br>' : '') + text
                });
                this.$nextTick(() => {
                    this.shouldListedForChange.p = false;
                });
            }
        }
    });
</script>

<style lang="sass" scoped>
</style>
