<template>
  <div
    v-if="$vuetify.breakpoint.smAndUp"
    class="d-flex align-center"
    :class="{
      'mx-2': $vuetify.breakpoint.xl
    }"
  >
    <span>
      <v-tooltip
        nudge-bottom="10px"
        bottom
      >
        <template #activator="{ on }">
          <v-btn
            v-show="$store.getters.authenticated"
            text
            depressed
            exact-active-class="no-highlight"
            :small="$vuetify.breakpoint.smAndDown"
            @click="openArticles"
            v-on="on"
          >
            <v-icon>
              article
            </v-icon>
            <span
              v-show="$vuetify.breakpoint.lgAndUp"
              class="ml-2"
            >
              {{ $t("topBar.articles") }}
            </span>
          </v-btn>
        </template>
        <span>{{ $t("topBar.articles") }}</span>
      </v-tooltip>
    </span>
    <span
      v-if="showArticleControl"
      class="links-border d-flex flex-nowrap"
    >
      <v-tooltip
        v-for="link of links"
        :key="link.label"
        nudge-bottom="10px"
        bottom
      >
        <template #activator="{ on }">
          <v-btn
            text
            :color="link.color"
            depressed
            :small="$vuetify.breakpoint.smAndDown"
            :disabled="link.disabled"
            @click="link.action()"
            v-on="on"
          >
            <span v-if="$vuetify.breakpoint.lgAndUp">
              <v-icon> {{ link.icon }} </v-icon>
              <span class="ml-2">
                {{ $t(link.label) }}
              </span>
            </span>
            <span
              v-else
              class="caption"
            >
              <template v-if="link.smallLabel">
                {{ link.smallLabel }}
              </template>
              <template v-else>
                <v-icon> {{ link.icon }} </v-icon>
              </template>
            </span>
          </v-btn>
        </template>
        <span>{{ $t(link.label) }}</span>
      </v-tooltip>
    </span>
  </div>
</template>

<script lang="ts">
    import {EventBus} from "@/utils/EventBus";
    import Vue from "vue";
    import {FlashMessage} from "@/model/FlashMessage";

    interface Link {
        icon: string;
        label: string;
        color?: string;
        smallLabel?: string;
        action: () => void;
        disabled: boolean
    }

    export default Vue.extend({
        name: "TopBarInternalLinks",
        props: {
            articleStep: {
                type: Number,
                default: null
            },
            disableSave: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            links(): Array<Link> {
                return [{
                    icon: 'save',
                    label: 'article.save',
                    color: 'accent',
                    action: this.saveArticle,
                    disabled: this.disableSave
                }];
            },
            showArticleControl(): boolean {
                return this.$route.name === "Article" && this.articleStep === 3;
            }
        },
        methods: {
            openArticles(): void {
                this.$route.path !== '/' ? this.$router.push('/') : null;
            },
            openExpansionPanel(): void {
                EventBus.$emit("activatePanel");
            },
            saveArticle(): void {
                EventBus.$emit("save", () => {
                    this.$store.dispatch('displayFlashMessage', {
                        type: 'success',
                        content: 'article.saved'
                    } as FlashMessage);
                });
            }
        }
    });
</script>

<style scoped lang="sass">
.links-border
    border-radius: 4px
    padding-bottom: 8px
    padding-top: 8px
    background-color: var(--v-primary-darken2)

    .v-btn
        margin-left: 2px
        margin-right: 2px
</style>
