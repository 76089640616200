import {DownloadType} from '@/enum/download_type';

const heading = 'nadpis';
const paragraph = 'odstavec';

export default {
    hello: 'Ahoj!',
    and: 'a',
    continue: 'Pokračovat',
    close: 'Zavřít',
    cancel: 'zrušit',
    yearWeek: '{1}. týden roku {0}',
    unexpectedError: 'Došlo k neočekávané chybě, zkuste prosím akci opakovat.',
    createdBy: "Vytvořeno v nástroji SEOWRITEE",
    affiliate: "Registrujte se do našeho affiliate programu a získejte finanční odměnu za Vámi získáné zákazníky!",
    unknownError: "Nastala neočekávaná chyba!",

    save: 'Uložit',
    delete: 'Smazat',

    subscriptions: {
        title: 'Aplikace je vám nyní připravena napsat kompletně celý článek skvěle optimalizovaný pro internetové vyhledávače a atraktivní pro čtenáře.',
        subTitle: 'Vyberte si tarif, zaplaťe online a začněte okamžitě generovat kvalitní SEO obsah.',

        perMonth: '{0} / měsíc',
        inspiration: 'články',
        upToInspirations: '{0} články měsíčně',
        keywords: '{0} klíčových slov ve 4 skupinách',
        buy: 'Koupit',

        allPlansInclude: 'Všechny tarify obsahují',
        mustChoosePlan: 'Musíte zvolit preferovaný tarif!',
        continueWithPlan: 'Pokračovat s \'{0}\'',
        noPlan: 'předplatné neaktivní',
        activePlan: 'aktivní',
        changePlan: 'změnit',
        choosePlan: 'vybrat',
        chosenPlan: 'vybráno',
        usageTooltip: 'Máte vytvořeno {0} z {1} článků pro toto období',
        unlimitedUsageTooltip: 'V tomto období máte vytvořeno {0} článků',
        planRenewTooltip: 'Počítadlo se obnoví',
        planWillBeCancelled: 'Vaše předplatné bude ukončeno',
        noInspirationsLeftThisPeriodUpgradeToContinue: 'V tomto období máte již vyčerpané všechny články. Pokud chcete pokračovat, přejděte na vyšší tarif nebo zakupte jednorázový balíček inspirací!',
        manageSubscription: 'Spravovat nebo změnit tarif či fakturační údaje',
        oneTimePacks: 'Jednorázové balíčky článků',

        tryOneFree: 'Vyzkoušejte jeden článek zdarma!',
        getOneFree: 'Pokračovat na zkušební článek',

        free: 'zdarma',
        individual: 'individuální',
        individualContact: 'Domluvíme se s vámi na individuální ceně. Kontaktujte nás prosím na email rudolf.sehnal@seowritee.com',

        features: {
            aiWriting: 'Až několik set tisíc slov napsaných umělou inteligencí',
            seoScore: 'SEO skóre vypočítané z 20+ parametrů',
            instructions: 'Podrobné pokyny krok za krokem',
            export: '4 typy exportu na vaši webovou stránku',
            chatGpt: 'ChatGPT pro ladění obsahu',
            keywordAnalysis: 'Automatická analýza klíčových slov',
            inspiration: 'Inspirace z TOP10 článků',
            plagiarismCheck: 'Kontrola plagiarismu'
        },

        usageLimit: {
            warning: 'Jsi skutečně těžce nadprůměrný psavec. Super, fandíme ti, kvalitního obsahu není nikdy dost :-) ' +
                'Můžeš stále generovat články dále, ale pokud budeš tímto tempem pokračovat v dalším měsíci, domluvíme ' +
                'si s tebou přechod na vyšší tarif BUSINESS. Děkujeme za pochopení a používání SEOWRITEE.',
            stop: 'Stále ti fandíme ve tvém intenzivním psaní, ale už jsi překročil limit i těch ' +
                'nejintenzivnějších psavců :-). Pokud potřebuješ v tomto měsíci psát i nadále, ' +
                'zaplať si další články pomocí jednorázové platby a na další měsíc si s námi ' +
                'domluv cenu pro BUSINESS licenci na rudolf.sehnal@seowritee.com',
            useExtraInspiration: 'Využít jednorázový článek (zbývá: {0}).',
            buyExtraInspirations: 'Zakoupit jednorázové články',
        },

        terms: {
            IAccept: 'Akceptuji',
            termsOfService: 'Obchodní podmínky',
            privacyPolicy: 'Ochranu osobních údajů',
            info: 'Jsem si vědom, že budu platit automaticky měsíčně až do doby, dokud se ze služby neodhlásím.'
        },

        change: 'Rychle můžete kdykoliv přejít na vyšší nebo nižší tarif. Nebudeme vám za to účtovat žádné extra poplatky. Nevyžadujeme žádnou smlouvu. Pokud budete chtít přestat službu používat, jednoduše přestanete platit.',
        upgradeToUse: 'Zvolte libovolný tarif, abyste získali přístup k této funkci!',

        list: {
            title: 'Nechte si napsat článek během pár minut jako od profesionálního SEO copywritera!',
            items: {
                1: 'Stačí když zadáte klíčové slovo, popíšete téma článku a na jeden klik vám AI Lucy napíše celý text.',
                2: 'Aplikace vytvoří analýzu klíčových slov a podle nich napíše obsah článku.',
                3: 'Součástí napsaného textu jsou také odpovědi na nejčastěji kladené otázky, které lidi zajímají.',
                4: 'Získáte také inspirace pro vylepšení všech částí článku z nejlépe optimalizovaného textu na internetu.',
                5: 'Snadno si upravíte AI napsaný title, meta description, nadpisy H1 až H6, bodycopy atd.',
                6: 'Během psaní a úprav aplikace automaticky hlídá přes 20 SEO faktorů důležitých pro vysoké umístění ve vyhledávačích.',
                7: 'Pak už jen zkontrolujete originalitu textu, článek exportujete a vložíte na web.'
            }
        }
    },
    newContentAvailable: 'Je k dispozici nová verze stránky!',
    refresh: 'Obnovit',
    logout: 'Odhlásit se',

    register: 'Zaregistrujte se a nechte Al napsat SEO článek zcela zdarma!',
    noCardRequired: 'Nemusíte vkládat platební kartu.',

    unsavedChanges: {
        title: 'Neuložené změny',
        text: 'Opravdu chcete odejít?',
        stay: 'Zůstat',
        saveAndLeave: 'Uložit a odejít',
        leave: 'Odejít'
    },

    steps: {
        step: 'Krok',
        initForm: 'Zadání základních parametrů',
        selectKeyword: 'Výběr klíčového slova',
        writeArticle: 'Psaní článku',
    },

    keywords: {
        locationTitle: 'Vyberte stát a jazyk, ve kterém se článek napíše.',
        title: "Vložte hlavní klíčové slovo článku, například “koláč”, “jablečný koláč” nebo “jablečný koláč recept”.",
        subtitle: "Klíčové slovo budete moci upravit později v analýze klíčových slov.",
        label: "Klíčová slova",
        manage: "Spravovat klíčová slova",
        hint: "Klíčové slovo v SEO optimalizaci textů pro vyhledávače je termín nebo fráze, kterou lidé používají při vyhledávání informací na internetu. Tyto klíčové fráze jsou důležité, protože vyhledávače je používají k indexaci a řazení stránek výsledků vyhledávání.",
        location: "Stát",
        language: "Jazyk",
        keyword: "Klíčové slovo",

        confirm: "Potvrdit",

        edit: {
            locationTitle: 'Zvolený stát a jazyk, ve kterém se článek napíše.',
            title: "Zde můžete zadat hlavní klíčové slovo znovu",
            confirm: "Potvrdit"
        },

        toCount: 'Zadaná slova jsou zvýrazněna, zaškrtnutá slova jsou navíc počítána do hustoty',
        occurrences: 'výskytů',
        countIntoDensity: 'započítat do hustoty',
        wordAlreadySelected: 'Dané slovo již je zadáno!',

        keywordSelection: 'Hlavní klíčové slovo článku je',
        keywordSelectionCaption: 'Během několika desítek sekund vám AI Lucy napíše celý článek. Vylaďte ho, exportujte a publikujte na web.',
        allKeywords: 'Analýza klíčových slov',
        selectNewKeyword: 'Zvolit nové klíčové slovo',
        avgMonthlySearches: 'Průměrná měsíční hledanost',

        info: {
            items: {
                1: 'Níže z navržených klíčových slovech ve sloupci “Vyberte hlavní klíčové slovo pro optimalizaci” můžete vybrat přesnější klíčové slovo, nebo můžete nechat to původní.',
                2: 'Hlavní klíčové slovo můžete vybrat pouze jedno. Na jeho základě získáte inspiraci pro vylepšení textů.',
                3: 'Jako hlavní klíčové slovo doporučujeme vyhrat takové, které má průměrný počet hledání měsíčně alespoň 400 a výše.',
                4: 'Ve sloupci “Vyberte pro psaní od AI Lucy” můžete změnit klíčová slova, na základě kterých budou odstavce článku napsány. Doporučujeme vybrat co nejvíce klíčových slov “související” a “otázky”.'
            },
            beforeButton: 'Zvolili jste hlavní klíčové slovo pro optimalizaci? Pokud ano, stiskněte tlačítko níže.',
            selectedForAi: 'Počet klíčových slov vybraných pro psaní textů od AI Lucy:',
            beforeButtonNoData: 'Lidé nevyhledávají klíčové slovo, které jste zadali nebo pouze několikrát měsíčně a také díky nízkému měsíčnímu počtu variant klíčového slova bude článek krátký. Máte nyní na výběr ze dvou možností.',
            tooManyKeywordsForAi: 'Zvolte nejvýše 20 klíčových slov pro AI Lucy'
        },

        list: {
            header: {
                title: 'Klíčová slova',
                keywordDifficulty: 'SEO obtížnost',
                unknownKeywordDifficulty: 'Neznámá SEO obtížnost',
                knownKeywordDifficulty: 'Čím je hodnota nižší, tím máte větší šanci získat lepší umístění ve výsledcích organického vyhledávání. Čím je hodnota vyšší, tím je klíčové slovo atraktivnější a konkurence vyšší.',
                averageSearches: 'Průměrný počet hledání měsíčně',
                chooseKeyword: 'Vyberte hlavní klíčové slovo pro optimalizaci',
                chooseKeywordHint: 'Pokud chcete, můžete změnit hlavní klíčové slovo, na základě kterého získáte inpirace z 10 webů nejlépe umístěných ve vyhledávačích.',
                chooseForAi: 'Vyberte pro psaní od AI Lucy',
                chooseForAiHint: 'Pokud chcete, můžete změnit klíčová slova, na která vám AI Lucy napíše nadpisy a odstavce článku včetně odpovědí na otázky.'
            },
            variations: {
                title: 'varianty',
                description: 'Toto jsou blízké varianty hlavního klíčového slova. Sem tam jimi nahraďte hlavní klíčové slovo, ale pozor na gramatiku – nikdy v textu nepoužívejte gramaticky nesprávné varianty.'
            },
            questions: {
                title: 'otázky',
                description: 'Otázky související s hlavním klíčovým slovem. Zapracujte je do článku a odpovězte na ně co nejpraktičtěji.'
            },
            longTail: {
                title: 'long tail',
                description: 'Méně vyhledávané, ale přesnější dotazy související s hlavním klíčovým slovem. Můžete je zakomponovat do článku a podrobněji rozebrat.'
            },
            related: {
                title: 'související',
                description: 'Související klíčová slova jsou sekundární výrazy, které pomáhají zúžit zaměření hlavního klíčového slova. Tato klíčová slova umožňují cílit na specifičtější fráze, které se odvíjejí od daného tématu. Pomohou vám neodbíhat od tématu článku.'
            },
            empty: 'Bohužel pro zvolené hlavní klíčové slovo nejsou dostupné alternativy pro tuto kategorii, protože je lidé na internetu nevyhledávají."',
            nothing: 'Bohužel pro zvolené hlavní klíčové slovo nejsou dostupné alternativy pro tuto kategorii, protože je lidé na internetu nevyhledávají."',
            chooseNewKeyword: 'Změnit klíčové slovo',
            chooseNewKeywordHint: 'Pokud je toto klíčové slovo výstižnější, můžete ho zvolit jako nové hlavní klíčové slovo pro vygenerování inspirací pro doladění textů.'
        },

        generateInspiration: 'Generovat SEO článek',
        generateInspirationAnyway: 'Přesto napsat článek',
        deleteArticleAndRefundCredit: 'Zrušit tvorbu tohoto článku a vrátit kredit',
        notCreatedInThisBillingPeriod: 'Nelze vrátit kredit, neboť článek nebyl vytvořen v tomto účtovacím období',

        aiPrompt: {
            title: 'Psaní textu AI',
            letHer: 'Dovolte Lucy napsat článek',
            description: 'Popište co nejvýstižněji téma článku, tedy vše, co by měl obsahovat. Čím přesněji, tím lépe.',
            promptExample: 'Příklad popisu: Rady a tipy, jak upéct lahodný jablečný koláč. Tradiční, moderní a zdravé recepty na jablečný koláč.',
        },

        simplify: {
            writeNow: 'Napsat článek hned',
            writeNowDescription: 'Nechávám na aplikaci, aby napsala celý článek bez okamžitého upřesnění obsahu odstavců.',
            specify: 'Upřesnit obsah článku',
            specifyDescription: 'Chci upřesnit obsah odstavců podle analýzy klíčových slov (minuta práce navíc).',
        },

        analyze: {
            description: 'Zadejte URL adresu článku, jehož SEO skóre chcete vylepšit.',
            urlInputHint: 'Například: https://www.vasweb.cz/clanek-pro-vylepseni',
            keywordSelectionCaption: {
                begin: 'Upravte obsah z URL adresy podle',
                bold: 'faktorů hodnocení SEO',
                end: '. Pokud texty nebyly extrahovány, zkopírujte je ručně.'
            },
            analyzeNow: 'Analyzovat článek hned',
            analyzeNowDescription: 'Nechávám na aplikaci, aby se postarala o extrahování bez okamžitého upřesnění obsahu odstavců.',
            specifyDescription: 'Chci upřesnit obsah odstavců podle analýzy klíčových slov před extrahováním (minuta práce navíc).',
            aiPrompt: {
                description: 'Popište co nejvýstižněji téma článku, tedy vše, co obsahuje. Čím přesněji, tím lépe.',
            }
        }
    },

    article: {
        getInspired: 'Inspirace z TOP obsahu',
        editYours: 'Upravte text napsaný AI Lucy',
        words: 'slov',
        numOfWords: '{0} slov',
        details: "SEO parametry článku",
        keyWordsCount: "Počet klíčových slov v tomto textu inspirace",

        list: 'Vaše články',
        listMore: 'další',
        create: 'Vytvořit nový článek',
        createAnotherInTrial: 'Pro vytvoření dalšího článku prosím zvolte předplatné níže.',
        view: 'Otevřít',
        createdAt: 'Vytvořen',
        empty: 'Zatím žádné články...',
        noTitle: "Článek bez titulku",

        createDescription: "Vytváření nového obsahu pro web nebo blog",

        analyze: {
            title: "Analýza článku z URL adresy",
            description: "Zlepšení obsahu zveřejněného na adrese URL",
            scrapeFail: {
                title: "Neúspěšná extrakce obsahu",
                description: "Je nám líto, ale SEOWRITEE nedokázal extrahovat obsah ze zadané adresy URL. Důvodem může být struktura webové stránky, omezení přístupu nebo nesprávná adresa URL. Zkontrolujte prosím, zda v adrese URL nejsou chyby, a zkuste to znovu.",
                creditRefunded: "Váš kredit byl vrácen.",
                deleteArticle: {
                    text: "Smazat článek",
                    description: "Můžete zkusit vytvořit nový článek nebo analyzovat jiný článek."
                },
                openEmpty: {
                    text: "Otevřít prázdný článek",
                    description: "Můžete vytvořit nový článek ručně s pomocí AI Lucy."
                }
            },
            scrapePartial: {
                title: "Částečná extrakce obsahu",
                description: "Je nám líto, ale SEOWRITEE nedokázal extrahovat veškerý obsah ze zadané adresy URL. Důvodem může být struktura webové stránky. Zkontrolujte prosím, zda v adrese URL nejsou chyby, a zkuste to znovu.",
                openPartial: {
                    text: "Otevřít částečný článek",
                    description: "Můžete doplnit chybějící části textu ručně nebo s pomocí AI Lucy."
                },
            }
        },

        contentExtraction: "Extrakce obsahu",

        save: 'Uložit článek',
        saved: 'Článek uložen',

        updated: 'Článek aktualizován',

        overview: {
            selectedKeyword: {
                title: 'Zvolené klíčové slovo',
                description: 'Hlavní klíčové slovo, neboli téma, o kterém píšete článek. Pomůžeme vám získat inspiraci pro jeho detailní popis a vysvětlení čtenářům, tedy cílové skupině.'
            },
            idealURL: {
                title: 'Ideální URL článku',
                description: 'Takto by měla vypadat URL článku, vstupní stránky nebo jakákoliv část webu z hlediska SEO. Čím kratší a relevantnější ke klíčovému slovu bude, tím lépe. Varianta s www nebo bez závisí na tom, jak máte nastavenou doménu webu.'
            },
            ALTDescription: {
                title: 'Popis obrázku ALT',
                description: 'Obrázky popište hlavním klíčovým slovem a jeho variantami atributem ALT obrázku. Tuto možnost většinou najdete v administraci webového obsahu.'
            },
            recommendedLength: {
                title: 'Minimální doporučená délka článku',
                description: 'Pokud chcete být na předních pozicích v organickém vyhledávání, váš článek musí mít správný počet slov. Vypočítali jsme minimální počet slov nejlepších článků na internetu a budeme váš počet slov sledovat za vás.'
            },
            recommendedDensity: {
                title: 'Doporučená hustota klíčových slov',
                description: 'Hlavní klíčové slovo a jeho varianty musí být v textu článku použity přiměřeně. Pohlídáme jeho správnou hustotu za vás.'
            },
            length: "Délka",
            density: "Hustota vybraných slov",
            moreDetails: "Více detailů",
            lessDetails: "Méně detailů",
            recommendedKeywordCount: 'při délce článku {0} slov je to {1} klíčových slov'
        },

        label: {
            metaTitle: 'meta nadpis',
            metaDescription: 'meta popis',
            h1h: 'H1 ' + heading,
            h1p: 'H1 ' + paragraph,
            h2h: 'H2 ' + heading,
            h2p: 'H2 ' + paragraph,
            h3h: 'H3 ' + heading,
            h3p: 'H3 ' + paragraph,
            h4h: 'H4 ' + heading,
            h4p: 'H4 ' + paragraph,
            h5h: 'H5 ' + heading,
            h5p: 'H5 ' + paragraph,
            h6h: 'H6 ' + heading,
            h6p: 'H6 ' + paragraph,
            images: 'obrázky',
        },

        inspirationFor: {
            title: 'Inspirace pro {0}',
            metaTitle: 'Text, který se objeví ve výsledcích vyhledávání. Inspirujte se těmi nejlepšími titulky a napište ještě lépe, atraktivněji a výstižněji.',
            metaDescription: 'Text, který se objeví ve výsledcích vyhledávání. Inspirujte se těmi nejlepšími popisy a napište ještě lépe, atraktivněji a výstižněji.',
            h: 'Inspirujte se titulky nejlepších článků na internetu a napište jej ještě atraktivněji a výstižněji. ',
            p: 'Inspirujte se odstavci nejlepších článků na internetu a napište jej ještě atraktivněji a výstižněji.'
        },
        inspirationDetail: 'Otevřít detail',
        noInspiration: 'Žádná ze stránek pro inspiraci nepoužila tuto sekci, vy ji ale můžete vyplnit.',
        hideInspiration: 'Skrýt tuto inspiraci',
        hideAndMarkUnrelevant: 'Skrýt a označit jako nerelevantní',
        copyInspiration: 'Zkopírovat k přepsání',
        copiedToClipboard: 'Zkopírováno do schránky!',

        placeholder: {
            metaTitle: 'Sem napište meta nadpis',
            metaDescription: 'Sem napište meta popis',
            heading: 'Sem napište nadpis',
            paragraph: 'Sem napište odstavec',
            question: 'Sem napište otázku',
            answer: 'Sem napište odpověď',
        },

        writeBetter: {
            meta: 'Upravte {0}, aby byl atraktivnější než inspirace',
            heading: 'Upravte {0}, aby byl výstižnější než inspirace',
            headingBrief: '{0} nadpis a odstavec',
            paragraph: 'Upravte odstavec, aby byl zajímavější než inspirace',
            placeholder: {
                meta: 'Sem napište {0} ideálně max {1} znaků dlouhý',
                heading: 'Napište vlastní odstavec, kterým vysvětlíte, co popisuje {0}',
                paragraph: 'Sem napište odstavec, který bude souviset s nadpisem výše a detailně téma vysvětlete'
            }
        },

        contentList: {
            title: 'Obsah článku',
            hint: 'Bude automaticky generován při psaní článku.',
            description: 'Seznam obsahu článku je důležitý pro rychlou orientaci čtenáře v textu. Vytvoří se automaticky během psaní nadpisů.',
            empty: 'nevyplněno',
        },

        questions: {
            title: 'Upravte odpověď nebo napište vlastní',
            question: 'Otázka',
            answer: 'Odpověď',
            remove: 'Smazat otázku'
        },

        answers: {
            title: "Odpovědi na otázky",
            description: "Kvalitu optimalizace článku a také obsahovou hodnotu pro čtenáře vám zvýší zodpovězení na často kladené otázky úzce související s klíčovým slovem."
        },

        tips: {
            label: 'Tipy',
            metaTitle: 'Kromě hlavního klíčového slova použijte v nadpisu číslovku, otázku jak, proč nebo kde, výčet, atraktivní cenu atd. a maximálně využijte doporučený počet znaků.',
            metaDescription: 'Pomocí hlavního klíčového slova a dalších informací popište co nejatraktivněji obsah článku pomocí výčtu obsahu, unikátních výhod, benefitů. Maximálně využijte doporučený počet znaků.',
            h1h: 'V nadpise použijte klíčové slovo a popište hlavní sdělení článku. Nadpis H1 použijte v článku pouze jednou.',
            h1p: 'Hlavní klíčové slovo smysluplně použijte v začátku odstavce a shrňte v něm obsah celého článku tak, aby jste čtenáře navnadili přečíst si ho celý až do konce.',
            h2h: 'V tomto a dalších nadpisech použijte další variantu klíčového slova nebo klíčové slovo ve formě otázky. Nadpisy H2 až H6 můžete v článku použít opakovaně.'
        },

        extendWithAi: {
            fillPrompt: 'Vyplňte instrukci pro AI Lucy',
            heading: {
                run: 'Generovat odstavec pro tento nadpis',
            },
            paragraph: {
                run: 'Generovat další část',
                toggle: 'Rozšířit odstavec za pomoci AI Lucy',
                prompt: 'Popište AI Lucy, o čem má v tomto odstavci psát dále.',
                use: 'Přidat k odstavci',
            },
            question: {
                run: 'Generovat odpověď na tuto otázku',
            },
            answer: {
                run: 'Generovat další část',
                toggle: 'Rozšířit odpověď na otázku za pomoci AI Lucy',
                prompt: 'Popište AI Lucy, o čem má v odpovědi na tuto otázku psát dále',
                use: 'Přidat k odpovědi',
            },
            discard: {
                tooltip: 'Zahodit',
                title: 'Určitě chcete zahodit následující rozšíření?',
                confirm: 'Zahodit'
            },
        },

        addSubpart: 'Vložit sekci "{0}" s odstavcem',
        addAnotherSubpart: 'Vložit další sekci "{0}" s odstavcem',
        removeSubpart: {
            tooltip: 'Smazat tento nadpis vč. odstavce',
            title: 'Určitě chcete smazat následující {0} včetně jeho odstavce?',
            confirm: 'Smazat'
        },
        addQuestion: 'Vložit další otázku',
        removeQuestion: {
            tooltip: 'Smazat tuto otázku',
            title: 'Určitě chcete smazat následující otázku včetně její odpovědi?',
            confirm: 'Smazat',
        },

        images: {
            title: 'Obrázky v článku',
            description: 'Seznam obrázků použitých v článku. Vyplňte ALT atributy obrázků, aby jej SEOWRITEE mohl zkontrolovat.',
            add: 'Přidat obrázek',
            remove: 'Smazat obrázek',
            alt: {
                header: 'ALT atribut',
                placeholder: 'Zde napište popis obrázku'
            },
            url: {
                header: 'URL adresa externě umístěného obrázku',
                placeholder: 'https://www.vasweb.cz/image.jpg',
            }
        },

        SERPPreview: {
            title: 'Podívejte se, jak bude vypadat váš SERP',
            description: 'Přesně tak lidé uvidí stránku vašeho webu ve výsledcích organického vyhledávání. Ujistěte se, že je atraktivní a láká lidi ke kliknutí. Ovlivní to návštěvnost vašeho webu. SERP znamená stránku s výsledky vyhledávání vyhledávače.'
        },
        urlSuggestion: {
            ideal: 'Doporučená ideální URL',
            yours: 'Vaše URL',
        },
        preview: 'Náhled článku',

        generateCopyLeaksReport: "Vygenerovat kontrolu plagiarismu",
        generatingCopyLeaksReport: "Probíhá kontrola, může to trvat pár minut...",
        twoChecksUsed: "Již byly využity 2/2 kontroly plagiarismu!",

        currentLength: {
            title: 'Aktuální délka vašeho článku',
            description: 'Aktuální počet slov za vás spočítáme v průběhu psaní článku a zároveň porovnáme s jeho ideálním počtem.'
        },
        currentDensity: {
            title: 'Aktuální hustota vybraných slov',
            description: 'Spočítáme aktuální hustotu klíčových slov a sledujeme jejich ideální počet použitý v článku.'
        },
        originality: {
            title: 'Kontrola originality',
            description: 'Důrazně doporučujeme zveřejňovat pouze originální text. Jakmile napíšete finální verzi článku, ujistěte se, že všechny jeho části jsou původní. Pokud ne, přepište je svými slovy.'
        },
        export: {
            title: 'Export článku',
            description: 'Hotový článek můžete exportovat čtyřmi různými způsoby, včetně HTML kódu, a vložit jej přímo do administrace vašeho webu nebo poslat někomu jinému k nasazení či použití.'
        },

        density: {
            low: 'Přidejte nějaká klíčová slova',
            ok: 'Množství klíčových slov je ideální',
            high: 'Klíčová slova opakujete příliš často, některá odstraňte'
        },

        download: {
            title: 'Stáhněte si obsah optimalizovaného článku a vložte jej do redakčního systému vašeho webu',
            formats: {
                [DownloadType.DOCX]: 'docx',
                [DownloadType.HTML]: 'html',
                [DownloadType.CLIPBOARD]: 'Zkopírovat do schránky',
                [DownloadType.RTF]: 'rtf'
            }
        },

        generateNewInspiration: 'Generovat další článek',

        recommendation: {
            title: 'Další tipy, jak článek optimalizovat a získat lepší pozice webu v internetových vyhledávačích',
            description: 'Zde je několik dalších tipů a triků, které pomohou vašemu článku umístit se vysoko ve výsledcích organického vyhledávání a získat bezplatnou návštěvnost webu.',
            items: {
                1: 'Texty napsané AI Lucy doplňte zajímavými informacemi, myšlenkami a využijte inspirace z TOP obsahu.',
                2: 'Do článku vložte obrázky, videa, grafy atd. a do popisu ALT dejte příslušná klíčová slova.',
                3: 'S hustotou klíčových slov v článku to nepřehánějte, držte se v rozmezí doporučené hustoty klíčového slova.',
                4: 'Inspirace nikdy nekopírujte do vašeho článku, protože vyhledávače penalizují duplikovaný obsah.',
                5: 'Váš článek by měl mít minimálně stejný počet slov, ideálně větší počet slov, než má článek umístěný na první pozici.',
                6: 'Vložte do článku odkazy na zajímavé zdroje související s tématem, které nejsou konkurenční vaší firmě.',
                7: 'Prolinkujte obsah článku na další stránky vašeho webu, pokud zmiňujete něco, co už jste napsali.',
                8: 'Obsah článku průběžně aktualizujte, vyhledávače lépe hodnotí aktuální informace.',
                9: 'Získejte pro článek kvalitní zpětné odkazy (přirozené, neplacené, z důvěrohodných a nekonkurenčních webů).',
                10: 'Sdílejte článek na sociálních sítích a oslovte ostatní autory, kteří píší o stejném tématu.',
                11: 'Vytvořte z obsahu článku video, podcast či prezentaci a sdílejte je vhodně na internetu.',
                12: 'Pokud hledáte profesionální poradenství s obsahovým a technickým SEO, obraťte se na nás přímo na https://seomaker.cz/'
            }
        },

        openAi: {
            loading: 'Umělá inteligence Lucy připravuje text...',
        }
    },

    seoScore: {
        seo: 'SEO',
        score: 'SEO skóre',
        showDetails: 'Zobrazit detaily',
        keywordVariants: {
            label: 'Varianty klíčového slova',
            description: 'Text článku by měl obsahovat více variant hlavního klíčového slova. Naleznete je v analýze klíčových slov: Klíčová slova - související. Zadané varianty klíčového slova: {0}',
            green: 'Dvě nebo více',
            yellow: 'Jedna',
            red: 'Žádná',
        },
        keywordLength: {
            label: 'Délka klíčového slova',
            description: 'Z čím většího počtu slov se zvolené hlavní klíčové slovo bude skládat (jedná se o tzv. long-tail), tím menší je konkurenčnost (viz. SEO obtížnost) ale i menší počet vyhledávání. Klíčové slovo je složeno z {0} slov',
            green: 'Tři a více slov',
            yellow: 'Dvě slova',
            red: 'Jedno slovo',
        },
        keywordInSlug: {
            label: 'URL článku obsahuje klíčové slovo',
            description: 'Zajistěte, aby URL publikovaného článku obsahovalo hlavní klíčové slovo za lomítkem. URL článku obsahuje "{1}"',
            green: 'Obsahuje',
            red: 'Neobsahuje',
        },
        imgAltIncludesKeyword: {
            label: 'ALT obrázků obsahuje klíčové slovo',
            description: 'Zajistěte, aby ALT, což je alternativní text obrázku obsahoval hlavní klíčové slovo. ALT obrázků obsahuje "{1}"',
            green: 'Obsahuje',
            red: 'Neobsahuje',
        },
        titleIncludesKeyword: {
            label: 'Meta nadpis obsahuje klíčové slovo',
            description: 'Meta title (nadpis) je HTML tag s největším významem pro SEO a musí obsahovat, ideálně hned na začátku, hlavní klíčové slovo. Meta nadpis obsahuje "{1}"',
            green: 'Obsahuje',
            red: 'Neobsahuje',
        },
        titleLength: {
            label: 'Délka meta nadpisu',
            description: 'Délka meta nadpisu je {0} znaků',
            green: 'Méně než {1} znaků',
            red: 'Více než {1} znaků',
        },
        descriptionIncludesKeyword: {
            label: 'Meta popis obsahuje klíčové slovo',
            description: 'Meta description (popis) tag informuje návštěvníky webu a také vyhledávače, o čem váš článek je. Zajistěte, aby obsahoval hlavní klíčové slovo. Meta popis obsahuje "{1}"',
            green: 'Obsahuje',
            red: 'Neobsahuje',
        },
        descriptionLength: {
            label: 'Délka meta popisu',
            description: 'Délka meta popisu je {0} znaků',
            green: 'Méně než {1} znaků',
            red: 'Více než {1} znaků',
        },
        keywordDensity: {
            label: 'Hustota hlavního klíčového slova',
            description: 'Hlavní klíčové slovo by se mělo přirozeně vyskytovat v textu. Nepřehánějte to s jeho množstvím a dodržte doporučenou hustotu. Hlavní klíčové slovo má hustotu {0}%',
            green: 'V doporučeném rozsahu {1} - {2}%',
            yellow: 'Nízká hustota (< {1}%)',
            red: 'Vysoká hustota (> {2}%)',
        },
        serpIsComplete: {
            label: 'SERP je vyplněný',
            description: 'Zajistěte, aby Meta nadpis a Meta popis byly v kódu článku vyplněny. Meta nadpis a meta popis jsou vyplněné',
            green: 'Vyplněné',
            red: 'Nevyplněné',
        },
        avgWordCountOfTopTen: {
            label: 'Delší než průměrný článek z TOP 10',
            description: 'Aktuální délka článku je {0} slov',
            green: 'Více než {1} slov.',
            red: 'Méně než {1} slov.',
        },
        longestWordCountOfTopTen: {
            label: 'Delší než nejdelší článek z TOP 10',
            description: 'Délka článku také ovlivňuje jeho SEO skóre. Pokud chcete získat první organické pozice ve vyhledávačích, napište delší článek, než je těch deset nejlepších. Aktuální délka článku je {0} slov',
            green: 'Více než {1} slov',
            red: 'Méně než {1} slov',
        },
        keywordInIntroduction: {
            label: 'Klíčové slovo v prvním odstavci',
            description: '"{1}" v prvním odstavci',
            green: 'Použito',
            red: '"Chybí',
        },
        keywordInH1: {
            label: 'Klíčové slovo v nadpisu H1',
            description: '"{1}" v nadpisu H1',
            green: 'Použito',
            red: '"Chybí',
        },
        keywordInH2: {
            label: 'Klíčové slovo v nadpisu H2',
            description: '"{1}" v nadpisu H2',
            green: 'Použito',
            red: '"Chybí',
        },
        keywordInH3: {
            label: 'Klíčové slovo v nadpisu H3',
            description: '"{1}" v nadpisu H3',
            green: 'Použito',
            red: '"Chybí',
        },
        relatedKeywords: {
            label: 'Další relevantní klíčová slova',
            description: 'Do nadpisů a odstavců článku zapracujte také další související klíčová slova, která naleznete v analýze klíčových slov. {0} dalších relevantních klíčových slov použito v článku',
            green: 'Alespoň polovina',
            red: 'Méně než polovina',
        },
        inboundLinks: {
            label: 'Interní odkazy',
            description: 'Do textu odstavců vložte několik interních linků vedoucích na vaše články s podobnou tématikou nebo vysvětlující pojmy v něm použité. Odkazy na vlastní stránky: {0}',
            green: 'Alespoň jeden odkaz',
            red: 'Žádné odkazy',
        },
        outboundLinks: {
            label: 'Externí odkazy',
            description: 'Ujistěte se, že textu článku obsahuje několik linků na weby (které nejsou vašimi konkurenty) se zajímavým a kvalitním obsahem. Odkazy na cizí stránky: {0}',
            green: 'Alespoň jeden odkaz',
            red: 'Žádné odkazy',
        },
        inspirationFromTopTen: {
            label: 'Inspirace z TOP 10 konkurenčních článků',
            description: 'Obohaťte váš článek informacemi z nejlépe optimalizovaných webů. Stačí kliknout na "Inspirace z TOP obsahu" pod každým nadpisem či odstavcem.',
            green: 'Inspirace z TOP 10 konkurenčních článků',
            red: 'Bez inspirace',
        },
        subHeadingDistribution: {
            label: 'Nadpisy dílčích částí článku',
            description: 'Pro snadnější čtení obsahu článku je důležité používat podnadpisy, které oddělí text dlouhých odstavců. Nadpis má {0} z {1} částí',
            green: 'Všechny vyplněné',
            red: 'Některé chybí',
        },
        paragraphLength: {
            label: 'Délka odstavců',
            description: 'Pro snadnější čtení obsahu článku jsou důležité krátké odstavce. Poměr krátkých odstavců (<150 slov) je {0}',
            green: 'Většina odstavců je krátká',
            yellow: 'Alespoň polovina odstavců je krátká',
            red: 'Odstavce jsou dlouhé',
        },
        sentenceLength: {
            label: 'Průměrná délka vět',
            description: 'Krátkě věty usnadňují a zrychlují čtení a pochopení obsahu článku. Průměrná délka věty je {0} slov',
            green: 'Méně než {1} slov',
            yellow: 'Méně než {2} slov',
            red: 'Více než než {2} slov',
        }
    },

    articleTable: {
        selectedKeyword: 'Hlavní klíčové slovo',
        url: {
            header: {
                text: 'URL adresa zveřejněného článku',
                tooltip: 'Zadejte adresu URL článku a zjistěte organickou pozici na Googlu a SEO skóre domény.'
            },
            dialog: {
                title: {
                    add: 'Přidat URL',
                    edit: 'Upravit URL',
                },
                placeholder: 'https://www.odkaz.cz/cesta-k-clanku',
                errors: {
                    couldNotSave: 'Nepodařilo se uložit URL článku, zkuste to prosím znovu.',
                }
            },
            tooltip: {
                add: 'Přidat URL článku',
                edit: 'Upravit URL článku',
            }
        },
        createdAt: 'Vytvořen',
        organicPosition: {
            header: 'Pozice ve vyhledávání',
            tooltip: 'Zjistěte pozici článku v organických výsledcích vyhledávání na Googlu. Tato informace má pouze informativní charakter a nezaručuje skutečnou pozici článku.'
        },
        reloadableChip: {
            remeasure: 'Přeměřit',
            cooldown: 'Přeměření je možné za {0} hodin a {1} minut',
            errors: {
                unexpectedError: 'Nastala neočekávaná chyba, zkuste to prosím znovu.',
            },
            error: "Chyba",
        },
        seoScore: 'SEO skóre článku',
        domainSeoScore: {
            header: {
                text: 'SEO skóre domény',
                tooltip: 'Zjistěte SEO skóre domény, na které je článek zveřejněný.',
            },
            tooltip: 'Otevřít v novém okně',
        },
        edit: 'Vylepšit článek',
    },

    articleCrossroad: {
        newContent: {
            header: 'Nový obsah',
            description: 'Vytváření nového obsahu pro web nebo blog',
            button: 'Vytvořit nový obsah',
        },
        existingContent: {
            header: 'Existující obsah',
            description: 'Zlepšení obsahu zveřejněného na adrese URL',
            button: 'Analyzovat existující obsah',
        }
    },

    errors: {
        requiredField: "Toto pole musíte vyplnit.",
        maxWords: "Maximální délka je {0} slov!",
        minLength: "Délka musí být alespoň {length} znaků.",
        startsWithProtocol: "URL musí začínat http:// nebo https://",
        invalidUrl: "Neplatná URL adresa",
    },
    topBar: {
        articles: 'Články & měření',
        seoParams: 'SEO Parametry',
        thisArticle: 'Tento článek',
        manual: 'Manuál',
        blog: 'Blog',
        promo: 'Domluvte si ukázku zdarma',
    },
    checkOutFeatures: "Podívejte se, s čím vším vám SEOWRITEE pomůže!"
};
