<template>
  <v-card
    id="keywordLocationLanguageForm"
    class="d-flex flex-column align-center primaryBorder ma-3 pa-5"
    :min-width="$vuetify.breakpoint.mdAndUp ? 800 : 0"
    max-width="800"
  >
    <v-card-text>
      <span class="text-h6 text--primary mb-5 d-block">
        {{ $t(langPath + 'locationTitle') }}
      </span>
      <v-form ref="keywordForm">
        <v-autocomplete
          v-model="form.location_code"
          :items="locations"
          :label="$t('keywords.location')"
          :rules="[rules.requiredRule]"
          outlined
          :disabled="isEdit"
        />
        <v-autocomplete
          v-show="languages.length"
          v-model="form.language_code"
          :items="languages"
          :label="$t('keywords.language')"
          :rules="[rules.requiredRule]"
          outlined
          :disabled="isEdit"
        />
        <span class="text-h6 text--primary mb-2 d-block">
          {{ $t(langPath + 'title') }}
          <v-tooltip bottom>
            <span>
              {{ $t('keywords.hint') }}
            </span>
            <template #activator="{ on }">
              <v-icon
                class="ml-2 mb-1"
                v-on="on"
              >
                help_outlined
              </v-icon>
            </template>
          </v-tooltip>
        </span>
        <div
          v-if="!isEdit"
          class="mb-4 text-caption"
        >
          {{ $t('keywords.subtitle') }}
        </div>
        <v-text-field
          v-model="form.keyword"
          :label="$t('keywords.keyword')"
          outlined
          hide-details
          :rules="[rules.requiredRule]"
          required
        />
        <div
          id="keywordSuggestionChips"
          class="d-flex flex-row flex-wrap"
        >
          <template v-if="loadingKeywordSuggestions">
            <span class="d-flex flex-row flex-wrap mt-2 mr-2">
              <v-chip
                v-for="(_, index) of Array(Number(Math.floor(Math.random() * 9) + 1))"
                :key="'loadingChip-' + index"
                label
                outlined
                class="mr-2 mb-2"
              >
                {{ $t('keywords.keyword') }} {{ index + 1 }}
                <v-progress-linear
                  indeterminate
                  class="chipLoader"
                />
              </v-chip>
            </span>
          </template>
          <template v-else>
            <span
              v-for="suggestion of keywordSuggestions"
              :key="suggestion.keyword + suggestion.monthly_searches_avg"
              class="d-flex flex-row mt-2 mr-2"
            >
              <v-chip
                :value="suggestion.keyword"
                label
                :outlined="suggestion.keyword !== form.keyword"
                :color="suggestion.keyword === form.keyword ? 'primary' : ''"
                class="noRight"
                style="margin-right: -3px; z-index: 5"
                @click="form.keyword = suggestion.keyword"
              >
                {{ suggestion.keyword }}
              </v-chip>
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-chip
                    :value="suggestion"
                    label
                    class="noLeft"
                    :outlined="suggestion.keyword === form.keyword"
                    :color="suggestion.keyword === form.keyword ? 'primary' : ''"
                    style="margin-left: -3px; padding-right: 10px; z-index: 4"
                    v-on="on"
                    @click="form.keyword = suggestion.keyword"
                  >
                    {{ suggestion.monthly_searches_avg }}
                  </v-chip>
                </template>
                {{ $t('keywords.avgMonthlySearches') }}
              </v-tooltip>
            </span>
          </template>
        </div>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn
        id="confirmKeywordButton"
        depressed
        :loading="loading"
        color="accent"
        @click="confirmKeywordForm"
      >
        {{ $t(langPath + 'confirm') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
    import Vue, {PropType} from 'vue';
    import {KeywordSuggestionLite} from "@/model/DataForSeo";
    import {rules} from "@/utils/rules";
    import {LocationLanguages} from "@/enum/locations_languages";
    import {Firestore} from "@/plugins/Firestore";
    import {ArticleMetadata} from "@/model/Article";
    import {scrollIntoViewIfNotVisible} from "@/utils/scroll";

    export default Vue.extend({
        name: "KeywordInput",
        props: {
            isEdit: {
                type: Boolean,
                default: false
            },
            loading: {
                type: Boolean,
                default: false
            },
            queryParams: {
                type: Object as PropType<Record<string, string>>,
                default: () => ({})
            },
            articleInProgress: {
                type: ArticleMetadata,
                default: null
            }
        },
        data: () => ({
            form: {
                keyword: '',
                language_code: null as (string | null),
                location_code: null as (string | null)
            },
            loadingKeywordSuggestions: false,
            keywordSuggestions: [] as KeywordSuggestionLite[],
            rules: rules,
            languages: [],
        }),
        computed: {
            langPath: function () {
                if (this.isEdit) {
                    return 'keywords.edit.';
                }
                return 'keywords.';
            },
            locations: function () {
                return LocationLanguages.map(({location_code: value, location_name: text}) => ({
                    value: value.toString(10),
                    text
                }));
            }
        },
        watch: {
            'form.location_code': function () {
                if (this.form.location_code) {
                    const location = LocationLanguages.find(location => location.location_code.toString(10) === this.form.location_code);
                    if (location) {
                        this.languages = location.available_languages.reduce((result: any, {
                            language_code: value,
                            language_name: text
                        }) => {
                            result.push({value, text});
                            return result;
                        }, []);
                        const queryParams = this.queryParams as any;
                        if (queryParams.language_code) {
                            this.form.language_code = queryParams.language_code;
                        } else if (this.languages.length === 1) {
                            this.form.language_code = (this.languages[0] as any).value;
                        } else {
                            this.form.language_code = null;
                        }
                    } else {
                        this.languages = [];
                        this.form.language_code = null;
                    }
                }
            },
            'form.keyword': function (keyword) {
                if (this.form.location_code && this.form.keyword) {
                    this.loadingKeywordSuggestions = true;
                    Firestore.getKeywordSuggestions(this.form.location_code, keyword)
                        .then(suggestions => {
                            if (this.form.keyword === keyword) {
                                this.keywordSuggestions = suggestions;
                                this.loadingKeywordSuggestions = false;
                                this.$nextTick(() => {
                                    this.$nextTick(() => {
                                        const scrollOptions = {
                                            behavior: 'smooth',
                                            block: 'end'
                                        } as ScrollIntoViewOptions;
                                        scrollIntoViewIfNotVisible('#confirmKeywordButton', scrollOptions);
                                    });
                                });
                            }
                        });
                }
            },
            articleInProgress: {
                immediate: true,
                deep: true,
                handler: function () {
                    if (this.articleInProgress) {
                        this.form.keyword = this.articleInProgress.keyword;
                        this.form.location_code = this.articleInProgress.location_code;
                        this.form.language_code = this.articleInProgress.language_code;
                    }
                }
            }
        },
        mounted: function () {
            if (Object.keys(this.queryParams).length) {
                const queryParams = this.queryParams as any;
                this.form.keyword = queryParams.keyword;
                this.form.location_code = queryParams.location_code;
                this.form.language_code = queryParams.language_code;
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                this.$nextTick(() => {
                    if (this.languages.findIndex((language: any) => language.value === this.form.language_code) !== -1) {
                        this.$emit('input', this.form);
                    } else {
                        this.form.language_code = null;
                    }
                });
            }
        },
        methods: {
            confirmKeywordForm: function () {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (this.$refs.keywordForm?.validate()) {
                    this.$emit('input', this.form);
                }
            },
        }
    });
</script>

<style scoped lang="sass">
.chipLoader
    position: absolute
    left: -16px
    width: calc(100% + 32px)
    height: 32px !important
    opacity: 0.10

.noRight
    border-right: none !important

.noLeft
    border-left: none !important
</style>
