<template>
  <v-card
    :id="'subCard' + bgType"
    class="subscriptionCard d-flex flex-column justify-space-between"
    flat
    :ripple="false"
    rounded
    :hover="!readOnly"
    :width="width"
    :height="height"
    dark
    @click="(readOnly || (plan.id === 'free' && hasNoPlanAndFreeIsUsed)) ? () => {} : $emit('input')"
  >
    <v-skeleton-loader
      v-if="loading"
      type="card-heading@2, list-item-two-line, list-item-three-line"
      class="ma-1"
    />
    <div
      v-else
      style="z-index: 1"
      class="fill-height"
    >
      <v-card-title class="d-flex justify-center text-center text-h3 mt-2">
        {{ plan.name }}
      </v-card-title>
      <div class="d-flex flex-column justify-space-between fill-height pa-5">
        <div class="text-center">
          <div v-if="plan?.priceLabel || plan?.metadata?.priceLabel_cs">
            <h2 class="text-h4 font-weight-medium font-italic mt-3 mb-9">
              {{ plan?.priceLabel || plan.metadata['priceLabel_' + $i18n.locale] }}
            </h2>
          </div>
          <div v-else-if="plan.price">
            <h2 class="text-h2 font-weight-medium">
              {{ plan.price.display }}
            </h2>
            {{ $t('subscriptions.perMonth', [plan.price.currency.toUpperCase()]) }}
          </div>
          <div class="d-flex flex-column align-content-space-between">
            <div
              v-if="plan.metadata"
              class="caption my-3"
              style="font-size: 17px !important; font-weight: 600"
            >
              {{ plan.metadata['description_' + $i18n.locale] }}
            </div>
          </div>
          <v-list>
            <v-list-item
              v-for="(feature, index) of features"
              :key="index"
            >
              <v-list-item-avatar size="38">
                <v-icon
                  x-large
                  color="accent"
                >
                  check
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content style="font-size: 15px !important; font-weight: 600">
                {{ feature.label }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <div
            v-if="plan?.metadata?.footnote_cs"
            class="text-caption"
          >
            {{ plan.metadata['footnote_' + $i18n.locale] }}
          </div>
        </div>
        <div class="pb-8 mb-9">
          <div v-if="readOnly && plan.id !== 'free'">
            <v-btn
              v-if="active"
              depressed
              width="100%"
              readonly="true"
              light
              color="white"
              style="text-transform: uppercase"
              class="mb-9"
            >
              {{ $t('subscriptions.activePlan') }}
            </v-btn>
            <v-btn
              v-else-if="plan.name === 'BUSINESS'"
              outlined
              width="100%"
              class="mb-9"
              @click="$emit('individual')"
            >
              {{ $t('subscriptions.choosePlan') }}
              <v-icon class="ml-2">
                open_in_new
              </v-icon>
            </v-btn>
            <StripeManageButton
              v-else
              v-model="stripeLoading"
              :message="'subscriptions.changePlan'"
              color="white"
              class="mb-9"
              :disabled="!stripeLoading && stripeDisabled"
            />
          </div>
          <div v-if="!readOnly">
            <v-chip
              v-if="plan.id === 'free' && hasNoPlanAndFreeIsUsed"
              style="text-transform: uppercase"
              disabled
              light
              color="white"
              class="d-flex align-center justify-center font-weight-medium mb-9"
            >
              {{ $t('subscriptions.chosenPlan') }}
            </v-chip>
            <v-chip
              v-else-if="active"
              style="text-transform: uppercase"
              light
              color="white"
              class="d-flex align-center justify-center font-weight-medium mb-9"
            >
              {{ $t('subscriptions.chosenPlan') }}
            </v-chip>
            <v-chip
              v-else
              style="text-transform: uppercase"
              class="d-flex align-center justify-center font-weight-medium mb-9"
              color="white"
              outlined
            >
              {{ $t('subscriptions.choosePlan') }}
            </v-chip>
          </div>
        </div>
      </div>
    </div>
    <div :style="`position: absolute; width: ${width}px; height: ${height}px; z-index: 0`">
      <component
        :is="'SubCard' + bgType"
        style="position: absolute;"
        class="rounded"
      />
    </div>
  </v-card>
</template>

<script lang="ts">
    import Vue, {PropType} from 'vue';
    import StripeManageButton from "@/components/account/StripeManageButton.vue";
    // @ts-ignore
    import SubCard1 from '@/assets/vue-svg-loader/subCard/SubCard1.svg';
    // @ts-ignore
    import SubCard2 from '@/assets/vue-svg-loader/subCard/SubCard2.svg';
    // @ts-ignore
    import SubCard3 from '@/assets/vue-svg-loader/subCard/SubCard3.svg';
    // @ts-ignore
    import SubCard4 from '@/assets/vue-svg-loader/subCard/SubCard4.svg';
    import {EventBus} from "@/utils/EventBus";
    import {StripeProductLike, StripeRecurringProduct} from "@/model/Stripe";
    import {TranslateResult} from "vue-i18n";

    export default Vue.extend({
        name: 'SubscriptionCard',
        components: {StripeManageButton, SubCard1, SubCard2, SubCard3, SubCard4,},
        props: {
            active: {
                type: Boolean,
                default: false
            },
            initial: {
                type: Boolean,
                default: false
            },
            bgType: {
                type: Number,
                default: 0
            },
            loading: {
                type: Boolean,
                default: true
            },
            plan: {
                type: Object as PropType<StripeRecurringProduct | StripeProductLike>,
                default: () => ({
                    id: 'null',
                    name: '',
                    recurring: true,
                    price: '0'
                })
            },
            readOnly: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            width: 300,
            height: 500,
            stripeLoading: false,
            stripeDisabled: false
        }),
        computed: {
            hasNoPlanAndFreeIsUsed: function () {
                return !this.$store.getters.hasPlan && this.$store.getters.user.trialUsed;
            },
            features: function (): { label: TranslateResult }[] {
                const ret = [];
                // @ts-ignore
                if (this.plan?.price?.transform_quantity) {
                    ret.push({
                        // @ts-ignore
                        label: this.$t('subscriptions.upToInspirations', [this.plan?.price?.transform_quantity?.divide_by])
                    });
                }
                if (this.plan.id !== 'free') {
                    ret.push({label: this.$t('subscriptions.features.plagiarismCheck')});
                }
                return ret;
            }
        },
        watch: {
            stripeLoading() {
                EventBus.$emit("stripeLoading");
            }
        },
        created: function (): void {
            EventBus.$on("stripeLoading", () => {
                this.stripeDisabled = true;
            });
        }
    });
</script>

<style lang="sass">
.v-application .subscriptionCard
    .v-chip
        text-shadow: none
    display: flex !important
    justify-content: center !important

    &.onlyBorder.primary
        background-color: transparent !important

    &.v-card--link:focus:before
        opacity: 0 !important

    .v-list
        background-color: transparent !important

        .v-list-item
            padding: 0

    .v-skeleton-loader .v-skeleton-loader__card-heading, .v-skeleton-loader__list-item-two-line, .v-skeleton-loader__list-item-three-line
        background: transparent !important

.plan-price
    font-size: 25px

#transformEl
    transition-duration: 500ms
    transition-timing-function: ease-in-out
    transform-origin: left

#transformEl2
    transition-duration: 500ms
    transition-timing-function: ease-in-out
    transform-origin: left

#subCard1
    #transformEl
        transform-origin: bottom right

    #transformEl2
        transform-origin: bottom right

#subCard2
    #transformEl2
        transform-origin: right

#subCard3
    #transformEl
        transform-origin: top left

    #transformEl2
        transform-origin: top left

#subCard4
    #transformEl
        transform-origin: top right

    #transformEl2
        transform-origin: bottom left

#subCard1:hover
    #transformEl
        transform: scale(5)

    #transformEl2
        transform: scale(5)

#subCard2:hover
    #transformEl
        transform: scale(5)

    #transformEl2
        transform: scale(5)

#subCard3:hover
    #transformEl
        transform: scale(5)

    #transformEl2
        transform: scale(10)

#subCard4:hover
    #transformEl
        transform: scale(5)

    #transformEl2
        transform: scale(5)

#subCard4:hover
    #transformEl
        transform: scale(5)
</style>
