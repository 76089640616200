<template>
  <div
    id="slug"
    class="d-flex justify-center"
  >
    <div class="article-content px-2">
      <div>
        <span class="text-caption-primary">
          {{ $t('article.urlSuggestion.ideal') }}:&nbsp;
        </span>
        <span
          class="to-highlight-part"
          v-html="sanitizeHTML(highlightWords(suggestedUrl),{
            allowedAttributes: allowedAttributes
          })"
        />
        <div>
          <span class="text-caption-primary">
            {{ $t('article.urlSuggestion.yours') }}:&nbsp;
          </span>
          <span
            class="to-highlight-part"
            v-html="sanitizeHTML(highlightWords($store.getters.article.url),{
              allowedAttributes: allowedAttributes
            })"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

    import Vue from 'vue';
    import HighlightWordsMixin from "@/mixins/HighlightWordsMixin";
    import sanitizeHTML from 'sanitize-html';
    import {removeDiacritics} from '@/utils/string';

    export default Vue.extend({
        name: "ArticleURLSuggestion",
        mixins: [HighlightWordsMixin],
        data: () => ({
            sanitizeHTML: sanitizeHTML,
        }),
        computed: {
            baseUrl: function (): string {
                try {
                    const parsedUrl = new URL(this.$store.getters.article.url);
                    return `${parsedUrl.protocol}//${parsedUrl.host}`;
                } catch {
                    return '';
                }
            },
            allowedAttributes: function () {
                const allowedAttributes = sanitizeHTML.defaults.allowedAttributes;
                allowedAttributes['mark'] = ['class'];
                return allowedAttributes;
            },
            suggestedUrl: function (): string {
                return this.baseUrl + '/' + removeDiacritics(this.$store.getters.article.selectedKeyword.split(' ').join('-'));
            },
        },
    });
</script>

<style scoped>

</style>
