import { render, staticRenderFns } from "./ArticleExport.vue?vue&type=template&id=0b77f1e2&scoped=true"
import script from "./ArticleExport.vue?vue&type=script&lang=ts"
export * from "./ArticleExport.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.13_css-loader@6.11.0_webpack@5.98.0__ejs@3.1.1_da81010b62634e1624d752d96320e5d3/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b77f1e2",
  null
  
)

export default component.exports