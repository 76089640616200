import {FirebaseApp} from "@/plugins/Firebase";
import {getFunctions, httpsCallable} from "firebase/functions";
import {collection, doc, getFirestore, addDoc, onSnapshot} from "firebase/firestore";
import {Store} from "@/plugins/Store";
import {config} from "@/config";

const db = getFirestore(FirebaseApp);
const functions = getFunctions(FirebaseApp, config.region);

const Stripe = {
    createStripeCheckoutSession: function (priceId: string, userUid: string, oneTime = false): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            const data = {
                price: priceId,
                success_url: window.location.origin,
                cancel_url: window.location.origin,
                // @ts-ignore
                client_reference_id: window.Rewardful && window.Rewardful.referral || ('checkout_'+(new Date).getTime())
            };
            if (oneTime) {
                (data as any).mode = "payment";
            }
            addDoc(collection(doc(collection(db, 'users'), userUid), 'checkout_sessions'), data).then(docRef => {
                const snapshotListenerStop = onSnapshot(docRef, snapshot => {
                    const data = snapshot.data();
                    if (data && data.url) {
                        snapshotListenerStop();
                        resolve(data.url);
                    }
                });
            }).catch(reject);
        });
    },

    createCustomerPortalSession: function (): void {
        httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink')({
            returnUrl: window.location.href,
            // configuration: "bpc_1JSEAKHYgolSBA358VNoc2Hs", // Optional ID of a portal configuration: https://stripe.com/docs/api/customer_portal/configuration
        }).then(response => {
            window.location.assign((response as any).data.url);
        }).catch(() => {
            // Also try older Fn name // TODO solve this better
            httpsCallable(functions, 'ext-firestore-stripe-subscriptions-createPortalLink')({
                returnUrl: window.location.href,
                // configuration: "bpc_1JSEAKHYgolSBA358VNoc2Hs", // Optional ID of a portal configuration: https://stripe.com/docs/api/customer_portal/configuration
            }).then(response => {
                window.location.assign((response as any).data.url);
            }).catch(() => {
                Store.dispatch("handleApiError");
            });
        });
    }
};

export {Stripe};
