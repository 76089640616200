<template>
  <div>
    <div
      class="d-flex flex-wrap ma-5"
      style="column-gap: 20px; row-gap: 20px; max-width: calc(50em + 20px);"
    >
      <v-card
        v-for="route in articleCreationRoutes"
        :key="route.name"
        :class="{'pie-card' : !$vuetify.theme.dark}"
        :color="hasCreditFromSubscriptionOrExtras ? 'accent' : 'warning'"
        dark
        hover
        min-width="24.75em"
        style="flex: 1"
        width="100%"
        @click="createArticle(route.name)"
      >
        <v-card-title class="d-flex flex-row flex-nowrap align-start">
          <v-icon
            class="mr-2"
            large
          >
            add
          </v-icon>
          <span class="font-weight-bold">
            {{ route.text }}
            <br>
            <span
              class="caption"
              style="opacity: .9; font-size: .9rem !important;"
            >
              {{ route.description }}
            </span>
          </span>
        </v-card-title>
        <v-card-subtitle v-if="!hasPlanOrExtras">
          {{ $t('article.createAnotherInTrial') }}
        </v-card-subtitle>
      </v-card>
    </div>
    <v-card>
      <v-data-table
        :loading="loading"
        :items="articles"
        :headers="headers"
        :server-items-length="serverItemsLength"
        :options.sync="options"
        :footer-props="{ disablePagination : loading,
                         disableItemsPerPage : loading,
                         'items-per-page-options': itemsPerPageOptions }"
        :disable-sort="loading"
      >
        <template #item.selectedKeyword="{ item }">
          {{ articleKeyword(item) }}
        </template>
        <template #item.url="{ item, index }">
          <span v-if="item.url === ''">
            -
          </span>
          <span v-else>
            <!-- eslint-disable-next-line vue/max-attributes-per-line -->
            <a :href="item.url" target="_blank" rel="noopener noreferrer">{{ item.url }}</a>
          </span>
          <v-tooltip
            v-if="!item.organicPosition || !item.organicPosition.isProcessing"
            top
          >
            <template #activator="{ on }">
              <v-btn
                icon
                plain
                v-on="on"
              >
                <v-icon @click="openUrlDialog(index)">
                  edit
                </v-icon>
              </v-btn>
            </template>
            {{ $t('articleTable.url.tooltip.' + (item.url === '' ? 'add' : 'edit')) }}
          </v-tooltip>
        </template>
        <template #item.created_at="{ item }">
          {{ item.created_at | date }}
        </template>
        <template #header.organicPosition="{ header }">
          {{ header.text }}
          <v-tooltip top>
            <template #activator="{ on }">
              <v-icon
                small
                v-on="on"
              >
                help
              </v-icon>
            </template>
            {{ $t('articleTable.organicPosition.tooltip') }}
          </v-tooltip>
        </template>
        <template #item.organicPosition="{ item }">
          <ReloadableChip
            v-if="item.organicPosition"
            :value="item.organicPosition"
            :hint="$t('articleTable.reloadableChip.remeasure')"
            :color-fn="organicPositionColor"
            @reload="remeasureOrganicPosition(item)"
          />
          <span v-else>
            -
          </span>
        </template>
        <template #item.seoScore="{ item }">
          <v-chip
            :color="seoScoreColor(item.seoScore)"
            text-color="white"
          >
            {{ item.seoScore + ' %' }}
          </v-chip>
        </template>
        <template #header.url="{ header }">
          {{ header.text }}
          <v-tooltip top>
            <template #activator="{ on }">
              <v-icon
                small
                v-on="on"
              >
                help
              </v-icon>
            </template>
            {{ $t('articleTable.url.header.tooltip') }}
          </v-tooltip>
        </template>
        <template #header.domainSeoScore="{ header }">
          {{ header.text }}
          <v-tooltip top>
            <template #activator="{ on }">
              <v-icon
                small
                v-on="on"
              >
                help
              </v-icon>
            </template>
            {{ $t('articleTable.domainSeoScore.header.tooltip') }}
          </v-tooltip>
        </template>
        <template #item.domainSeoScore="{ item }">
          <v-tooltip
            v-if="item.url !== ''"
            top
          >
            <template #activator="{ on }">
              <v-btn
                icon
                outlined
                elevation="0"
                v-on="on"
                @click="openDomainSeoScore(item)"
              >
                <v-icon>
                  open_in_new
                </v-icon>
              </v-btn>
            </template>
            {{ $t('articleTable.domainSeoScore.tooltip') }}
          </v-tooltip>
          <span v-else>
            -
          </span>
        </template>
        <template #item.actions="{ item }">
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn
                icon
                outlined
                elevation="0"
                v-on="on"
                @click="openArticle(item)"
              >
                <v-icon>
                  {{ articleEditIcon(item) }}
                </v-icon>
              </v-btn>
            </template>
            {{ $t('steps.step') }}: {{ articleEditLabel(item) }}
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <UrlEditDialog
      v-model="urlDialog"
      :article="editingArticleIndex !== null ? articles[editingArticleIndex] : null"
      :loading="urlDialogLoading"
      :error="urlDialogError"
      @action="updateArticleUrl"
    />
  </div>
</template>

<script lang="ts">
    import Vue from "vue";
    import {Firestore} from "@/plugins/Firestore";
    import {Article, ArticlesPage, OrganicPosition} from "@/model/Article";
    import UserPlanMixin from "@/mixins/UserPlanMixin";
    import {FlashMessage} from "@/model/FlashMessage";
    import {TranslateResult} from "vue-i18n";
    import {DataTableHeader} from "vuetify/types";
    import firebase from "firebase/compat";
    import {Sort, SortString} from "@/model/Sort";
    import DocumentData = firebase.firestore.DocumentData;
    import UrlEditDialog from "@/components/article/table/components/UrlEditDialog.vue";
    import ReloadableChip from "@/components/article/table/components/ReloadableChip.vue";
    import {ExternalLinks} from "@/config/ExternalLinks";
    import {Functions} from "@/plugins/Functions";

    interface ArticleCreationRoute {
        name: string;
        text: string;
        description: string;
    }

    export default Vue.extend({
        name: 'ArticleTable',
        components: {UrlEditDialog, ReloadableChip},
        mixins: [UserPlanMixin],
        data: () => ({
            loading: false,
            articles: [] as Article[],

            options: {
                itemsPerPage: 10,
                sortBy: ['created_at'],
                sortDesc: [true],
                multiSort: false,
                mustSort: false
            },
            serverItemsLength: 0,
            initialLoad: true,
            articlesPage: null as ArticlesPage | null,
            itemsPerPageOptions: [10, 25, 50, 100],

            urlDialog: false,
            urlDialogLoading: false,
            urlDialogError: null as string | null,
            editingArticleIndex: null as number | null
        }),
        computed: {
            headers: function (): DataTableHeader[] {
                return [
                    {
                        text: this.$t('articleTable.selectedKeyword') as string,
                        value: 'selectedKeyword',
                        sortable: false,
                    },
                    {
                        text: this.$t('articleTable.url.header.text') as string,
                        value: 'url',
                        sortable: false,
                    },
                    {
                        text: this.$t('articleTable.createdAt') as string,
                        value: 'created_at',
                        align: 'center'
                    },
                    {
                        text: this.$t('articleTable.organicPosition.header') as string,
                        value: 'organicPosition',
                        align: 'center',
                        sortable: false
                    },
                    {
                        text: this.$t('articleTable.seoScore') as string,
                        value: 'seoScore',
                        align: 'center',
                        sortable: false
                    },
                    {
                        text: this.$t('articleTable.domainSeoScore.header.text') as string,
                        value: 'domainSeoScore',
                        align: 'center',
                        sortable: false
                    },
                    {
                        text: this.$t('articleTable.edit') as string,
                        value: 'actions',
                        sortable: false,
                        align: 'center'
                    }
                ];
            },
            articleCreationRoutes: function (): ArticleCreationRoute[] {
                return [
                    {
                        name: 'CreateArticle',
                        text: this.$t('article.create') as string,
                        description: this.$t('article.createDescription') as string
                    },
                    {
                        name: 'AnalyzeArticle',
                        text: this.$t('article.analyze.title') as string,
                        description: this.$t('article.analyze.description') as string
                    }
                ];
            }
        },
        watch: {
            options: {
                handler: function (newVal, oldVal) {
                    if (!this.initialLoad) {
                        if (newVal.itemsPerPage !== oldVal.itemsPerPage || newVal.sortBy !== oldVal.sortBy || newVal.sortDesc !== oldVal.sortDesc) {
                            // if sort or itemsPerPage changed, reset page to 1
                            this.getUserArticles();
                        } else if (newVal.page !== oldVal.page) {
                            if (newVal.page > oldVal.page) {
                                this.getUserArticles(true, this.articlesPage?.last);
                            } else {
                                this.getUserArticles(false, this.articlesPage?.first);
                            }
                        }
                    }
                },
                deep: true
            }
        },
        created: function () {
            this.getUserArticles();
        },
        methods: {
            getUserArticles: function (nextPage = true, article?: DocumentData): void {
                this.loading = true;
                Firestore.getUserArticlesCount()
                    .then(count => {
                        this.serverItemsLength = count;
                        const sort = this.options.sortBy[0] ? {
                            field: this.options.sortBy[0],
                            sortString: this.options.sortDesc[0] ? SortString.DESC : SortString.ASC
                        } as Sort : undefined;
                        return nextPage ? Firestore.getUserArticlesNextPage(this.options.itemsPerPage, sort, article)
                            : Firestore.getUserArticlesPreviousPage(this.options.itemsPerPage, sort, article);
                    })
                    .then(articlesPage => {
                        this.articlesPage = articlesPage;
                        this.articles = articlesPage.items;
                    })
                    .finally(() => {
                        this.loading = false;
                        if (this.initialLoad) {
                            this.initialLoad = false;
                        }
                    });
            },

            createArticle: function (name: string): void {
                if (this.hasCreditFromSubscriptionOrExtras) {
                    this.$router.push({ name: name });
                } else {
                    this.$store.dispatch('displayFlashMessage', {
                        type: 'error',
                        content: 'subscriptions.noInspirationsLeftThisPeriodUpgradeToContinue'
                    } as FlashMessage);
                    this.$router.push('/account');
                }
            },
            openArticle: function (article: Article) {
                this.$router.push('/article/' + article.id);
            },

            articleKeyword: function (article: Article): string {
                return article.selectedKeyword === '' ? article.keyword : article.selectedKeyword;
            },
            articleEditIcon: function (article: Article): string {
                if (article.selectedKeyword) {
                    return 'description';
                } else if (article.language_code) {
                    return 'toc';
                } else {
                    return 'list_alt';
                }
            },
            articleEditLabel: function (article: Article): TranslateResult {
                if (article.selectedKeyword) {
                    return this.$t('steps.writeArticle');
                } else if (article.language_code) {
                    return this.$t('steps.selectKeyword');
                } else {
                    return this.$t('steps.initForm');
                }
            },

            openUrlDialog: function (index: number) {
                this.editingArticleIndex = index;
                this.urlDialogError = null;
                this.$nextTick(() => {
                    this.urlDialog = true;
                });
            },
            updateArticleUrl: function (url: string) {
                const index = this.editingArticleIndex as number;
                // new || deleted || changed
                if (this.articles[index].url === '' || url === '' || this.articles[index].url !== url) {
                    this.urlDialogLoading = true;
                    this.urlDialogError = null;
                    Firestore.updateUserArticleFields(this.articles[index], {
                        url: url,
                        organicPosition: null
                    } as Partial<Article>)
                        .then(() => {
                            this.urlDialog = false;
                            this.urlDialogLoading = false;
                            if (url !== '') {
                                this.getOrganicPosition(this.articles[index]);
                            }
                            // Notify user about success
                            this.$store.dispatch('displayFlashMessage', {
                                content: 'article.updated',
                                type: 'success'
                            } as FlashMessage);
                        })
                        .catch(() => {
                            this.urlDialogLoading = false;
                            this.urlDialogError = this.$t('articleTable.url.dialog.errors.couldNotSave') as string;
                        });
                } else {
                    this.urlDialog = false;
                }
            },
            remeasureOrganicPosition: function (article: Article) {
                this.getOrganicPosition(article);
            },
            getOrganicPosition: function (article: Article) {
                // update ui
                article.organicPosition = {
                    position: null,
                    error: null,
                    isProcessing: true,
                    timestamp: Date.now()
                } as OrganicPosition;

                Functions.D4SGetOrganicPosition({
                    article_id: article.id as string,
                })
                    .then((res) => {
                        article.organicPosition = res;
                    })
                    .catch((error) => {
                        window.console.error(error);
                        article.organicPosition = {
                            position: null,
                            error: 'articleTable.reloadableChip.errors.unexpectedError',
                            isProcessing: false,
                            timestamp: Date.now()
                        } as OrganicPosition;
                    });
            },

            organicPositionColor: function (value: number): string {
                return value < 11 ? 'accent' : value < 21 ? 'warning' : 'error';
            },
            seoScoreColor: function (value: number): string {
                return value > 66 ? 'accent' : value > 33 ? 'warning' : 'error';
            },

            openDomainSeoScore: function (article: Article) {
                const url = new URL(article.url);
                window.open(ExternalLinks.domainSeoTest + url.hostname, '_blank');
            }
        }
    });

    // TODO: if order on selectedKeyword (does not work as user would expect) - firestore uses lexicographical order
    // - would need another field (on create - copy keyword / on update - copy selectedKeyword to keyword)
    // - existing articles would need to be updated
    // - lexicographical order (asc: V > i > v)
</script>

<style scoped lang="sass">

</style>
