<template>
  <div :class="$attrs">
    <v-input
      v-model="localValue"
      :hide-details="hideDetails"
      class="one-line-input"
      :class="{
        'light': !isDark,
        'dark': isDark
      }"
      :rules="rules"
    >
      <template #prepend>
        <slot name="prepend" />
      </template>
      <ckeditor
        ref="editor"
        v-model="localValue"
        :editor="editor"
        :config="config"
        :words-to-highlight="wordsToHighlight"
        hide-toolbar
        :multiline="false"
        class="flex-grow-1 flex-shrink-1 one-line-editor"
        @blur="scrollToStart"
      />
      <template #append>
        <slot name="append" />
      </template>
    </v-input>
  </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import { InlineEditor } from '@ckeditor/ckeditor5-editor-inline';
    import { Essentials } from '@ckeditor/ckeditor5-essentials';
    import { Highlight } from '@ckeditor/ckeditor5-highlight';
    import { Bold } from '@ckeditor/ckeditor5-basic-styles';
    import { Paragraph } from '@ckeditor/ckeditor5-paragraph';

    export default Vue.extend({
        name: "OneLineEditor",
        inject: ["theme"],
        model: {
            prop: 'value',
            event: 'input'
        },
        props: {
            value: {
                type: String,
                default: () => ''
            },
            placeholder: {
                type: String,
                default: null
            },
            wordsToHighlight: {
                type: Object as PropType<{ [key: string]: string[] }>,
                default: () => ({})
            },
            rules: {
                type: Array as PropType<any[]>,
                default: () => []
            },
            hideDetails: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            editor: InlineEditor,
        }),
        computed: {
            config: function (): any {
                return {
                    placeholder: this.placeholder,
                    toolbar: [],
                    plugins: [
                        Bold,
                        Paragraph,
                        Highlight,
                        Essentials,
                    ],
                };
            },
            localValue: {
                get: function(): string {
                    return this.value;
                },
                set: function(val: string) {
                    this.$emit('input', val);
                }
            },
            isDark: function (): boolean {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                return this.theme.isDark;
            },
        },
        methods: {
            scrollToStart() {
                if (this.$refs.editor) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    const paragraph = this.$refs.editor.$el.childNodes[0];

                    if (paragraph) {
                        paragraph.scrollLeft = 0;
                    }
                }
            }
        }
    });
</script>

<style lang="sass">
.one-line-input
    > .v-input__append-outer
        margin: 0 !important

    > .v-input__control
        > .v-input__slot 
            margin-bottom: 4px !important
            max-width: 100%
        
        > .v-messages
            margin-left: 12px
            overflow: hidden

    &.error--text
        > .v-input__control > .v-input__slot > .one-line-editor
            box-shadow: inset 0 0 0 2px var(--v-error-base) !important
            border-width: 0 !important
            padding: 0 13px !important

            &.ck-focused
                box-shadow: inset 0 0 0 2px var(--v-error-base) !important

            p
                padding: 9px 0 9px !important

        &.light
            color: black !important

        &.dark
            color: white !important

    &:not(.error--text) > .v-input__control > .v-input__slot > .one-line-editor
        &.ck-focused
            box-shadow: inset 0 0 0 2px var(--v-primary-base) !important
            border-width: 0 !important
            padding: 0 13px !important


    &.light > .v-input__control > .v-input__slot > .one-line-editor
        background-color: rgba(0, 0, 0, 0.06)
        border-collapse: collapse
        border-style: solid
        border-width: 1px
        border-color: rgba(0, 0, 0, 0.36)

        &:hover:not(.ck-focused)
            background-color: rgba(0, 0, 0, 0.12)
            border-color: currentColor
        
        > .ck-placeholder::before
            color: rgba(0, 0, 0, 0.36)

    &.dark > .v-input__control > .v-input__slot > .one-line-editor
        background-color: rgba(255, 255, 255, 0.08)
        border-collapse: collapse
        border-style: solid
        border-width: 1px
        border-color: rgba(255, 255, 255, 0.24)

        &:hover:not(.ck-focused)
            background-color: rgba(255, 255, 255, 0.12)
            border-color: currentColor
        
        > .ck-placeholder::before
            color: rgba(255, 255, 255, 0.48)

.one-line-editor
    border-radius: 4px !important
    min-height: 40px !important
    box-shadow: none !important
    padding: 0 12px !important

    p
        margin: 0 !important
        padding: 8px 0 8px
        overflow-x: auto
        white-space: nowrap
        -ms-overflow-style: none
        scrollbar-width: none
        width: 100%

        ::-webkit-scrollbar
            display: none
    
    &.ck-focused p
        padding: 9px 0 9px !important

</style>
