<template>
  <div class="d-flex justify-center">
    <v-expand-x-transition>
      <div
        v-show="showInspirations"
        class="article-inspiration"
      >
        <ArticleContentInspirations
          v-for="tag of ['h', 'p']"
          :key="part + tag"
          :inspirations="inspirations"
          :loading-inspirations="loadingInspirations"
          :part="part + tag"
          :words-to-highlight="wordsToHighlight"
        />
      </div>
    </v-expand-x-transition>
    <div
      :id="part"
      class="article-content"
    >
      <transition-group
        name="card-list"
        mode="out-in"
      >
        <v-btn
          v-if="value.length === 0 && !$store.getters.articleAiLoading.loadingRatioContent"
          key="addFirstPartButton"
          class="multi-line-button mt-12"
          outlined
          block
          color="primary"
          @click="addSubpart(0)"
        >
          <v-icon class="mr-2">
            playlist_add
          </v-icon>
          {{ $t('article.addSubpart', [$t('article.label.' + part + 'h')]) }}
        </v-btn>
        <div
          v-for="({uid}, index) of value"
          :key="uid"
          class="card-list-item mt-14"
        >
          <ArticleContentPartEditor
            :part="part"
            :index="index"
            :words-to-highlight="wordsToHighlight"
            :show-inspirations="showInspirations"
            :has-inspirations="hasInspirations"
            @toggle-inspirations="toggleInspirations"
            @remove="onSubPartRemove(index)"
          >
            <template #paragraphToolbarAppend>
              <VerticalDivider />
              <v-chip
                v-if="part !== 'h1'"
                small
                :disabled="$store.getters.articleAiLoading.loadingRatioContent > 0"
                outlined
                class="chip-text-primary"
                @click="addSubpart(index)"
              >
                <v-icon class="mr-2">
                  playlist_add
                </v-icon>
                {{ $t('article.addAnotherSubpart', [$t('article.label.' + part + 'h')]) }}
              </v-chip>
            </template>
          </ArticleContentPartEditor>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script lang="ts">
    import Vue, {PropType} from 'vue';
    import {ArticleInspirations, ArticlePartContent} from "@/model/Article";
    import ArticleContentPartEditor from "@/components/article/ArticleContentPartEditor.vue";
    import ArticleContentInspirations from "@/components/article/ArticleContentInspirations.vue";
    import VerticalDivider from "@/components/VerticalDivider.vue";
    import EventsListenerMixin from "@/mixins/EventsListenerMixin";

    export default Vue.extend({
        name: 'ArticleContentPartEditors',
        components: {VerticalDivider, ArticleContentInspirations, ArticleContentPartEditor},
        mixins: [EventsListenerMixin],
        props: {
            part: {
                type: String,
                default: () => ''
            },
            wordsToHighlight: {
                type: Object as PropType<{ [key: string]: string[] }>,
                default: () => ({})
            },
            inspirations: {
                type: Object as PropType<ArticleInspirations>,
                default: () => ({})
            },
            loadingInspirations: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            showInspirations: false,
            dialog: false,
            dialogIndex: 0,
        }),
        computed: {
            events: function () {
                return {
                    [this.part + '-inspiration-expand']: this.toggleInspirations,
                };
            },
            articleId: function (): string {
                return this.$route.params.articleId as string;
            },
            value: function (): ArticlePartContent[] {
                return this.$store.getters.article[this.part];
            },
            hasInspirations: function (): boolean {
                return Object.values(this.inspirations)
                    .some(site => {
                        return (site as any)[this.part + 'h']?.length > 0
                            || (site as any)[this.part + 'p']?.length > 0;
                    });
            }
        },
        methods: {
            addSubpart: function (index: number): void {
                this.$store.commit('insertArticleContentSubPart', {
                    contentPart: this.part,
                    index: index + 1,
                    aiLoading: false
                });
            },
            onSubPartRemove: function (index: number) {
                this.$store.commit('removeArticleContentSubPart', {
                    contentPart: this.part,
                    index: index,
                });
            },
            toggleInspirations: function () {
                this.showInspirations = !this.showInspirations;
            }
        }
    });
</script>

<style lang="sass" scoped>
</style>
