<template>
  <div
    id="answers"
    class="d-flex justify-center my-15"
  >
    <div class="article-content">
      <transition-group
        name="card-list"
        mode="out-in"
      >
        <v-btn
          v-if="questions.length === 0 && !$store.getters.articleAiLoading.loadingRatioContent"
          key="addFirstPartButton"
          class="multi-line-button mt-12"
          outlined
          block
          color="primary"
          @click="addQuestion(0)"
        >
          <v-icon class="mr-2">
            playlist_add
          </v-icon>
          {{ $t('article.addQuestion') }}
        </v-btn>
        <div
          v-for="({uid}, index) in questions"
          :key="uid"
          class="card-list-item mt-14"
        >
          <ArticleQuestionsEditor
            :index="index"
            :words-to-highlight="wordsToHighlight"
            @add-question="addQuestion(index)"
          />
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script lang="ts">
    import Vue, {PropType} from 'vue';
    import {ArticleQuestion} from "@/model/Article";
    import ArticleQuestionsEditor from "@/components/article/ArticleQuestionsEditor.vue";

    export default Vue.extend({
        name: 'ArticleQuestionsEditors',
        components: {ArticleQuestionsEditor},
        props: {
            wordsToHighlight: {
                type: Object as PropType<{ [key: string]: string[] }>,
                default: () => ({})
            },
        },
        computed: {
            questions: function (): ArticleQuestion[] {
                return this.$store.getters.article.questions;
            },
        },
        methods: {
            addQuestion: function (index: number): void {
                this.$store.commit('insertArticleQuestion', {
                    index: index + 1,
                    aiLoading: false
                });
            },
        },
    });
</script>

<style scoped lang="sass">
</style>
