<template>
  <div class="flashMessages">
    <v-alert
      v-for="flashMessage of $store.getters.flashMessages"
      :key="flashMessage.type"
      dismissible
      :type="flashMessage.type"
      :color="flashMessage.type === 'success' ? 'accent' : flashMessage.type"
      @input="onDismiss(flashMessage.type)"
    >
      {{ $t(flashMessage.content) }}
    </v-alert>
  </div>
</template>

<script lang="ts">
    import Vue from "vue";

    export default Vue.extend({
        name: "FlashMessages",
        methods: {
            onDismiss: function (type: string): void {
                this.$store.dispatch('clearFlashMessage', type);
            }
        }
    });
</script>

<style scoped lang="sass">
.flashMessages
    position: fixed
    z-index: 10
    right: 1em
</style>
