<template>
  <div style="width: 100%">
    <v-dialog
      v-model="preview"
      max-width="90vw"
    >
      <template #activator="{ on }">
        <v-btn
          depressed
          color="primary"
          class="multi-line-button"
          :block="block"
          v-on="on"
        >
          <v-spacer />
          {{ $t('article.preview') }}
          <v-spacer />
          <v-icon class="ml-2">
            open_in_new
          </v-icon>
        </v-btn>
      </template>
      <v-card
        class="pa-2"
        style="max-height: 800px; overflow-y: hidden"
      >
        <v-card
          max-height="785"
          flat
          class="mb-4"
          style="overflow-y: scroll"
        >
          <v-card-text
            class="scrollable-content mt-4 article-preview-in-app"
            v-html="sanitizeHTML(getContentToExport(article, DownloadType.HTML)[0], {
              allowedTags: sanitizeHTML.defaults.allowedTags.concat([ 'title' ]),
              allowedAttributes: {
                ...sanitizeHTML.defaults.allowedAttributes,
                'h1': [ 'id' ],
                'h2': [ 'id' ],
                'h3': [ 'id' ],
                'h4': [ 'id' ],
                'h5': [ 'id' ],
                'h6': [ 'id' ]
              }
            })"
          />
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              @click="preview = false"
            >
              {{ $t('close') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
    import Vue from "vue";
    import {DownloadType} from "@/enum/download_type";
    import sanitizeHTML from "sanitize-html";
    import {getContentToExport} from "@/service/articleExport";
    import {Article} from "@/model/Article";

    export default Vue.extend({
        name: "ArticlePreview",
        props: {
            block: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            preview: false,
            getContentToExport: getContentToExport,
            DownloadType: DownloadType,
            sanitizeHTML: sanitizeHTML,
        }),
        computed: {
            article: function (): Article {
                return this.$store.getters.article;
            },
        },
    });
</script>

<style lang="sass">
.article-preview-in-app
    & > p
        margin: 1.5em 0 3em

    ul
        margin-top: 1em
        margin-bottom: 3em

    h4, h5, h6
        font-size: 1.17em
// Same as Chrome's h3
</style>
