<template>
  <div>
    <v-btn-toggle
      v-if="$vuetify.breakpoint.mdAndUp"
      v-model="$i18n.locale"
      class="mx-2"
      mandatory
      group
    >
      <v-btn
        v-for="langName of $i18n.availableLocales"
        :key="langName"
        :value="langName"
      >
        {{ langName.toUpperCase() }}
      </v-btn>
    </v-btn-toggle>
    <v-select
      v-else
      v-model="$i18n.locale"
      dense
      hide-details
      outlined
      :items="$i18n.availableLocales"
      style="max-width: 5em"
    >
      <template #selection="{ item }">
        {{ item.toUpperCase() }}
      </template>
      <template #item="{ item }">
        {{ item.toUpperCase() }}
      </template>
    </v-select>
  </div>
</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        name: 'LanguageSelector',
        watch: {
            '$i18n.locale': function (newLocale): void {
                // @ts-ignore
                this.$moment.locale(newLocale);
                this.$vuetify.lang.current = newLocale;
            }
        },
        created: function () {
            // @ts-ignore
            this.$moment.locale(this.$i18n.locale);
            this.$vuetify.lang.current = this.$i18n.locale;
        }
    });

</script>

<style scoped>

</style>
