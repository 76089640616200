<template>
  <div
    v-if="$vuetify.breakpoint.mdAndUp"
    class="d-flex align-center"
    :class="{
      'mx-2': $vuetify.breakpoint.xl
    }"
  >
    <a
      href="https://calendly.com/seowritee"
      target="_blank"
      class="text-decoration-none"
    >
      <v-tooltip
        nudge-bottom="10px"
        bottom
      >
        <template #activator="{ on }">
          <v-btn
            color="accent"
            depressed
            exact-active-class="no-highlight"
            :small="$vuetify.breakpoint.smAndDown"
            v-on="on"
          >
            <v-icon>
              video_call
            </v-icon>
            <span
              v-show="$vuetify.breakpoint.xl && $route.name !== 'Article'"
              class="ml-2"
            >
              {{ $t('topBar.promo') }}
            </span>
          </v-btn>
        </template>
        <span>
          {{ $t('topBar.promo') }}
          <v-icon class="ml-2">open_in_new</v-icon>
        </span>
      </v-tooltip>
    </a>
    <a
      v-for="link of links"
      :key="link.label"
      :href="link.link"
      target="_blank"
      class="text-decoration-none"
    >
      <v-tooltip
        nudge-bottom="10px"
        bottom
      >
        <template #activator="{ on }">
          <v-btn
            text
            depressed
            exact-active-class="no-highlight"
            :small="$vuetify.breakpoint.smAndDown"
            v-on="on"
          >
            <v-icon>
              {{ link.icon }}
            </v-icon>
            <span
              v-show="$vuetify.breakpoint.xl"
              class="ml-2"
            >
              {{ $t(link.label) }}
            </span>
          </v-btn>
        </template>
        <span>
          {{ $t(link.label) }}
          <v-icon class="ml-2">open_in_new</v-icon>
        </span>
      </v-tooltip>
    </a>
  </div>
</template>

<script lang="ts">
    import Vue from "vue";
    import {ExternalLinks} from "@/config/ExternalLinks";

    interface link {
        link: string,
        label: string,
        icon: string
    }

    export default Vue.extend({
        name: "TopBarLinks",
        data: () => ({
            links: [{
                link: ExternalLinks.manual,
                label: 'topBar.manual',
                icon: 'help_outline'
            }, {
                link: ExternalLinks.blog,
                label: 'topBar.blog',
                icon: 'menu_book'
            }] as link[]
        })
    });
</script>

<style scoped>

</style>
