import { render, staticRenderFns } from "./ArticleCrossroadView.vue?vue&type=template&id=75f94835&scoped=true"
import script from "./ArticleCrossroadView.vue?vue&type=script&lang=ts"
export * from "./ArticleCrossroadView.vue?vue&type=script&lang=ts"
import style0 from "./ArticleCrossroadView.vue?vue&type=style&index=0&id=75f94835&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.7_css-loader@6.9.0_vue-template-compiler@2.7.16_webpack@5.89.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75f94835",
  null
  
)

export default component.exports