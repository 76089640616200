import {Firestore} from "@/plugins/Firestore";
import {OpenAICompletionData, OpenAiCompletionType} from "@/model/OpenAI";
import {Unsubscribe} from "firebase/firestore";
import {Store} from "@/plugins/Store";
import {ArticleContent, ArticleContentKey, KeywordForAi} from "@/model/Article";
import {reformatOpenAiResponseToHtml} from "@/utils/string";
import {KeywordType} from "@/enum/keyword_type";

const watchers: Record<string, Unsubscribe> = {};

export function watchForAi(): Promise<void[]> {
    const promises = [];

    if (!Store.getters.article.aiPrompt && !Store.getters.article.noAiPrompt) {
        return Promise.resolve([]);
    }

    const article = Store.getters.article;
    if (!article.metaTitle.length) {
        watchForAiMeta();
    }
    for (const aiPart of <(OpenAiCompletionType & keyof ArticleContent)[]>['h1', 'h2', 'h3']) {
        promises.push(Firestore.getArticleOpenAIState(Store.getters.article.id, aiPart)
            .then((response: OpenAICompletionData | null) => {
                if (response) {
                    let index = 0;
                    while (index < response.count) {
                        if (index + 1 > article[aiPart].length) {
                            Store.commit('insertArticleContentSubPart', {
                                contentPart: aiPart,
                                index: index,
                                aiLoading: true,
                            });
                        }
                        if (!article[aiPart][index].h.length) {
                            watchForAiContent(aiPart, index);
                        }
                        index++;
                    }
                }
            }));
    }
    promises.push(Firestore.getArticleOpenAIState(Store.getters.article.id, 'question')
        .then((response: OpenAICompletionData | null) => {
            if (response) {
                let index = 0;
                while (index < response.count) {
                    if (index + 1 > article.questions.length) {
                        Store.commit('insertArticleQuestion', {
                            index: index + 1,
                            aiLoading: true
                        });
                    }
                    index++;
                }

                const questionsForAi = article.keywordsForAi.filter((keyword: KeywordForAi) => keyword.type === KeywordType.QUESTION)
                    .map((keyword: KeywordForAi) => keyword.keyword);
                for (const [index, articleQuestion] of article.questions.entries()) {
                    // TODO use uid or some better id than 'question' itself
                    if (!articleQuestion.answer.length && questionsForAi.includes(articleQuestion.question)) {
                        watchForAiQuestion(index, articleQuestion.question);
                    }
                }
            }
        }));

    return Promise.all(promises);
}

function setMetaLoading(value: boolean) {
    for (const metaPart of ['metaTitle', 'metaDescription']) {
        Store.commit('setArticleMetaAiLoading', {
            metaPart: metaPart,
            value: value
        });
    }
}

function setContentLoading(contentPart: ArticleContentKey, index: number, value: boolean) {
    for (const contentKey of ['h', 'p']) {
        Store.commit('setArticleContentAiLoading', {
            contentPart: contentPart,
            index: index,
            contentKey: contentKey,
            value: value
        });
    }
}

function setQuestionLoading(index: number, value: boolean) {
    for (const questionKey of ['question', 'answer']) {
        Store.commit('setArticleQuestionAiLoading', {
            index: index,
            questionKey: questionKey,
            value: value
        });
    }
}

function watchForAiMeta() {
    setMetaLoading(true);
    watchers.meta = Firestore.createArticleOpenAIWatcher(Store.getters.article.id, 'meta', (data: OpenAICompletionData) => {
        if (data.state === 'failed' || data.count === 0) {
            setMetaLoading(false);
        } else {
            if (data.touch > 0) {
                setAiMeta().then(() => {
                    setMetaLoading(false);
                });
            }
        }
    });
}

// TODO solve index change if user added content in the meantime - for now button is disabled. Maybe use UID in parts
function watchForAiContent(aiPart: OpenAiCompletionType & ArticleContentKey, index: number): void {
    setContentLoading(aiPart, index, true);
    watchers[aiPart + '-' + index] = Firestore.createArticleOpenAIWatcher(Store.getters.article.id, aiPart, (data: OpenAICompletionData) => {
        if (data.state === 'failed' || data.count === 0) {
            setContentLoading(aiPart, index, false);
        } else {
            if (data.touch > 0) {
                setAiContent(aiPart, index).then(() => {
                    setContentLoading(aiPart, index, false);
                }).catch(() => null);
            }
        }
    });
}

// TODO solve index change if user added content in the meantime - for now button is disabled. Maybe use UID in parts
function watchForAiQuestion(index: number, question: string) {
    setQuestionLoading(index, true);
    watchers['q-' + index] = Firestore.createArticleOpenAIWatcher(Store.getters.article.id, 'question', (data: OpenAICompletionData) => {
        if (data.state === 'failed' || data.count === 0) {
            setQuestionLoading(index, false);
        } else {
            if (data.touch > 0) {
                setAiQuestion(index, question).then(() => {
                    setQuestionLoading(index, false);
                }).catch(() => null);
            }
        }
    });
}

function setAiMeta() {
    return Firestore.getArticleOpenAIValues(Store.getters.article.id, 'meta')
        .then((content) => {
            if (content.length && content[0]) {
                for (const metaPart of ['metaTitle', 'metaDescription']) {
                    Store.commit('setArticleMeta', {
                        metaPart: metaPart,
                        value: metaPart === 'metaTitle' ? content[0].value1 : content[0].value2
                    });
                }
            }
        });
}

function setAiContent(aiPart: OpenAiCompletionType & ArticleContentKey, index: number): Promise<void> {
    return new Promise((resolve, reject) => {
        Firestore.getArticleOpenAIValues(Store.getters.article.id, aiPart)
            .then((content) => {
                if (content.length && content[index] && content[index].value1) {
                    for (const contentKey of ['h', 'p']) {
                        Store.commit('setArticleContent', {
                            contentPart: aiPart,
                            index: index,
                            contentKey: contentKey,
                            value: reformatOpenAiResponseToHtml(contentKey === 'h' ? content[index].value1 : content[index].value2)
                        });
                    }
                    resolve();
                    return;
                }
                reject();
            });
    });
}

function setAiQuestion(index: number, question: string): Promise<void> {
    return new Promise((resolve, reject) => {
        Firestore.getArticleOpenAIValues(Store.getters.article.id, 'question')
            .then((content) => {
                for (const aiQuestion of content) {
                    if (aiQuestion.keyword === question || /* for backward compatibility - drop in the future */ aiQuestion.value1 === question) {
                        for (const questionKey of ['question', 'answer']) {
                            Store.commit('setArticleQuestion', {
                                index: index,
                                questionKey: questionKey,
                                value: reformatOpenAiResponseToHtml(questionKey === 'question' ? aiQuestion.keyword : aiQuestion.value2)
                            });
                        }
                        resolve();
                        return;
                    }
                }
                reject();
            });
    });
}

export function clearAiContentWatchers(): void {
    for (const unsubscribe of Object.values(watchers)) {
        unsubscribe();
    }
}
