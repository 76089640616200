import {Density} from "@/enum/density";
import {keywordDensityStatus, keywordDensityThresholds} from "@/utils/ArticleComputed";
import {permute, stripTagsAndEntities} from "@/utils/string";
import {SeoScoreMetric, SeoScoreMetricName} from "@/model/SeoScore";
import {EventBus} from "@/utils/EventBus";
import {scrollIntoViewIfNotVisible} from "@/utils/scroll";

function richContentIncludesPermutation(content: string, searchList: Array<string>): boolean {
    const strippedContent = stripTagsAndEntities(content).toLowerCase();
    return searchList.some(search => permute(search.toLowerCase())
        .some((variant: string) => strippedContent.includes(variant))
    );
}

const openAndScrollToKeywordsInput = () => {
    EventBus.$emit('keywords-expand');
    setTimeout(() => {
        scrollIntoViewIfNotVisible('#keywordInputListAfter');
    }, 300);
};

const openKeywordsAnalysis = () => {
    EventBus.$emit('keywords-selection-expand');
};

/**
 * @src https://docs.google.com/spreadsheets/d/1OqESKAo-okjUFg916kevKUlAl67lA8ih4TZnAbcl_gw/edit#gid=0
 */
export const seoScoreMetrics: Record<SeoScoreMetricName, SeoScoreMetric> = {
    keywordVariants: {
        getStatus: function (value) {
            value = value as number;
            return value >= 2 ? 'GREEN' : value > 0 ? 'YELLOW' : 'RED';
        },
        getClickAction: () => {
            openAndScrollToKeywordsInput();
            openKeywordsAnalysis();
        },
    },
    keywordLength: {
        getStatus: function (value) {
            value = value as number;
            return value > 2 ? 'GREEN' : value > 1 ? 'YELLOW' : 'RED';
        }
    },
    keywordInSlug: {
        getStatus: function (value) {
            return value ? 'GREEN' : 'RED';
        },
        domSelector: '#slug'
    },
    imgAltIncludesKeyword: {
        getStatus: function (value) {
            return value ? 'GREEN' : 'RED';
        },
        domSelector: '#images'
    },
    titleIncludesKeyword: {
        getStatus: function (value, referenceValue) {
            return richContentIncludesPermutation(value as string, referenceValue as Array<string>) ? 'GREEN' : 'RED';
        },
        domSelector: '#metaTitle'
    },
    titleLength: {
        getStatus: function (value, referenceValue) {
            return (value as number) <= (referenceValue as number) ? 'GREEN' : 'RED';
        },
        domSelector: '#metaTitle'
    },
    descriptionIncludesKeyword: {
        getStatus: function (value, referenceValue) {
            return richContentIncludesPermutation(value as string, referenceValue as Array<string>) ? 'GREEN' : 'RED';
        },
        domSelector: '#metaDescription'
    },
    descriptionLength: {
        getStatus: function (value, referenceValue) {
            return (value as number) <= (referenceValue as number) ? 'GREEN' : 'RED';
        },
        domSelector: '#metaDescription'
    },
    keywordDensity: {
        getStatus: function (density) {
            const densityStatus = keywordDensityStatus(density as number);
            return densityStatus === Density.OK ? 'GREEN' : densityStatus === Density.LOW ? 'YELLOW' : 'RED';
        },
        getTrParams: (density) => [
            Math.round(density as number * 100) / 100,
            keywordDensityThresholds[Density.LOW],
            keywordDensityThresholds[Density.HIGH]
        ],
        getClickAction: openAndScrollToKeywordsInput,
    },
    serpIsComplete: {
        getStatus: function (value) {
            return value ? 'GREEN' : 'RED';
        },
        domSelector: '#metaTitle'
    },
    avgWordCountOfTopTen: {
        getStatus: function (value, referenceValue) {
            return (value as number) >= (referenceValue as number) ? 'GREEN' : 'RED';
        }
    },
    longestWordCountOfTopTen: {
        getStatus: function (value, referenceValue) {
            return (value as number) >= (referenceValue as number) ? 'GREEN' : 'RED';
        }
    },
    keywordInIntroduction: {
        getStatus: function (value, referenceValue) {
            return richContentIncludesPermutation(value as string, referenceValue as Array<string>) ? 'GREEN' : 'RED';
        },
        domSelector: '#h1pw'
    },
    keywordInH1: {
        getStatus: function (value, referenceValue) {
            value = value as Array<string>;
            return value.some((h1h: string) =>
                richContentIncludesPermutation(h1h, referenceValue as Array<string>)
            ) ? 'GREEN' : 'RED';
        },
        domSelector: '#h1'
    },
    keywordInH2: {
        getStatus: function (value, referenceValue) {
            value = value as Array<string>;
            return value.some((h2h: string) =>
                richContentIncludesPermutation(h2h, referenceValue as Array<string>)
            ) ? 'GREEN' : 'RED';
        },
        domSelector: '#h2'
    },
    keywordInH3: {
        getStatus: function (value, referenceValue) {
            value = value as Array<string>;
            return value.some((h3h: string) =>
                richContentIncludesPermutation(h3h, referenceValue as Array<string>)
            ) ? 'GREEN' : 'RED';
        },
        domSelector: '#h3'
    },
    relatedKeywords: {
        getStatus: function (value) {
            return (value as number) > 0.5 ? 'GREEN' : 'RED';
        },
        getTrParams: function (value) {
            return [Math.round(value as number * 100) + '%'];
        },
        getClickAction: () => {
            EventBus.$emit('keywords-selection-expand');
            setTimeout(() => {
                scrollIntoViewIfNotVisible('#related');
            }, 300);
        },
    },
    inboundLinks: {
        getStatus: function (value) {
            return (value as number) >= 1 ? 'GREEN' : 'RED';
        }
    },
    outboundLinks: {
        getStatus: function (value) {
            return (value as number) >= 1 ? 'GREEN' : 'RED';
        },
        domSelector: '#recommendation',
    },
    inspirationFromTopTen: {
        getStatus: function (value) {
            return value ? 'GREEN' : 'RED';
        },
        getClickAction: () => {
            EventBus.$emit('h1-inspiration-expand');
            setTimeout(() => {
                scrollIntoViewIfNotVisible('#h1');
            }, 300);
        },
    },
    subHeadingDistribution: {
        getStatus: function (value, referenceValue) {
            return value === referenceValue ? 'GREEN' : 'RED';
        }
    },
    paragraphLength: {
        getStatus: function (shortParagraphRatio) {
            shortParagraphRatio = shortParagraphRatio as number;
            return shortParagraphRatio > 0.99 ? 'GREEN' : shortParagraphRatio > 0.5 ? 'YELLOW' : 'RED';
        },
        getTrParams: function (value) {
            return [Math.round(value as number * 100) + '%'];
        },
    },
    sentenceLength: {
        getStatus: function (avgSentenceWordCount) {
            avgSentenceWordCount = avgSentenceWordCount as number;
            return avgSentenceWordCount < 20 ? 'GREEN' : avgSentenceWordCount < 25 ? 'YELLOW' : 'RED';
        },
        getTrParams: function (value) {
            return [value, 20, 25];
        },
    }
};
