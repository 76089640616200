export class User {
    constructor(uid: string, name: string, email: string) {
        this.uid = uid;
        this.name = name;
        this.email = email;
    }

    uid: string;
    name: string;
    email: string;
    trialUsed?: boolean;
    theme?: boolean;
    articlesCreatedThisPeriod?: number;
    extraInspirations?: number;
}

export interface Plan {
    id: string,
    name: string,
    metadata?: Record<string, string>,
    monthly_credit: number,
    period_end: Date,
    cancel_at_period_end: boolean
}
