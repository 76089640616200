<template>
  <UserLayout :article-step="0">
    <div
      class="d-flex flex-row align-center justify-center flex-wrap"
    >
      <v-card
        v-for="route in crossroadRoutes"
        :key="route.header"
        class="d-flex flex-column align-center primaryBorder my-6 ma-3 pa-10 flex-grow-1 routeCard"
        max-width="800"
        style="flex: 1"
      >
        <v-card-title class="word-break-normal pb-0">
          <h1>
            {{ route.header }}
          </h1>
        </v-card-title>
        <v-card-title>
          {{ route.description }}
        </v-card-title>
        <v-card-actions>
          <v-btn
            depressed
            color="accent"
            @click="openRoute(route.route)"
          >
            {{ route.button }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </UserLayout>
</template>

<script lang="ts">
    import Vue from 'vue';
    import UserLayout from "@/layouts/UserLayout.vue";

    interface CrossroadRoute {
        header: string;
        description: string;
        button: string;
        route: string;
    }

    export default Vue.extend({
        name: "ArticleCrossroadView",
        components: {UserLayout},
        computed: {
            crossroadRoutes: function(): CrossroadRoute[] {
                return [
                    {
                        header: this.$t('articleCrossroad.newContent.header') as string,
                        description: this.$t('articleCrossroad.newContent.description') as string,
                        button: this.$t('articleCrossroad.newContent.button') as string,
                        route: 'CreateArticle'
                    },
                    {
                        header: this.$t('articleCrossroad.existingContent.header') as string,
                        description: this.$t('articleCrossroad.existingContent.description') as string,
                        button: this.$t('articleCrossroad.existingContent.button') as string,
                        route: 'AnalyzeArticle'
                    }
                ];
            }
        },
        methods: {
            openRoute: function (name: string) {
                this.$router.push( {name: name });
            }
        }
    });
</script>

<style scoped lang="sass">
.routeCard
    min-width: 80%
    h1
        font-size: 1.5em

    @media (min-width: 600px)
        min-width: 550px

        h1
            font-size: 2em

</style>
