<template>
  <div class="d-flex flex-column fill-height">
    <UserLayout>
      <div v-if="$store.getters.authenticated">
        <ArticleTable v-if="hasPlanOrExtras || $store.getters.user.trialUsed" />
        <SubscriptionSelection v-if="!$store.getters.hasPlan" />
      </div>
      <v-progress-linear
        v-else
        color="accent"
        indeterminate
      />
    </UserLayout>
  </div>
</template>

<script lang="ts">
    import Vue from 'vue';
    import UserLayout from '@/layouts/UserLayout.vue';
    import SubscriptionSelection from "@/components/subscriptions/SubscriptionSelection.vue";
    import UserPlanMixin from "@/mixins/UserPlanMixin";
    import ArticleTable from "@/components/article/table/ArticleTable.vue";

    export default Vue.extend({
        name: 'HomeView',
        components: {ArticleTable, SubscriptionSelection, UserLayout},
        mixins: [UserPlanMixin],
    });
</script>

<style scoped lang="sass">
.loading-container
    position: absolute
    height: 90vh
    width: 100%

.loading-circle
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)

</style>
