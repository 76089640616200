import VueRouter from 'vue-router';
import Vue, {Component} from 'vue';
import HomeView from "@/views/HomeView.vue";
import CreateArticleView from "@/views/CreateArticleView.vue";
import ArticleView from "@/views/ArticleView.vue";
import AccountView from "@/views/AccountView.vue";
import ArticleCrossroadView from "@/views/ArticleCrossroadView.vue";

Vue.use(VueRouter);

// route level code-splitting
// this generates a separate chunk ([name].[hash].js) for this route
// which is lazy-loaded when the route is visited.
// const getView = (name: string) => () => import(`@/views/${name}.vue`) as Component;

interface Route {
    path: string,
    name: string,
    component: Component,
    props?: object
}

const routes: Array<Route> = [
    {
        path: '/',
        name: 'Home',
        component: HomeView
    }, {
        path: '/account',
        name: 'Account',
        component: AccountView
    }, {
        path: '/createArticle',
        name: 'CreateArticle',
        component: CreateArticleView
    }, {
        path: '/article/:articleId',
        name: 'Article',
        component: ArticleView
    }, {
        path: '/analyzeArticle',
        name: 'AnalyzeArticle',
        component: CreateArticleView,
        props: { isAnalyze: true }
    }, {
        path: '/articleCrossroad',
        name: 'ArticleCrossroadView',
        component: ArticleCrossroadView,
    }, {
        path: '/:pathMatch(.*)*',
        //@ts-ignore
        redirect: { name: 'Home' }
    }
];

// Enable for route-level code-split
// for (const route of routes) {
//     route.component = getView(route.name);
// }

const Router = new VueRouter({
    mode: 'history',
    routes
});

export {Router};
