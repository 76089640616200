<template>
    <v-card
        :id="keywordListId"
        flat
        :class="{'pie-card' : !$vuetify.theme.dark}"
    >
        <v-simple-table>
            <thead class="primaryGradientBackground whiteText">
            <tr>
                <th class="py-5">
                    {{ $t('keywords.list.header.title') + ' - ' }}
                    <span class="font-weight-bold text-subtitle-1">
              {{ $t(KeywordTypeLangPaths[keywordType] + 'title') }}
            </span>
                </th>
                <th
                    v-if="!readOnly"
                    class="text-end text-wrap-balance"
                >
                    {{ $t('keywords.list.header.chooseKeyword') }}
                    <v-tooltip bottom>
                        <template #activator="{ on }">
                            <v-icon
                                small
                                color="tooltipIcon"
                                v-on="on"
                            >
                                info_outlined
                            </v-icon>
                        </template>
                        {{ $t('keywords.list.header.chooseKeywordHint') }}
                    </v-tooltip>
                </th>
                <th
                    id="keywordDifficulty"
                    class="text-end"
                >
                    {{ $t('keywords.list.header.keywordDifficulty') }}
                    <v-tooltip bottom>
                        <template #activator="{ on }">
                            <v-icon
                                small
                                color="tooltipIcon"
                                v-on="on"
                            >
                                info_outlined
                            </v-icon>
                        </template>
                        {{ $t('keywords.list.header.knownKeywordDifficulty') }}
                    </v-tooltip>
                </th>
                <th class="text-end">
                    <span>{{ $t('keywords.list.header.averageSearches') }}</span>
                </th>
                <th
                    class="align-end"
                >
                    {{ $t('keywords.list.header.chooseForAi') }}
                    <v-tooltip bottom>
                        <template #activator="{ on }">
                            <v-icon
                                small
                                color="tooltipIcon"
                                v-on="on"
                            >
                                info_outlined
                            </v-icon>
                        </template>
                        {{ $t('keywords.list.header.chooseForAiHint') }}
                    </v-tooltip>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="(keyword, index) in keywordsOrdered"
                :key="keywordType + index"
                :class="{'selected-keyword': keyword.keyword === article.selectedKeyword}"
                @click="handleRowClick(keyword.keyword)"
            >
                <td>
                    {{ keyword.keyword }}
                    <v-icon
                        v-if="keyword.keyword === article.selectedKeyword"
                        class="ml-1 selected-keyword-check"
                        size="21"
                        color="selectedKeywordIcon"
                    >
                        check_circle
                    </v-icon>
                </td>
                <td v-if="!readOnly">
                    <div class="d-flex justify-end keyword-change">
                        <v-tooltip bottom>
                            <template #activator="{ on }">
                                <v-btn
                                    v-show="(keyword.keyword !== article.keyword || loading) && keyword.keyword === currentSelectedKeyword"
                                    v-on="on"
                                    color="accent"
                                    small
                                    depressed
                                    :loading="loading"
                                    class="multi-line-button"
                                    max-width="8em"
                                    @click="$emit('change-main-keyword', keyword.keyword)"
                                >
                                    {{ $t('keywords.list.chooseNewKeyword') }}
                                </v-btn>
                            </template>
                            {{ $t('keywords.list.chooseNewKeywordHint') }}
                        </v-tooltip>
                        <div class="d-flex justify-center">
                            <v-radio
                                class="ml-4"
                                :value="keyword.keyword"
                            />
                        </div>
                    </div>
                </td>
                <td class="text-end">
            <span
                v-if="keywordDifficulty(keyword)"
            >
              {{ keywordDifficulty(keyword) }}
            </span>
                    <v-tooltip
                        v-else
                        top
                    >
                        <template #activator="{ on }">
                            <v-icon
                                :class="{
                    'dark-icon' : $vuetify.theme.dark && keyword.keyword === article.selectedKeyword,
                    'text--disabled' : keyword.keyword !== article.selectedKeyword,
                    'light-icon' : !$vuetify.theme.dark && keyword.keyword === article.selectedKeyword
                  }"
                                small
                                v-on="on"
                            >
                                block
                            </v-icon>
                        </template>
                        {{ $t('keywords.list.header.unknownKeywordDifficulty') }}
                    </v-tooltip>
                </td>
                <td class="text-end">
                    <div class="d-flex justify-end">
                        {{ averageSearches(keyword.keyword_info) }}
                    </div>
                </td>
                <td>
                    <div class="d-flex justify-center">
                        <v-checkbox
                            v-model="selectedForAiLocal"
                            :class="keyword.keyword === article.selectedKeyword ? 'inverted-checkbox' : ''"
                            :value-comparator="compareAiKeywords"
                            :value="keyword.keyword"
                            :disabled="readOnly"
                            @click.stop=""
                        />
                    </div>
                </td>
            </tr>
            <tr v-if="!keywords || keywords.length === 0">
                <td
                    class="pa-6"
                    colspan="6"
                >
                    {{ $t('keywords.list.empty') }}
                </td>
            </tr>
            </tbody>
        </v-simple-table>
    </v-card>
</template>

<script lang="ts">
    import Vue, {PropType} from 'vue';
    import {KeywordTypeLangPaths} from '@/enum/keyword_type';
    import {Article, KeywordForAi} from "@/model/Article";
    import {Firestore} from "@/plugins/Firestore";
    import {FlashMessage} from "@/model/FlashMessage";

    export default Vue.extend({
        name: 'KeywordsList',
        props: {
            keywordType: {
                type: Number,
                default: 0
            },
            keywords: {
                type: Array as PropType<Record<string, any>>,
                default: () => []
            },
            readOnly: {
                type: Boolean,
                default: false
            },
            article: {
                type: Article,
                default: new Article
            },
            currentSelectedKeyword: {
                type: String,
                default: null
            },
            selectedForAi: {
                type: Array as PropType<KeywordForAi[]>,
                default: () => []
            }
        },
        data: () => ({
            KeywordTypeLangPaths: KeywordTypeLangPaths,
            loading: false,
            selectedForAiLocal: [] as (KeywordForAi | string)[]
        }),
        computed: {
            keywordListId(): string {
                return KeywordTypeLangPaths[this.keywordType].split('.')[2];
            },
            keywordsOrdered(): Array<any> {
                const clone = this.keywords?.slice();
                if (clone) {
                    return clone.sort((a: any, b: any) => {
                        if (a.keyword_info && b.keyword_info) {
                            return +this.averageSearches(b.keyword_info) - +this.averageSearches(a.keyword_info);
                        } else if (a.serp_info) {
                            return 1;
                        } else {
                            return -1;
                        }
                    });
                }
                return [];
            }
        },
        watch: {
            selectedForAi: {
                immediate: true,
                handler: function () {
                    this.selectedForAiLocal = this.selectedForAi;
                }
            },
            selectedForAiLocal: function () {
                for (let idx = 0; idx < this.selectedForAiLocal.length; idx++) {
                    const type = (<KeywordForAi>this.selectedForAiLocal[idx]).type;
                    if (type === undefined) {
                        this.selectedForAiLocal[idx] = {
                            keyword: this.selectedForAiLocal[idx] as string,
                            type: this.keywordType
                        };
                    }
                }
                this.$emit('update:selectedForAi', this.selectedForAiLocal);
            }
        },
        deactivated: function () {
            // keep loading until user leaves current page
            this.loading = false;
        },
        methods: {
            averageSearches: function (keywordInfo: any): string {
                return Math.round(keywordInfo.monthly_searches
                    .map(({search_volume}: any) => search_volume)
                    .reduce((a: number, b: number) => a + b, 0) / keywordInfo.monthly_searches?.length).toLocaleString(this.$i18n.locale);
            },
            keywordDifficulty: function (keyword: Record<string, any>): string | null {
                if (keyword.keyword_properties) {
                    return keyword.keyword_properties.keyword_difficulty || null;
                } else {
                    return null;
                }
            },
            handleRowClick: function (keyword: string): void {
                this.$emit('update:currentSelectedKeyword', keyword);
            },
            compareAiKeywords: function (aiKeyword: KeywordForAi, compareTo: string): boolean {
                return aiKeyword.keyword === compareTo;
            }
        }
    });
</script>

<style scoped lang="sass">
.whiteText tr > th
    color: white !important

th
    border-bottom: none !important
    padding: 1em !important

.selected-keyword
    background: var(--v-selectedKeyword-base)
    color: var(--v-selectedKeywordColor-base)

    .selected-keyword-check
        transition: none

    &:hover
        color: var(--v-selectedKeywordColor-base) !important
        background: var(--v-selectedKeywordHover-base) !important

.light-icon
    color: white !important

.dark-icon
    color: black !important

</style>

<style lang="sass">
.inverted-checkbox .theme--dark.v-icon,
.inverted-checkbox .theme--light.v-icon
    color: var(--v-selectedKeywordColor-base)

.selected-keyword
    .v-input--selection-controls__input
        color: var(--v-selectedKeywordColor-base)

.v-input--selection-controls__input
    color: var(--v-selectedKeyword-base)
</style>
