import Vue from "vue";
import VuetifyLib from "vuetify/lib";
import cs from "vuetify/src/locale/cs";
import en from "vuetify/src/locale/en";
import MaterialIcon from "@/components/MaterialIcon.component.vue";

Vue.use(VuetifyLib);

const commonColors = {
    primary: '#0887dc',
    primary0: '#0887dc',
    primary1: '#0887dc',
    primary2: '#319BE2',
    primary3: '#51AAE6',
    primary4: '#81C2ED',
    accent: '#92d051',
    warning: '#fb8c00',
    error: '#FF5C93',
    markerYellow: '#f7ff00',
    markerGreen: '#00ffb2',
    markerBlue: '#00ffff',
    markerPink: '#ff0056',
    ai: '#cd7ec2',
    ai1: '#92d051',
    ai2: '#0887dc',
    ai3: '#FF5C93',
    tooltipIcon: '#ffffff',
};

const themes = {
    light: {
        ...commonColors,
        greyTint: '#8C8C8C',
        greyTintBtn: '#757575',
        background: '#f6f7ff',
        selectedKeyword: '#333333',
        selectedKeywordHover: '#444444',
        selectedKeywordColor: '#ffffff',
        selectedKeywordIcon: '#ffffff',
        progressBackground: '#bdbdbd',
    },
    dark: {
        ...commonColors,
        greyTint: '#9b9b9b',
        greyTintBtn: '#b9b9b9',
        background: '#121212',
        selectedKeyword: '#ffffff',
        selectedKeywordHover: '#eeeeee',
        selectedKeywordColor: '#000000',
        selectedKeywordIcon: '#333333',
        progressBackground: '#555659',
    }
};

function missingMaterialIcons(ids: string[]) {
    const icons = {};
    for (const id of ids) {
        for (const suffix of ['fill', 'outline', 'two-tone', 'round', 'sharp']) {
            const name = `${id}_${suffix}`;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            icons[name] = {
                component: MaterialIcon,
                props: {
                    name
                }
            };
        }
    }
    return icons;
}

// TODO (when back on Vue(tify) 3: use `createVueI18nAdapter`, see https://next.vuetifyjs.com/en/features/internationalization/#vue-i18n
const Vuetify = new VuetifyLib({
    icons: {
        iconfont: 'md',
        values: {
            ...missingMaterialIcons(['info'])
        }
    },
    lang: {
        locales: {en, cs},
        current: 'en'
    },
    theme: {
        options: {
            customProperties: true,
        },
        themes
    }
});

export {Vuetify, themes};
