<template>
  <div class="d-flex flex-column align-center">
    <span
      v-for="(group, index1) of features"
      :key="index1"
      class="d-flex flex-wrap justify-center"
    >
      <v-chip
        v-for="(feature, index2) of group"
        :key="index1 + '-' + index2"
        x-large
        label
        outlined
        color="primary"
        class="ma-2"
      >
        <v-icon
          x-large
          color="accent"
          class="mr-2"
        >
          check
        </v-icon>
        {{ $t('subscriptions.features.' + feature) }}
      </v-chip>
    </span>
  </div>
</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        name: "FeatureList",
        data: () => ({
            features: [
                ['aiWriting', 'seoScore'],
                ['instructions', 'export'],
                ['chatGpt', 'keywordAnalysis', 'inspiration']
            ]
        })
    });
</script>


<style scoped lang="sass">

</style>
