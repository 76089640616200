import { render, staticRenderFns } from "./NewContentDialog.vue?vue&type=template&id=33d4574a&scoped=true"
import script from "./NewContentDialog.vue?vue&type=script&lang=ts"
export * from "./NewContentDialog.vue?vue&type=script&lang=ts"
import style0 from "./NewContentDialog.vue?vue&type=style&index=0&id=33d4574a&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.7_css-loader@6.9.0_vue-template-compiler@2.7.16_webpack@5.89.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33d4574a",
  null
  
)

export default component.exports