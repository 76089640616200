<template>
  <div>
    <div
      class="mt-2 mx-1 pointer"
      @click="showDetails = !showDetails"
    >
      <SeoScoreProgressBar
        :small="showDetails"
        :green="stats.GREEN / seoScoreMetricNames.length * 100"
        :yellow="(stats.GREEN + stats.YELLOW) / seoScoreMetricNames.length * 100"
      >
        <template v-if="showDetails">
          {{ $t('seoScore.score') }}:<strong>{{ seoScore }}%</strong>
        </template>
        <div
          v-else
          class="d-flex flex-column align-center"
        >
          <div class="text-h4">
            {{ seoScore }}%
          </div>
          <div class="mt-2">
            {{ $t('seoScore.score') }}
          </div>
        </div>
      </SeoScoreProgressBar>
      <v-tooltip
        bottom
      >
        <template #activator="{ on }">
          <div
            style="width:100%;"
            class="d-flex justify-center align-end"
            v-on="on"
          >
            <v-icon>{{ showDetails ? 'expand_less' : 'expand_more' }}</v-icon>
          </div>
        </template>
        {{ $t('seoScore.showDetails') }}
      </v-tooltip>
    </div>
    <v-expand-transition>
      <v-list v-show="showDetails">
        <template v-if="demo">
          <v-list-item
            v-for="metricName of seoScoreMetricNames"
            :key="metricName"
            class="mouse-cursor v-list-item--superdense"
          >
            <SeoScoreIcon status="GREEN" />
            <span class="ml-2 text-caption">{{ $t('seoScore.' + metricName + '.label') }}</span>
          </v-list-item>
        </template>
        <template v-else>
          <v-tooltip
            v-for="metricName of seoScoreMetricNames"
            :key="metricName"
            right
          >
            <template #activator="{ on }">
              <v-list-item
                class="v-list-item--superdense"
                :class="{
                  'mouse-cursor': getClickAction(metricName) === undefined
                }"
                v-on="on"
                @click="getClickAction(metricName)?.()"
              >
                <SeoScoreIcon :status="getStatus(metricName)" />
                <span class="ml-2 text-caption">{{ $t('seoScore.' + metricName + '.label') }}</span>
              </v-list-item>
            </template>
            <div
              v-if="$te('seoScore.' + metricName + '.description')"
              class="font-weight-bold mb-2"
            >
              {{ $t('seoScore.' + metricName + '.description', getTrParams(metricName)) }}
            </div>
            <div>
              <SeoScoreIcon :status="getStatus(metricName)" />
              {{
                $t('seoScore.' + metricName + '.' + getStatus(metricName).toLowerCase(), getTrParams(metricName))
              }}
            </div>
          </v-tooltip>
        </template>
      </v-list>
    </v-expand-transition>
  </div>
</template>

<script lang="ts">
    import Vue, {PropType} from "vue";
    import {
        SeoMetricValue,
        seoScoreContext,
        SeoScoreContext,
        SeoScoreMetricName,
        seoScoreMetricNames,
        seoScoreStatuses,
        SeoScoreStatus
    } from "@/model/SeoScore";
    import SeoScoreIcon from "@/components/seoscore/SeoScoreIcon.vue";
    import SeoScoreProgressBar from "@/components/SeoScoreProgressBar.vue";
    import {ArticleMetasConfig} from "@/config/ArticleConfig";
    import {keywordOccurrences} from "@/utils/ArticleComputed";
    import {Article, ArticlePart, ArticlePartContent, Keyword, KeywordForAi} from "@/model/Article";
    import {KeywordType} from "@/enum/keyword_type";
    import {permute, removeDiacritics} from "@/utils/string";
    import {getContentToExport} from "@/service/articleExport";
    import {DownloadType} from "@/enum/download_type";
    import {seoScoreMetrics} from "@/plugins/SeoScore";
    import {Scroll} from "@/config/Behaviour";
    import {FlashMessage} from "@/model/FlashMessage";
    import {Firestore} from "@/plugins/Firestore";
    import {EventBus} from "@/utils/EventBus";
    import {Image} from "@/model/Scrapper";

    export default Vue.extend({
        name: "SeoScore",
        components: {SeoScoreProgressBar, SeoScoreIcon},
        props: {
            context: {
                type: Object as PropType<SeoScoreContext>,
                default: () => seoScoreContext
            },
            demo: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            showDetails: false,
            seoScoreStatuses: seoScoreStatuses,
            seoScoreMetrics: seoScoreMetrics,
            seoScoreMetricNames: seoScoreMetricNames,
        }),
        computed: {
            forceZeroScore: function (): boolean {
                return this.$store.getters.articleWordCount.getSum() === 0
                    || this.$store.getters.articleAiLoading.loadingRatioTotal > 0.9;
            },
            stats: function (): Record<SeoScoreStatus, number> {
                if (this.demo) {
                    return {
                        GREEN: seoScoreMetricNames.length,
                        YELLOW: 0,
                        RED: 0,
                    };
                }
                if (this.forceZeroScore) {
                    return {
                        GREEN: 0,
                        YELLOW: 0,
                        RED: seoScoreMetricNames.length,
                    };
                } else {
                    const results = seoScoreMetricNames
                        .map((metricName: SeoScoreMetricName) => this.getStatus(metricName));
                    return {
                        GREEN: results.filter(status => status === 'GREEN').length,
                        YELLOW: results.filter(status => status === 'YELLOW').length,
                        RED: results.filter(status => status === 'RED').length,
                    };
                }
            },
            seoScore: function (): number {
                return Math.round(((this.stats.GREEN + (this.stats.YELLOW / 2)) / seoScoreMetricNames.length) * 100);
            },
            checkedKeywords: function (): Array<string> {
                return this.$store.getters.article.allKeywords
                    .filter((keyword: Keyword) => keyword.keyword.trim().length)
                    .filter((keyword: Keyword) => keyword.checked)
                    .map((keyword: Keyword) => keyword.keyword);
            },
            // Seo score metrics
            // @src https://docs.google.com/spreadsheets/d/1OqESKAo-okjUFg916kevKUlAl67lA8ih4TZnAbcl_gw/edit#gid=0
            keywordVariants: function (): Array<SeoMetricValue> {
                return [this.$store.getters.article.allKeywords
                    .filter((keyword: Keyword) => keyword.keyword.trim().length)
                    .length];
            },
            keywordLength: function (): Array<SeoMetricValue> {
                return [this.$store.getters.article.selectedKeyword.split(' ').length];
            },
            keywordInSlug: function (): Array<SeoMetricValue> {
                const slug = this.$store.getters.article.url.split('/').pop() || '';
                const keyword = removeDiacritics(this.$store.getters.article.selectedKeyword.split(' ').join('-'));
                return [slug.includes(keyword), keyword];
            },
            imgAltIncludesKeyword: function (): Array<SeoMetricValue> {
                const keyword = this.$store.getters.article.selectedKeyword;
                const alts = this.$store.getters.article.images.map((image: Image) => image.alt).map((alt: string) => alt.toLowerCase());
                return [alts.some((alt: string) => alt.includes(keyword)), keyword];
            },
            titleIncludesKeyword: function (): Array<SeoMetricValue> {
                return [this.$store.getters.article.metaTitle,
                        this.checkedKeywords];
            },
            titleLength: function (): Array<SeoMetricValue> {
                return [this.$store.getters.article.metaTitle.length,
                        ArticleMetasConfig.metaTitle.suggestedLength];
            },
            descriptionIncludesKeyword: function (): Array<SeoMetricValue> {
                return [this.$store.getters.article.metaDescription,
                        this.checkedKeywords];
            },
            descriptionLength: function (): Array<SeoMetricValue> {
                return [this.$store.getters.article.metaDescription.length,
                        ArticleMetasConfig.metaDescription.suggestedLength];
            },
            keywordDensity: function (): Array<SeoMetricValue> {
                return [this.context.articleDensity];
            },
            serpIsComplete: function (): Array<SeoMetricValue> {
                return [(this.$store.getters.article.metaTitle.length > 0
                    && this.$store.getters.article.metaDescription.length > 0)];
            },
            avgWordCountOfTopTen: function (): Array<SeoMetricValue> {
                return [this.$store.getters.articleWordCount.getSum(),
                        this.context.topTenGeometricAverageWordCount];
            },
            longestWordCountOfTopTen: function (): Array<SeoMetricValue> {
                return [this.$store.getters.articleWordCount.getSum(),
                        this.context.topTenLongestWordCount];
            },
            keywordInIntroduction: function (): Array<SeoMetricValue> {
                return [this.$store.getters.article.h1[0]?.p,
                        this.checkedKeywords];
            },
            keywordInH1: function (): Array<SeoMetricValue> {
                return [this.$store.getters.article.h1.map((h1: ArticlePartContent) => h1.h),
                        this.checkedKeywords];
            },
            keywordInH2: function (): Array<SeoMetricValue> {
                return [this.$store.getters.article.h2.map((h2: ArticlePartContent) => h2.h),
                        this.checkedKeywords];
            },
            keywordInH3: function (): Array<SeoMetricValue> {
                return [this.$store.getters.article.h3.map((h3: ArticlePartContent) => h3.h),
                        this.checkedKeywords];
            },
            relatedKeywords: function (): Array<SeoMetricValue> {
                const selectedRelatedKeywords = this.$store.getters.article.keywordsForAi
                    .filter(((keyword: KeywordForAi) => keyword.type === KeywordType.RELATED))
                    .map((keyword: KeywordForAi) => keyword.keyword);
                // TODO save all related keywords from DataForSeo to this.$store
                const articleContent = getContentToExport(this.$store.getters.article, DownloadType.CLIPBOARD)[1];
                const usedKeywords = selectedRelatedKeywords
                    .filter((keyword: string) => {
                        return permute(keyword).some(variant => keywordOccurrences(articleContent, variant) > 0);
                    });
                return [usedKeywords.length / (selectedRelatedKeywords.length || 1)];
            },
            inboundLinks: function (): Array<SeoMetricValue> {
                const content = getContentToExport(this.$store.getters.article, DownloadType.HTML)[0];
                const relativeLinksLength = (content.match(new RegExp(/(<a.*?href="\/.*?<\/a>)/, 'gi'))
                    || [])
                    // Do not consider internal anchors as links
                    .filter(match => !(match.includes('href="#') || match.includes("href='#")))
                    .length;
                const sameSiteLinksLength = (content.match(new RegExp(/(<a.*?href="\w.*?<\/a>)/, 'gi'))
                    || [])
                    // Do not consider internal anchors as links
                    .filter(match => !(match.includes('href="#') || match.includes("href='#")))
                    // Count only links to the same domain
                    .filter(match => this.homeDomain ? match.includes(this.homeDomain) : true)
                    .length;
                return [relativeLinksLength + sameSiteLinksLength];
            },
            outboundLinks: function (): Array<SeoMetricValue> {
                return [(getContentToExport(this.$store.getters.article, DownloadType.HTML)[0]
                    .match(new RegExp(/(<a.*?href="\w.*?<\/a>)/, 'gi'))
                    || [])
                    // Do not consider internal anchors as links
                    .filter(match => !(match.includes('href="#') || match.includes("href='#")))
                    // Do not count links to the same domain
                    .filter(match => this.homeDomain ? !match.includes(this.homeDomain) : true)
                    .length];
            },
            inspirationFromTopTen: function (): Array<SeoMetricValue> {
                return [true];
            },
            subHeadingDistribution: function (): Array<SeoMetricValue> {
                const total = this.$store.getters.article.getAllParts().length;
                const missing = this.$store.getters.article.getAllParts()
                    .map((part: ArticlePart) => part.value)
                    .map((partContents: ArticlePartContent[]) => {
                        return partContents.filter((partContent: ArticlePartContent) =>
                            partContent.h.length === 0 && partContent.p.length !== 0
                        ).length;
                    })
                    .filter((missingHeadings: number) => missingHeadings > 0)
                    .length;
                return [total - missing, total];
            },
            paragraphLength: function (): Array<SeoMetricValue> {
                const paragraphWordCounts = (
                    getContentToExport(this.$store.getters.article, DownloadType.HTML)[0]
                        .match(new RegExp(/(<p[^>]*>[^>]*<\/p>)/, 'gi'))
                    || []).map((paragraph: string) => paragraph.split(' ').length);
                const shortParagraphs = paragraphWordCounts.filter((words: number) => words < 150);
                return [shortParagraphs.length / (paragraphWordCounts.length || 1)];
            },
            sentenceLength: function (): Array<SeoMetricValue> {
                let sentenceCount = 0;
                let totalWordCount = 0;
                this.$store.getters.article.getAllParts()
                    .map((part: ArticlePart) => part.value)
                    .forEach((partContents: ArticlePartContent[]) => {
                        partContents.map((part: ArticlePartContent) => {
                            totalWordCount += part.p.split(' ').length;
                            sentenceCount += (part.p.match(new RegExp(/[.!?。！？;¿¡]/, 'gi')) || []).length;
                        });
                    });
                return [Math.round((totalWordCount / (sentenceCount || 1)) * 10) / 10];
            },
            // END Seo score metrics
            homeDomain: function (): string | null {
                try {
                    const parsedUrl = new URL(this.$store.getters.article.url);
                    return parsedUrl.host;
                } catch {
                    return null;
                }
            },
            loadingCompleted: function (): boolean {
                return this.$store.getters.articleAiLoading.loadingCompleted;
            }
        },
        watch: {
            seoScore: function (newScore: number, oldScore: number) {
                if (newScore !== oldScore) {
                    this.$store.commit('setArticleSeoScore', newScore);
                }
            },
            loadingCompleted: function (val: boolean) {
                if (val) {
                    Firestore.checkIfSeoScoreHistoryCollectionExists(this.$store.getters.article)
                        .then(exists => {
                            // if SeoScoreHistory collection does not exist - save the whole article
                            if (!exists) {
                                EventBus.$emit('debounce-flush'); // flush all debounced emits to get current data
                                this.$nextTick(() => { // wait for next event cycle for emits to finish
                                    Firestore.updateUserArticleFields(this.$store.getters.article, {seoScore: this.seoScore} as Partial<Article>)
                                        .then(() => {
                                            this.$store.dispatch('setArticleLastSavedSnapshotHash');
                                            this.$store.dispatch('displayFlashMessage', {
                                                content: 'article.saved',
                                                type: 'info'
                                            } as FlashMessage);
                                        })
                                        .catch(error => {
                                            console.error(error);
                                            this.$store.dispatch('displayFlashMessage', {
                                                type: 'error',
                                                content: 'unexpectedError',
                                            } as FlashMessage);
                                        });
                                });
                            }
                        });
                }
            }
        },
        methods: {
            getStatus: function (metricName: SeoScoreMetricName): SeoScoreStatus {
                const result: SeoMetricValue | Array<SeoMetricValue> = this[metricName];
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                return seoScoreMetrics[metricName].getStatus(...result);
            },
            getTrParams: function (metricName: SeoScoreMetricName): Array<SeoMetricValue | undefined> {
                const values: SeoMetricValue | Array<SeoMetricValue> = this[metricName];
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                return seoScoreMetrics[metricName].getTrParams?.(...values) || values;
            },
            getClickAction: function (metricName: SeoScoreMetricName): (() => void) | undefined {
                const action = seoScoreMetrics[metricName].getClickAction;
                if (action) {
                    return action;
                }
                const selector = seoScoreMetrics[metricName].domSelector;
                if (selector) {
                    return () => this.$vuetify.goTo(selector, {
                        duration: Scroll.duration,
                        offset: Scroll.offset
                    });
                }
            }
        }
    });
</script>

<style scoped lang="sass">
.text-in-tooltip--disabled
    color: #cbcbcb
    font-size: 0.8rem
</style>
