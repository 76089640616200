const LocalStorage = {
    getTheme (): boolean {
        return localStorage.theme === "true";
    },
    setTheme (value: boolean): void {
        localStorage.theme = value;
    }
};

export { LocalStorage };
